import React, {useEffect, useState} from "react";
import Layout from "../Layout";
import {deleteProduct, getAllProducts} from "./AdminApi";
import {isAuthenticated} from "../../auth";
import {Link} from "react-router-dom";
import UpdateProduct from "./UpdateProduct";
import AdminRoute from "../../auth/AdminRoute";

const ManageProducts = () => {

    const [products, setProducts] = useState([]);
    const [open, setOpen] = useState([]);
    const {user, token} = isAuthenticated();

    const toggleOpen = (pid) => {
        if(open.includes(pid)) {
            setOpen(open.filter(id => id !== pid))
        } else {
            let newOpen = [...open];
            newOpen.push(pid);
            setOpen(newOpen)
        }
    };

    const loadProducts = () => {
        getAllProducts()
            .then(data => {
            if(data.error) {
                console.log(data.error);
            } else {
                setProducts(data);
            }
        })
    };

    const removeProduct = (productId) => {
        deleteProduct(productId, user._id, token)
            .then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    loadProducts();
                }
            });
    };

    const showProductList = (p,i) => (
        <div key={i}>
            <div className="pdt" key={i}>

                <strong>{p.name}</strong>
                <span>
                    <span className="" onClick={() => toggleOpen(p._id)}>Edit</span>
                    &emsp;
                    <span className="" onClick={() => removeProduct(p._id)}>Delete</span>
                </span>
            </div>

            {open.includes(p._id) &&
                <UpdateProduct productId={p._id} />

            }
        </div>
    );


    useEffect(() => {
        loadProducts();
    }, []);

    return(
        <div className="ManageProducts">
            <div className="products-list">
                <h3>Total: {products.length} products</h3>
                {products && products.map((p,i) => (
                    showProductList(p,i)
                ))}

            </div>
        </div>
    )
};

export default ManageProducts