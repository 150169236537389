import React from 'react';
import './WorkshopPage.scss';
import {phoneCheck} from "./helpers/browserCheck";

const WorkshopPage = () => {
    return(
        <div className={phoneCheck() ? "WorkshopPageMobile" : "WorkshopPage"}>
            <h1>Rainbow of Gratitude</h1>

            <p>A 30-day workshop on color therapy, affirmations and self appreciation. Rewire your brain, balance your chakras and make your way to a Magical Life, with blessings of Ganesha.</p>
            <p>To join the Whatsapp group, please click on the link for your preferred language.</p>


            <div className="text-container">
                <a href="https://chat.whatsapp.com/GbRMWLxLcgZ0xpVaVjoIEL" target="_blank">English</a>
                <a href="https://chat.whatsapp.com/C0qQX6i9DDkHAVprmQHmIU" target="_blank">हिन्दी</a>
                <a href="https://chat.whatsapp.com/Bq1s6qfs3bSG5CGB8qZG0L" target="_blank">मराठी</a>
                <a href="https://chat.whatsapp.com/ENuhabs3x3X2MtzkPVefAG" target="_blank">ગુજરાતી</a>
            </div>

        </div>
    )
};

export default WorkshopPage;


