import React, {useEffect, useState} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './Courses.scss';
import {Link, useHistory} from "react-router-dom";
import DisplayImage from "./DisplayImage";
import {check, iPadCheck, phoneCheck} from "./helpers/browserCheck";
import {testimonials} from "./testimonial-carousel/testimonials";
import Carousel from "./testimonial-carousel/Carousel";
import {info} from './coursesInfo';
import grandmasters from "../assets/About/grandmasters1.jpg";
import lakshmi from "../assets/About/lakshmi.JPG";
import sadhana from "../assets/About/sadhana.JPG";

const Courses = () => {

    const [productsForSelectedCategory, setProductsForSelectedCategory] = useState([]);
    const [products, setProducts] = useState([]);
    const browser_history = useHistory();

    const isActive = (history, path) => {
        if(history.location.pathname === path) {
            return { color: '#9a0000' }
        } else {
            return {  color: '#000' }
        }
    };

    const init = () => {
        allProducts();
    };

    const allProducts = () => {
        let arr=[];
        info.categories.map((c,i) => {
            c.courses.map((co,ix) => {
                arr.push(co);
            });
            setProducts(arr);
        });
        setProductsForSelectedCategory([])
    };

    const handleOnClick = (catId) => {
        {info.categories.filter(c =>
            c.catId === catId
        ).map(filteredC => {
            setProductsForSelectedCategory(filteredC.courses)
        }
        )}
        setProducts([]);
    };

    useEffect(() => {
        localStorage.setItem("products-for-category", JSON.stringify(productsForSelectedCategory));
        // window.scrollTo(0, 0);
    });

    useEffect(() => {
        init();
        const data = localStorage.getItem("products-for-category");
        if(data) {
            setProductsForSelectedCategory(JSON.parse(data))
        }
    },[]);

    const checkForDuplicates = (arr) => {
        // https://stackoverflow.com/questions/39885893/how-to-find-duplicate-values-in-a-javascript-array-of-objects-and-output-only-u
        let occ ={};
        return arr.filter(function(x) {
            if (occ[x.name]) return false;
            occ[x.name] = true;
            return true;
        })
    };

    const aboutReiki = () => (
        <div className={check() ? "about-reiki mobile" : "about-reiki"}>

            <h5 className="reiki-title">REIKI</h5>
            <p>It comes from the Japanese words <span className="cursive">'rei'</span>: universal and <span className="cursive">'ki'</span>: life energy</p>
            <p>Mikao Usui was the creator of this spiritual practice in the 1800s. Today, it is used over the world as an alternative therapy for the treatment of physical, emotional, and mental health problems. According to the inscription on his memorial stone, Usui taught Reiki to over 2,000 people during his lifetime.</p>
            <p>With Reiki attunement, you have invited peace, joy, gratitude and healing into your life. With the Universal Life Force, you will begin to see magical changes in everything, from your attitude to your actions.</p>

            <p>
                The five principles of Reiki teach you to love yourself, understand yourself and your needs, your weaknesses and your strengths. It teaches you to master your thoughts and make choices fearlessly. It is basically a self-study course.
            </p>

            <div>
                When used with the right intention, Reiki benefits us in many ways by
                <ul>
                    <li>enabling relaxation</li>
                    <li>reducing physical, mental and emotional pain and distress</li>
                    <li>reducing symptoms of illness</li>
                    <li>helping you tackle anxiety and depression</li>
                    <li>giving you mental peace and calm</li>
                    <li>helping you live a life of gratitude</li>
                    <li>showing you the true meaning of happiness</li>
                    <li>helps you live your life to its fullest potential</li>
                    <li>showing you how to make the right choices</li>
                    <li>helping us understand the life that you want!</li>
                </ul>
            </div>
            <p>Your understanding and application of the Reiki principles changes as you advance to the next levels.</p>
            <p>Let us help you embark on a beautiful journey towards happiness, joy and enlightenment</p>
        </div>
    );

    const intro = () => (
        <div className="reiki-intro">
            <div className="reiki-intro-container">
                <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651482688/HealingAndWellness/dpk9wxugqeuesroztnek.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651313689/HealingAndWellness/rudnnsgdzge9gkhudq6h.jpg"}
                     className="reiki-intro-img" />
                <div className={phoneCheck() ? "text-container-mobile" : iPadCheck() ? "text-container-tablet" : "text-container"}>
                    <div className="intro-reiki-title">HEALING & WELLNESS</div>
                    <p className="intro-reiki-text">Everyone can and should live a better life. At Dishaa, we offer a range of healing modalities to improve one’s quality of life and mental, spiritual and emotional well-being.</p>
                </div>
            </div>

            {phoneCheck() ? <div className="healing-modalities-mobile">
                <div className="title">HEALING MODALITIES</div>
                <div className="healing-modalities-lists">
                    <ul>
                        <li>Reiki Level 1-2</li>
                        <li>Advanced Reiki Therapy (ART)</li>
                        <li>Crystal Therapy</li>
                        <li>Tarot Card Readings</li>
                        <li>Distance Healing</li>
                        <li>Chakra Balancing</li>
                        <li>Pendulum dowsing</li>
                        <li>Ho'oponopono Chanting</li>
                        <li>Neuro-Linguistic Programming technique (NLP)</li>
                    </ul>
                </div>
            </div>
                :
                iPadCheck() ?
                    <div className="healing-modalities-mobile">
                        <div className="title">HEALING MODALITIES</div>
                        <div className="healing-modalities-lists">
                            <ul>
                                <li>Reiki Level 1-2</li>
                                <li>Advanced Reiki Therapy (ART)</li>
                                <li>Crystal Therapy</li>
                                <li>Tarot Card Readings</li>
                                <li>Distance Healing</li>
                            </ul>
                            <ul>
                                <li>Chakra Balancing</li>
                                <li>Pendulum dowsing</li>
                                <li>Ho'oponopono Chanting</li>
                                <li>Neuro-Linguistic Programming <br/> technique (NLP)</li>
                            </ul>
                        </div>
                    </div>
                    :
                    <div className="healing-modalities">
                        <div className="title">HEALING MODALITIES</div>
                            <div className="healing-modalities-lists">
                            <ul>
                                <li>Reiki Level 1-2</li>
                                <li>Advanced Reiki Therapy (ART)</li>
                                <li>Crystal Therapy</li>
                            </ul>
                            <ul>
                                <li>Tarot Card Readings</li>
                                <li>Distance Healing</li>
                                <li>Chakra Balancing</li>
                            </ul>
                            <ul>
                                <li>Pendulum dowsing</li>
                                <li>Ho'oponopono Chanting</li>
                                <li>Neuro-Linguistic Programming technique (NLP)</li>
                            </ul>
                        </div>
                </div>}

            {phoneCheck() ?
                <div className="reiki-info-mobile">
                    <div className="title">WHAT IS REIKI?</div>
                    <div className="reiki-info-container">
                        <p>Reiki is a powerful healing life force. At Dishaa, we are guided by the basic principles of Reiki which teach us to be free of anger, free of worry, to be focused and dedicated to our work, to be grateful and to respect everybody, including ourselves.</p>
                        <p>We conduct regular Reiki healing classes and meditation sessions. All our volunteers and women we train are Reiki attuned.</p>
                        <img className="reiki-info-logo-img" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651437341/HealingAndWellness/ofhukduwgolce41ovata.png" />
                        <p>The word Reiki comes from the Japanese words ‘rei’: universal and ‘ki’: life energy.</p>
                        <p>Mikao Usui was the creator of this spiritual practice in the 1800s. Today, it is used all over the world as an alternative therapy for the treatment of physical, emotional, and mental health problems. According to the inscription on his memorial stone, Usui taught Reiki to over 2,000 people during his lifetime.</p>

                        <div className="reiki-info-text-container">
                            <div className="info-within-img-container">
                                <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651482688/HealingAndWellness/ee0ks10uzgbbfe3cfgr9.jpg" />
                                <div className="info-within-img-text">
                                    <p>With Reiki attunement, you invite peace, joy, gratitude, and healing into your life.
                                        With the Universal Life Force, you will begin to see magical changes in everything, from your attitude to your actions. You learn the five principles and how to apply them to your life.</p>
                                    <p>The five principles of Reiki teach you to love yourself, understand yourself and your needs, your weaknesses, and your strengths. It teaches you to master your thoughts and make choices fearlessly. It is basically a self-study course. </p>
                                </div>
                            </div>
                            <p>When used with the right intention, Reiki benefits us in many ways by</p>
                            <div className="reiki-info-lists">
                                <ul>
                                    <li>enabling relaxation</li>
                                    <li>reducing physical, mental, and emotional pain and distress</li>
                                    <li>reducing symptoms of illness</li>
                                    <li>helping you tackle anxiety and depression</li>
                                    <li>giving you mental peace and calm</li>
                                    <li>helping you live a life of gratitude</li>
                                    <li>showing you the true meaning of happiness</li>
                                    <li>helping you live your life to its fullest potential!</li>
                                    <li>showing you how to make the right choices</li>
                                    <li>helping us understand the life that we want</li>
                                </ul>
                            </div>
                            <p>Your understanding and application of the Reiki principles changes as you advance to the next levels.</p>
                        </div>
                    </div>
                </div>
                :
                <div className="reiki-info">
                <div className="title">WHAT IS REIKI?</div>
                <div className="reiki-info-container">
                    <img className="reiki-info-logo-img" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651437341/HealingAndWellness/ofhukduwgolce41ovata.png" />
                    <div className="reiki-info-text-container">
                        <p>Reiki is a powerful healing life force. At Dishaa, we are guided by the basic principles of Reiki which teach us to be free of anger, free of worry, to be focused and dedicated to our work, to be grateful and to respect everybody, including ourselves.</p>
                        <p>We conduct regular Reiki healing classes and meditation sessions. All our volunteers and women we train are Reiki attuned.</p>
                        <p>The word Reiki comes from the Japanese words ‘rei’: universal and ‘ki’: life energy.</p>
                        <p>Mikao Usui was the creator of this spiritual practice in the 1800s. Today, it is used all over the world as an alternative therapy for the treatment of physical, emotional, and mental health problems. According to the inscription on his memorial stone, Usui taught Reiki to over 2,000 people during his lifetime.</p>
                        <div className="info-within-img-container">
                            <img src={iPadCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651482688/HealingAndWellness/ee0ks10uzgbbfe3cfgr9.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651439406/HealingAndWellness/wc7ve319e7gzw6zpwaxn.png"} />
                            <div className="info-within-img-text">
                                <p>With Reiki attunement, you invite peace, joy, gratitude, and healing into your life.
                                    With the Universal Life Force, you will begin to see magical changes in everything, from your attitude to your actions. You learn the five principles and how to apply them to your life.</p>
                                <p>The five principles of Reiki teach you to love yourself, understand yourself and your needs, your weaknesses, and your strengths. It teaches you to master your thoughts and make choices fearlessly. It is basically a self-study course. </p>
                            </div>
                        </div>
                        <p>When used with the right intention, Reiki benefits us in many ways by</p>
                        <div className={iPadCheck() ? "reiki-info-lists-tablet" : "reiki-info-lists"}>
                            <ul>
                                <li>enabling relaxation</li>
                                <li>reducing physical, mental, and emotional pain and distress</li>
                                <li>reducing symptoms of illness</li>
                                <li>helping you tackle anxiety and depression</li>
                                <li>helping us understand the life that we want</li>
                            </ul>
                            <ul>
                                <li>helping you live a life of gratitude</li>
                                <li>showing you the true meaning of happiness</li>
                                <li>helping you live your life to its fullest potential!</li>
                                <li>showing you how to make the right choices</li>
                                <li>giving you mental peace and calm</li>
                            </ul>
                        </div>
                        <p>Your understanding and application of the Reiki principles changes as you advance to the next levels.</p>
                    </div>
                </div>
            </div>}

            {/*<div className="testimonials-container">*/}
            {/*    <div>TESTIMONIALS</div>*/}
            {/*</div>*/}

        </div>
    );


    const courses = () => (

        <div>
            <h5 className="course-title">BOOK A CLASS</h5>
            <div className={check() ? "courses-container-mobile" : "courses-container"}>

                <div className="courses-tabs">

                    <Link
                        to={`/courses`}
                        onClick={() => allProducts()}
                        style={isActive(browser_history, `/courses`)}>

                        <p>All Courses</p>
                    </Link>

                    {info.categories.map((cat, i) => (
                        <Link
                            to={`/courses/category/${cat.catId}/products`}
                            onClick={() => handleOnClick(cat.catId)}
                            key={i}
                            style={isActive(browser_history, `/courses/category/${cat.catId}/products`)}>
                            <p>
                                {/*{console.log(cat.catId)}*/}
                                {cat.categoryName}
                            </p>
                        </Link>
                    ))}
                </div>

                <div className="course-content">
                    <div className='products'>
                        {products.map((pdt, i) => (
                            <div key={i} className="product">
                                <Link to={`/product/${pdt._id}`} key={i}>
                                    <div className="product-image">
                                        <DisplayImage item={pdt} url="product"/>
                                    </div>
                                    <div className="product-title">{pdt.course}</div>
                                </Link>
                            </div>
                        ))
                        }

                        {productsForSelectedCategory.map((pdt, i) => (
                            <div key={i} className="product">
                                <Link to={`/product/${pdt.id}`} key={i}>
                                    <div className="product-image">
                                        <DisplayImage item={pdt} url="product"/>
                                    </div>
                                    <div className="product-title">{pdt.course}</div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>

    );


    return(
        <div className="Courses">
            <Navbar/>

            {intro()}

            {/*{aboutReiki()}*/}
            {/*{courses()}*/}

            {/*<div className={check() ? "grandmasters-mobile" : "grandmasters"}>*/}
            {/*    <img src={grandmasters} alt="photo of reiki grandmasters Lakshmi Bhat and Sadhana Desai"/>*/}
            {/*    <span style={{ textAlign: 'center' }}>KNOW YOUR <div className="reiki"><b>REIKI</b></div> GRAND MASTERS</span>*/}
            {/*</div>*/}

            {/*<p className="grandmaster-intro">We conduct regular Reiki healing classes and meditation sessions. Reiki Grandmasters Lakshmi Bhat and Sadhana Desai — trustees of Dishaa Pratishthaan — conduct these sessions and have attuned over 300 people since they began in 2012.</p>*/}

            {/*<div className="lakshmi-sadhana">*/}
            {/*    <div className={check() ? "lakshmi-mobile" : "lakshmi"}>*/}
            {/*           <span className="img-container">*/}
            {/*               <img src={lakshmi} alt="Grandmaster Lakshmi Bhat"/>*/}
            {/*           </span>*/}
            {/*        <span className="grandmaster-info">*/}
            {/*               <p className="grandmaster-name">Lakshmi Bhat</p>*/}
            {/*               <p><strong>Lakshmi Bhat</strong> is a Reiki Grandmaster and  the Chief Trustee of Dishaa. She is the guiding light, the soul and driving force of our family. She is also an avid crocheter, a trained classical dancer and for 13 years, she worked as a software professional</p>*/}
            {/*                <p>She started teaching yoga & Reiki 17 years ago and has been able to help innumerable women find their way to happiness. Several of whom are a part of Dishaa Pratishthaan today. </p>*/}
            {/*                <p>It has been 21 years since she began her Reiki practise and it has been applied in all her actions and adventures in life. Every big and beautiful thing that happens at Dishaa, is her brainchild. She is also known as Sherlock around our office.</p>*/}
            {/*               <h5>Her journey with Reiki</h5>*/}
            {/*               <p>Reiki and Lakshmi, Where do I even begin?*/}
            {/*                    This is the 20th year of me being a Reiki channel.*/}
            {/*                    For two decades, the light has moulded me and made me who I am today.*/}
            {/*                    It has polished me, inside out.*/}
            {/*                    Ouch!! It used to hurt me badly as my Ego was being pulled out of me. The more I resisted, the more it hurt. Till I realised it was me who had to change, to live the life I so badly wanted.*/}

            {/*                    Even as I let go... I realised that there is a lot more to let go. All that I had learnt before I got attuned to Reiki, had to be unlearnt and relearned.*/}

            {/*                    Initially, it was just hand positions but I soon realised 'Practicing Reiki' meant following the 5 principles of the Universal Lifeforce. As I practised the Reiki hand positions, the unlearning and relearning was easier than I thought.*/}
            {/*                </p>*/}
            {/*           </span>*/}
            {/*    </div>*/}

            {/*    {check() ?*/}
            {/*        <div className="sadhana-mobile">*/}
            {/*               <span className="img-container">*/}
            {/*                   <img src={sadhana} alt="Grandmaster Sadhana Desai"/>*/}
            {/*               </span>*/}
            {/*            <span className="grandmaster-info">*/}
            {/*                    <p className="grandmaster-name">Sadhana Desai</p>*/}
            {/*                    <p><strong>Sadhana Desai</strong> is a Reiki Grandmaster who has been practising Reiki for the last 17 years. She is the Co-Trustee of Dishaa and is always ready to share jokes, comforting words and a few pearls of wisdom too. She is a fantastic baker, an embroidery enthusiast and the Watson to our Sherlock.</p>*/}
            {/*                    <h5>Her journey with Reiki</h5>*/}
            {/*                    <p>My time practising Reiki has been magical. It began when I was at my yoga class and I started feeling very unwell. On my way back, my friends, who were Reiki attuned, asked me if I would like to receive it since I was so unwell. I said yes, as I was desperate to feel better. And by the time we reached home, I felt fine… I almost felt like a new person! And then I just had to learn this and know more. So, I learnt Reiki from Anandiji in Bahrain. It has taken care of my health in so many different ways and made me a better person today. Reiki is a miracle, which I believe in and choose to believe also. It has shown me how to live a good life!</p>*/}
            {/*               </span>*/}
            {/*        </div>*/}

            {/*        :*/}

            {/*        <div className="sadhana">*/}
            {/*                <span className="grandmaster-info">*/}
            {/*                    <p className="grandmaster-name">Sadhana Desai</p>*/}
            {/*                    <p><strong>Sadhana Desai</strong> is a Reiki Grandmaster who has been practising Reiki for the last 17 years. She is the Co-Trustee of Dishaa and is always ready to share jokes, comforting words and a few pearls of wisdom too. She is a fantastic baker, an embroidery enthusiast and the Watson to our Sherlock.</p>*/}
            {/*                    <h5>Her journey with Reiki</h5>*/}
            {/*                    <p>My time practising Reiki has been magical. It began when I was at my yoga class and I started feeling very unwell. On my way back, my friends, who were Reiki attuned, asked me if I would like to receive it since I was so unwell. I said yes, as I was desperate to feel better. And by the time we reached home, I felt fine… I almost felt like a new person! And then I just had to learn this and know more. So, I learnt Reiki from Anandiji in Bahrain. It has taken care of my health in so many different ways and made me a better person today. Reiki is a miracle, which I believe in and choose to believe also. It has shown me how to live a good life!</p>*/}
            {/*                </span>*/}
            {/*            <span className="img-container">*/}
            {/*                    <img src={sadhana} alt="Grandmaster Sadhana Desai"/>*/}
            {/*                </span>*/}
            {/*        </div>*/}
            {/*    }*/}

            {/*</div>*/}

            <Carousel slides={testimonials}/>
            <Footer/>
        </div>
    )
};

export default Courses
