import React, {useEffect, useState} from 'react';
import {isAuthenticated} from "../auth";
import {Link, useHistory, Route} from "react-router-dom";
import {emptyCart, getCart} from "./helpers/cart";
import {createHamperOrder, hamperRazorpay} from "./CoreApi";
import logo from "../assets/DishaaLogo.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import {saveBillingAddress, saveMultipleShippingAddress, saveShippingAddress} from "./user/UserApi";
import PrivateRoutes from "../auth/PrivateRoutes";
import {
    addBillingAddressToLocalStorage,
    addDeliveryMethodToLocalStorage,
    addShippingAddressToLocalStorage,
    getCheckoutInfoFromLocalStorage,
    getCheckoutTotal,
    getDeliveryMethod,
    getMultipleAddressesFromLocalStorage,
    getShippingFee,
    removeCheckoutInfoFromLocalStorage,
    saveMultipleAddressInLocalStorage
} from "./helpers/checkout";
import DisplayImage from "./DisplayImage";
import GuestCheckout from "./GuestCheckout";
import {check} from "./helpers/browserCheck";
import ReactPhoneInput from "react-phone-input-2";


function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true)
        };
        script.onerror = () => {
            resolve(false)
        };
        document.body.appendChild(script)
    })
}

function toggle(value){
    return !value;
}

const requiredAsterisk = () => (
    <span style={{ color: 'red'}}><sup>*</sup></span>
);

const Checkout = () => {

    const {user, token} = isAuthenticated();

    const [cartItems, setCartItems] = useState([]);
    const browser_history = useHistory();

    useEffect(() => {
        setCartItems(getCart());
    }, []);

    const __CHARS__ = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    const randomString = (length, chars)=> {
        let result = '';
        for (let i = length; i > 0; --i) {
            result += chars[Math.round(Math.random() * (chars.length - 1))];
        }
        return result;
    };

    const getCartSubtotal = () => {
        return cartItems.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.price;
        }, 0);
    };

    const isActive = (history, path) => {
        if(history.location.pathname === path) {
            return { color: '#f00' }
        } else {
            return {  color: '#000' }
        }
    };

    const showCartItemsInSummary = () => (
        <div className="cart-items-in-summary">
            <div className="title">Cart Summary</div>
            <hr/>
            <div className="item-container">
            {cartItems.map((item, index) => (
                <div key={index} className="item">
                    {item.images.length === 0 ? <DisplayImage item={item} url='/hamper' /> : <img src={item.images[0].url} /> }
                    {/*<DisplayImage item={item} url='/hamper' />*/}
                    <div className="info">
                        <div>{item.name}</div>
                        <div>₹{item.price} each</div>
                        <div>Qty: {item.count}</div>
                        <div><b>Total: {item.count * item.price}</b></div>
                    </div>
                </div>
                ))}
            </div>
        </div>
    );

    const showCartSummary = () => (
        <div className="cart-summary">

            {showCartItemsInSummary()}
            <hr/>

            <div className="summary-item-container">
                <div className="summary-item">
                    <div>Subtotal <small> ({cartItems.length} {cartItems.length === 1 ? 'item' : 'items'})</small></div>
                    <div>₹{getCartSubtotal()}</div>
                </div>
                <div className="summary-item">
                    <div>Shipping fee</div>
                    <div>{getDeliveryMethod() === 'Shipping' ? '₹' +getShippingFee() : getDeliveryMethod() === 'Pickup' ? '₹0' : 'Added to total in the next step'}</div>
                </div>
                <hr/>
                <div className="summary-item">
                    <div>Est. total</div>
                    <div><b>₹{getCheckoutTotal() ? getCheckoutTotal() : getCartSubtotal()}</b></div>
                </div>

            </div>
        </div>
    );



    const checkoutList = () => {
        return(
            <div className="checkout-list">
                {/*<h4 className="">Your account</h4>*/}
                <ul className="user-links">
                    <Link to={`/checkout/shipping-details/${user._id}`} style={isActive(browser_history, `/checkout/shipping-details/${user._id}`)}>
                        <li>Shipping Details</li>
                    </Link>
                    <Link to={`/checkout/delivery-method/${user._id}`} style={isActive(browser_history, `/checkout/delivery-method/${user._id}`)}>
                        <li>Delivery Method</li>
                    </Link>
                    <Link to={`/checkout/payment-info`} style={isActive(browser_history, `/checkout/payment-info`)}>
                        <li>Billing Info</li>
                    </Link>
                    <Link to={`/checkout/review`} style={isActive(browser_history, `/checkout/review`)}>
                        <li>Review & Place Order</li>
                    </Link>
                </ul>
            </div>
        )
    };



    const paymentInfo = () => {

        const [sameAddressFlag, setSameAddressFlag] = useState(true);
        const [val, setVal] = useState({
            firstName: '',
            lastName: '',
            email: '',
            flatBuilding: '',
            streetName: '',
            landmark: '',
            city: '',
            pincode: 0,
            state: '',
            error: '',
            success: false,
        });
    const [saveBillingAddressFlag, setSaveBillingAddressFlag] = useState(false);

        const { firstName, lastName, email, flatBuilding, streetName, landmark, city, pincode, state, success, error } = val;

        const handleChange = field => event => {
            setVal({ ...val, error: false, [field]: event.target.value });
        };

        const handleSubmit = event => {
            event.preventDefault();
            setVal({ ...val, error: false });

            let billingAddress = { firstName, lastName, email, flatBuilding, streetName, landmark, pincode, city, state};

            // set up a function to save this info in local storage
            addBillingAddressToLocalStorage(billingAddress, () => {
                console.log(localStorage.getItem('checkoutInfo'))
            });

            // ask user if this address needs to be saved (and set to primary address - later)
            if(saveBillingAddressFlag) {
                saveBillingAddress(user._id, token, { firstName, lastName, email, flatBuilding, streetName, landmark, pincode, city, state})
                    .then(data => {
                        if (data.error) {
                            setVal({ ...val, error: data.error, success: false });
                        } else {
                            setVal({
                                ...val,
                                firstName: '',
                                lastName: '',
                                email: '',
                                flatBuilding: '',
                                streetName: '',
                                landmark: '',
                                city: '',
                                pincode: 0,
                                state: '',
                                error: '',
                                success: true
                            });
                        }
                    });
            }
            browser_history.push('/checkout/review');
        };


        const saveShippingAddressAsBillingAddressInLocalStorage = () => {
            let info = getCheckoutInfoFromLocalStorage();
            addBillingAddressToLocalStorage(info.shippingAddress, () => {
                console.log(JSON.parse(localStorage.getItem('checkoutInfo')))
            });
        };


        const handleClick = () => {
            browser_history.push('/checkout/review');
        };

        return(
            <div className="payment-info">


                <div className="checkbox-field">
                    <input
                        type="checkbox"
                        checked={sameAddressFlag}
                        onChange={() => setSameAddressFlag(!sameAddressFlag)}
                        // value={saveAddress}
                    />
                    <label htmlFor="sameAddress">Billing address same as shipping address?</label>
                </div>
                {sameAddressFlag && <button type='button' className='button' onClick={handleClick}>Continue</button>}

                {!sameAddressFlag ?
                    <div>
                        Please enter your billing address below:
                        <div className="shipping-form-container">
                            <form onSubmit={handleSubmit} className="shipping-form">
                                <div className='text-input-container'>
                                    <div className="field">
                                        <input className="inputField" type="email" name="email" id="email" placeholder="Enter your email" onChange={handleChange('email')} value={email} required={true}/>
                                        <label htmlFor="email">Email {requiredAsterisk()}</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type="text" name="firstname" id="firstname" placeholder="Enter your first name" onChange={handleChange('firstName')} value={firstName} required={true}/>
                                        <label htmlFor="firstname">First Name {requiredAsterisk()}</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type="text" name="lastname" id="lastname" placeholder="Enter your last name" onChange={handleChange('lastName')} value={lastName} required={true}/>
                                        <label htmlFor="lastname">Last Name {requiredAsterisk()}</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type={"text"} name="flatBuilding" id="flatBuilding" placeholder="Enter your Flat, House no., Building/Office No." onChange={handleChange('flatBuilding')} value={flatBuilding} required={true}/>
                                        <label htmlFor="flatBuilding">Flat, House no., Building/Office No. {requiredAsterisk()}</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type="text" name="streetName" id="streetName" placeholder="Enter your Area, Street, Sector/Village" onChange={handleChange('streetName')} value={streetName} required={true}/>
                                        <label htmlFor="streetName">Area, Street, Sector/Village {requiredAsterisk()}</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type="text" name="landmark" id="landmark" placeholder="Enter a Landmark (Optional)" onChange={handleChange('landmark')} value={landmark}/>
                                        <label htmlFor="landmark">Landmark (Optional)</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type="text" name="city" id="city" placeholder="Enter your Town/City" onChange={handleChange('city')} value={city} required={true}/>
                                        <label htmlFor="city">Town/City {requiredAsterisk()}</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type="number" name="pincode" id="pincode" placeholder="Enter your Pincode" onChange={handleChange('pincode')} value={pincode} required={true}/>
                                        <label htmlFor="pincode">Pincode {requiredAsterisk()}</label>
                                    </div>
                                    <div className="field">
                                        <input className="inputField" type="text" name="state" id="state" placeholder="Enter your State " onChange={handleChange('state')} value={state} required={true}/>
                                        <label htmlFor="state">State {requiredAsterisk()}</label>
                                    </div>
                                    <div className="checkbox-field">
                                        <input
                                            type="checkbox"
                                            checked={saveBillingAddressFlag}
                                            onChange={() => setSaveBillingAddressFlag(!saveBillingAddressFlag)}
                                            // value={saveAddress}
                                            id="checkbox"
                                        />
                                        <label htmlFor="checkbox">Save as default billing address?</label>
                                    </div>
                                </div>
                                <div>
                                    <button type='submit' className='button'>Continue</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    :
                    saveShippingAddressAsBillingAddressInLocalStorage()
                }
            </div>
        )
    };

    const review = () => {
        return(
            <div className="review">
                Please review your cart and when you’re ready, click on Place Order below.
                {showCheckout()}
            </div>
        )
    };

    const showCheckout = () => (
        <div className="place-order-button">
            { isAuthenticated() ? (
                <button className={`button ${cartItems.length < 1 ? 'disable' : ''}`}
                        disabled={cartItems.length < 1}
                        onClick={displayRazorPay}>Place order</button>
            ) : (
                <div className="sign-in-msg">
                    <Link to="/login">Sign in</Link> to checkout.
                </div>
            ) }
        </div>
    );




    ///////// RAZORPAYYYY

    async function displayRazorPay() {
        // setManualPay(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return
        }
        hamperRazorpay(getCheckoutTotal()).then(res => {

            const options = {
                key: 'rzp_live_yFrqffHWFPwGBU',
                // key: 'rzp_test_ibi4ooyfwxXdZ2',
                currency: 'INR',
                amount: res.amount.toString(),
                order_id: res.order_id,
                name: 'Hamper Purchase',
                // description: ' *insert description* ',
                image: {logo},
                handler: function (response) {
                    placeHamperOrder(cartItems, ('hamper_' + response.razorpay_payment_id), getCheckoutTotal());
                    // alert(response.razorpay_payment_id)
                    // alert(response.razorpay_order_id)
                    // alert(response.razorpay_signature)
                },
                prefill: {
                    name: user.firstName,
                    email: user.email,
                    contact: user.callNumber
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open()

        })
    }

    const placeHamperOrder = (hampers, paymentId, amount) => {
        let deliveryAddress;
        let billingAddress;
        let checkoutInfo = getCheckoutInfoFromLocalStorage();
        if(checkoutInfo.multipleShippingAddress.length > 0) {
            // do something
        } else {
            deliveryAddress = checkoutInfo.shippingAddress
            billingAddress = checkoutInfo.billingAddress
        }

        const orderData = {
            hampers,
            payment_id: paymentId,
            amount,
            deliveryAddress,
            billingAddress,
            deliveryMethod: checkoutInfo.method,
            shippingFee: getShippingFee(),
            // orderNumber: randomString(4, __CHARS__)
        };

        // console.table("hamper item table:>>> ",hampers);

        createHamperOrder(user._id, token, orderData)
            .then( response => {
                console.log("Hamper Order created successfully!");
                browser_history.push('/payment-complete');
            })
            .catch(error => {
                console.log(error);
            });
        emptyCart(() => {
            console.log("Payment was successful");
        });
        removeCheckoutInfoFromLocalStorage(() => {
            console.log("Checkout information removed from local storage successfully.")
        })
    };

    ////////// END RAZORPAY

    const showCartItemsInSummaryMobile = () => (
        <div className="cart-items-in-summary-mobile">
            <div className="title">Cart Summary</div>
            <hr/>
            <div className="item-container">
                {cartItems.map((item, index) => (
                    <div key={index} className="item">
                        <img src={item.images[0].url} />
                        {/*<DisplayImage item={item} url='/hamper' />*/}
                        <div className="info">
                            <div>{item.name}</div>
                            <div>₹{item.price} each</div>
                            <div>Qty: {item.count}</div>
                            <div><b>Total: {item.count * item.price}</b></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    const checkoutListMobile = () => {
        return(
            <div className="checkout-list-mobile">
                <ul className="user-links">
                    <Link to={`/checkout/shipping-details/${user._id}`} style={isActive(browser_history, `/checkout/shipping-details/${user._id}`)}>
                        <li>Shipping Details</li>
                    </Link>
                    <Link to={`/checkout/delivery-method/${user._id}`} style={isActive(browser_history, `/checkout/delivery-method/${user._id}`)}>
                        <li>Delivery Method</li>
                    </Link>
                    <Link to={`/checkout/payment-info`} style={isActive(browser_history, `/checkout/payment-info`)}>
                        <li>Billing Info</li>
                    </Link>
                    <Link to={`/checkout/review`} style={isActive(browser_history, `/checkout/review`)}>
                        <li>Review & Place Order</li>
                    </Link>
                </ul>
            </div>
        )
    };


    const [showCartSummaryInCheckout, setShowCartSummaryInCheckout] = useState(false);

    const showCartSummaryMobile = () => (
        <div className="cart-summary-mobile">

            <div className="cart-summary-header" onClick={() => setShowCartSummaryInCheckout(!showCartSummaryInCheckout)}>
                <div>{getCheckoutInfoFromLocalStorage() === {} ? 'Cart Summary' : <b>{getCheckoutTotal() ? 'Total ₹'+getCheckoutTotal() : 'Est. Subtotal ₹'+getCartSubtotal()}</b>}</div>
                <div>View details</div>
            </div>

            {showCartSummaryInCheckout &&
                <>
                    {showCartItemsInSummaryMobile()}
                    <div className="summary-item-container">
                        <div className="summary-item">
                            <div>Subtotal <small> ({cartItems.length} {cartItems.length === 1 ? 'item' : 'items'})</small></div>
                            <div><b>₹{getCartSubtotal()}</b></div>
                        </div>
                        <div className="summary-item">
                            <div>Shipping fee</div>
                            <div>{getDeliveryMethod() === 'Shipping' ? '₹' +getShippingFee() : getDeliveryMethod() === 'Pickup' ? '₹0' : 'Added to total in the next step'}</div>
                        </div>
                        <hr/>
                        <div className="summary-item">
                            <div>Est. total</div>
                            <div><b>₹{getCheckoutTotal() ? getCheckoutTotal() : getCartSubtotal()}</b></div>
                        </div>
                    </div>
                </>
            }

        </div>
    );

    const checkoutProcess = () => (
        <div>
            {checkoutListMobile()}
            <div className="checkout-action-mobile">
                <PrivateRoutes path="/checkout/shipping-details/:userId" component={() => shipping()}/>
                <PrivateRoutes path="/checkout/delivery-method/:userId" component={() => deliveryMethod()}/>
                <PrivateRoutes path="/checkout/payment-info" component={() => paymentInfo()}/>
                <PrivateRoutes path="/checkout/review" component={() => review()} />
            </div>
        </div>
    );


    return(
        <div className="Checkout">
            <Navbar/>

            {isAuthenticated() ?
                check() ?

                    <div className="checkout-container-mobile">
                        {showCartSummaryMobile()}
                        {checkoutProcess()}
                    </div>
                    :
                    <div className="checkout-container">
                        <div className="checkout_list-action">
                            <div className="checkout-list">
                                {checkoutList()}
                            </div>
                            <div className="checkout-action">
                                <PrivateRoutes path="/checkout/shipping-details/:userId" component={() => shipping()}/>
                                <PrivateRoutes path="/checkout/delivery-method/:userId" component={() => deliveryMethod()}/>
                                <PrivateRoutes path="/checkout/payment-info" component={() => paymentInfo()}/>
                                <PrivateRoutes path="/checkout/review" component={() => review()} />
                            </div>
                        </div>
                        <div className="order-summary">
                            {showCartSummary()}
                        </div>
                </div>

                :
                <Route path="/checkout/guest" component={GuestCheckout}/>
            }


            <Footer/>
        </div>
    )
}

export default Checkout






















export const shipping = () => {

    const {user, token} = isAuthenticated();
    const browser_history = useHistory();
    const [values, setValues] = useState({
        firstName: user ? user.firstName : '',
        lastName: user ? user.lastName : '',
        email: user ? user.email : '',
        callNumber: user ? user.callNumber : '',
        flatBuilding: user ? user.shippingAddress.flatBuilding : '',
        streetName: user ? user.shippingAddress.streetName : '',
        landmark: user ? user.shippingAddress.landmark : '',
        city: user ? user.shippingAddress.city : '',
        pincode: user ? user.shippingAddress.pincode : 0,
        state: user ? user.shippingAddress.state : '',
        error: '',
        success: false,
        content: ''
    });


    const [saveAddress, setSaveAddress] = useState(false);
    const [cart, setCart] = useState([]);
    const [multipleAddressFlag, setMultipleAddressFlag] = useState(false);
    const [disableMultipleAddressFlag, setDisableMultipleAddressFlag] = useState(true);

    const { firstName, lastName, email, flatBuilding, streetName, landmark, city, pincode, state, callNumber, success, error,addresses, selectOptions, content } = values;


    const handleChange = field => event => {
        setValues({ ...values, error: false, [field]: event.target.value });
    };

    const handlePhoneOnChange = value => {
        setValues({ ...values, callNumber: value })
    };

    const handleSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false });

        let address = { firstName, lastName, email, callNumber, flatBuilding, streetName, landmark, pincode, city, state};

        // set up a function to save this info in local storage
        addShippingAddressToLocalStorage(address, () => {
            setDisableMultipleAddressFlag(false);
        });

        console.log(address)

        // ask user if this address needs to be saved (and set to primary address - later)
        if(saveAddress) {
            saveShippingAddress(user._id, token, {firstName, lastName, email, callNumber, flatBuilding, streetName, landmark, pincode, city, state})
                .then(data => {
                    if (data.error) {
                        setValues({ ...values, error: data.error, success: false });
                    } else {
                        setValues({
                            ...values,
                            firstName: '',
                            lastName: '',
                            email: '',
                            callNumber: '',
                            flatBuilding: '',
                            streetName: '',
                            landmark: '',
                            city: '',
                            pincode: 0,
                            state: '',
                            error: '',
                            success: true
                        });
                    }
                });
        }
        browser_history.push(`/checkout/delivery-method/${user._id}`)
    };


    useEffect(() => {
        setCart(getCart());
        removeCheckoutInfoFromLocalStorage(() => {
            console.log("Checkout information removed from local storage successfully.")
        });
    }, []);

    // const handleOnChange = (position) => {
    //     console.log(checkedState)
    //     const newArr = checkedState.map((x,i) => {
    //         const container = {};
    //
    //         container.item = x;
    //         container.checked = (i === position ? !i.checked : i.checked);
    //
    //         console.log('whaddd', container)
    //
    //         return container;
    //
    //     });
    //
    //     // const updatedCheckedState = checkedState.map((item, index) =>
    //     //         index === position ? !item.checked : item.checked
    //     // );
    //     console.log(newArr);
    //     // setCheckedState(updatedCheckedState);
    //     // console.table(updatedCheckedState);
    // };

    // const handleMultipleSelectChange = (e) => {
    //     let target = e.target
    //     let name = target.name
    //     //here
    //     let value = Array.from(target.selectedOptions, option => option.value);
    //     setValues({...values,
    //         [name]: value
    //     });
    // };


    return(
        <div className="main-shipping-container">
            {!multipleAddressFlag && <>
                <div className="main-title">Enter Shipping Address</div>
                <div className="shipping-form-container">
                    <form onSubmit={handleSubmit} className="shipping-form">
                        <div className='text-input-container'>
                            <div className="field">
                                <input className="inputField" type="email" name="email" id="email" placeholder="Enter your email" onChange={handleChange('email')} value={email} required={true}/>
                                <label htmlFor="email">Email {requiredAsterisk()}</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="firstname" id="firstname" placeholder="Enter your first name" onChange={handleChange('firstName')} value={firstName} required={true}/>
                                <label htmlFor="firstname">First Name {requiredAsterisk()}</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="lastname" id="lastname" placeholder="Enter your last name" onChange={handleChange('lastName')} value={lastName} required={true}/>
                                <label htmlFor="lastname">Last Name {requiredAsterisk()}</label>
                            </div>

                            {/*<div className="field">*/}
                            {/*    <ReactPhoneInput*/}
                            {/*        inputExtraProps={{*/}
                            {/*            name: "Phone",*/}
                            {/*            required: true,*/}
                            {/*        }}*/}
                            {/*        country="in"*/}
                            {/*        value={callNumber}*/}
                            {/*        onChange={() => handlePhoneOnChange()}*/}
                            {/*        containerClass={'field'}*/}
                            {/*        inputClass={'inputField'}*/}
                            {/*    />*/}
                            {/*    <label htmlFor="callNumber">Primary Phone Number  {requiredAsterisk()}</label>*/}
                            {/*</div>*/}

                            <div className="field">
                                <input className="inputField" type={"text"} name="flatBuilding" id="flatBuilding" placeholder="Enter your Flat, House no., Building/Office No." onChange={handleChange('flatBuilding')} value={flatBuilding} required={true}/>
                                <label htmlFor="flatBuilding">Flat, House no., Building/Office No. {requiredAsterisk()}</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="streetName" id="streetName" placeholder="Enter your Area, Street, Sector/Village" onChange={handleChange('streetName')} value={streetName} required={true}/>
                                <label htmlFor="streetName">Area, Street, Sector/Village {requiredAsterisk()}</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="landmark" id="landmark" placeholder="Enter a landmark (Optional) " onChange={handleChange('landmark')} value={landmark} />
                                <label htmlFor="landmark">Landmark (Optional)</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="city" id="city" placeholder="Enter your Town/City" onChange={handleChange('city')} value={city} required={true}/>
                                <label htmlFor="city">Town/City {requiredAsterisk()}</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="number" name="pincode" id="pincode" placeholder="Enter your Pincode" onChange={handleChange('pincode')} value={pincode} required={true}/>
                                <label htmlFor="pincode">Pincode {requiredAsterisk()}</label>
                            </div>
                            <div className="field">
                                <input className="inputField" type="text" name="state" id="state" placeholder="Enter your State " onChange={handleChange('state')} value={state} required={true}/>
                                <label htmlFor="state">State {requiredAsterisk()}</label>
                            </div>
                            <div className="checkbox-field">

                                <input
                                    type="checkbox"
                                    checked={saveAddress}
                                    onChange={() => setSaveAddress(toggle)}
                                    className="" />
                                <label htmlFor="saveAddress">Save as default shipping address?</label>
                            </div>
                        </div>

                        <div>
                            <button type='submit' className='button'>Continue</button>
                        </div>
                    </form>
                </div>
            </>}

            {!disableMultipleAddressFlag && <div className="field">

                <div id="multipleAddress">
                    <label htmlFor="multipleAddress">Want us to ship to your friends and family for you?</label><br/>
                    <input type="radio" value="Yes" name="yes" onChange={() => setMultipleAddressFlag(true)} checked={multipleAddressFlag === true}/> Yes
                    &emsp;
                    <input type="radio" value="No" name="no" onChange={() => setMultipleAddressFlag(false)} checked={multipleAddressFlag === false}/> No
                </div>
                <button onClick={() => browser_history.push(`/checkout/delivery-method/${user._id}`)} className="button">Continue</button>

                {/*<input*/}
                {/*    type="checkbox"*/}
                {/*    checked={multipleAddressFlag}*/}
                {/*    onChange={() => setMultipleAddressFlag(!multipleAddressFlag)}*/}
                {/*    disabled={disableMultipleAddressFlag}*/}
                {/*    // value={saveAddress}*/}
                {/*/>*/}
                {/*<label htmlFor="multipleAddress">Want us to ship to your friends and family for you?</label>*/}
            </div>}
            {multipleAddressFlag &&
                <SaveMultipleAddressComponent />
            }

        </div>
    );
};






















export const deliveryMethod = () => {

    const browser_history = useHistory();
    const [method, setMethod] = useState('Shipping');

    const handleOnChange = (event) => {
        let deliveryMethod = event.target.value;
        // console.log(shippingFee)
        // addDeliveryMethodToLocalStorage(deliveryMethod, (deliveryMethod === 'Shipping' ? shippingFee : 0), () => {
        //    console.log(JSON.parse(localStorage.getItem('checkoutInfo')));
        // });
        setMethod(deliveryMethod);
    };
    const [shippingFee, setShippingFee] = useState(0);

    const handleClick = () => {

        // set up a function to save this info in local storage
        addDeliveryMethodToLocalStorage(method, (method === 'Shipping' ? shippingFee : 0), () => {
            browser_history.push('/checkout/payment-info')
        });

        // update subtotal in local storage
        // updateCartTotal(100, (updatedSubtotal) => {
        //     console.log('updated subtotal!', updatedSubtotal)
        // })
        browser_history.push('/checkout/payment-info')
    };

    const getTotalWeightInCart = () => {
        let cart = getCart();
        console.log('cart in the get total weight in cart function: ',cart);
        // console.log('total weight: ', cart.reduce((prev, next) => prev + (next.count * next.weight), 0));
        return cart
            .reduce((prev, next) => prev + (next.count * next.weight), 0);
    };

    const getShippingFeeBMCandTMC = () => {
        let totalFee = 0;
        let boxedItemVolWeight = 0;
        let nonBoxedItemVolWt = 0;
        let nonBoxedItemWeight = 0;
        let nonBoxedItemFee = 0;
        let boxedItemFee = 0;

        let cart = getCart();
        cart.map(c => {
            if(c.name === 'Gifting Hamper 25' || c.name === 'Gifting Hamper 31' || c.name === 'Gifting Hamper 34' || c.name === 'Gifting Hamper 41') {
                boxedItemVolWeight = boxedItemVolWeight + (c.count * c.volumeWeight);
            } else {
                nonBoxedItemVolWt = nonBoxedItemVolWt + (c.count * c.volumeWeight);
                nonBoxedItemWeight = nonBoxedItemWeight + (c.count * c.weight);
            }
        });

        if(boxedItemVolWeight === 0) {
            boxedItemFee = 0
        } else if (boxedItemVolWeight <= 1000) {
            boxedItemFee = 90;
        } else if (boxedItemVolWeight > 1000 && boxedItemVolWeight <= 2000) {
            boxedItemFee = 180;
        } else if (boxedItemVolWeight > 2000 && boxedItemVolWeight <= 3000) {
            boxedItemFee = 270;
        } else if (boxedItemVolWeight > 3000 && boxedItemVolWeight <= 4000) {
            boxedItemFee = 360;
        } else if (boxedItemVolWeight > 4000 && boxedItemVolWeight <= 5000) {
            boxedItemFee = 450;
        } else if (boxedItemVolWeight > 5000 && boxedItemVolWeight <= 6000) {
            boxedItemFee = 540;
        } else if (boxedItemVolWeight > 6000 && boxedItemVolWeight <= 7000) {
            boxedItemFee = 630;
        } else if (boxedItemVolWeight > 7000 && boxedItemVolWeight <= 8000) {
            boxedItemFee = 720;
        } else if (boxedItemVolWeight > 8000 && boxedItemVolWeight <= 9000) {
            boxedItemFee = 810;
        } else if (boxedItemVolWeight > 9000 && boxedItemVolWeight <= 10000) {
            boxedItemFee = 900;
        }  else {
            boxedItemFee = 1000;
        }

        // ONLY ACTUAL WEIGHT TAKEN INTO CONSIDERATION
        if(nonBoxedItemWeight <= 1000) {
            nonBoxedItemFee = 90;
        } else if (nonBoxedItemWeight > 1000 && nonBoxedItemWeight <= 2000 ) {
            nonBoxedItemFee = 180;
        } else if (nonBoxedItemWeight > 2000 && nonBoxedItemWeight <= 3000) {
            nonBoxedItemFee = 270;
        } else if (nonBoxedItemWeight > 3000 && nonBoxedItemWeight <= 4000) {
            nonBoxedItemFee = 360;
        } else if (nonBoxedItemWeight > 4000 && nonBoxedItemWeight <= 5000) {
            nonBoxedItemFee = 450;
        } else if (nonBoxedItemWeight > 5000 && nonBoxedItemWeight <= 6000) {
            nonBoxedItemFee = 540;
        } else if (nonBoxedItemWeight > 6000 && nonBoxedItemWeight <= 7000) {
            nonBoxedItemFee = 630;
        } else if (nonBoxedItemWeight > 7000 && nonBoxedItemWeight <= 8000) {
            nonBoxedItemFee = 720;
        } else if (nonBoxedItemWeight > 8000 && nonBoxedItemWeight <= 9000) {
            nonBoxedItemFee = 810;
        } else if (nonBoxedItemWeight > 9000 && nonBoxedItemWeight <= 10000) {
            nonBoxedItemFee = 900;
        } else {
            nonBoxedItemFee = 1000;
        }


        // VOLUME WEIGHT AND ACTUAL WEIGHT COMPARISON
        // if(nonBoxedItemWeight >= nonBoxedItemVolWt) {
        //     // calculate according to actual weight
        //     if(nonBoxedItemWeight <= 1000) {
        //         nonBoxedItemFee = 90;
        //     } else if(nonBoxedItemWeight > 1000 && nonBoxedItemWeight <= 2000 ) {
        //         nonBoxedItemFee = 180;
        //     } else if(nonBoxedItemWeight > 2000 && nonBoxedItemWeight <= 3000) {
        //         nonBoxedItemFee = 270;
        //     } else if(nonBoxedItemWeight > 3000 && nonBoxedItemWeight <= 4000) {
        //         nonBoxedItemFee = 360;
        //     } else if(nonBoxedItemWeight > 4000 && nonBoxedItemWeight <= 5000) {
        //         nonBoxedItemFee = 450;
        //     } else if(nonBoxedItemWeight > 5000 && nonBoxedItemWeight <= 6000) {
        //         nonBoxedItemFee = 540;
        //     } else if(nonBoxedItemWeight > 6000 && nonBoxedItemWeight <= 7000) {
        //         nonBoxedItemFee = 630;
        //     } else if(nonBoxedItemWeight > 7000 && nonBoxedItemWeight <= 8000) {
        //         nonBoxedItemFee = 720;
        //     } else if(nonBoxedItemWeight > 8000 && nonBoxedItemWeight <= 9000) {
        //         nonBoxedItemFee = 810;
        //     } else if(nonBoxedItemWeight > 9000 && nonBoxedItemWeight <= 10000) {
        //         nonBoxedItemFee = 900;
        //     } else {
        //         nonBoxedItemFee = 1000;
        //     }
        // } else {
        //     // calculate according to vol weight
        //     if(nonBoxedItemVolWt <= 1000) {
        //         nonBoxedItemFee = 90;
        //     } else if(nonBoxedItemVolWt > 1000 && nonBoxedItemVolWt <= 2000) {
        //         nonBoxedItemFee = 180;
        //     } else if(nonBoxedItemVolWt > 2000 && nonBoxedItemVolWt <= 3000) {
        //         nonBoxedItemFee = 270;
        //     } else if(nonBoxedItemVolWt > 3000 && nonBoxedItemVolWt <= 4000) {
        //         nonBoxedItemFee = 360;
        //     } else if(nonBoxedItemVolWt > 4000 && nonBoxedItemVolWt <= 5000) {
        //         nonBoxedItemFee = 450;
        //     } else if(nonBoxedItemVolWt > 5000 && nonBoxedItemVolWt <= 6000) {
        //         nonBoxedItemFee = 540;
        //     } else if(nonBoxedItemVolWt > 6000 && nonBoxedItemVolWt <= 7000) {
        //         nonBoxedItemFee = 630;
        //     } else if(nonBoxedItemVolWt > 7000 && nonBoxedItemVolWt <= 8000) {
        //         nonBoxedItemFee = 720;
        //     } else if(nonBoxedItemVolWt > 8000 && nonBoxedItemVolWt <= 9000) {
        //         nonBoxedItemFee = 810;
        //     } else if(nonBoxedItemVolWt > 9000 && nonBoxedItemVolWt <= 10000) {
        //         nonBoxedItemFee = 900;
        //     }
        //     // console.log('vol weight considered:', nonBoxedItemFee)
        // }

        totalFee = nonBoxedItemFee + boxedItemFee;
        return totalFee;
    };

    const getShippingFeeWithinMH = (totalWeight) => {
        let totalFee = 0;
        let boxedItemVolWeight = 0;
        let nonBoxedItemVolWt = 0;
        let nonBoxedItemWeight = 0;
        let nonBoxedItemFee = 0;
        let boxedItemFee = 0;
        // const totalVolWeight = getTotalVolumeWeightInCart();
        // const totalWeightOfNonBoxedItems = getTotalWeightOfNonBoxedItems();
        // const totalVolWeightOfNonBoxedItems = getTotalVolWeightOfNonBoxedItems();

        // check for boxed items
        let cart = getCart();
        cart.map(c => {
            if(c.name === 'Gifting Hamper 25' || c.name === 'Gifting Hamper 31' || c.name === 'Gifting Hamper 34' || c.name === 'Gifting Hamper 41') {
                boxedItemVolWeight = boxedItemVolWeight + (c.count * c.volumeWeight);
            } else {
                nonBoxedItemVolWt = nonBoxedItemVolWt + (c.count * c.volumeWeight);
                nonBoxedItemWeight = nonBoxedItemWeight + (c.count * c.weight);
            }
        });

        console.log('nonBoxedItemVolWt --> ', nonBoxedItemVolWt);
        console.log('nonBoxedItemWeight --> ', nonBoxedItemWeight);
        console.log('boxedItemVolWeight --> ', boxedItemVolWeight);
        console.log('boxedItemFee --> ', boxedItemFee)


        //check for boxed item
        if(boxedItemVolWeight === 0) {
            boxedItemFee = 0
        } else if (boxedItemVolWeight <= 1000) {
            boxedItemFee = 120;
        } else if (boxedItemVolWeight > 1000 && boxedItemVolWeight <= 2000) {
            boxedItemFee = 240;
        } else if (boxedItemVolWeight > 2000 && boxedItemVolWeight <= 3000) {
            boxedItemFee = 360;
        } else if (boxedItemVolWeight > 3000 && boxedItemVolWeight <= 4000) {
            boxedItemFee = 480;
        } else if (boxedItemVolWeight > 4000 && boxedItemVolWeight <= 5000) {
            boxedItemFee = 600;
        } else if (boxedItemVolWeight > 5000 && boxedItemVolWeight <= 6000) {
            boxedItemFee = 720;
        } else if (boxedItemVolWeight > 6000 && boxedItemVolWeight <= 7000) {
            boxedItemFee = 840;
        } else if (boxedItemVolWeight > 7000 && boxedItemVolWeight <= 8000) {
            boxedItemFee = 960;
        } else if (boxedItemVolWeight > 8000 && boxedItemVolWeight <= 9000) {
            boxedItemFee = 1080;
        } else if (boxedItemVolWeight > 9000 && boxedItemVolWeight <= 10000) {
            boxedItemFee = 1200;
        }


        if (nonBoxedItemWeight === 0) {
            nonBoxedItemFee = 0
        } else if (nonBoxedItemWeight <= 1000) {
            nonBoxedItemFee = 120;
        } else if (nonBoxedItemWeight > 1000 && nonBoxedItemWeight <= 2000 ) {
            nonBoxedItemFee = 240;
        } else if (nonBoxedItemWeight > 2000 && nonBoxedItemWeight <= 3000) {
            nonBoxedItemFee = 360;
        } else if (nonBoxedItemWeight > 3000 && nonBoxedItemWeight <= 4000) {
            nonBoxedItemFee = 480;
        } else if (nonBoxedItemWeight > 4000 && nonBoxedItemWeight <= 5000) {
            nonBoxedItemFee = 600;
        } else if (nonBoxedItemWeight > 5000 && nonBoxedItemWeight <= 6000) {
            nonBoxedItemFee = 720;
        } else if (nonBoxedItemWeight > 6000 && nonBoxedItemWeight <= 7000) {
            nonBoxedItemFee = 840;
        } else if (nonBoxedItemWeight > 7000 && nonBoxedItemWeight <= 8000) {
            nonBoxedItemFee = 960;
        } else if (nonBoxedItemWeight > 8000 && nonBoxedItemWeight <= 9000) {
            nonBoxedItemFee = 1080;
        } else if (nonBoxedItemWeight > 9000 && nonBoxedItemWeight <= 10000) {
            nonBoxedItemFee = 1200;
        } else {
            nonBoxedItemFee = 1000;
        }

        // if(nonBoxedItemWeight >= nonBoxedItemVolWt) {
        //     // calculate according to actual weight
        //     if(nonBoxedItemWeight <= 1000) {
        //         nonBoxedItemFee = 90;
        //     } else if(nonBoxedItemWeight > 1000 && nonBoxedItemWeight <= 2000 ) {
        //         nonBoxedItemFee = 180;
        //     } else if(nonBoxedItemWeight > 2000 && nonBoxedItemWeight <= 3000) {
        //         nonBoxedItemFee = 270;
        //     } else if(nonBoxedItemWeight > 3000 && nonBoxedItemWeight <= 4000) {
        //         nonBoxedItemFee = 360;
        //     } else if(nonBoxedItemWeight > 4000 && nonBoxedItemWeight <= 5000) {
        //         nonBoxedItemFee = 450;
        //     } else if(nonBoxedItemWeight > 5000 && nonBoxedItemWeight <= 6000) {
        //         nonBoxedItemFee = 540;
        //     } else if(nonBoxedItemWeight > 6000 && nonBoxedItemWeight <= 7000) {
        //         nonBoxedItemFee = 630;
        //     } else if(nonBoxedItemWeight > 7000 && nonBoxedItemWeight <= 8000) {
        //         nonBoxedItemFee = 720;
        //     } else if(nonBoxedItemWeight > 8000 && nonBoxedItemWeight <= 9000) {
        //         nonBoxedItemFee = 810;
        //     } else if(nonBoxedItemWeight > 9000 && nonBoxedItemWeight <= 10000) {
        //         nonBoxedItemFee = 900;
        //     } else {
        //         nonBoxedItemFee = 1000;
        //     }
        // } else {
        //     // calculate according to vol weight
        //     if(nonBoxedItemVolWt <= 1000) {
        //         nonBoxedItemFee = 90;
        //     } else if(nonBoxedItemVolWt > 1000 && nonBoxedItemVolWt <= 2000) {
        //         nonBoxedItemFee = 180;
        //     } else if(nonBoxedItemVolWt > 2000 && nonBoxedItemVolWt <= 3000) {
        //         nonBoxedItemFee = 270;
        //     } else if(nonBoxedItemVolWt > 3000 && nonBoxedItemVolWt <= 4000) {
        //         nonBoxedItemFee = 360;
        //     } else if(nonBoxedItemVolWt > 4000 && nonBoxedItemVolWt <= 5000) {
        //         nonBoxedItemFee = 450;
        //     } else if(nonBoxedItemVolWt > 5000 && nonBoxedItemVolWt <= 6000) {
        //         nonBoxedItemFee = 540;
        //     } else if(nonBoxedItemVolWt > 6000 && nonBoxedItemVolWt <= 7000) {
        //         nonBoxedItemFee = 630;
        //     } else if(nonBoxedItemVolWt > 7000 && nonBoxedItemVolWt <= 8000) {
        //         nonBoxedItemFee = 720;
        //     } else if(nonBoxedItemVolWt > 8000 && nonBoxedItemVolWt <= 9000) {
        //         nonBoxedItemFee = 810;
        //     } else if(nonBoxedItemVolWt > 9000 && nonBoxedItemVolWt <= 10000) {
        //         nonBoxedItemFee = 900;
        //     }
        //     // console.log('vol weight considered:', nonBoxedItemFee)
        // }

        totalFee = nonBoxedItemFee + boxedItemFee;
        console.log('This is the TOTAL SHIPPING FEE ======>>> ', totalFee);


        // check for non-boxed items
        // if(totalWeightOfNonBoxedItems > totalVolWeightOfNonBoxedItems) {
        //     // ACTUAL WEIGHT
        //     if(totalWeight <= 1000) {
        //         totalFee += 90;
        //     } else if(totalWeightOfNonBoxedItems >= 1001 && totalWeightOfNonBoxedItems <= 2000 ) {
        //         totalFee += 180;
        //     } else if(totalWeightOfNonBoxedItems > 2001 && totalWeightOfNonBoxedItems <= 3000) {
        //         totalFee += 270;
        //     }else if(totalWeightOfNonBoxedItems > 3001 && totalWeightOfNonBoxedItems <= 4000) {
        //         totalFee += 360;
        //     } else if(totalWeightOfNonBoxedItems > 4001 && totalWeightOfNonBoxedItems <= 5000) {
        //         totalFee += 450;
        //     }  else if(totalWeightOfNonBoxedItems > 5001 && totalWeightOfNonBoxedItems <= 6000) {
        //         totalFee += 540;
        //     }  else if(totalWeightOfNonBoxedItems > 6001 && totalWeightOfNonBoxedItems <= 7000) {
        //         totalFee += 630;
        //     }  else if(totalWeightOfNonBoxedItems > 7001 && totalWeightOfNonBoxedItems <= 8000) {
        //         totalFee += 720;
        //     }  else if(totalWeightOfNonBoxedItems > 8001 && totalWeightOfNonBoxedItems <= 9000) {
        //         totalFee += 810;
        //     }  else if(totalWeightOfNonBoxedItems > 9001 && totalWeightOfNonBoxedItems <= 10000) {
        //         totalFee += 900;
        //     } else {
        //         totalFee += 1000;
        //     }
        // } else {
        //     // VOLUMETRIC WEIGHT
        //     if(totalVolWeightOfNonBoxedItems <= 1000) {
        //         totalFee += 90;
        //     } else if(totalVolWeightOfNonBoxedItems >= 1001 && totalVolWeightOfNonBoxedItems <= 2000 ) {
        //         totalFee += 180;
        //     } else if(totalVolWeightOfNonBoxedItems > 2001 && totalVolWeightOfNonBoxedItems <= 3000) {
        //         totalFee += 270;
        //     }else if(totalVolWeightOfNonBoxedItems > 3001 && totalVolWeightOfNonBoxedItems <= 4000) {
        //         totalFee += 360;
        //     } else if(totalVolWeightOfNonBoxedItems > 4001 && totalVolWeightOfNonBoxedItems <= 5000) {
        //         totalFee += 450;
        //     }  else if(totalVolWeightOfNonBoxedItems > 5001 && totalVolWeightOfNonBoxedItems <= 6000) {
        //         totalFee += 540;
        //     }  else if(totalVolWeightOfNonBoxedItems > 6001 && totalVolWeightOfNonBoxedItems <= 7000) {
        //         totalFee += 630;
        //     }  else if(totalVolWeightOfNonBoxedItems > 7001 && totalVolWeightOfNonBoxedItems <= 8000) {
        //         totalFee += 720;
        //     }  else if(totalVolWeightOfNonBoxedItems > 8001 && totalVolWeightOfNonBoxedItems <= 9000) {
        //         totalFee += 810;
        //     }  else if(totalVolWeightOfNonBoxedItems > 9001 && totalVolWeightOfNonBoxedItems <= 10000) {
        //         totalFee += 900;
        //     } else {
        //         totalFee += 1000;
        //     }
        // }



        // if(totalWeight < 500) {
        //     totalFee = 90 + 15; // 15 -> includes envelope, sticker and labour charge
        // } else if(totalWeight > 500 && totalWeight < 1000) {
        //     totalFee = 90 + 20; // 20 -> includes small box, sticker and labour charge
        // } else if (totalWeight > 1000 && totalWeight < 2000) {
        //     totalFee = 180 + 20; // 20 -> includes small tray box, sticker and labour charge
        // } else if (totalWeight >2000 && totalWeight < 3000) {
        //     totalFee = 270 + 25; // 25 -> includes big tray box, sticker and labour charge
        // } else if (totalWeight > 3000 && totalWeight < 6000) {
        //     totalFee = 360 + 65 // 65 -> includes biggest box, sticker and labour charge
        // } else if (totalWeight > 6000) {
        //
        // }

        // if(totalWeight <= 1000) {
        //     totalFee = 90;
        // } else if(totalWeight >= 1001 && totalWeight <= 2000 ) {
        //     totalFee = 180;
        // } else if(totalWeight > 2001 && totalWeight <= 3000) {
        //     totalFee = 270;
        // }else if(totalWeight > 3001 && totalWeight <= 4000) {
        //     totalFee = 360;
        // } else if(totalWeight > 4001 && totalWeight <= 5000) {
        //     totalFee = 450;
        // }  else if(totalWeight > 5001 && totalWeight <= 6000) {
        //     totalFee = 540;
        // }  else if(totalWeight > 6001 && totalWeight <= 7000) {
        //     totalFee = 630;
        // }  else if(totalWeight > 7001 && totalWeight <= 8000) {
        //     totalFee = 720;
        // }  else if(totalWeight > 8001 && totalWeight <= 9000) {
        //     totalFee = 810;
        // }  else if(totalWeight > 9001 && totalWeight <= 10000) {
        //     totalFee = 900;
        // } else {
        //     totalFee = 1000;
        // }

        // console.log('total shipping fee', totalFee)
        return totalFee;
    };

    const getShippingFeeOutsideMH = () => {

        let totalFee = 0;
        let boxedItemVolWeight = 0;
        let nonBoxedItemVolWt = 0;
        let nonBoxedItemWeight = 0;
        let nonBoxedItemFee = 0;
        let boxedItemFee = 0;

        // check for boxed items
        let cart = getCart();
        cart.map(c => {
            if(c.name === 'Gifting Hamper 25' || c.name === 'Gifting Hamper 31' || c.name === 'Gifting Hamper 34' ||
                c.name === 'Gifting Hamper 41') {
                boxedItemVolWeight = boxedItemVolWeight + (c.count * c.volumeWeight);
            } else {
                nonBoxedItemVolWt = nonBoxedItemVolWt + (c.count * c.volumeWeight);
                nonBoxedItemWeight = nonBoxedItemWeight + (c.count * c.weight);
            }
        });



        //check for boxed item
        if(boxedItemVolWeight === 0) {
            boxedItemFee = 0
        } else if (boxedItemVolWeight <= 1000) {
            boxedItemFee = 150;
        } else if (boxedItemVolWeight > 1000 && boxedItemVolWeight <= 2000) {
            boxedItemFee = 300;
        } else if (boxedItemVolWeight > 2000 && boxedItemVolWeight <= 3000) {
            boxedItemFee = 450;
        } else if (boxedItemVolWeight > 3000 && boxedItemVolWeight <= 4000) {
            boxedItemFee = 600;
        } else if (boxedItemVolWeight > 4000 && boxedItemVolWeight <= 5000) {
            boxedItemFee = 750;
        } else if (boxedItemVolWeight > 5000 && boxedItemVolWeight <= 6000) {
            boxedItemFee = 900;
        } else if (boxedItemVolWeight > 6000 && boxedItemVolWeight <= 7000) {
            boxedItemFee = 1050;
        } else if (boxedItemVolWeight > 7000 && boxedItemVolWeight <= 8000) {
            boxedItemFee = 1200;
        }

        if (nonBoxedItemWeight === 0) {
            nonBoxedItemFee = 0;
        } else if(nonBoxedItemWeight <= 1000) {
            nonBoxedItemFee = 150;
        } else if(nonBoxedItemWeight > 1000 && nonBoxedItemWeight <= 2000) {
            nonBoxedItemFee = 300;
        } else if(nonBoxedItemWeight > 2000 && nonBoxedItemWeight <= 3000) {
            nonBoxedItemFee = 450;
        } else if(nonBoxedItemWeight > 3000 && nonBoxedItemWeight <= 4000) {
            nonBoxedItemFee = 600;
        } else if(nonBoxedItemWeight > 4000 && nonBoxedItemWeight <= 5000) {
            nonBoxedItemFee = 750;
        } else if (nonBoxedItemWeight > 5000 && nonBoxedItemWeight <= 6000) {
            boxedItemFee = 900;
        } else if (nonBoxedItemWeight > 6000 && nonBoxedItemWeight <= 7000) {
            boxedItemFee = 1050;
        } else if (nonBoxedItemWeight > 7000 && nonBoxedItemWeight <= 8000) {
            boxedItemFee = 1200;
        }


        // if(nonBoxedItemWeight >= nonBoxedItemVolWt) {
        //     // calculate according to actual weight
        // } else {
        //     // calculate according to vol weight
        //     if(nonBoxedItemVolWt <= 1000) {
        //         nonBoxedItemFee = 150;
        //     } else if(nonBoxedItemVolWt > 1000 && nonBoxedItemVolWt <= 2000) {
        //         nonBoxedItemFee = 300;
        //     } else if(nonBoxedItemVolWt > 2000 && nonBoxedItemVolWt <= 3000) {
        //         nonBoxedItemFee = 450;
        //     } else if(nonBoxedItemVolWt > 3000 && nonBoxedItemVolWt <= 4000) {
        //         nonBoxedItemFee = 600;
        //     } else if(nonBoxedItemVolWt > 4000 && nonBoxedItemVolWt <= 5000) {
        //         nonBoxedItemFee = 750;
        //     } else if (nonBoxedItemVolWt > 5000 && nonBoxedItemVolWt <= 6000) {
        //         boxedItemFee = 900;
        //     } else if (nonBoxedItemVolWt > 6000 && nonBoxedItemVolWt <= 7000) {
        //         boxedItemFee = 1050;
        //     } else if (nonBoxedItemVolWt > 7000 && nonBoxedItemVolWt <= 8000) {
        //         boxedItemFee = 1200;
        //     }
        //     // console.log('vol weight considered:', nonBoxedItemFee)
        // }

        totalFee = nonBoxedItemFee + boxedItemFee;

        return totalFee === 0 || totalFee < 0 ? 0 : totalFee;

    };

    // const getShippingFeeOutsideMH = totalWeight => {
    //     let totalFee = 0;
    //     if(totalWeight <= 1000) {
    //         totalFee = 170;
    //     } else if(totalWeight >= 1001 && totalWeight <= 2000 ) {
    //         totalFee = 340
    //     } else if(totalWeight > 2001 && totalWeight <= 3000) {
    //         totalFee = 510;
    //     } else if(totalWeight >= 3001 && totalWeight <= 4000 ) {
    //         totalFee = 680
    //     } else if(totalWeight >= 4001 && totalWeight <= 5000 ) {
    //         totalFee = 850
    //     } else if(totalWeight >= 5001 && totalWeight <= 6000 ) {
    //         totalFee = 1020
    //     } else if(totalWeight >= 6001 && totalWeight <= 7000 ) {
    //         totalFee = 1190
    //     } else if(totalWeight >= 7001 && totalWeight <= 8000 ) {
    //         totalFee = 1360
    //     } else if(totalWeight >= 8001 && totalWeight <= 9000 ) {
    //         totalFee = 1530
    //     } else if(totalWeight >= 9001 && totalWeight <= 10000 ) {
    //         totalFee = 1700
    //     } else {
    //         totalFee = 2000
    //     }
    //
    //     return totalFee + 100;
    // };



    const getCheckoutInfo = () => {
       let info =  getCheckoutInfoFromLocalStorage();
       let pincode = '';
       if(info.multipleShippingAddress.length === 0) {
           pincode = info.shippingAddress.pincode;
           // console.log()
           let zones = Math.floor(pincode/10000);
           let goaZone = Math.floor(pincode/1000);
           let mumZone = Math.floor(pincode/100);
           if(zones >= 40 && zones <= 44) {
               if(goaZone === 403) {
                   console.log('goa');
                   setShippingFee(getShippingFeeOutsideMH(getTotalWeightInCart()));
               } else if (pincode >= 400001 && pincode <= 421605) {
                   console.log('bmc and tmc');
                   setShippingFee(getShippingFeeBMCandTMC());
               }
               else {
                   setShippingFee(getShippingFeeWithinMH(getTotalWeightInCart()));
                   console.log('MH')
               }
           }
           else {
               console.log('out of MH')
               setShippingFee(getShippingFeeOutsideMH(getTotalWeightInCart()));
           }
       } else {
           console.log('price for multiple shipping address');
           info.multipleShippingAddress.map((item) => {
               pincode = item.pincode;
               let zones = Math.floor(pincode/10000);
               let goaZone = Math.floor(pincode/1000);
               if(zones >= 40 && zones <= 44) {
                   if(goaZone === 403) {
                       console.log('multiple goa');
                   }
                   else {
                       // getShippingFeeWithinMH()
                   }

               }
           })
       }
    };

    useEffect(() => {
        // setCart(getCart());
        getCheckoutInfo();
    }, []);

    return(
        <div className="delivery-method">
            <div className="delivery-method-title">Select your preferred delivery method</div>
            <input type="radio" value="Shipping" name="shipping" onChange={handleOnChange} checked={method === "Shipping"}/> Shipping
            &emsp;
            <input type="radio" value="Pickup" name="pickup" onChange={handleOnChange} checked={method === "Pickup"}/> Pickup

            {method === 'Shipping' &&
            <div className="shipping">
                <b>Shipping fee: ₹{shippingFee} </b> (Will be added to total in the next step)
            </div>
            }

            {method === 'Pickup' &&
            <div className="pickup">
                <div><b>Pickup Address:</b></div>
                <p>63/103, Kachnar building, <br/>Vasant Vihar, <br/>Thane West - 400610</p>

                <div><b>Pickup Instructions</b></div>
                <div>We will get in touch with you as soon as your order is ready for pickup.</div>

            </div>
            }

            <button type='button' className='button' onClick={handleClick}>Continue</button>
        </div>
    )
};






















/////// save multiple addresses component starts here

export const SaveMultipleAddressComponent = () => {

    const {user, token} = isAuthenticated();
    const browser_history = useHistory();
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email:  '',
        flatBuilding: '',
        streetName: '',
        landmark:  '',
        city: '',
        pincode:  0,
        state:  '',
        error: '',
        success: false,
        content: ''
    });
    const { firstName, lastName, email, flatBuilding, streetName, landmark, city, pincode, state, success, error, content } = values;


    const handleChange = field => event => {
        setValues({ ...values, error: false, [field]: event.target.value });
    };

    const handleSave = event => {
        // save address to db
        event.preventDefault();
        setValues({ ...values, error: false});

        let address = { firstName, lastName, email, flatBuilding, streetName, landmark, pincode, city, state, content};
        saveMultipleAddressInLocalStorage(address, () => {
            // console.log(localStorage.getItem('checkoutInfo'))
            setValues({
                ...values,
                firstName: '',
                lastName: '',
                email: '',
                flatBuilding: '',
                streetName: '',
                landmark: '',
                city: '',
                pincode: 0,
                state: '',
                content: '',
                error: '',
                success: true})
        })
    };



    const handleContinueClick = () => {
        // save those multiple addresses to DB

        saveMultipleShippingAddress(user._id, token, getMultipleAddressesFromLocalStorage())
            .then(data => {
                if(data.error)
                    console.log(data.error)
                else {
                    console.log('data storage successful: ',data);
                    // redirect to next step
                    browser_history.push(`/checkout/delivery-method/${user._id}`);
                }
            })
    };

    // const [cart, setCart] = useState([]);
    //
    // useEffect(() => {
    //     setCart(getCart());
    // }, []);

    return(
        <div>
            <div>
                <div>

                    <textarea className="inputField" placeholder='Content of this delivery' onChange={handleChange('content')} value={content}/>
                    <div className="field">
                        <input className="inputField" type="email" name="email" id="email" placeholder="Enter your email" onChange={handleChange('email')} value={email} required={true}/>
                        <label htmlFor="email">Email {requiredAsterisk()}</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="text" name="firstname" id="firstname" placeholder="Enter your first name" onChange={handleChange('firstName')} value={firstName} required={true}/>
                        <label htmlFor="firstname">First Name {requiredAsterisk()}</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="text" name="lastname" id="lastname" placeholder="Enter your last name" onChange={handleChange('lastName')} value={lastName} required={true}/>
                        <label htmlFor="lastname">Last Name {requiredAsterisk()}</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type={"text"} name="flatBuilding" id="flatBuilding" placeholder="Enter your Flat, House no., Building/Office No." onChange={handleChange('flatBuilding')} value={flatBuilding} required={true}/>
                        <label htmlFor="flatBuilding">Flat, House no., Building/Office No. {requiredAsterisk()}</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="text" name="streetName" id="streetName" placeholder="Enter your Area, Street, Sector/Village" onChange={handleChange('streetName')} value={streetName} required={true}/>
                        <label htmlFor="streetName">Area, Street, Sector/Village {requiredAsterisk()}</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="text" name="landmark" id="landmark" placeholder="Enter a Landmark (Optional)" onChange={handleChange('landmark')} value={landmark}/>
                        <label htmlFor="landmark">Landmark (Optional)</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="text" name="city" id="city" placeholder="Enter your Town/City" onChange={handleChange('city')} value={city} required={true}/>
                        <label htmlFor="city">Town/City {requiredAsterisk()}</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="number" name="pincode" id="pincode" placeholder="Enter your Pincode" onChange={handleChange('pincode')} value={pincode} required={true}/>
                        <label htmlFor="pincode">Pincode {requiredAsterisk()}</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="text" name="state" id="state" placeholder="Enter your State " onChange={handleChange('state')} value={state} required={true}/>
                        <label htmlFor="state">State {requiredAsterisk()}</label>
                    </div>
                </div>
                <button type="button" onClick={handleSave}>Save</button>
                <div>
                    <button type='button' className='button' onClick={handleContinueClick}>Continue to Delivery method</button>
                </div>
            </div>
        </div>
    )
};
