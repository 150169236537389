import React, {useEffect} from 'react';
import Navbar from "./Navbar";
import './About.scss';
import grandmasters from '../assets/About/grandmasters1.jpg';
import lakshmi from '../assets/About/lakshmi.JPG';
import sadhana from '../assets/About/sadhana.JPG';
import earring from '../assets/About/about/IMG_7463.JPG';
import pendant from '../assets/About/about/IMG_6377.JPG';
import purse from '../assets/About/about/IMG_6641.JPG';
import coaster from '../assets/About/about/IMG_6592.JPG';
import turtle from '../assets/About/about/IMG_7462.JPG';
import flowerdiya from '../assets/About/about/IMG_7466.JPG';
import Footer from "./Footer";
import {check, phoneCheck} from "./helpers/browserCheck";
import Fade from 'react-reveal/Fade';

const About = () => {

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return(
        <div className="About">
            <Navbar/>
            <Fade bottom>

            <div className={phoneCheck() ? "about-title-mobile" : "about-title"}>ABOUT US</div>

            {phoneCheck() ?
                <div className="about-intro-mobile">
                    <p>Dishaa Pratishthaan is a registered non-profit charitable trust based in Thane.
                        We are a team of multi-talented women who make a difference in the community and spread happiness and joy to as many people as possible.
                    </p>
                </div>
                :
                <div className="about-intro">
                    <p>Dishaa Pratishthaan is a registered non-profit charitable trust based in Thane.
                        We are a team of multi-talented women who make a difference in the community and spread happiness and joy to as many people as possible.
                    </p>
                </div>
            }

            {phoneCheck() ?
                <div className="our-vision-container-mobile">
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655359594/About/mg8zlvilarvvmgvzmkei.png" />
                    <div className="our-vision-title">OUR VISION</div>
                    <div className="our-vision-text">
                        Healthy living with positive thinking! <br/>
                            We envision a world in which everyone has a full stomach, a head full of beautiful dreams, and the will to make them happen.

                    </div>
                </div>
                :
                <div className='our-vision-container'>
                    <div className='our-vision-img'>
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655189924/About/lf3bkknbqkqxpfga56ac.jpg" />
                        <div className="our-vision-title">OUR VISION</div>
                    </div>
                    <div className="our-vision-text-container">
                        <p className="our-vision-text">Healthy living with positive thinking! <br/>
                            We envision a world in which everyone has a full stomach, a head full of beautiful dreams, and the will to make them happen.
                        </p>
                    </div>
                </div>}

            {phoneCheck() ?
                <div className="our-mission-container-mobile">
                    <div className="our-mission-title">OUR MISSION</div>
                    <div className="our-mission-text">
                        <ul>
                            <li>To heal and be the answer to many prayers.</li>
                            <li>To empower women and children, by helping them hone their talents.</li>
                            <li>To encourage women to be positive, independent and believe in themselves.</li>
                            <li>To provide food to the hungry and needy.</li>
                            <li>To encourage and foster an environment of learning in childre</li>
                            <li>To train women, help them learn new skills, earn a living, and feel a sense of self and identity.</li>
                        </ul>
                    </div>
                </div>
            :
                <div className="our-mission-container">
                    <div className="our-mission-title">OUR MISSION</div>
                    <div className="our-mission-text">
                        <ul>
                            <li>To heal and be the answer to many prayers.</li>
                            <li>To empower women and children, by helping them hone their talents.</li>
                            <li>To encourage women to be positive, independent and believe in themselves.</li>
                            <li>To provide food to the hungry and needy.</li>
                            <li>To encourage and foster an environment of learning in childre</li>
                            <li>To train women, help them learn new skills, earn a living, and feel a sense of self and identity.</li>
                        </ul>
                    </div>
                </div>}

            {phoneCheck() ?
                <div className="our-effect-container-mobile">
                    <div className="title">OUR EFFECT</div>
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655360716/About/d6redd53crdp6hrpdhoy.png" />
                    <div className="our-effect-content">
                        <div className="content">The ripple effect of our positive work began with the children in the basti and carried on to the parents at home. Today, we find an increased number of happy faces, more respectful behaviour, a drive among the children to learn and go to school, a curiosity about things beyond the settlement and most importantly, healthy, happy faces. All this encourages and motivates us to continue doing our work.</div>
                    </div>
                </div>
            :
                <div className="our-effect-container">
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655191873/About/fwzdpm1tesyvw9srbj5f.jpg" />
                    <div className="our-effect-content">
                        <div className="title">OUR EFFECT</div>
                        <div className="content">The ripple effect of our positive work began with the children in the basti and carried on to the parents at home. Today, we find an increased number of happy faces, more respectful behaviour, a drive among the children to learn and go to school, a curiosity about things beyond the settlement and most importantly, healthy, happy faces. All this encourages and motivates us to continue doing our work.</div>
                    </div>
                </div>}

            {phoneCheck() ?
                <div className="our-trustees-container-mobile">
                    <div className="title">OUR TRUSTEES</div>
                    <div className="our-trustees-lakshmi">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655361190/About/lfl6qaemvhryopf2khbj.png" />
                        <div className="lakshmi-content">
                            <div className="name">Lakshmi Bhat</div>
                            <div className="sub-name">Chief Trustee</div>
                            <div className="content">Lakshmi is the soul of Dishaa. She began her career as a software professional but found herself called to her higher purpose as a yoga teacher, tarot card reader, and a Reiki teacher. Lakshmi is a guiding light to many and counsels and heals whoever comes knocking on her door. She started Dishaa Pratishthaan to spread joy, laughter, peace and healing in the community. Being a talented crocheter is another feather in her flamboyant cap!</div>
                        </div>
                    </div>
                    <div className="our-trustees-quotes">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655361413/About/mjl7zyia1izpvjvbwclw.png" />
                        <div className="text">“Our core principle is to spread happiness in whatever way possible. We see Dishaa as an answer to people’s prayer, and a way to attain peace and tranquillity.“</div>
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655361624/About/h2oevqzelcvwtjn3w0fl.png" />
                    </div>

                    <br/><br/><br/>
                    <div className="our-trustees-lakshmi">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655212140/About/fhaedwmpzb7dejfvgkvu.png" />
                        <div className="lakshmi-content">
                            <div className="name">Sadhana Desai</div>
                            <div className="sub-name">Co-Trustee</div>
                            <div className="content">Amazed by Lakshmi’s vision, Sadhana joined Dishaa as a co-trustee at the very beginning. Sadhana retired as a homemaker and dedicated her life to working for the community. She is a Reiki teacher, a fantastic baker and enjoys working with wool and thread. Her infectious laugh can lift anybody’s spirits and her counsel has brought comfort to many.</div>
                        </div>
                    </div>
                    <div className="our-trustees-quotes">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655361413/About/mjl7zyia1izpvjvbwclw.png" />
                        <div className="text">“There is tremendous value in the work we’re doing at Dishaa, it impacts so many lives in such beautiful ways!”</div>
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655361624/About/h2oevqzelcvwtjn3w0fl.png" />
                    </div>
                </div>
            :
                <div className="our-trustees-container">
                    <div className="title">OUR TRUSTEES</div>
                    <div className="our-trustees-lakshmi">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655212133/About/gyzogc0ol7kxemuq3e4k.png" />
                        <div className="lakshmi-content">
                            <div className="name">Lakshmi Bhat</div>
                            <div className="sub-name">Chief Trustee</div>
                            <div className="content">Lakshmi is the soul of Dishaa. She began her career as a software professional but found herself called to her higher purpose as a yoga teacher, tarot card reader, and a Reiki teacher. Lakshmi is a guiding light to many and counsels and heals whoever comes knocking on her door. She started Dishaa Pratishthaan to spread joy, laughter, peace and healing in the community. Being a talented crocheter is another feather in her flamboyant cap!</div>
                        </div>
                    </div>
                    <div className="our-trustees-quotes">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655214152/About/hlbwrgkeclxjxgcid212.png" />
                        <div className="text">“Our core principle is to spread happiness in whatever way possible. We see Dishaa as an answer to people’s prayer, and a way to attain peace and tranquillity.“</div>
                    </div>

                    <br/><br/><br/>
                    <div className="our-trustees-lakshmi">
                        <div className="lakshmi-content">
                            <div className="name">Sadhana Desai</div>
                            <div className="sub-name">Co-Trustee</div>
                            <div className="content">Amazed by Lakshmi’s vision, Sadhana joined Dishaa as a co-trustee at the very beginning. Sadhana retired as a homemaker and dedicated her life to working for the community. She is a Reiki teacher, a fantastic baker and enjoys working with wool and thread. Her infectious laugh can lift anybody’s spirits and her counsel has brought comfort to many.</div>
                        </div>
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655212140/About/fhaedwmpzb7dejfvgkvu.png" />
                    </div>
                    <div className="our-trustees-quotes">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655214462/About/epmivqc2sjc6wovfbrhx.png" />
                        <div className="text">“There is tremendous value in the work we’re doing at Dishaa, it impacts so many lives in such beautiful ways!”</div>
                    </div>
                </div>}

            </Fade>
            <Footer/>
        </div>
    )
};

export default About
