import React, {useState, useEffect} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {phoneCheck} from "./helpers/browserCheck";
import './Journey.scss';

const Journey = () => {

    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
            setSelectedYear('2012');
            console.log(selectedYear)
    }, []);

    return(
        <div className="Journey">
                <Navbar/>

                <div className={phoneCheck() ? "journey-title-mobile" : "journey-title"}>OUR JOURNEY</div>

                <div className="journey-year-container">
                    <div className="journey-years">
                            {journeyItems.map((jy,i) => (
                        <button key={i} onClick={() => setSelectedYear(jy.year)} className="year-button">{jy.year}</button>
                    ))}
                    </div>
                </div>

                <hr className="hr-journey"/>
                {/*<img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1655361624/About/h2oevqzelcvwtjn3w0fl.png" width="50%"/>*/}

                {journeyItems.map((j,i) => (
                    <div key={i} className="journey-content-container">
                            {j.year === selectedYear ?
                                <div className="journey-item-content-container">
                                        <p className="year-title">{j.year}</p>
                                        {j.content.map((jitem, idx) => (
                                            <p key={idx} className="journey-item-content">{jitem}</p>
                                        ))}
                                </div>
                                :
                                null}
                    </div>
                ))}

                {/*{journeyItems.map((j,i) => (*/}
                {/*    <div style={{backgroundColor: '#ffe6e6', margin: '8px', padding: '8px'}}>*/}
                {/*            <button key={i}>{j.year}</button>*/}
                {/*            <hr/>*/}
                {/*            <div>*/}
                {/*                    content*/}
                {/*            </div>*/}
                {/*    </div>*/}
                {/*))}*/}

                <Footer/>
        </div>
    )
};

export default Journey;

const journeyItems = [
        {
                year: 2012,
                content: [
                        "Trustees Lakshmi & Sadhana begin taking in-person Reiki classes, meditation sessions and counselling sessions in Thane."
                ]
        },
        {
                year: 2013,
                content: [
                        "More people begin attending Reiki classes from across Bombay. We expand to sessions for children, teenagers, the elderly."
                ]
        },
        {
                year: 2014,
                content: [
                        "We visit Vatsalya, a women’s old age home and Maa Niketan, an orphanage for young girls, which sparks a need to do something for the community.",
                    "We begin an informal gathering near a low-income settlement in Majiwada, Thane. Under the shade of a tree, we serve a meal to whoever came to us. We teach games, songs, prayers, lessons and stories to children using visual aids, crayons and colouring sheets. We soon begin operating out of a small room and also started providing grains and funds to an anganwadi there."
                ]
        },
        {
                year: 2015,
                content: [
                        "We end operation in Majiwada and begin conducting laughter and yoga camps in various gardens and parks in Thane.",
                    "We resume meal service in a large homeless dwelling under a flyover in Kapurbawdi. Here too we teach children games, songs, prayers, lessons and stories."
                ]
        },
        {
                year: 2016,
                content: [
                        "We shift our operation to a low-income settlement in Azad Nagar, Kapurbawdi. We continue our meal service out of a small room and began teaching children basic English, Hindi and Maths. We also hold workshops for drawing, painting, singing, embroidery, newspaper rolling crafts, paper flower crafts and more. We donate funds to St Joseph’s English High School in the settlement and also conduct summer workshops for their students. Our team teaches them diya painting, paper crafts, quilling, drawing and more.",
                    "Our crafts workshops for women in the settlement begin. Many of these women married and had children while very young. The workshops expand to regular training in embroidery, stitching, quilling, beadwork, newspaper rolling, drawing, painting, and crochet. As an incentive, we offer them a stipend of ₹50 every day."
                ]
        },
        {
                year: 2017,
                content: [
                        "Dishaa Pratishthaan is registered as a trust! \n" +
                        "Our Reiki classes continue getting bigger and we take sessions in Krishnai Hall to accommodate the growing numbers.\n",
                    "Our training sessions results in an enthusiastic team producing beautiful, handcrafted products. Our first major product takes life in time for Raksha Bandhan. Amazon India invites us to set up a stall in their office as a part of their CSR initiative. Orders started pouring in for purses as gifting items for Navratri.",
                    "As our product range expands, we become gifting partners for weddings, haldi-kumkum get-togethers, reunions, birthdays, retirement parties, housewarmings and more."
                ]
        },
        {
                year: 2018,
                content: [
                        "We began renting another room in the settlement establishing one for meal service and one for production. Our operations, products and organization grow larger!",
                    "We are invited to set up stalls in HSBC Bank, SBI headquarter and festivals and events in housing societies around Thane. Our products gain popularity and become preferred gifts, especially during the festive season."
                ]
        },
        {
                year: 2019,
                content: [
                        "We began operating out of two locations - Kapurbawdi and Vasant Vihar. The former is for meal service and production while the latter serves as our head office.",
                    "Through the year, we hold exhibitions on our premises inviting the community to enjoy our products."
                ]
        },
        {
                year: 2020,
                content: [
                        "Despite the difficult lockdown conditions, we safely provide our team of women work from home. After the first lockdown, we resume our meal service in Kapurbawdi.",
                    "We begin taking Reiki classes online! Participants join us from around India, USA, Australia, Singapore, Bahrain. We offer free meditation sessions to those who are not Reiki attuned with great success.",
                    "Since in person events stopped, we took our products online and received orders from across the country and the world."
                ]
        },
        {
                year: 2021,
                content: [
                        "We start donation projects to other organisations. Our donations included menstrual hygiene products, masks, cloth pads, crochet caps, booties & blankets to orphanages, hospitals, and other non-profit organisations. All made in-house by our volunteers.",
                    "Associate brands begin working with our team of ladies and contributing funds to Dishaa.",
                    "Lakshmi adds Crystal Therapy to her repertorie and we begin offering online sessions which are received with great enthusiasm."
                ]
        },
        {
                year: 2022,
                content: [
                        "Our organisation gets a huge donation in the form of industrial sewing machines for our team. We resume in-person Reiki classes! Starting with our team of ladies in Kapurbawdi.",
                    "Our donation projects begin within our operations base. As children go back to school, we donate books and stationery to them."
                ]
        }
];
