import React, {useEffect, useState} from 'react';
import jwt from 'jsonwebtoken';
import './ResetPassword.scss';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {resetPassword} from "./CoreApi";
import {useHistory} from "react-router-dom";
import logo from "../assets/DishaaLogo.png";
import {check} from "./helpers/browserCheck";

const ResetPassword = ({ match }) => {

    const [values, setValues] = useState({
        name: '',
        token: '',
        newPassword: '',
        confirmNewPassword: '',
        buttonText: 'Reset Password',
        passwordMatchError: ''
    });
    const { name, token, newPassword, confirmNewPassword, buttonText, passwordMatchError } = values;
    const history = useHistory();

    const handleChange = field =>  event => {
        setValues({... values, [field]: event.target.value, passwordMatchError: '' })
    };

    const handleSubmit = () => {
        if(newPassword !== confirmNewPassword) {
            setValues({...values, passwordMatchError: 'Passwords do not match! Try again.'})
        } else {
            resetPassword(newPassword, token)
                .then(data => {
                    if (data.error) {
                        setValues({...values, passwordMatchError: data.error});
                    } else {
                        setValues({...values, newPassword: '', confirmNewPassword: '' , buttonText: "Success!"});
                        history.push('/login');
                    }
                });
        }

    };

    const showValidationErrors = () => (
        <div className="validation-error-block">
                <ul style={{textAlign: 'left', display: passwordMatchError ? 'none' : ''}}>
                    Password:
                    <li>Must be more than 8 characters long </li>
                    <li>Must contain at least one lowercase letter (a-z)</li>
                    <li>Must contain at least one number (0-9)</li>
                </ul>
            {passwordMatchError && <p>{passwordMatchError}</p>}
        </div>
    );

    useEffect(() => {
        let token = match.params.token;
        let { name } = jwt.decode(token);
        if(token) {
            setValues({...values, name, token})
        }
    },[]);

    return(
        <div className="ResetPassword">
            <Navbar/>
            <div className={check() ? "reset-password-container-mobile" : "reset-password-container"}>
                <div>
                    <img src={logo}
                         alt="logo"/>
                    <h3>Hi {name}, enter your new password below!</h3>
                </div>
                <div className='text-input-container'>
                    {showValidationErrors()}
                    <div className="field">
                        <input type="password" name="newPassword" id="newPassword" placeholder="Enter your password" onChange={handleChange('newPassword')} value={newPassword} required/>
                        <label htmlFor="newPassword">Password</label>
                    </div>
                    <div className="field">
                        <input type="password" name="confirmNewPassword" id="confirmNewPassword" placeholder="Confirm your password" onChange={handleChange('confirmNewPassword')} value={confirmNewPassword} required/>
                        <label htmlFor="confirmNewPassword">Confirm Password</label>
                    </div>
                </div>
                <div>
                    <button type='submit' className={check() ? 'button-mobile' : 'button'} onClick={handleSubmit}>{buttonText}</button>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default ResetPassword;
