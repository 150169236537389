import React from 'react';

const CarouselLeftArrow = ({ onClick }) => {
    return(
        <a className="carousel__arrow carousel__arrow--left" href="#" onClick={onClick}>
            <span className="fa fa-2x fa-angle-left" />
        </a>
    )
};

export default CarouselLeftArrow