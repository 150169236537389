import React from 'react';

const CarouselIndicator = ({ index, activeIndex, isActive, onClick }) => {
    return(
        <li>
            <a className={index === activeIndex ? "carousel__indicator carousel__indicator--active" : "carousel__indicator"} onClick={onClick}/>
        </li>
    )
};

export default CarouselIndicator