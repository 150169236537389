import React, {useState} from 'react';

const CarouselSlide = ({ index, activeIndex, slide }) => {

    const [translatedVersion, setTranslatedVersion] = useState(false);

    return(
        <li className={index === activeIndex ? "carousel__slide carousel__slide--active" : "carousel__slide"}>
            <p className="carousel-slide__quote">
                { translatedVersion ? slide.translation : slide.quote}
                {activeIndex === 1 && <div onClick={() => setTranslatedVersion(!translatedVersion)} className="translation-cta">{translatedVersion ? 'Back to original' : 'See translation'}</div>}
            </p>

            <p>
                <strong className="carousel-slide__user">{slide.user}</strong>
            </p>
        </li>
    )
};

export default CarouselSlide