import React, {useEffect, useState} from 'react';
import Layout from "../Layout";
import { isAuthenticated } from "../../auth";
import {Button, Form} from "semantic-ui-react";
import { login, authenticate } from "../../auth";
import { Link, Redirect, useHistory } from 'react-router-dom';
import {getPurchaseHistoryForUser} from "./UserApi";
import moment from 'moment';
import Navbar from "../Navbar";
import './UserDashboard.scss';
import Profile from "./Profile";
import PrivateRoutes from "../../auth/PrivateRoutes";
import PurchaseHistory from "./PurchaseHistory";
import TreasureBox from "./TreasureBox";
import Footer from "../Footer";
import Navratri from "./Navratri";
import {check} from "../helpers/browserCheck";
import DetailedOrder from "./DetailedOrder";

const UserDashboard = () => {

    const [history, setHistory] = useState([]);
    const [activeStyle, setActiveStyle] = useState( '');
    const browser_history = useHistory();


    const { user, token } = isAuthenticated();

    const isActive = (history, path) => {
        if(history.location.pathname === path) {
            return { color: '#f00' }
        } else {
            return {  color: '#000' }
        }
    };

    const settingsList = () => {
        return(
            <div className="settings-list">
                <h4 className="">Your account</h4>
                <ul className="user-links">

                    <Link to={`/account/profile/${user._id}`} style={isActive(browser_history, `/account/profile/${user._id}`)}>
                        <li>Profile</li>
                    </Link>

                    <Link to={`/account/orders`} style={isActive(browser_history, `/account/orders`)}>
                        <li>Purchase history</li>
                    </Link>
                    {user.level !== 'Not attuned yet' && <li className="">
                        <Link to={`/account/treasure-box`} style={isActive(browser_history, `/account/treasure-box`)}>Treasure Box</Link>
                    </li>}
                    {user.navratri === 'Yes' && <li className="">
                        <Link to={`/account/10Meditations`} style={isActive(browser_history, `/account/10Meditations`)}>10 Meditations to <br/>Circle Inwards</Link>
                    </li>}

                </ul>
            </div>
        )
    };

    const init = (userId, token) => {
        // append history of digital products into this data array when needed
        getPurchaseHistoryForUser(user._id, token)
            .then(data => {
                if(data.error) {
                    console.log(data.error);
                } else {
                    setHistory(data);
                }
            })
    };

    useEffect(() => {
        init(user._id, token)
    }, []);

    return(
        <div className="UserDashboard">
            <Navbar/>
            <div className={check() ? "settings-container-mobile" : "settings-container"}>
                {settingsList()}
                <div className="settings">
                    <PrivateRoutes path="/account/orders" component={() => <PurchaseHistory history={history} />}/>
                    <PrivateRoutes path="/account/profile/:userId" component={Profile}/>
                    {user.level !== 'Not attuned yet' && <PrivateRoutes path="/account/treasure-box" component={TreasureBox}/>}
                    {user.navratri === 'Yes' && <PrivateRoutes path="/account/10Meditations" component={Navratri} />}

                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default UserDashboard;
