import React from 'react';
import {Link, Redirect} from "react-router-dom";
import {check} from "./helpers/browserCheck";
import {isAuthenticated} from "../auth";

const GuestCheckout = () => {
    return(
        // isAuthenticated() ?
            <div style={style}>
            <p>Please <Link to="/login" style={linkStyle}>sign in</Link> or <Link to="/register" style={linkStyle}>create an account</Link> to buy our products online.</p>
            <hr style={hrStyle}/>
            <p>If you would like to make an offline purchase<br/>
                get in touch with us on +91 91528 43218.
            </p>
        </div>
            // :
            // <Redirect to='/cart' />
    )
};

export default GuestCheckout;

export const style = {
    textAlign: 'center',
    padding: check() ? '30px' : '60px',
    fontSize: '15px'
};

export const hrStyle = {
    marginLeft: check() ? '10%' : '30%',
    marginRight: check() ? '10%' : '30%'
};

export const linkStyle = {
    color: 'black',
    textDecoration: 'underline',
    fontWeight: 'bold'
};
