import React, {useState} from 'react';
import { Link, useHistory} from 'react-router-dom';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './Donate.scss';
import image from '../assets/Revamp/Donate.JPG';
import {createDonateOrder, razorpay} from "./CoreApi";
import logo from "../assets/DishaaLogo.png";
import {isAuthenticated} from "../auth";
import {check} from "./helpers/browserCheck";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true)
        };
        script.onerror = () => {
            resolve(false)
        };
        document.body.appendChild(script)
    })
}

const Donate = () => {

    const [selected, setSelected] = useState(false);
    const history = useHistory();
    const { user, token } = isAuthenticated();
    const [values, setValues] = useState({
        name: user ? user.firstName + ' ' + user.lastName : '',
        email: user ? user.email : '',
        phone: user ? user.callNumber : '',
        pancard: '',
        amount: 0,
        success: false,
        error: ''
    });

    const { name, email, phone, pancard, amount, success, error } = values;


    const handleChange = field => event => {
        setValues({ ...values, [field]: event.target.value });
    };

    const placeDonateOrder = (name, email, phone, pancard, amount, paymentId) => {

        createDonateOrder({payment_id: paymentId,
            amount: amount,
            name: name,
            email: email,
            phone: phone,
            pancard: pancard})
            .then(res =>
                {
                    setValues({...values,
                        name: user ? user.firstName + ' ' + user.lastName : '',
                        email: user ? user.email : '',
                        phone: user ? user.callNumber : '',
                        amount: '',
                        pancard: ''})
                })
            .catch(error => {
                console.log(error);
            });
    };

    const handleError = () => (
        <div className="" style={{ display: error ? '' : 'none', color: 'red', padding: '16px', borderRadius: '4px', background: '#fcceca', marginBottom: '16px' }}>
            {error}
        </div>
    );

    async function displayRazorPay() {

        if(pancard === '' || !validPancard.test(pancard)) {
            setValues({ ...values, error: 'Enter a valid 10-digit pan card number.'})
        } else if(email === '' && !validEmail.test(email)) {
            setValues({ ...values, error: 'Enter a valid email address.'})
        } else if(phone === '' && !validPhone.test(phone)) {
            setValues({ ...values, error: 'Enter a valid phone number.'})
        } else if(name === '') {
            setValues({ ...values, error: 'Name is required.'})
        } else if (amount === 0) {
            setValues({ ...values, error: 'Please select an amount to donate OR enter an amount of your choice.'})
        } else {
            setValues({ ...values, error: false});
            const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

            if (!res) {
                alert('Razorpay SDK failed to load. Are you online?');
                return
            }

            razorpay(amount).then(res => {

                const options = {
                    key: 'rzp_live_yFrqffHWFPwGBU',
                    // key: 'rzp_test_ibi4ooyfwxXdZ2',
                    currency: 'INR',
                    amount: res.amount.toString(),
                    order_id: res.order_id,
                    name: 'Donation to Dishaa',
                    image: {logo},
                    payment: {
                        capture : 'automatic',
                        capture_options : {
                            automatic_expiry_period : 12,
                            // manual_expiry_period : 7200,
                            // refund_speed : 'optimum'
                        }
                    },
                    handler: function (response) {

                        placeDonateOrder(
                            user ? user.firstName + ' ' + user.lastName : name,
                            user ? user.email : email,
                            user ? user.callNumber : phone,
                            pancard,
                            amount,
                            response.razorpay_payment_id);
                        history.push('/donation-complete');
                        // alert(response.razorpay_payment_id)
                        // alert(response.razorpay_order_id)
                        // alert(response.razorpay_signature)
                    },
                    prefill: {
                        name: user ? user.firstName + ' ' + user.lastName : name,
                        email: user ? user.email : email,
                        contact: user ? user.callNumber : phone
                    }
                };
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();

            })

        }
    }


    const handleQuickAmountClick = (amount) => {
        setValues({...values, amount});
        setSelected(true);
    };

    const handleCustomAmountClick = (amount) => {
        setValues({...values, amount});
        setSelected(false);
    };

    const donateDiv = () => (
        <div className={check() ? "donate-container-mobile" : "donate-container"}>
            <div className="donate-info">
                <img src={image}/>
                <p><b>Dishaa Pratishthaan is a proudly women-led non-profit organisation.</b> <br/> We hope to be the answer to the prayers of those in need.</p>
                <p>Donations help us provide nourishing meals to the underprivileged children of a low-income area in Kapurbawdi, Thane throughout the week. We also help us train underprivileged women from the same slum with skills that they can use to become financially independent along with donating various items to other on-ground organisations in need.</p>
                <p>Take action and donate with us today. <br/> Your contribution will help feed the body and minds of many!</p>
                <p>Dishaa Pratishthaan is a registered non-profit charitable organisation (Regn no: E-10282/Thane) under Section 80g of Income Tax Act 1961 of India.</p>
            </div>
            <div className="donate-form">
                <p className="title">DONATE NOW</p>
                <div>
                    {handleError()}
                    <p className="payment-option-title">Enter your details</p>
                    <div className="field">
                        <input className="inputField" id="name" name="name" type="text" placeholder="Enter your full name" value={name} onChange={handleChange('name')}/>
                        <label htmlFor="name">Name</label>
                    </div>
                    <div className="field">
                        <input className="inputField" id="email" name="email" type="text" placeholder="Enter your email address" value={email} onChange={handleChange('email')}/>
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="field">
                        <input className="inputField" id="phone" name="phone" type="text" placeholder="Enter your phone number" value={phone} onChange={handleChange('phone')}/>
                        <label htmlFor="phone">Phone number</label>
                    </div>
                    <div className="field">
                        <input className="inputField" id="pancard" name="pancard" type="text" placeholder="Enter PAN card number" value={pancard} onChange={handleChange('pancard')}/>
                        <label htmlFor="pancard">PAN Card Number</label>
                    </div>
                </div>
                <p className="payment-option-title">Choose amount</p>
                <div className="amount-button-group">
                    <button className={amount === 500 ? "button-active btn-grp" : "button btn-grp"} onClick={() => handleQuickAmountClick(500)}>₹500/-</button>
                    <button className={amount === 1000 ? "button-active btn-grp" : "button btn-grp"} onClick={() => handleQuickAmountClick(1000)}>₹1000/-</button>
                    <button className={amount === 1500 ? "button-active btn-grp" : "button btn-grp"} onClick={() => handleQuickAmountClick(1500)}>₹1500/-</button>
                </div>
                <div className="amount-button-group2">
                    <button className={amount === 2000 ? "button-active btn-grp" : "button btn-grp"} onClick={() => handleQuickAmountClick(2000)}>₹2000/-</button>
                    <div className="btn-grp form-with-icon">
                        <i className="fa fa-rupee"/>
                        <input className="inputField" type="text" placeholder="Custom amount"
                               onChange={(e) => handleCustomAmountClick(e.target.value)}
                               value={selected ? '' : amount}
                        />
                    </div>
                </div>

                <p className="payment-option-title">Complete payment <span><i className="fa fa-lock"/> SECURE</span></p>
                <div className="donate-button-group">
                    <button className="button payment-button"
                            onClick={displayRazorPay}>
                        Card/UPI/GPay
                    </button>

                </div>
                <div className="donate-disclaimer">
                    <p>Dishaa Pratishthaan is committed to your privacy, please read our <a href={window.location.origin+"/important-links/#privacyPolicy"}>privacy policy here</a>. Your payment details will be processed by <a href="https://razorpay.com/privacy/" target="_blank">Razorpay</a>, a payment gateway company (for credit/debit cards), and a record of your donation will be stored by Dishaa Pratishthaan.</p>
                    <p>For any queries, issues or an alternative way to donate regarding donations, <Link to="/contact">contact us</Link>.</p>
                    <p>Dishaa Pratishthaan is a non-profitable charitable trust registered under 80G and 12AA. Donations go to Dishaa Pratishthaan to be used at their discretion for its various charitable purposes. Your contribution to Dishaa Pratishthaan is tax-deductible.</p>
                    <p>We do not have the facility for international donations at this moment.</p>
                </div>

            </div>
        </div>
    );


    return(
        <div className="Donate">
            <Navbar/>
            {donateDiv()}
            <Footer/>
        </div>
    )
};

export default Donate;

export const validPhone = new RegExp('^[0-9]{8,12}$')
export const validEmail = new RegExp('.+\\@.+\\..+');
export const validPancard = new RegExp('^[a-zA-Z0-9]{10}$');
