import React, {useState} from 'react';
import Layout from "../Layout";
import {Button, Form} from "semantic-ui-react";
import { login, authenticate, isAuthenticated } from "../../auth";
import {Redirect, Route} from 'react-router-dom';
import {Link, useHistory} from "react-router-dom";
import Navbar from "../Navbar";
import './Login.scss';
import logo from '../../assets/DishaaLogo.png';
import ForgotPassword from "../ForgotPassword";

const Login = ({ location }) => {

    const [values, setValues] = useState({
        email: '',
        password: '',
        error: '',
        loading: false,
        redirectToReferrer: false
    });
    const [visible, setVisible] = useState(false);
    const { email, password, loading, error, redirectToReferrer } = values;
    const { user } = isAuthenticated();
    const history = useHistory();

    const handleChange = field => event => {
        setValues({ ...values, error: false, [field]: event.target.value });
    };

    // const handleShowPassword = () => {
    //     setVisible(!visible);
    // };

    const showLoginForm = () => (
        <div className="login-form-container">

            <form onSubmit={handleLogin} className="login-form">
                <div>
                    <img src={logo}
                     alt="logo"/>
                    <h3>Sign in to your Dishaa account</h3>
                </div>
                <div className='text-input-container'>
                    <div className="field">
                        <input type="email" name="email" id="email" placeholder="Enter your email" onChange={handleChange('email')} value={email}/>
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="field">
                        <i className={visible ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => setVisible(!visible)}>&nbsp; {visible ? "Hide" : "See"} Password</i>
                        <input type={visible ? "text" : "password"} name="password" id="password" placeholder="Enter your password" onChange={handleChange('password')} value={password}/>
                        <label htmlFor="password">Password</label>
                    </div>

                    {/*<input className="text-input" type="email" placeholder='Email (required)' onChange={handleChange('email')} value={email} />*/}
                    {/*<input className="text-input" placeholder='Password (required)' type="password"  onChange={handleChange('password')} value={password}/>*/}
                </div>
                <div>
                    <Link to='/forgotpassword' className="forgot-password">Forgot password?</Link>
                </div>
                <div>
                    <button type='submit' className='button'>Login</button>
                </div>
                {showRegister()}
            </form>
        </div>
    );

    const handleLogin = event => {
        event.preventDefault();
        setValues({ ...values, error: false, loading: true });
        login( {email, password} )
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, loading: false });
                } else {
                    authenticate(data,
                        () => setValues({
                            ...values,
                            redirectToReferrer: true
                        }))
                }
            });
    };

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showLoadingState = () => (
        loading &&
        (<div className="alert alert-info">
            <h3>Loading...</h3>
        </div>)
    );

    const redirectUser = () => {
        if(redirectToReferrer) {
            if(user && user.role === 1) {
                return <Redirect to="/admin/account" />
            } else {
                // console.log('from: ', location.state.from)
                history.go(-2);
                // return <Redirect to={`/account/profile/${user._id}`} />
            }
        }
        if(isAuthenticated()) {
            return <Redirect to="/" />
        }
    };

    const showRegister = () => (
        <div className="register-message">
            <h3>Don't have an account?</h3>
            <Link to={`/register`}>Create Account</Link>
        </div>
    );

    return(
        <div className="Login">
            <Navbar/>
                {showLoadingState()}
                {showError()}
                {redirectUser()}
                {showLoginForm()}

        </div>
    )
};

export default Login;
