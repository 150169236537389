import React, {useEffect, useState} from "react";
import {getRelatedProducts, getSingleProduct} from "./CoreApi";
import DisplayImage from "./DisplayImage";
import {addItemToCart, isItemPresentInCart, updateCartItem} from "./helpers/cart";
import Navbar from "./Navbar";
import './ProductDetailPage.scss';
import {Link} from "react-router-dom";
import {info} from "./coursesInfo";
import Footer from "./Footer";
import {check} from "./helpers/browserCheck";

const ProductDetailPage = (props) => {

    const [product, setProduct] = useState({});
    const [relatedProduct, setRelatedProduct] = useState([]);
    const [error, setError] = useState(false);
    const [quantity, setQuantity] = useState(product.count);
    const [addedToCart, setAddedToCart] = useState(false);

    const addToCart = () => {
        addItemToCart(product, () => {
            // setRedirect(true);
            setAddedToCart(true);
        })
    };

    const loadSingleProduct = productId => {
        if(product) {
            getSingleProduct(productId)
                .then(data => {
                    if(data.error) {
                        setError(data.error)
                    }
                    else {
                        setProduct(data);
                        getRelatedProducts(data._id).then(data => {
                            if(data.error) {
                                setError(data.error)
                            } else {
                                setRelatedProduct(data);
                            }
                        })
                    }
                })
        }
    };

    const handleQuantityChange = productId => event => {
        let quant = event.target.value;
        setQuantity(quant < 1 ? 1 : quant);
        if(quant >= 1) {
            updateCartItem(productId, quant);
        }
    };

    const showQuantity = () => (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <span className="input-group-text">
                    Quantity
                </span>
            </div>
            <input type="number" className="form-control" value={quantity} onChange={handleQuantityChange(product._id)}/>
        </div>
    );

    const goBack = () => (
        <div className="go-back">
            <Link to="/reiki">Back</Link>
        </div>
    );

    const classInfo = (c,i) => (
        c.comingSoon ?  showComingSoonMessage() :
            <div key={i} className="course-info">

                <div className="learn">
                    <p className="title">What will you learn?</p>
                    {c.whatWillYouLearn.map((l, index) => (
                        <p className="content" key={index}>{l}</p>
                    ))}
                </div>


                <div className={check() ? "first-band-mobile" : "first-band"}>
                    <div className="outline-box">
                        <p className="title">What can you do with {product.name}?</p>
                        <ul>{c.whatCanYouDo.map((w,index) => (
                            <li key={index}>{w}</li>
                        ))}</ul>
                    </div>
                    <div className="filled-box">
                        <p className="title">Mindset changes that you can hope to achieve?</p>
                        <ul>{c.mindsetChanges.map((m,index) => (
                            <li key={index}>{m}</li>
                        ))}</ul>
                    </div>
                    <div className="outline-box">
                        <p className="title">Course schedule over the 2 days</p>
                        <ul>{c.courseSchedule.map((s,index) => (
                            <li key={index}>{s}</li>
                        ))}</ul>
                    </div>
                </div>


                <div className={check() ? "second-band-mobile" : "second-band"}>
                    <div className="box1">
                        <p className="title">Materials provided</p>
                        <ul>{c.materialsProvided.map((m,index) => (
                            <li key={index}>{m}</li>
                        ))}</ul>
                    </div>
                    <div className="box2">
                        <p className="title">Language of instruction</p>
                        <p>{c.language}</p>
                    </div>
                    <div className="box3">
                        <p className="title">Who is this course for?</p>
                        <ul>{c.whoIsThisCourseFor.map((who,index) => (
                            <li key={index}>{who}</li>
                        ))}</ul>
                    </div>
                </div>

                <div className="schedule">
                    Interested? <Link to='/schedule'>See schedule</Link>
                </div>


                <div className="faq">
                    <p className="faq-title">FAQs</p>
                    <div className="faq-content">
                        {c.FAQs.map((faq,index) => (
                            <div key={index}>
                                <p className="faq-question"><span className="q">Q.</span> <span>{faq.question}</span></p>
                                <p className="faq-answer"><span className="a">A.</span> <span>{faq.answer}</span></p>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
    );

    const showComingSoonMessage = () => (
        <div className="coming-soon">
            Coming Soon!
        </div>
    );

    const getAMPM = (time) => {
        let arr = time.split(":");
        let hh = parseInt(arr[0]);
        if(hh >= 12) {
            return 'PM'
        } else {
            return 'AM'
        }
    };

    const getTime = (time) => {
        let ampm = '';
        let arr = time.split(":");
        let hh = parseInt(arr[0])+2;
        let h=0;
        if(hh >= 12) {
            h = hh - 12;
            ampm = 'PM';
            if(h>=12){
                h = h - 12;
                ampm = 'PM';
            }
        } else {
            h = hh;
            ampm = 'AM';
        }
        if(h === 0) {
            h=12;
            ampm='PM'
        }
        return h.toString()+':'+arr[1]+ampm;
    };

    useEffect(() => {
        const productId = props.match.params.productId;
        loadSingleProduct(productId)
    }, [props, addedToCart]);

    return (
        <div className="PDP">

            <Navbar/>

            <div className="pdp-wrapper">

                {info.categories.map((cat, index) => {
                    return cat.courses.map((course, indx) => {
                        if(course.course.toUpperCase() === (product.name || '').toUpperCase()){
                            return (
                                <>
                                    <div className="pdp-jumbotron">
                                        <span className="pdp-img">
                                            <DisplayImage item={product} url="product"/>
                                        </span>
                                        <span className="pdp-name__info">
                                            <p className="pdt-name">{product.name}</p>
                                        </span>
                                    </div>
                                    {classInfo(course,indx)}
                                </>)
                        }
                    })
                })}

                {/*{info.map((c,i) => {*/}
                {/*    if(c.course.toUpperCase() === (product.name || '').toUpperCase()) {*/}
                {/*        return (*/}
                {/*        <>*/}
                {/*            <div className="pdp-jumbotron">*/}
                {/*                <span className="pdp-img">*/}
                {/*                    <DisplayImage item={product} url="product"/>*/}
                {/*                </span>*/}
                {/*                <span className="pdp-name__info">*/}
                {/*                    /!*{!c.comingSoon && <p className="date-time">{`Date: ${moment(product.date).format("MMM Do")} - ${moment(product.date).add(1, 'days').format("MMM Do")} ${moment(product.date).format("YYYY")} • Time: ${product.time + getAMPM(product.time)} to ${getTime(product.time)} Indian Standard Time (IST)`}</p>}*!/*/}
                {/*                     <p className="pdt-name">{product.name}*/}
                {/*                     /!*• {c.comingSoon ? 'Price TBD' : ₹`${product.price}`}*!/*/}
                {/*                     </p>*/}
                {/*                    /!*<p><b>₹{c.comingSoon ? 'TBD' : `${product.price}`}</b></p>*!/*/}
                {/*                </span>*/}
                {/*                /!*{!c.comingSoon && <span>*!/*/}
                {/*                /!*    <button onClick={addToCart} className="button">{addedToCart ? 'Added' : 'Add to cart'}</button>*!/*/}
                {/*                /!*</span>}*!/*/}
                {/*            </div>*/}
                {/*            {classInfo(c,i)}*/}
                {/*        </>)*/}
                {/*    }*/}
                {/*})}*/}
            </div>

            <Footer/>
        </div>
    );


};

export default ProductDetailPage
