import React, {useEffect} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './WhatWeDo.scss';
import { iPadCheck, phoneCheck} from "./helpers/browserCheck";
import rollacoaster from '../assets/Revamp/whatwedo/rollacoaster.JPG';
import beadnbloom from '../assets/Revamp/whatwedo/beadnbloom.JPG';
import theartseies from '../assets/Revamp/whatwedo/theartseries.JPG';
import patacake from '../assets/Revamp/whatwedo/patacake.JPG';
import thedenimproject from '../assets/Revamp/whatwedo/thedenimproject.JPG';
import popopaint from '../assets/Revamp/whatwedo/popopaint.JPG';
import knottyknots from '../assets/Revamp/whatwedo/knottyknots.JPG';
import thebagtree from '../assets/Revamp/whatwedo/thebagtree.JPG';
import yarnaffairs from '../assets/Revamp/whatwedo/yarnaffairs.JPG';
import a1creations from '../assets/Revamp/whatwedo/A1 Creations.jpg';
import quilingstory from '../assets/Revamp/whatwedo/quillingstory.jpg';
import mealService from '../assets/Revamp/mealService.JPG';
import mindfulness from '../assets/Revamp/mindfulness.JPG';
import socialImpact from '../assets/Revamp/socialImpact.jpeg';
import womenEmpowerment from '../assets/Revamp/womenEmpowerment.JPG';
import mindfulnessOnline from '../assets/Revamp/mindfulnessOnline.png';
import we2 from '../assets/Revamp/whatwedo/New.JPG'
import we3 from '../assets/Revamp/whatwedo/New2.jpeg'
import we4 from '../assets/Revamp/whatwedo/IMG_8687.JPG'

import shapeup from '../assets/Revamp/whatwedo/shapeup.PNG';
import pulseofvedas from '../assets/Revamp/whatwedo/ayurveda.jpeg';
import saidriving from '../assets/Revamp/whatwedo/sai.png';
import swayampak from '../assets/Revamp/whatwedo/swayampak.png';
import workoutwithme from '../assets/Revamp/whatwedo/workoutwithme.PNG';
import anandyoga from '../assets/Revamp/whatwedo/anandyoga.png';
import {Link} from "react-router-dom";

const WhatWeDo = () => {

    useEffect(() => {
        // window.scrollTo(0, 0);
    }, []);

    return(
        <div className="WhatWeDo">
            <Navbar/>
            {phoneCheck() ?

            <div className="intro-mobile">

                <div className="inner-container">
                    <h1 className="title">WHAT WE DO</h1>
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651162169/WhatWeDo/vpduhx4kea7mazztyc29.jpg"/>
                    <div className="intro-text">
                        <p>We nourish the mind, body, and soul. Our mission is to uplift, empower and spread happiness to people from all walks of life.</p>
                        <p>Dishaa is guided by the basic principles of Reiki, which teach us to be free of anger, and worry, dedicated to our work, grateful, and respect everybody, most importantly ourselves.</p>
                    </div>
                </div>
            </div>
                :
                <div className={iPadCheck() ? "intro-tablet" : "intro"}>
                    <div className="inner-container">
                        <h1 className="title">WHAT WE DO</h1>
                        <div className="intro-text">
                            <p>We nourish the mind, body, and soul. Our mission is to uplift, empower and spread happiness to people from all walks of life.</p>
                            <p>Dishaa is guided by the basic principles of Reiki, which teach us to be free of anger, and worry, dedicated to our work, grateful, and respect everybody, most importantly ourselves.</p>
                        </div>
                    </div>
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650875104/WhatWeDo/gmodohozttw7aijepdws.png"/>
                </div>
            }


            <div className="what-we-do">
                <div className='what-we-do-container' id="mealService">
                    <div className="title"><i>ANNADAN</i> - MEAL SERVICE</div>
                    <div className="inner-container annadan">
                        {/*<img src={mealService} width={phoneCheck() ? "100%" : iPadCheck() ? "70%" : "40%"} />*/}
                        <p>According to the ​​Global Hunger Index report of 2021, ‘the fight against hunger is dangerously off-track,’ in India. We’ve been tackling hunger within our community since 2015. We envision a world in which everyone has a full stomach, a head full of dreams, and the will to make them happen.</p>
                        <div className="annadan-background">
                            <img className="annadan-image"
                                 src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651166703/WhatWeDo/lyd0tmgkwxaaqzsewxjk.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650875117/WhatWeDo/hyqjagnpzobfqalrlh6r.png"} width="100%"/>
                            <p>We serve a nourishing meal of <i>khichdi</i> - rice, vegetables, mixed lentils, tempered with masalas - to children and people in need in the community. </p>
                            <p>In low-income communities, children are often on their own until their daily wage-earning parents return. With many mouths to feed in each home, adequate nutrition is a huge hurdle.</p>
                                <p>We began in a small <i>basti</i> in Majhiwada, Thane and have since moved our base to a <i>basti</i> in Kapurbawdi, Thane. From a room within the tenement, we serve <i>khichdi</i> throughout the week.</p>
                            <p>We have over the years provided additional meals to construction workers, homeless people in various pockets of Thane, families of the sick outside Thane Civil Hospital. During the migrant crisis of 2020, we served packaged food to those leaving from Thane’s Majiwada bus junction.</p>
                        <p>Help us nourish more lives <a href="https://pages.razorpay.com/pl_LznAGUuHa3xYw6/view" className="here-link" target="_blank">here.</a></p>
                        </div>
                    </div>
                </div>

                <div className='what-we-do-container' id="womenEmpowerment">
                    <div className="title" >WOMEN EMPOWERMENT</div>
                    <div className={phoneCheck() ? "inner-container women-empowerment-mobile" : iPadCheck() ? "inner-container women-empowerment-tablet" : "inner-container women-empowerment"}>
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650890954/WhatWeDo/gkweihdhjgjv8vszbdjs.png" />
                        <div className="women-empowerment-text">
                            <p>As a women-led organisation, we empower women from all walks of life.</p>
                            <p>With alternative Reiki therapy, and counselling sessions countless women have found a safe space at Dishaa. With us, they find solace from their burdens, find purpose, mentors for their work and businesses, joy, laughter, support, care and so much more.</p>
                            <p>From our base in Kapurbawdi, we train underprivileged women in various skills and crafts and pay them a salary too. They are skilled in stitching, crochet, newspaper rolling, beadwork, quilling and more. <br/> They now make products for <Link to="/associate-brands" className="here-link"> various businesses.</Link></p>
                            <p>We also support them with yoga, Reiki, meditation, counselling sessions, workshops, financial assistance for their children’s education.</p>
                            <p>Join us in empowering more women <a href="https://pages.razorpay.com/pl_LznAGUuHa3xYw6/view" className="here-link" target="_blank">here.</a></p>
                        </div>
                    </div>
                </div>

                <div className='what-we-do-container' id="socialImpact">
                    <div className="title">SOCIAL IMPACT ACTIVITIES</div>
                    {(iPadCheck() || phoneCheck()) ? <div className="inner-container social-impact-tablet">
                        <div className="social-impact-text">
                            <p>Throughout the year, we conduct various activities that positively impact the community.</p>
                            <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651167384/WhatWeDo/ibyyt1laqf6emyhxzouu.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650895628/WhatWeDo/kfpwbza6d1gut28z0ory.png"} />
                            <p>We take summer workshops with underprivileged children, instilling a love to learn and nurturing their creativity. To benefit their mental and emotional well-being, we use positive affirmations, prayer and meditation in our workshops too.</p>
                            <p>We donate <b>menstrual hygiene products, masks, cloth pads, crochet caps, booties & blankets</b> to orphanages, hospitals, and other non-profit organisations. All these items are made in-house by our volunteers.</p>
                            <p>Uplift more lives with us <Link to="/courses" className="here-link">here.</Link></p>
                        </div>
                    </div>
                    :
                    <div className="inner-container social-impact">
                        <div className="social-impact-text">
                            <p>Throughout the year, we conduct various activities that positively impact the community.</p>
                            <p>We take summer workshops with underprivileged children, instilling a love to learn and nurturing their creativity. To benefit their mental and emotional well-being, we use positive affirmations, prayer and meditation in our workshops too.</p>
                            <p>We donate <b>menstrual hygiene products, masks, cloth pads, crochet caps, booties & blankets</b> to orphanages, hospitals, and other non-profit organisations. All these items are made in-house by our volunteers.</p>
                            <p>Uplift more lives with us <Link to="/courses" className="here-link">here.</Link></p>
                        </div>
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650895628/WhatWeDo/kfpwbza6d1gut28z0ory.png" />
                    </div>}
                </div>

                <div className='what-we-do-container' id="dishaaProducts">
                    <div className="title">GIFTING HAMPERS</div>
                    <div className="inner-container">
                        <div className="gifting-hampers-text">
                            <p>When you think of celebrating, think of us! Our hampers are handcrafted with careful attention to detail and come in various colours and moods. They are perfect for weddings, festivals, birthday parties, farewells, and other celebrations. All the hampers are handcrafted and sourced from women-led businesses associated with us. The proceeds from the sale of the hampers go towards our annadan service and the financial independence of the women we support.</p>
                            <p>Buy a gift hamper <a href="https://www.dishaamela.com/shop-all" target="_blank" className="here-link">here.</a></p>
                        </div>
                    </div>
                </div>

                {iPadCheck() ? <div className='what-we-do-container' id="mindfulness">
                    <div className="title">ALTERNATIVE HEALING SESSIONS</div>
                    <p className="healing-sesh-text-tablet">Every month we conduct Reiki sessions that have helped several people lead a better, holistic life. We offer Reiki Levels 1 to 3B, crystal therapy, tarot card readings, etc.</p>
                    <div className="inner-container alt-healing-sessions">

                        <div className="alt-healing-session-text">

                            <p>Our Reiki grandmasters have been teaching since 2012 and have helped over 300 people find solace in spirituality.</p>
                            <p>Read about Reiki <Link to="/courses" className="here-link">here.</Link></p>
                        </div>
                        <img className="healing-illustration" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650897500/WhatWeDo/l4adnyxa8xkyifxo39dx.png" />

                    </div>
                    <img className="healing-collage" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650897501/WhatWeDo/pzjwdhpamczayzp1zbmd.png" />
                </div> :
                    <div className='what-we-do-container' id="mindfulness">
                        <div className="title">ALTERNATIVE HEALING SESSIONS</div>
                        <div className={phoneCheck() ? "inner-container alt-healing-sessions-mobile" : "inner-container alt-healing-sessions"}>

                            <div className="alt-healing-session-text">
                                <p>Every month we conduct Reiki sessions that have helped several people lead a better, holistic life. We offer Reiki Levels 1 to 3B, crystal therapy, tarot card readings, etc.</p>
                                <p>Our Reiki grandmasters have been teaching since 2012 and have helped over 300 people find solace in spirituality.</p>
                                <p>Read about Reiki <Link to="/courses" className="here-link">here.</Link></p>
                            </div>
                            <img className="healing-illustration" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650897500/WhatWeDo/l4adnyxa8xkyifxo39dx.png" />

                        </div>
                        <img className="healing-collage" src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651166703/WhatWeDo/sgkqphqvgfd0lfqkhluz.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650897501/WhatWeDo/pzjwdhpamczayzp1zbmd.png"} />
                    </div>
                }

                {/*<div className='what-we-do-container' id="dishaaProducts">*/}
                {/*    <div className="title">DISHAA MELA</div>*/}
                {/*    <div className="inner-container">*/}
                {/*        <h4><u>HANDMADE PRODUCTS</u></h4>*/}
                {/*        <p>When we started out, we trained the women from a low-income area in Kapurbawdi with various skills and crafts such as, sewing and stitching, crochet, newspaper rolling, bead work, quilling and more. Together, we created a vast range of handmade products that were well-received and popular in our local community. The product range, scope and categories kept growing so much that we decided to expand! All our products have found a home under various brands focused on a particular story and product type. This way, Dishaa continues to focus on <i>annadan</i> and our other donation activities.</p>*/}
                {/*        <p>All these newly-formed small businesses contribute a part of their proceeds to Dishaa Pratishthaan. Several of them also employ the women that we have trained.</p>*/}
                {/*        <p>When you purchase a product from any of these brands, you’re a part of the <i><b>annadan</b></i> we do at Dishaa.</p>*/}
                {/*        <p>We are proud to be associated with them!</p>*/}

                {/*        <div className="collective-container">*/}
                {/*            {collective.map((item, idx) => (*/}
                {/*                <div key={idx} className={phoneCheck() ? "collective-item-mobile" : iPadCheck() ? "collective-item-tablet" : "collective-item"}>*/}
                {/*                    <img src={item.img} width="100%"/>*/}
                {/*                    <h6>{item.name}</h6>*/}
                {/*                    <p>{item.tagline}</p>*/}
                {/*                    <div className="social-links">*/}
                {/*                        {item.website && <a href={item.website} target="_blank">Website</a>}*/}
                {/*                        {item.instagram && <a href={item.instagram} target="_blank">Instagram</a>}*/}
                {/*                        {item.whatsapp && <a href={item.whatsapp} target="_blank">WhatsApp</a>}*/}

                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            ))}*/}
                {/*        </div>*/}

                {/*        <h4><u>SERVICES</u></h4>*/}
                {/*        <p>We are also associated with talented individuals from our local community who provide a range of services. When you avail of their services, you are also contributing to Dishaa’s <i><b>annadan</b></i> and other social impact activities. Get in touch with them to know more!</p>*/}


                {/*        <div className="services-container">*/}
                {/*            {services.map((item, idx) => (*/}
                {/*                <div key={idx} className={phoneCheck() ? "services-item-mobile" : iPadCheck() ? "services-item-tablet" : "services-item"}>*/}
                {/*                    <img src={item.img} width="100%"/>*/}
                {/*                    <h6>{item.name}</h6>*/}
                {/*                    <p>{item.tagline}</p>*/}
                {/*                    <div className="social-links">*/}
                {/*                        {item.website && <a href={item.website} target="_blank">Website</a>}*/}
                {/*                        {item.instagram && <a href={item.instagram} target="_blank">Instagram</a>}*/}
                {/*                        {item.whatsapp && <a href={item.whatsapp} target="_blank">WhatsApp</a>}*/}

                {/*                    </div>*/}
                {/*                    /!*<div className="social-links">*!/*/}
                {/*                    /!*    {item.website && <a href={item.website} target="_blank"><i className="fa fa-chrome"/></a>}*!/*/}
                {/*                    /!*    <a href={item.instagram} target="_blank"><i className="fa fa-instagram"/></a>*!/*/}
                {/*                    /!*    {item.whatsapp && <a href={item.whatsapp} target="_blank"><i className="fa fa-whatsapp"/></a>}*!/*/}

                {/*                    /!*</div>*!/*/}

                {/*                </div>*/}
                {/*            ))}*/}
                {/*        </div>*/}
                {/*    </div>*/}


                {/*</div>*/}
            </div>

            <Footer/>
        </div>
    )
};

export default WhatWeDo;

export const collective = [
    {
        img: rollacoaster,
        name: 'Roll-a-coaster',
        tagline: 'Handmade upcycled newspaper diyas, lamps and more',
        website: 'https://www.rollacoaster.in/',
        whatsapp: 'https://wa.me/c/919930260106',
        instagram: 'https://instagram.com/rollacoaster_with_vandana'
    },
    {
        img: beadnbloom,
        name: 'Bead-n-Bloom',
        tagline: 'Handcrafted jewellery, bead and crystal trees, and paper flowers',
        website: 'https://www.beadnbloom.com/',
        whatsapp: 'https://wa.me/c/919833743385',
        instagram: 'https://instagram.com/beadnbloom'
    },
    {
        img: patacake,
        name: 'Pat-a-Cake',
        tagline: 'Cakes and muffins that feed your soul',
        website: 'https://www.patacake.co/',
        whatsapp: '',
        instagram: 'https://www.instagram.com/patacake_by_Sadhana/'
    },
    {
        img: thebagtree,
        name: 'The Bag Tree',
        tagline: 'Fabric bags and utility products',
        website: 'http://www.thebagtree.in',
        whatsapp: 'https://wa.me/c/919082181121',
        instagram: 'https://instagram.com/thebagtree21'
    },
    {
        img: knottyknots,
        name: 'Knotty Knots',
        tagline: 'Crochet bags, purses, accessories and home decor products',
        website: '',
        whatsapp: 'https://wa.me/c/919820546543',
        instagram: 'https://instagram.com/anitakhaneja'
    },
    {
        img: thedenimproject,
        name: 'The Denim Project',
        tagline: 'Bags made from upcycled jeans',
        website: '',
        whatsapp: '',
        instagram: 'https://www.instagram.com/thedenimprojectin/'
    },
    {
        img: popopaint,
        name: 'Pop-o-Paint',
        tagline: 'Hand painted handmade products',
        website: '',
        whatsapp: '',
        instagram: 'https://instagram.com/pop.o.paint'
    },
    {
        img: theartseies,
        name: 'The Art Series',
        tagline: 'Art, illustration and design products',
        website: '',
        whatsapp: '',
        instagram: 'https://www.instagram.com/the.art.series/'
    },
    {
        img: quilingstory,
        name: 'The Quiling Story',
        tagline: 'A range of products made with quilling papers',
        website: '',
        whatsapp: 'https://wa.me/c/919820566388',
        instagram: ''
    },
    {
        img: yarnaffairs,
        name: 'Yarn Affairs',
        tagline: 'Crochet cushions, baby sweater and booties, amigurumi toys and more',
        website: '',
        whatsapp: '',
        instagram: 'https://www.instagram.com/yarnaffairs/'
    },
    {
        img: a1creations,
        name: 'A1 Creations',
        tagline: 'Salwar stitching, blouses stitching, bhagwan asan, devi ka ghagra and other garment tailoring',
        website: '',
        whatsapp: 'https://wa.me/c/917977903102',
        instagram: ''
    }


];


export const services = [
    {
        img: shapeup,
        name: 'Shape Up with Bhargavi',
        tagline: 'Learn classic mat pilates and POP Pilates. Transform, tone and shape up your body! Beginner and advanced friendly online classes for women. Get in touch for online batches and timings below.',
        whatsapp: 'https://wa.me/918591894213'
    },
    {
        img: pulseofvedas,
        name: 'Ayurvedic Consultation',
        tagline: 'Nadiparikshan and consultation, accurate diagnoses and appropriate guidance with Dr Sumedha Kulkarni (B.A.M.S, Pune). Contact her below for appointment online or in clinic, Nadiparikshan and consultation.',
        whatsapp: 'https://wa.me/919920714101'
    },
    {
        img: workoutwithme,
        name: 'Workout with (Me)ghashree',
        tagline: 'Learn aerobics, yoga, meditation, dance and Bokwa (a fun, movement-based cardio exercise!). Get moving and get flexible! Regular batches and private training available for women. Get in touch for online slots and timings below.',
        whatsapp: 'https://wa.me/919833612607'
    },
    {
        img: anandyoga,
        name: 'Anandyoga Institute',
        tagline: 'Yoga classes for women by certified yoga therapist Rashmi Kulkarni. Learn beginner and advanced yoga asanas, advanced yoga techniques, meditation and more. Get in touch for batches and timings below.',
        whatsapp: 'https://wa.me/919819779958'
    },
    {
        img: saidriving,
        name: 'Sai Driving Classes for Women',
        tagline: 'Learn how to ride a 2-wheeler and/or a 4-wheeler with Vaishali Jadhav, an experienced driving teacher who has taught over 250 women since 2010. Get in touch for batches and timings below.',
        whatsapp: 'https://wa.me/917738388651'
    },
    {
        img: swayampak,
        name: 'Swayampak',
        tagline: 'Delicious ready-to-eat and ready-to-fry eats by Sangeeta! Find delightful palate pleasers like, vada pav, kothimbir vadi, sabudana vada. Catering for large parties available. Get in touch for the menu below.',
        whatsapp: 'https://wa.me/919867208440'
    }

];
