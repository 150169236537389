import React, {useEffect, useState} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {getSingleHamper} from "./CoreApi";
import DisplayImage from "./DisplayImage";
import './HamperPDP.scss';
import {addItemToCart, addItemToCart1, addItemWithVariantToCart, updateCartItem} from "./helpers/cart";
import {Link, useHistory} from 'react-router-dom';
import {check} from "./helpers/browserCheck";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isAuthenticated, logout} from "../auth";
import profile from "../assets/Profile.png";
// import {testHamperItem} from "./DishaaMela";

const HamperPDP = (props) => {

    const [hamperDetails, setHamperDetails] = useState({});
    const [quantity, setQuantity] = useState(1);
    const history = useHistory();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const notify = () => toast('Added to cart!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {backgroundColor: '#feece4', borderRadius: '8px', color: '#9a0000', letterSpacing: '1.1px', fontFamily: 'Avenir-light, sans-serif', fontSize: '16px'},
    });

    const increaseQuantityStepper = (quant, varStock) => {
        // console.log("quant: ", quant);
        // console.log("varStock: ", varStock);

        if(quant < varStock)
            setQuantity(quant+1);


    };

    const decreaseQuantityStepper = (quant) => {
        if(quant > 1)
            setQuantity(quant-1);
    };

    const handleQuantityChange = hamperId => event => {
        let quant = event.target.value;
        setQuantity(quant < 1 ? 1 : quant);
    };

    useEffect(() => {
        const hamperId = props.match.params.hamperId;
        getSingleHamper(hamperId)
            .then(data => {
                if(data.error)
                    console.log(data.error);
                else {
                    // console.log(data);
                    setHamperDetails(data);
                }
            });
        // window.scrollTo(0, 0)

        // getSingleHamper(hamperId)
        //     .then(data => {
        //         if(data.error)
        //             console.log(data.error);
        //         else {
        //             // console.log(data);
        //             setHamperDetails(data);
        //         }
        //     })
    }, [dropdownOpen]);

    // const addToCartVariant = (hamperId, varColor) => {
    //
    //     getSingleHamper(hamperId)
    //         .then(data => {
    //             if(data.error) {
    //                 console.log(data.error)
    //             }
    //             else {
    //                 // console.log(quantity, varColor)
    //                 addItemWithVariantToCart(data, quantity, varColor,() => {
    //                     console.log('DONE!');
    //                     notify();
    //                 })
    //             }
    //         })
    //
    // };

    // const addToCart = (hamperId) => {
    //
    //     getSingleHamper(hamperId)
    //         .then(data => {
    //             if(data.error) {
    //                 console.log(data.error)
    //             }
    //             else {
    //                 // console.log(quantity, varColor)
    //                 addItemToCart(data, quantity,() => {
    //                     // console.log('DONE!');
    //                     notify();
    //                 })
    //             }
    //         })
    //
    // };

    const addToCart1 = (hamperId, varColor) => {

        getSingleHamper(hamperId)
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                }
                else {
                    // console.log(quantity, varColor)
                    addItemToCart1(data, quantity, varColor,() => {
                        console.log('DONE!');
                        notify();
                    })
                }
            })

    };

    const goBack = () => {
        history.push('/shop');
    };

    const showOOS = () => {
        return(
            <span className="badge badge-primary badge-pill">This item is out of stock</span>
        )
    };

    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(hamperDetails.variants);
    const [selectedItem, setSelectedItem] = useState('');

    const toggleDropdown = () => setOpen(!isOpen);

    // const handleItemClick = (color) => {
    //     selectedItem === color ? setSelectedItem(null) : setSelectedItem(color);
    // };

    const [selected, setSelected] = useState('');

    const handleVariantClick = (v) =>  {
        setSelectedItem(v.color);
        setDropdownOpen(false);
        // console.log(" on variant click",v.stock, v.color)
    };


    const variantDropdownMenu = () => (
        <div className="drop-down">
            {hamperDetails.variants && hamperDetails.variants.map((v,i) => (
                <div className="dropdown-menu-item" onClick={() => handleVariantClick(v)} key={i}>
                    {v.color}
                </div>
            ))}
        </div>
    );

    return(
        <div className="HamperPDP">
            <Navbar/>
            <ToastContainer />
            <div>
                <div className={check() ? "go-back-mobile" : "go-back"} onClick={() => goBack()}>
                    <i className="fa fa-chevron-left"/>
                    <span>Back</span>
                </div>
                <div className={check() ? "pdp-container-mobile" : "pdp-container"}>
                    <div className="hamper-img">
                        {hamperDetails.images && hamperDetails.images.map(img => (
                            <div key={img.public_id}>
                                <img src={img.url} /> <br/>
                            </div>
                            ))}
                        {/*<DisplayImage item={hamperDetails} url="hamper"/>*/}
                    </div>
                    <div className="text-container">
                        <div className="title">{hamperDetails.name}</div>
                        <p className="price">₹{hamperDetails.price}</p>
                        <div className="description">
                            {hamperDetails.keep === true ?
                            <>
                                <p>A lovingly handcrafted gift hamper for the festival season!</p>
                                {/*<p>Share the divine light with your loved ones with a special hamper.</p>*/}
                                {/*<p>We’ve put together lovingly handmade items in the hamper from our associate brands. </p>*/}
                            </> :
                            hamperDetails.keep === false ? <p>
                                Spread the Christmas cheer with a hamper! We’ve put together lovingly handmade items from our associate brands for a hamper of goodies for you to gift.
                            </p> : <></>
                            }

                            <div>This hamper contains:</div>
                            <ul>
                                {hamperDetails.items && hamperDetails.items.map((item, i) => (
                                    <li key={i}>{item}</li>
                                ))}
                            </ul>
                        </div>


                        {hamperDetails.variants && hamperDetails.variants.length !== 0 &&
                            <div style={{backgroundColor: '#e6e7e8', padding: '5%', borderRadius: '8px'}}>
                                <p><b>There are color options available for this hamper!</b></p>
                                <div>You have selected: <u><span className="" onClick={() => setDropdownOpen(!dropdownOpen)}>
                            {selectedItem.length === 0 ? 'Select' : selectedItem} <i className="fa fa-angle-down"/> </span></u>
                                </div>
                                {dropdownOpen && variantDropdownMenu()}
                            </div>}

                        {/*{dropdownOpen && variantDropdownMenu()}*/}

                        {/*{hamperDetails.variants && hamperDetails.variants.length !==0 && <div>You selected: {selected}</div>}*/}

                        <br/>

                        {hamperDetails.variants && hamperDetails.variants.length !== 0 ?
                            <div>
                                {hamperDetails.variants.map((v,i) => (

                                    <div key={i}>
                                        {v.color === selectedItem && v.stock !== 0 && <><div className="quantity-stepper">
                                            <button onClick={() => decreaseQuantityStepper(quantity)} className="button">-</button>
                                            <input type="number" className="button" disabled={true} value={quantity <= v.stock ? quantity : setQuantity(1)} onChange={handleQuantityChange(props.match.params.hamperId)}/>
                                            <button onClick={() => increaseQuantityStepper(quantity, v.stock)} className="button">+</button>
                                        </div>
                                            {/*<button className="button-atc" onClick={() => addToCartVariant(props.match.params.hamperId, v.color)}>Add to cart</button>*/}
                                            <button className="button-atc" onClick={() => addToCart1(props.match.params.hamperId, v.color)}>Add to cart</button>
                                            </>
                                        }
                                        {v.color === selectedItem && v.stock === 0 && showOOS()}
                                    </div>
                                    )
                                )}

                                {/*{hamperDetails.variants.map((v,i) => (*/}
                                {/*    <div key={i}>*/}
                                {/*        {v.color === selected && v.stock !== 0 && <div className="quantity-stepper">*/}
                                {/*            <button onClick={() => decreaseQuantityStepper(quantity)} className="button">-</button>*/}
                                {/*            <input type="number" className="button" disabled={true} value={quantity} onChange={handleQuantityChange(props.match.params.hamperId)}/>*/}
                                {/*            <button onClick={() => increaseQuantityStepper(quantity)} className="button">+</button>*/}
                                {/*        </div>}*/}
                                {/*        {v.color === selected && v.stock === 0 ? showOOS() : <button className="button-atc" onClick={() => addToCart(props.match.params.hamperId)}>Add to cart</button>}*/}
                                {/*    </div>*/}
                                {/*))}*/}

                            </div>
                        :

                            <div>
                                {hamperDetails.stock !== 0 && <div className="quantity">Quantity</div>}
                                {hamperDetails.stock !== 0 && <div className="quantity-stepper">
                                    <button onClick={() => decreaseQuantityStepper(quantity)} className="button">-</button>
                                    <input type="number" className="button" disabled={true} value={quantity} onChange={handleQuantityChange(props.match.params.hamperId)}/>
                                    <button onClick={() => increaseQuantityStepper(quantity, hamperDetails.stock)} className="button">+</button>
                                </div>}
                                {hamperDetails.stock === 0 ? showOOS() : <button className="button-atc" onClick={() => addToCart1(props.match.params.hamperId, '')}>Add to cart</button>}
                                {/*{hamperDetails.stock === 0 ? showOOS() : <button className="button-atc" onClick={() => addToCart(props.match.params.hamperId)}>Add to cart</button>}*/}
                            </div>
                        }

                        {/*{hamperDetails.stock !== 0 && <div className="quantity">Quantity</div>}*/}
                        {/*{hamperDetails.stock !== 0 && <div className="quantity-stepper">*/}
                        {/*    <button onClick={() => decreaseQuantityStepper(quantity)} className="button">-</button>*/}
                        {/*    <input type="number" className="button" disabled={true} value={quantity} onChange={handleQuantityChange(props.match.params.hamperId)}/>*/}
                        {/*    <button onClick={() => increaseQuantityStepper(quantity)} className="button">+</button>*/}
                        {/*</div>}*/}
                        {/*{hamperDetails.stock === 0 ? showOOS() : <button className="button-atc" onClick={() => addToCart(props.match.params.hamperId)}>Add to cart</button>}*/}
                        <br/>
                        {/*{console.log(localStorage.getItem('scrollPos'))}*/}
                        <div>With this hamper, you are contributing to:</div>
                        <ul>
                            <li><i>Annadan</i> - meal donation</li>
                            <li>Activities to champion education</li>
                            <li>Empowerment and financial independence of women</li>
                            <li>Promotion of handmade crafts</li>
                            <li>Growth of women-led businesses</li>
                        </ul>
                    </div>
                </div>

            </div>

            <Footer/>
        </div>
    )
};

export default HamperPDP;

// {hamperDetails.variants && hamperDetails.variants.length !==0 && <select className="text-input" value={selected} onChange={e => {setSelected(e.target.value); console.log(hamperDetails.variants)}}>
//     <option>Select a color:</option>
//     {hamperDetails.variants.length !==0 && hamperDetails.variants.map((v,i) => (
//         <option value={v.color} key={i}>{v.color}</option>
//     ))}
// </select>}
