import React, {useState} from "react";
import './Modal.scss';
import {phoneCheck} from "./helpers/browserCheck";

function Modal({ setOpenModal }) {
    const [visible, setVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [correctPass, setCorrectPass] = useState(false);

    const handleChange = (e) => {
        setPassword(e.target.value);
        setCorrectPass(false);
        // console.log(password)
    };

    const handleSubmit = () => {
        if(password === 'InnerCh!LDH3@ling') {
            setOpenModal(false);
        }  else {
            setCorrectPass(true);
        }
    };

    // https://github.com/machadop1407/React-Modal-Tutorial/tree/main
    return (
        <div className="Modal">
            <div className="modalBackground">
                <div className={phoneCheck() ? "modalContainer-mobile" : "modalContainer"}>
                    <div className="body">
                        <p>This page contains password protected content. Please enter the password below.</p>
                    </div>

                    {correctPass && <p className="wrong-password-text">Wrong password!</p>}

                    <div className={phoneCheck() ? "input-field-container-mobile" : "input-field-container"}>
                        <div>Password:</div>
                        {/*<i className={visible ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => setVisible(!visible)}>&nbsp; {visible ? "Hide" : "See"} Password</i>*/}
                        <input type={visible ? "text" : "password"} name="password" id="password" placeholder="Password" onChange={e => handleChange(e)} value={password}/>

                    </div>

                    <div className="footer">
                        <button onClick={handleSubmit} className={phoneCheck() ? "submitBtn-mobile" : "submitBtn"}>Continue</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
