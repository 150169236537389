import React, {useEffect, useState, useRef} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { logout, isAuthenticated } from '../auth/index';
import { cartTotal } from "./helpers/cart";
import './Navbar.scss';
import { menuData } from "./HamburgerMenuItems";
import logo from '../assets/dpt.png';
import cart from '../assets/cart.png';
import profile from '../assets/Profile.png';
import logo_h from '../assets/logo_hor.png';
import {check, phoneCheck} from "./helpers/browserCheck";
import UpdateProduct from "./admin/UpdateProduct";

const Navbar = () => {

    const [isMenuClicked, setMenuClick] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [childDropdownOpen, setChildDropdownOpen] = useState([]);
    const [childDropdownOpenHB, setChildDropdownOpenHB] = useState([]);
    const history = useHistory();
    const [state, setState] = useState(0);
    let ref = useRef();
    let navMenuRef = useRef();
    let hamburgerMenuRef = useRef();
    const [navmenustateforref,setNavmenustateforref] = useState(false);
    const [dropdownChildren, setDropdownChildren] = useState([]);
    const [dropdownChildrenHB, setDropdownChildrenHB] = useState([]);


    const {user} = isAuthenticated();


    const handleLogOut = () => {
        return history.push('/');
    };

    const handleMenuClick = () => {
        setMenuClick(!isMenuClicked);
    };

    const isActive = (history, path) => {
        if(history.location.pathname === path) {
            return { color: 'rgb(128,0,0)' }
        } else {
            return {  color: '#000' }
        }
    };

    const userDropdownMenu = () => (
        <div className="drop-down">
            { isAuthenticated() &&
            <>
                <Link to={`/${isAuthenticated().user.role === 0 ? `account/profile/${user._id}` : `admin/account`}`}
                                          className="dropdown-menu-item"
                      // onClick={setDropdownOpen(false)}
                >
                    Dashboard
                </Link>
                <span onClick={ () => {
                    logout(() => handleLogOut());
                    // setDropdownOpen(false);
                }
                } className="dropdown-menu-item" >Logout</span>
            </>
            }

        </div>
    );
    const itemsInCart = () => (
        <span className="items-in-cart">
            {cartTotal()}
        </span>
    );

    useEffect(() => {

        const accountHandler = (event) => {
            if (dropdownOpen && ref.current && !ref.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        const navMenuHandler = (event) => {
            if (navmenustateforref && navMenuRef.current && !navMenuRef.current.contains(event.target)) {
                setNavmenustateforref(false);
            }
        };

        const hamburgerMenuHandler = (event) => {
            if (isMenuClicked && hamburgerMenuRef.current && !hamburgerMenuRef.current.contains(event.target)) {
                setMenuClick(false);
            }
        };

        document.addEventListener("mousedown", accountHandler);
        document.addEventListener("touchstart", accountHandler);
        document.addEventListener("mousedown", navMenuHandler);
        document.addEventListener("touchstart", navMenuHandler);
        document.addEventListener("mousedown", hamburgerMenuHandler);
        document.addEventListener("touchstart", hamburgerMenuHandler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", accountHandler);
            document.removeEventListener("touchstart", accountHandler);
            document.removeEventListener("mousedown", navMenuHandler);
            document.removeEventListener("touchstart", navMenuHandler);
            document.removeEventListener("mousedown", hamburgerMenuHandler);
            document.removeEventListener("touchstart", hamburgerMenuHandler);
        };


    },[dropdownOpen, navmenustateforref, isMenuClicked, cartTotal()]);

    const hamburgerMenu = () => (
        <div className="toolbar" ref={hamburgerMenuRef}>

            <div className="nav-bar">
               <span className="nav-logo__name">
                   <span className="menu-button" onClick={handleMenuClick}>
                       <i className="fa fa-bars"/>
                   </span>

                   <Link to="/" className='logo-container-mobile'>
                       <img src={logo_h} className="logo-mobile"/>
                   </Link>
                   <Link to={`/cart`} className="nav-cart-icon" >
                       <img src={cart}/>
                       <sup className="items-in-cart">
                           {itemsInCart()}
                       </sup>
                   </Link>
                   {/*<Link to={`/donate`} >*/}
                   {/*    <button className="donate-button-mobile">*/}
                   {/*         Donate*/}
                   {/*    </button>*/}
                   {/*</Link>*/}
               </span>
            </div>

            { menuData.length && (
                <nav className={` hamburger-menu ${isMenuClicked ? `show` : ``}`}>
                    <div className="close-menu">
                        {isAuthenticated() ?
                            <div>
                                <div>Hi, {user.firstName}!</div>
                            </div>
                            :
                            <Link to={`/login`}
                                  className=""
                            >LOGIN</Link>
                        }
                        <span onClick={() => setMenuClick(false)} className="close-button"><i className="fa fa-times"/></span>
                    </div>
                    {isAuthenticated() && <div className="menu-items">
                        <div className="menu-item">
                            <Link to={`/${isAuthenticated().user.role === 0 ? `account/profile/${user._id}` : `admin/account`}`} className="menu-link">
                                DASHBOARD
                            </Link>
                        </div>
                        <div className="menu-item">
                            <span onClick={ () => {logout(() => handleLogOut())}} className="menu-link">
                                LOGOUT
                            </span>
                        </div>
                    </div>}
                    <ul className="menu-items">
                        {/*<li key='cart' className="menu-item-cart"*/}
                        {/*    style={isActive(history, '/cart')}>*/}
                        {/*    <Link to='/cart' className="menu-link" >CART</Link>*/}
                        {/*</li>*/}
                        { menuData.map((item) =>
                            <li key={item.label} className="menu-item" style={isActive(history, `${item.url}`)}>
                                {item.children ?
                                    <span
                                        onClick={() => handleHBMenuClick(item.children, item.id)}
                                        className="menu-link">
                                           {item.label} &nbsp; <i className="fa fa-angle-down"/>
                                       </span> :

                                    <Link to={item.url} className="menu-link">{item.label}</Link>
                                }
                                {childDropdownOpenHB.includes(item.id) && childrenDropdownInHamburger()}
                            </li>
                        )}
                        <li key='donate' className="menu-item"
                            style={isActive(history, '/donate')}>
                            <Link to='/donate' className="menu-link" >DONATE</Link>
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );

    const childrenDropdownInHamburger = () => (
        <div className="hb-dropdown-menu">
            {dropdownChildrenHB.map((i) => (
                    <Link to={i.url} key={i.id} className="hb-dropdown-menu-item" style={isActive(history, `${i.url}`)}>
                        {i.label}
                    </Link>
                )
            )}
        </div>
    );

    const childDropdownMenu = () => (
        <div className="shop-drop-down" ref={navMenuRef}>
            {dropdownChildren.map((i) => (
                <Link to={i.url} key={i.id} className="shop-dropdown-menu-item" style={isActive(history, `${i.url}`)}>
                    {i.label}
                </Link>
                )
            )}
        </div>
    );

    const handleHBMenuClick = (children, parentId) => {
        setDropdownChildrenHB(children);
        if(childDropdownOpenHB.includes(parentId)) {
            setChildDropdownOpenHB(childDropdownOpenHB.filter(id => id !== parentId))
        } else {
            let newChildDropdownOpenHB = [...childDropdownOpenHB];
            newChildDropdownOpenHB.splice(0,1, parentId);
            setChildDropdownOpenHB(newChildDropdownOpenHB)
        }
    };

    const handleNavMenuClick = (children, parentId) => {
            setDropdownChildren(children);
            if(childDropdownOpen.includes(parentId)) {
                setChildDropdownOpen(childDropdownOpen.filter(id => id !== parentId))
            } else {
                let newChildDropdownOpen = [...childDropdownOpen];
                newChildDropdownOpen.splice(0,1, parentId);
                setChildDropdownOpen(newChildDropdownOpen)
            }
    };


    const navigation = () => (
        <div className="navigation">
            <div className="nav-bar">
               <span className="navbar-logo-items">
                   <span className="nav-logo__name">
                       <Link to="/" className='logo-container'>
                           <img src={logo_h} />
                       </Link>
                    </span>
                    <span className="">
                        <ul className="nav-menu-items">
                           { menuData.map((item) => (
                               <li key={item.id} className="menu-item">
                                   {item.children ?
                                       <span onClick={() => handleNavMenuClick(item.children, item.id)} className="menu-link">
                                           {item.label} &nbsp; <i className="fa fa-angle-down"/>
                                       </span>
                                       :
                                       <Link to={item.url} className="menu-link" style={isActive(history, `${item.url}`)}>
                                           {item.label}
                                       </Link>}
                                   {childDropdownOpen.includes(item.id) && childDropdownMenu()}
                               </li>
                           ))}
                       </ul>
                    </span>
               </span>

                <span className="right-nav-ctas" ref={ref}>

                   <Link to={`/cart`} className="cta-item" >
                       {/*<i className="fa fa-shopping-cart cart-icon"/>*/}
                       <img src={cart} />
                       <sup>
                           {/*<small className="cart-badge">{cartTotal()}</small>*/}
                           {itemsInCart()}
                       </sup>
                   </Link>
                   {/*<Link to={`/donate`} >*/}
                       <a href="https://pages.razorpay.com/pl_LznAGUuHa3xYw6/view"><button className="donate-button-web">
                            Donate
                       </button></a>
                   {/*</Link>*/}
                    {!isAuthenticated() ? (
                        <span>
                           <Link to={`/login`} className="cta-item" style={isActive(history, `/login`)}>LOGIN</Link>
                       </span>
                        ) :
                        (
                            <span className="cta-item" onClick={() => setDropdownOpen(!dropdownOpen)} >
                               {/*<i className="fa fa-user cart-icon"/>*/}
                               <img src={profile} />
                               {/*<i className="fa fa-chevron-down"/>*/}
                           </span>
                        )}
                    {dropdownOpen && userDropdownMenu()}
               </span>

            </div>
        </div>
    );

    return (
        <div className="Navbar">
            {check() ? <div>{hamburgerMenu()}</div> : navigation()}
        </div>
    )

};

export default Navbar
