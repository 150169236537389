import React, {useEffect, useState} from 'react';
import './Register.scss';
import { Link } from 'react-router-dom';
import { signup } from '../../auth/index';
import logo from "../../assets/DishaaLogo.png";
import Navbar from "../Navbar";
import {useHistory} from "react-router-dom";
import ReactPhoneInput from "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import {getAllReikiLevels} from "../admin/AdminApi";
// import {setReikiLevelByNewUser} from "../admin/AdminApi";

const Register = () => {

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        callNumber: '',
        waNumber: '',
        city: '',
        error: '',
        success: false,
    });
    const history = useHistory();
    const [visible, setVisible] = useState(false);
    const [levels, setLevels] = useState([]);

    const { firstName, lastName, email, password, callNumber, waNumber, city, success, error } = values;

    useEffect(() => {
        loadReikiLevels()
    }, []);

    const loadReikiLevels = () => {
        getAllReikiLevels()
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                } else {
                    setLevels(data)
                }
            })
    };

    const handleChange = field => event => {
        setValues({ ...values, error: false, [field]: event.target.value });

    };

    const handlePhoneOnChange = value => {
        setValues({ ...values, callNumber: value })
    };

    const handleWAOnChange = value => {
        setValues({ ...values, waNumber: value })
    };

    const showRegisterForm = () => (
        <div className="register-form-container">

            <form onSubmit={handleSubmit} className="register-form">
                <div>
                    <img src={logo} />
                    <h3>Create your Dishaa account</h3>
                </div>
                <div className='text-input-container'>
                    {/*<img src={logo} />*/}
                    {/*<h3>Create your Dishaa account</h3>*/}
                    <div className="field">
                        <input className="inputField" type="text" name="firstname" id="firstname" placeholder="Enter your first name" onChange={handleChange('firstName')} value={firstName}/>
                        <label htmlFor="firstname">First Name</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="text" name="lastname" id="lastname" placeholder="Enter your last name" onChange={handleChange('lastName')} value={lastName}/>
                        <label htmlFor="lastname">Last Name</label>
                    </div>
                    <div className="field">
                        <input className="inputField" type="email" name="email" id="email" placeholder="Enter your email" onChange={handleChange('email')} value={email}/>
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="field">
                        <i className={visible ? "fa fa-eye-slash" : "fa fa-eye"} onClick={() => setVisible(!visible)}>&nbsp; {visible ? "Hide" : "See"} Password</i>
                        <input className="inputField" type={visible ? "text" : "password"} name="password" id="password" placeholder="Enter your password" onChange={handleChange('password')} value={password}/>
                        <label htmlFor="password">Password</label>
                    </div>

                    <div className="field">
                        <PhoneInput
                            inputExtraProps={{
                                name: "Phone",
                                required: true,
                            }}
                            country="in"
                            value={callNumber}
                            onChange={handlePhoneOnChange}
                            containerClass={'field'}
                            inputClass={'inputField'}
                        />
                        <label htmlFor="callNumber">Primary Phone Number</label>
                    </div>

                    <div className="field">
                        <ReactPhoneInput
                            inputExtraProps={{
                                name: "phone",
                                required: true,
                            }}
                            country="in"
                            value={waNumber}
                            onChange={handleWAOnChange}
                            containerClass={'field'}
                            inputClass={'inputField'}
                        />
                        <label htmlFor="waNumber">WhatsApp Number</label>
                    </div>

                    {/*<div className="field">*/}
                    {/*    <input type="number" name="callNumber" id="callNumber" placeholder="Enter your Call Number" onChange={handleChange('callNumber')} value={callNumber}/>*/}
                    {/*    <label htmlFor="callNumber">Call Number</label>*/}
                    {/*</div>*/}
                    {/*<div className="field">*/}
                    {/*    <input type="number" name="waNumber" id="waNumber" placeholder="Enter your WhatsApp Number" onChange={handleChange('waNumber')} value={waNumber}/>*/}
                    {/*    <label htmlFor="waNumber">WhatsApp Number</label>*/}
                    {/*</div>*/}
                    <div className="field">
                        <input className="inputField" type="text" name="city" id="city" placeholder="Enter your city" onChange={handleChange('city')} value={city}/>
                        <label htmlFor="city">City</label>
                    </div>

                    {/*<div className="field">*/}
                    {/*    <input className="inputField" type="text" name="level" id="level" placeholder="Enter Reiki level you're attuned to" onChange={handleChange('level')} value={level}/>*/}
                    {/*    <label htmlFor="level">Reiki Level</label>*/}
                    {/*</div>*/}


                    {/*<div className="field">*/}
                    {/*    <select className="custom-select" onChange={handleChange('navratri')}>*/}
                    {/*        <option>Please select</option>*/}
                    {/*        <option value="Yes">Yes</option>*/}
                    {/*        <option value="No">No</option>*/}
                    {/*    </select>*/}
                    {/*    <label htmlFor="level">Here to access the 10 Meditations?</label>*/}
                    {/*</div>*/}


                    {/*<div className="field">*/}
                    {/*    <select className="custom-select" onChange={handleChange('level')}>*/}
                    {/*        <option>Update level</option>*/}
                    {/*        {levels.map((rl, i) => (*/}
                    {/*            <option key={i} value={rl}>{rl}</option>*/}
                    {/*        ))}*/}
                    {/*    </select>*/}
                    {/*    <label htmlFor="level">Reiki Level</label>*/}
                    {/*</div>*/}

                </div>
                <div>
                    <button type='submit' className='button'>Create account</button>
                </div>
                {loginButton()}
            </form>
        </div>
    );

    const handleSubmit = event => {
        event.preventDefault();
        setValues({ ...values, error: false });
        signup( {firstName, lastName, email, password, callNumber, waNumber, city} )
            .then(data => {
            if (data.error) {
                setValues({ ...values, error: data.error, success: false });
            } else {
                setValues({
                    ...values,
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    callNumber: '',
                    waNumber: '',
                    city: '',
                    error: '',
                    success: true
                });
                history.push('/login');
                // return <Redirect to='/login'/>
            }
        });
    };

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            New account is created. Please <Link to="/login">log in</Link>.
        </div>
    );

    const loginButton = () => (
        <div className="login-message">
            <h3>Already have an account?</h3>
            <Link to={`/login`} className="button">Sign in</Link>
        </div>
    );


    return (
        <div className="Register">
            <Navbar/>
            {showError()}
            {showSuccess()}
            {showRegisterForm()}
        </div>
    )

};

export default Register;
