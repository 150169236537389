import React, {useEffect, useState, useRef, useMemo} from 'react';
import Navbar from "./Navbar";
import {getProducts} from "./CoreApi";
import ProductCard from "./ProductCard";
import cover from '../assets/cover.png';
import { Link } from "react-router-dom";
import './Home.scss';
import {isAuthenticated} from "../auth";
import meditation from '../assets/meditation.png';
import Footer from "./Footer";
import {check, iPadCheck, phoneCheck} from "./helpers/browserCheck";
import quotes from '../assets/Revamp/Q.png';
import bjpg from '../assets/Revamp/B.jpg';
import logo from '../assets/DishaaLogo.png';
import mealService from '../assets/Revamp/MEAL SERVICE.png';
import womenEmpowerment from '../assets/Revamp/WOMEN EMPOWERMENT.png';
import mindfulness from '../assets/Revamp/MINDFULNESS.png';
import socialImpact from '../assets/Revamp/SOCIAL IMPACT.png';
import giftinghampers from '../assets/Revamp/GiftingHampers.png';
import collective from '../assets/Revamp/ASSOCIATES.png';
import img2 from '../assets/Revamp/img2.jpg';
import donate from '../assets/Revamp/DONATE.png';
import buyProduct from '../assets/Revamp/BUY A PRODUCT.png';
import takeClass from '../assets/Revamp/TAKE A CLASS.png';
import whatsapp from '../assets/Revamp/whtsap.png';
import instagram from '../assets/Revamp/insta.png';
import facebook from '../assets/Revamp/fb.png';
import impactImg from '../assets/Revamp/Our Impact bg.png';
import Fade from 'react-reveal/Fade';
import {update} from "./user/UserApi";
import CountUp from "react-countup";

const Home = () => {

    const [values, setValues] = useState({
        name: '',
        email: '',
        error: ''
    });

    const { name, email } = values;
    const { user } = isAuthenticated();

    const [productsBySell, setProductsBySell] = useState([]);
    const [productsByArrival, setProductsByArrival] = useState([]);
    const [error, setError] = useState(false);



    // const loadProductsBySell = () => {
    //     getProducts('sold')
    //         .then(data => {
    //             if(data.error) {
    //                 setError(data.error)
    //             } else {
    //                 setProductsBySell(data)
    //             }
    //         })
    // };
    //
    // const loadProductsByArrival = () => {
    //     getProducts('createdAt')
    //         .then(data => {
    //             if(data.error) {
    //                 setError(data.error)
    //             } else {
    //                 setProductsByArrival(data)
    //             }
    //         })
    // };

    // const bestSellers = () => (
    //     <div className="best-sellers">
    //         <h3 className="">Best sellers</h3>
    //         <div className="products">
    //             {productsBySell.map((pdt, index) => (
    //                 <ProductCard pdt={pdt} key={index} />
    //             ))}
    //         </div>
    //     </div>
    // );
    //
    // const newArrivals = () => (
    //     <div className="new-arrivals">
    //         <h3 className="">New Arrivals</h3>
    //         <div className="products">
    //             {productsByArrival.map((pdt, index) => (
    //                 <ProductCard pdt={pdt} key={index} />
    //             ))}
    //         </div>
    //     </div>
    // );

    // const upcomingClasses = () => (
    //     <div>
    //         <div className="upcoming-classes">
    //             <h3 className="title">UPCOMING CLASSES</h3>
    //             <div className="products">
    //                 {productsByArrival.map((pdt, index) => (
    //                     <ProductCard pdt={pdt} key={index} />
    //                 ))}
    //             </div>
    //         </div>
    //     </div>
    // );

    const introBanner = () => (
        <div className="intro-container">
            <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1648474106/Homepage/k0oj4nxlly56kwkryqsf.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1648474106/Homepage/rgrhwuf6ckr9ald3dvna.jpg"}
                 className={phoneCheck() ? "intro-img-mobile" : iPadCheck() ? "intro-img-tablet" : "intro-img"}/>
            <a href="https://pages.razorpay.com/pl_LznAGUuHa3xYw6/view"><button className={phoneCheck() ? "donate-button-mobile" : iPadCheck() ? "donate-button-tablet" : "donate-button-web"}>Donate</button></a>
            {/*<div className="wwd-style">WHAT WE DO</div>*/}
            {/*<div className={check() ? "tagline-container-mobile" : "tagline-container"}>*/}
            {/*    <h1 className="tagline">HEALTHY LIVING WITH POSITIVE THINKING</h1>*/}
            {/*    <p className="tagline-subtitle">Dishaa Pratishthaan has helped change the lives of many through this simple philosophy</p>*/}
            {/*</div>*/}
        </div>
    );

    const introLines = () => (
        <div className={phoneCheck() ? "intro-text-mobile" : iPadCheck() ? "intro-text-tablet" : "intro-text"}>
            <span>Dishaa Pratishthaan is a women-led non profit organisation based in Thane, India. We provide nutritious mid-day meals and empower underprivileged women.</span>
        </div>
    );

    const init = () => {
        // loadProductsByArrival();
        // loadProductsBySell();
    };

    useEffect(() => {
        init();
        // window.scrollTo(0,0);
    }, []);

    // https://bobbyhadz.com/blog/react-check-if-element-in-viewport
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const isInViewport1 = useIsInViewport(ref1);
    // console.log('isInViewport1: ', isInViewport1);

    // const isInViewport2 = useIsInViewport(ref2);
    // console.log('isInViewport2: ', isInViewport2);

    function useIsInViewport(ref) {
        const [isIntersecting, setIsIntersecting] = useState(false);

        const observer = useMemo(
            () =>
                new IntersectionObserver(([entry]) =>
                    setIsIntersecting(entry.isIntersecting),
                ),
            [],
        );

        useEffect(() => {
            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }, [ref, observer]);

        return isIntersecting;
    }


    return(
            <div className="Home" >
                <Navbar/>
                {introBanner()}
                <Fade bottom>
                {introLines()}
                </Fade>
                {/*<div className={phoneCheck() ? "text-with-logo-mobile" : iPadCheck() ? "text-with-logo-tablet" : "text-with-logo"}>*/}
                {/*    <img src={quotes} />*/}
                {/*    <span>Dishaa Pratishthaan is a women-led non profit organisation based in Thane, India. We provide nutritious mid-day meals and empower underprivileged women. We also donate resources and financial aid to other organisations. </span>*/}
                {/*    /!*<img src={logo} />*!/*/}
                {/*    /!*<div className="text">We are a women-led non-profit organisation based in Thane, India. At Dishaa, we believe that everybody can and should live a better life. Through a holistic approach we help people nourish the minds, bodies and souls, whether it is through our alternative therapy of Reiki or our various social impact activities. Our core principle is to spread happiness in whatever way possible. We see Dishaa as an answer to people’s prayer, and a way to attain peace and tranquillity.</div>*!/*/}
                {/*</div>*/}

                <Fade bottom>
                <div className="what-we-do">
                    <div className="title">WHAT WE DO</div>
                    <div className={"flex-container"}>
                        {activities.map((item, index) => (
                                <a href={window.location.pathname+"work/#"+item.link}  className={ phoneCheck() ? "flex-item-mobile" : iPadCheck() ? "flex-item-tablet" : "flex-item"}>
                                <div key={index}
                                     // className={ check() ? "flex-item-mobile" : "flex-item"}
                                >
                                {item.icon && <img src={item.icon} />}
                                    <div className="icon-text"><b>{item.title}</b> <br/> <div style={{fontSize: '14px'}}>{item.subtitle}</div> </div>
                            </div>
                                </a>
                        ))}
                    </div>
                    {/*<div className="what-we-do-end"/>*/}
                </div>
                </Fade>

                {/*<Fade bottom>*/}
                    {impact(ref1, isInViewport1)}
                {/*</Fade>*/}

                <Fade bottom>{phoneCheck() ?
                    <p className="post-impact-text-mobile">
                    We strongly believe that everyone has the right to live a better life.
                    Our philosophy ‘healthy living, positive thinking’ guides us in nourishing the minds, bodies, and souls of countless people.
                    You can help us uplift many more.
                </p> : <p className="post-impact-text">
                    We strongly believe that everyone has the right to live a better life. <br/>Our philosophy ‘healthy living, positive thinking’ guides us in nourishing the minds, bodies, and souls of countless people.
                    <br/>
                    You can help us uplift many more.
                    </p>}</Fade>


                <Fade bottom>{supportUsSection()}</Fade>


                {/*<img src={img2} className="home-img1" width="100%"/>*/}

                {/*<div className="take-action-container">*/}
                {/*    <h1 className="title">TAKE ACTION</h1>*/}
                {/*    <div className="action-items">*/}
                {/*        {actionItems && actionItems.map((item, index) => (*/}
                {/*                <div key={index} className={iPadCheck() ? "action-item-tablet" : phoneCheck() ? "action-item-mobile" : "action-item"}>*/}
                {/*                    <Link to={item.link}>*/}
                {/*                        {item.icon && item.title === 'SPREAD THE WORD' ? social(item.icon) : item.icon && <img src={item.icon} /> }*/}
                {/*                        <div className="icon-text">{item.title}</div>*/}
                {/*                    </Link>*/}
                {/*            </div>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</div>*/}

                <Footer/>
            </div>
        )
};

const supportUsSection = () => (
    <div className="support-us-container">
        <div className="title">SUPPORT US</div>
        <div className={phoneCheck() ? "donate-section-mobile" : "donate-section"}>
            <div className="donate-section-images">
                <div className="donate-section-image">
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1645695455/Homepage/cuzsgk25mhbgadbf3j4r.jpg" />
                    <p>Donate meals</p>
                </div>
                <div className="donate-section-image">
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1645695462/Homepage/k0bohiuxijljyed3jjvi.jpg" />
                    <p>Donate hampers to children</p>
                </div>
                <div className="donate-section-image">
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1645695460/Homepage/pudacjupzemmz695xcuw.jpg" />
                    <p>Donate menstrual hygiene products</p>
                </div>
            </div>
            <Link to="/donate" ><button className="button">Donate</button></Link>
        </div>
        <div className={phoneCheck() ? "shop-section-mobile" : "shop-section"}>
            <div className="img-container">
                <div className="image-block">
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1645695461/Homepage/uc3uwaw5nt50i0bt4cfg.jpg" />
                    <p>Buy gifting items</p>
                </div>
                <div>
                    {/*<Link to="/shop" >*/}
                        <a href="https://dishaamela.com"><button className="button">Visit Shop</button></a>
                    {/*</Link>*/}
                </div>
            </div>
            <div className="img-container">
                <div className="image-block">
                    <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1645695460/Homepage/yv7lulsizxko9gmchfwt.jpg" />
                    <p>Attend a Reiki class</p>
                </div>
                <div>
                    <Link to="/courses" ><button className="button">Read More</button></Link>
                </div>
            </div>
        </div>
    </div>
);


const impact = (ref1, isInViewport1) => (
    <div className="impact-container" ref={ref1}>
        {/*<img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1646985680/Homepage/dffkkkq8cbbrkpawiswi.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1644919342/Homepage/o8rx6ethy1u2rdejmesl.png"} className="impact-image" width="100%"/>*/}
        <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1646993320/Homepage/oce0rbtrmw96nmfkksgt.png" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1644919342/Homepage/o8rx6ethy1u2rdejmesl.png"} className="impact-image" width="100%"/>
        {/*<div className="title">OUR IMPACT</div>*/}
        {!phoneCheck() &&
            <div className="numbers-container">

                <div className="title">OUR IMPACT</div>
                <div className="numbers">
                    {/*<div className="title">OUR IMPACT</div>*/}
                    <div className="impact1">
                        <p>
                            {/*https://www.npmjs.com/package/react-countup*/}
                            {isInViewport1 ? <CountUp
                                end={5.2}
                                decimals={1}
                                decimal="."
                                suffix="L+"
                            /> : '5.2L+'}
                        </p>
                        <div className="impact-number-text">meals served</div>
                    </div>
                    <div className="impact2">
                        <p>{isInViewport1 ? <CountUp end={40} /> : 40}</p>
                        <div className="impact-number-text">underprivileged <br/>women empowered</div>
                    </div>
                    <div className="impact3">
                        <p>{isInViewport1 ? <CountUp end={10} /> : 10}</p>
                        <div className="impact-number-text">organisations aided</div>
                    </div>
                    <div className="impact4">
                        <p>{isInViewport1 ? <CountUp end={300} /> : 300}</p>
                        <div className="impact-number-text">menstrual hygiene <br/>kits provided </div>
                    </div>
                </div>
            </div>}

    </div>
);

// impact numbers for mobile
{/*<div className="numbers-container-mobile">*/}
{/*    /!*<div className="title">OUR IMPACT</div>*!/*/}
{/*    <div className="numbers">*/}
{/*        <div className="test-title">OUR IMPACT</div>*/}
{/*        <div className="impact1">*/}
{/*            <p>5.2L+</p>*/}
{/*            <div className="impact-number-text">meals served</div>*/}
{/*        </div>*/}
{/*        <div className="impact2">*/}
{/*            <p>40</p>*/}
{/*            <div className="impact-number-text">underprivileged women empowered</div>*/}
{/*        </div>*/}
{/*        <div className="impact3">*/}
{/*            <p>10</p>*/}
{/*            <div className="impact-number-text">organisations aided</div>*/}
{/*        </div>*/}
{/*        <div className="impact4">*/}
{/*            <p>300</p>*/}
{/*            <div className="impact-number-text">menstrual hygiene kits provided </div>*/}
{/*        </div>*/}
{/*    </div>*/}
{/*</div>*/}

const social = (socials) => (
    <div className="socials">
            <img src={socials.whatsapp.logo} className="social-icon" onClick={() => openUrl(socials.whatsapp.url)}/>
            <img src={socials.instagram.logo} className="social-icon" onClick={() => openUrl(socials.instagram.url)}/>
            <img src={socials.facebook.logo} className="social-icon" onClick={() => openUrl(socials.facebook.url)}/>
    </div>
);

const openUrl = (url) => {
    window.open(url)
};

export default Home

export const activities = [

    {
        title: 'NOURISH',
        subtitle: 'Annadan - Meal Service',
        icon: mealService,
        link: 'mealService'
    },
    {
        title: 'UPLIFT',
        subtitle: 'Social impact activities',
        icon: socialImpact,
        link: 'socialImpact'
    },


    {
        title: 'EMPOWER',
        subtitle: 'Women empowerment programs',
        icon: womenEmpowerment,
        link: 'womenEmpowerment'
    },
    {
        title: 'CREATE ',
        subtitle: 'Gifting Hampers',
        icon: giftinghampers,
        link: 'dishaaProducts'
    },
    {
        title: 'HEAL',
        subtitle: 'Alternative healing sessions',
        icon: mindfulness,
        link: 'mindfulness'
    },


];

export const actionItems = [
    {
        title: 'DONATE',
        icon: donate,
        link: '/contact'
    },
    {
        title: 'ATTEND A REIKI SESSION',
        icon: takeClass,
        link: '/courses'
    },
    {
        title: 'BUY A PRODUCT',
        icon: buyProduct,
        link: '/work/#dishaaCollective'
    },
    {
        title: 'SPREAD THE WORD',
        icon:
            {
                whatsapp:
                    {
                        logo: whatsapp,
                        url: 'https://wa.me/919152843218'
                    },
                instagram:
                    {
                        logo: instagram,
                        url: 'https://www.instagram.com/dishaapratishthaan/'
                    },
                facebook:
                    {
                        logo: facebook,
                        url: 'https://www.facebook.com/dishaapratishthaan/'
                    },
            }
    }
];

