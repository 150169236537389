import React, {useState} from "react";
import {isAuthenticated} from "../../auth";
import {Button, Form} from "semantic-ui-react";
import Layout from "../Layout";
import {createCategory} from "./AdminApi";
import {Link} from "react-router-dom";

const AddCategory = () => {
    const [category, setCategory] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    // destructure user and token from local storage
    const { user, token} = isAuthenticated();

    const handleChange = e => {
        setError(false);
        setCategory(e.target.value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        setError(false);
        setSuccess("false");
        // make request to api to create category
        createCategory(user._id, token, { name: category })
            .then(data => {
                if(data.error) {
                    setError(data.error);
                    setSuccess(false)
                } else {
                    setError('');
                    setSuccess(true)
                }
            })
    };

    const newCategoryForm = () => (
            <form onSubmit={handleSubmit} className="create-form">
                <input className="text-input" placeholder='Category name' onChange={handleChange} value={category}/>
                <button type='submit' className="button">Create category</button>
            </form>
    );

    const showSuccess = () => (
        <h5 className="text-success" style={{ display: success ? '' : 'none' }}>
            Category with the name {category} is created!
        </h5>
    );

    const showError = () => (
        <h5 className="text-danger" style={{ display: error ? '' : 'none' }}>
            Oops!
        </h5>
    );

    const goBack = () => (
        <div>
            <Link to="/admin/dashboard">Back to Dashboard</Link>
        </div>
    );

    return(
            <div className="AddCategory__Product">
                {showSuccess()}
                {showError()}
                {/*{goBack()}*/}
                {newCategoryForm()}
            </div>
    )
};

export default AddCategory
