import React, {useEffect, useState} from 'react';
import {isAuthenticated} from "../../auth";
import {read, update, updateUserInLocalStorage} from "./UserApi";
import {Redirect} from "react-router-dom";
import {check} from "../helpers/browserCheck";

const Profile = ({ match }) => {

    const [values, setValues] = useState({
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        callNumber: '',
        waNumber: '',
        level: '',
        error: false,
        success: false
    });
    const [editName, setEditName] = useState(false);
    const [editEmail, setEditEmail] = useState(false);
    const [editCallNumber, setEditCallNumber] = useState(false);
    const [editWAPhone, setEditWAPhone] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [comingSoonMsg, setComingSoonMsg] = useState(false);
    const {firstName, lastName, email, password, callNumber, waNumber, level, error, success} = values;

    const {token} = isAuthenticated();

    const init = (userId) => {
        read(userId, token).then(data => {
            if(data.error) {
                setValues({...values, error: true})
            } else {
                setValues({...values, firstName: data.firstName, lastName: data.lastName, email: data.email, callNumber: data.callNumber, waNumber:data. waNumber, level: data.level})
            }
        });
    };

    const handleChange = field => e => {
        setValues({...values, error: false, [field]: e.target.value})
    };

    const handleSubmit = e => {
        e.preventDefault();
        update(match.params.userId, token, { firstName, lastName, email, password, callNumber, waNumber })
            .then(data => {
                if(data.error) {
                    setValues({...values, error: true})
                } else {
                    updateUserInLocalStorage(data, () => {
                        setValues({...values, firstName: data.firstName, lastName: data.lastName, email: data.email, callNumber: data.callNumber, waNumber: data.waNumber, success: true});
                    });
                }
            });
        setEditName(false);
        setEditEmail(false);
        setEditCallNumber(false);
        setEditWAPhone(false);
    };

    const redirectUser = success => {
        if(success) {
            return <Redirect to='/account' />
        }
    };

    const showNameForm = () => (
        <>
            <input type="text" onChange={handleChange('firstName')} value={firstName} className="edit-name"/>
            <input type="text" onChange={handleChange('lastName')} value={lastName} className="edit-name"/>
            <div className="edit-cta-container">
                <span onClick={ () => setEditName(false) } className="">Cancel</span>
                <button onClick={handleSubmit} className="button">Save</button>
            </div>
        </>
    );

    const showEmailForm = () => (
        <>
            <input type="email" onChange={handleChange('email')} value={email} className="edit-name"/>
            <div className="edit-cta-container">
                <span onClick={ () => setEditEmail(false) } className="">Cancel</span>
                <button onClick={handleSubmit} className="button">Save</button>
            </div>
        </>
    );

    const showPhoneForm = () => (
        <>
            <input type="number" onChange={handleChange('callNumber')} value={callNumber} className="edit-name"/>
            <div className="edit-cta-container">
                <span onClick={ () => setEditCallNumber(false) } className="">Cancel</span>
                <button onClick={handleSubmit} className="button">Save</button>
            </div>
        </>
    );

    const showWAPhoneForm = () => (
        <>
            <input type="number" onChange={handleChange('waNumber')} value={waNumber} className="edit-name"/>
            <div className="edit-cta-container">
                <span onClick={ () => setEditWAPhone(false) } className="">Cancel</span>
                <button onClick={handleSubmit} className="button">Save</button>
            </div>
        </>
    );

    const comingSoonMessage = () => (
        <div>
            Functionality coming soon!
        </div>
    );

    const profileUpdate = () => (
        <div className={check() ? "profile-update-mobile" : "profile-update"}>
            <div className="profile">
                <h3>Your personal information</h3>
                <div className="info-container">
                    {editName ? showNameForm() : (
                        <>
                            <div className="info-item">
                                <p>Name</p>
                                <span onClick={() => setEditName(true)}>Edit</span>
                            </div>
                            {/*{console.log(name)}*/}
                            <div>{firstName} {lastName}</div>
                        </>
                    )}

                </div>

                <div className="info-container">
                    {editEmail ? showEmailForm() : (
                        <>
                            <div className="info-item">
                                <p>Email address</p>
                                <span onClick={()=>setEditEmail(true)}>Edit</span>
                            </div>
                            <div>{email}</div>
                        </>
                    )}

                </div>

                <div className="info-container">

                    {editCallNumber ? showPhoneForm() : (
                        <>
                            <div className="info-item">
                                <p>Phone number</p>
                                <span onClick={()=>setEditCallNumber(true)}>Edit</span>
                            </div>
                            <div>{callNumber}</div>
                        </>
                    )}

                </div>

                <div className="info-container">

                    {editWAPhone ? showWAPhoneForm() : (
                        <>
                            <div className="info-item">
                                <p>WhatsApp number</p>
                                <span onClick={()=>setEditWAPhone(true)}>Edit</span>
                            </div>
                            <div>{waNumber}</div>
                        </>
                    )}

                </div>
            </div>

            <div className="password">
                <h3>Password</h3>

                <div className="info-container">
                    <div className="info-item">
                        <p>Password</p>
                        <span onClick={() => setComingSoonMsg(!comingSoonMsg)}>Edit</span>
                    </div>
                    <div>{comingSoonMsg ? comingSoonMessage() : '••••••••'}</div>
                </div>

            </div>

        </div>
    );

    useEffect(() => {
        init(match.params.userId);
    }, [level]);

    return(

        <div className="">
            {profileUpdate()}
        </div>

    )
};

export default Profile
