import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './ImportantLinks.scss';

const ImportantLinks = () => {

    const PrivacyPolicy = () => (
        <div id="privacyPolicy" className="main-container">
            <p className="container-title">Privacy Policy</p>
            <p>This Privacy Policy applies to the www.dishaapratishthaan.com website</p>
            <p>Dishaa Pratishthaan recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. Dishaa Pratishthaan respects the privacy of all of our customers.</p>
            <p>This Policy describes how we treat user information we collect on www.trustdishaa.com and other offline sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.</p>

            <div className="container-subtitle">Information we collect</div>
            <p>Contact information: We might collect your name, email, mobile number, phone number, street, city, state, pincode.</p>
            <p>Payment and billing information: We NEVER collect your credit card number or credit card expiry date or other details pertaining to your credit card on our website. Credit card information will be obtained and processed by our online payment partner Razorpay.</p>

            <div className="container-subtitle">How we collect information</div>
            <p>We collect information directly from you when you register with us for a course for the following uses</p>
            <p>We use information to contact you: We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.</p>
            <p>We use information to respond to your requests or questions: We might use your information to confirm your registration for an event or contest.</p>
            <p>We use information to improve our products and services: We might use your information to customize your experience with us.</p>
            <p>We use information to look at site trends and customer interests: We may use your information to make our website and products better.</p>
            <p>We use information for marketing purposes:  We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products.</p>
            <p>We use information to send you transactional communications: We might send you emails or SMS about your account or a purchase.</p>
            <p>We use information as otherwise permitted by law</p>

            <div className="container-subtitle">Sharing of information with third-parties</div>
            <p>We will share information with third parties who perform services on our behalf. We share information with Razorpay, who is our payment partner.</p>
            <p>We may share information if we think we have to in order to comply with the law or to protect ourselves. We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory body requests it. Or, we might also share information when we are investigating potential fraud.</p>
            <p>We may share information with any successor to all or part of our business. For example, if part of our business is sold we may give our customer list as part of that transaction.</p>
            <p>We may share your information for reasons not described in this policy. We will tell you before we do this.</p>

            <div className="container-subtitle">Jurisdiction</div>
            <p>If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</p>

        </div>
    );

    const TandC = () => (
        <div id="termsAndConditions" className="main-container">
            <p className="container-title">Terms and Conditions</p>

            <p>Revised: 20th July, 2020</p>

            <p>Please read this terms of service agreement carefully. By using this website or ordering products from this website you agree to be bound by all of the terms and conditions of this agreement.</p>
            <p>This terms of service agreement (the "agreement") governs your use of this website, [www.dishaapratishthaan.com] (the "website"), [Dishaa Pratishthaan] ("Dishaa Pratishthaan") offer of products for purchase on this website, or your purchase of products available on this website. This agreement includes, and incorporates by this reference, the policies and guidelines referenced below. [Dishaa Pratishthaan] reserves the right to change or revise the terms and conditions of this agreement at any time by posting any changes or a revised agreement on this website. [Dishaa Pratishthaan] will alert you that changes or revisions have been made by indicating on the top of this agreement the date it was last revised. The changed or revised agreement will be effective immediately after it is posted on this website. Your use of the website following the posting of any such changes or of a revised agreement will constitute your acceptance of any such changes or revisions. [Dishaa Pratishthaan] encourages you to review this agreement whenever you visit the website to make sure that you understand the terms and conditions governing use of the website. This agreement does not alter in any way the terms or conditions of any other written agreement you may have with [Dishaa Pratishthaan] for other products or services. If you do not agree to this agreement (including any referenced policies or guidelines), please immediately terminate your use of the website. If you would like to print this agreement, please click the print button on your browser toolbar.</p>

            <div className="container-subtitle"><u>Courses</u></div>

            <div className="container-subtitle">Terms of offer</div>
            <p>This website offers for sale certain courses (the "courses"). By placing an order for courses through this website, you agree to the terms set forth in this agreement.</p>

            <div className="container-subtitle">Customer Solicitation</div>
            <p>
                Unless you notify us [Dishaa Pratishthaan], you are agreeing to continue to receive further emails about [Dishaa Pratishthaan]
            </p>

            <div className="container-subtitle">Opt out procedure</div>
            <p>
                [Dishaa Pratishthaan] has proprietary rights and trade secrets in the products. You may not copy, reproduce, resell or redistribute any product manufactured and/or distributed by [Dishaa Pratishthaan].<br/>
                [Dishaa Pratishthaan] also has rights to all trademarks and trade dress and specific layouts of this webpage, including calls to action, text placement, images and other information.
            </p>

            <div className="container-subtitle"><u>Website</u></div>

            <div className="container-subtitle">Content; intellectual property; third party links</div>
            <p>In addition to making courses available, this website also offers information and marketing materials. This website also offers information, both directly and through indirect links to third-party websites, about nutritional and dietary supplements. [Dishaa Pratishthaan] does not always create the information offered on this website; instead the information is often gathered from other sources. To the extent that [Dishaa Pratishthaan] does create the content on this website, such content is protected by intellectual property laws of India, foreign nations, and international bodies. Unauthorized use of the material may violate copyright, trademark, and/or other laws. You acknowledge that your use of the content on this website is for personal, noncommercial use. Any links to third-party websites are provided solely as a convenience to you. [Dishaa Pratishthaan] does not endorse the contents on any such third-party websites. [Dishaa Pratishthaan]  is not responsible for the content of or any damage that may result from your access to or reliance on these third-party websites. If you link to third-party websites, you do so at your own risk. </p>

            <div className="container-subtitle">Use of this website</div>
            <p>[Dishaa Pratishthaan] is not responsible for any damages resulting from use of this website by anyone. You will not use the website for illegal purposes. You will (1) abide by all applicable local, state, national, and international laws and regulations in your use of the website (including laws regarding intellectual property), (2) not interfere with or disrupt the use and enjoyment of the website by other users, (3) not resell material on the website, (4) not engage, directly or indirectly, in transmission of "spam", chain letters, junk mail or any other type of unsolicited communication, and (5) not defame, harass, abuse, or disrupt other users of the website.</p>

            <div className="container-subtitle">License</div>
            <p>By using this website, you are granted a limited, non-exclusive, non-transferable right to use the content and materials on the website in connection with your normal, noncommercial, use of the website. You may not copy, reproduce, transmit, distribute, or create derivative works of such content or information without express written authorization from [Dishaa Pratishthaan] or the applicable third party (if third party content is at issue).</p>

            <div className="container-subtitle">Posting</div>
            <p>By posting, storing, or transmitting any content on the website, you hereby grant [Dishaa Pratishthaan] a perpetual, worldwide, non-exclusive, royalty-free, assignable, right and license to use, copy, display, perform, create derivative works from, distribute, have distributed, transmit and assign such content in any form, in all media now known or hereinafter created, anywhere in the world. [Dishaa Pratishthaan] does not have the ability to control the nature of the user-generated content offered through the website. You are solely responsible for your interactions with other users of the website and any content you post. [Dishaa Pratishthaan] is not liable for any damage or harm resulting from any posts by or interactions between users. [Dishaa Pratishthaan] reserves the right, but has no obligation, to monitor interactions between and among users of the website and to remove any content [Dishaa Pratishthaan] deems objectionable, in muscleup nutrition's sole discretion.</p>

            <div className="container-subtitle">Limitation of Liability</div>
            <p>[Dishaa Pratishthaan] entire liability, and your exclusive remedy, in law, in equity, or otherwise, with respect to the website content and products and/or for any breach of this agreement is solely limited to the amount you paid, less shipping and handling, for products purchased via the website.
                [Dishaa Pratishthaan] will not be liable for any direct, indirect, incidental, special or consequential damages in connection with this agreement or the products in any manner, including liabilities resulting from (1) the use or the inability to use the website content or products; (2) the cost of procuring substitute products or content; (3) any products purchased or obtained or transactions entered into through the website; or (4) any lost profits you allege.
                Some jurisdictions do not allow the limitation or exclusion of liability for incidental or consequential damages so some of the above limitations may not apply to you.</p>

            <div className="container-subtitle">Indemnification</div>
            <p>You will release, indemnify, defend and hold harmless [Dishaa Pratishthaan], and any of its contractors, agents, employees, officers, directors, shareholders, affiliates and assigns from all liabilities, claims, damages, costs and expenses, including reasonable attorneys' fees and expenses, of third parties relating to or arising out of (1) this agreement or the breach of your warranties, representations and obligations under this agreement; (2) the website content or your use of the website content; (3) the products or your use of the products (including trial products); (4) any intellectual property or other proprietary right of any person or entity; (5) your violation of any provision of this agreement; or (6) any information or data you supplied to [Dishaa Pratishthaan]. When [Dishaa Pratishthaan] is threatened with suit or sued by a third party, [Dishaa Pratishthaan] may seek written assurances from you concerning your promise to indemnify [Dishaa Pratishthaan]; your failure to provide such assurances may be considered by [Dishaa Pratishthaan] to be a material breach of this agreement. [Dishaa Pratishthaan] will have the right to participate in any defense by you of a third-party claim related to your use of any of the website content or products, with counsel of [Dishaa Pratishthaan] choice at its expense. [Dishaa Pratishthaan] will reasonably cooperate in any defense by you of a third-party claim at your request and expense. You will have sole responsibility to defend [Dishaa Pratishthaan] against any claim, but you must receive [Dishaa Pratishthaan] prior written consent regarding any related settlement. The terms of this provision will survive any termination or cancellation of this agreement or your use of the website or products.</p>

            <div className="container-subtitle">Privacy</div>
            <p>[Dishaa Pratishthaan] believes strongly in protecting user privacy and providing you with notice of Dishaa Pratishthaan's use of data. Please refer to [Dishaa Pratishthaan] privacy policy, incorporated by reference herein, that is posted on the website.</p>

            <div className="container-subtitle">Agreement to be bound</div>
            <p>By using this website or ordering products, you acknowledge that you have read and agree to be bound by this agreement and all terms and conditions on this website.</p>

            <div className="container-subtitle"><u>General</u></div>

            <div className="container-subtitle">Force majeure</div>
            <p>[Dishaa Pratishthaan] will not be deemed in default hereunder or held responsible for any cessation, interruption or delay in the performance of its obligations hereunder due to earthquake, flood, fire, storm, natural disaster, act of god, war, terrorism, armed conflict, labor strike, lockout, or boycott.</p>

            <div className="container-subtitle">Cessation of operation</div>
            <p>[Dishaa Pratishthaan] may at any time, in its sole discretion and without advance notice to you, cease operation of the website and distribution of the products.</p>

            <div className="container-subtitle">Entire agreement</div>
            <p>This agreement comprises the entire agreement between you and [Dishaa Pratishthaan] and supersedes any prior agreements pertaining to the subject matter contained herein.</p>

            <div className="container-subtitle">Effect of waiver</div>
            <p>The failure of [Dishaa Pratishthaan] to exercise or enforce any right or provision of this agreement will not constitute a waiver of such right or provision. If any provision of this agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this agreement remain in full force and effect.</p>

            <div className="container-subtitle">Governing law; jurisdiction</div>
            <p>This website originates from the [Thane, Maharashtra]. This agreement will be governed by the laws of the state of [Maharashtra] without regard to its conflict of law principles to the contrary. Neither you nor [Dishaa Pratishthaan] will commence or prosecute any suit, proceeding or claim to enforce the provisions of this agreement, to recover damages for breach of or default of this agreement, or otherwise arising under or by reason of this agreement, other than in courts located in state of [Maharashtra]. By using this website or ordering products, you consent to the jurisdiction and venue of such courts in connection with any action, suit, proceeding or claim arising under or by reason of this agreement. You hereby waive any right to trial by jury arising out of this agreement and any related documents.</p>

            <div className="container-subtitle">Statute of limitation</div>
            <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the website or products or this agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

            <div className="container-subtitle">Waiver of class action rights</div>
            <p>By entering into this agreement, you hereby irrevocably waive any right you may have to join claims with those of others in the form of a class action or similar procedural device. Any claims arising out of, relating to, or connection with this agreement must be asserted individually.</p>

            <div className="container-subtitle">Termination</div>
            <p>[Dishaa Pratishthaan] reserves the right to terminate your access to the website if it reasonably believes, in its sole discretion, that you have breached any of the terms and conditions of this agreement. Following termination, you will not be permitted to use the website and [Dishaa Pratishthaan] may, in its sole discretion and without advance notice to you, cancel any outstanding orders for products. If your access to the website is terminated, [Dishaa Pratishthaan] reserves the right to exercise whatever means it deems necessary to prevent unauthorized access of the website. This agreement will survive indefinitely unless and until [Dishaa Pratishthaan] chooses, in its sole discretion and without advance to you, to terminate it.</p>

            <div className="container-subtitle">Domestic use</div>
            <p>[Dishaa Pratishthaan] makes no representation that the website or products are appropriate or available for use in locations outside india. Users who access the website from outside India do so at their own risk and initiative and must bear all responsibility for compliance with any applicable local laws.</p>

            <div className="container-subtitle">Assignment</div>
            <p>You may not assign your rights and obligations under this agreement to anyone. [Dishaa Pratishthaan] may assign its rights and obligations under this agreement in its sole discretion and without advance notice to you.</p>

            <p>By using this website or ordering products from this website you agree to be bound by all of the terms and conditions of this agreement.</p>

        </div>
    );

    const OCRpolicies = () => (
        <div id="cancellationPolicy" className="main-container">
            <p className="container-title">Cancellation Policy</p>
            <p>Dishaa Pratishthaan requires a 24-hour notice if you are not attending a session. If you cannot attend a session you paid for, you can attend a future session. If you have not attended after three scheduled sessions, we will refund the whole amount to you. </p>
        </div>
    );

    return(
        <div className="ImportantLinks">
            <Navbar/>
            {PrivacyPolicy()}
            {TandC()}
            {OCRpolicies()}
            <Footer/>
        </div>
    )
};

export default ImportantLinks;
