import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {iPadCheck, phoneCheck} from "./helpers/browserCheck";
import './AssociateBrands.scss';
import rollacoaster from "../assets/Revamp/whatwedo/rollacoaster.JPG";
import beadnbloom from "../assets/Revamp/whatwedo/beadnbloom.JPG";
import patacake from "../assets/Revamp/whatwedo/patacake.JPG";
import thebagtree from "../assets/Revamp/whatwedo/thebagtree.JPG";
import knottyknots from "../assets/Revamp/whatwedo/knottyknots.JPG";
import thedenimproject from "../assets/Revamp/whatwedo/thedenimproject.JPG";
import popopaint from "../assets/Revamp/whatwedo/pop-o-paint.jpeg";
import punnygreetsco from "../assets/Revamp/whatwedo/punnygreetsco.jpeg";
import theartseies from "../assets/Revamp/whatwedo/theartseries.JPG";
import quilingstory from "../assets/Revamp/whatwedo/quillingstory.jpg";
import yarnaffairs from "../assets/Revamp/whatwedo/yarnaffairs.JPG";
import a1creations from "../assets/Revamp/whatwedo/A1 Creations.jpg";
import pulseofvedas from "../assets/Revamp/whatwedo/ayurveda.jpeg";
import workoutwithme from "../assets/Revamp/whatwedo/workoutwithme.PNG";
import anandyoga from "../assets/Revamp/whatwedo/anandyoga.png";
import saidriving from "../assets/Revamp/whatwedo/sai.png";
import swayampak from "../assets/Revamp/whatwedo/swayampak.png";
import {Link} from "react-router-dom";

const AssociateBrands = () => {
    return(
        <div className="AssociateBrands">
            <Navbar/>
            <div className='what-we-do-container' id="dishaaProducts">

                <div className="inner-container">
                    <br/><br/>
                    <div className="title">ASSOCIATE BRANDS</div>
                    <br/>
                    <p>Our associate businesses are inspiring women-led organisations that contribute a part of their proceeds to Dishaa Pratishthaan. Several of them also employ the underprivileged women who we have trained. The products in our <a href="https://www.dishaamela.com/shop-all" className="shop-page-link" target="_blank">shop</a> contain handcrafted items curated from these businesses.</p>
                    <p>When you purchase a product directly from any of these brands or avail of their services, you’re a part of the <i><b>annadan</b></i> - meal service and social impact activities we do at Dishaa!</p>
                    <p>We are proud to be associated with <a href="https://www.dishaamela.com/meet-our-brands" target="_blank" className="shop-page-link">them!</a></p>

                    {/*<div className="collective-container">*/}
                    {/*    {brands.map((item, idx) => (*/}
                    {/*        <div key={idx} className={phoneCheck() ? "collective-item-mobile" : iPadCheck() ? "collective-item-tablet" : "collective-item"}>*/}
                    {/*            <img src={item.img} width="100%"/>*/}
                    {/*            <h6>{item.name}</h6>*/}
                    {/*            <p>{item.tagline}</p>*/}
                    {/*            <div className="social-links">*/}
                    {/*                {item.catalog && <a href={item.catalog} target="_blank">Catalog</a>}*/}
                    {/*                {item.instagram && <a href={item.instagram} target="_blank">Instagram</a>}*/}
                    {/*                {item.whatsapp && <a href={item.whatsapp} target="_blank">WhatsApp</a>}*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default AssociateBrands;

export const brands = [
    {
        img: a1creations,
        name: 'A1 Creations',
        tagline: 'Expertly tailored garments and other sewing crafts: salwars, blouses, bhagwan asan, devi ka ghagra and more.',
        catalog: 'https://wa.me/c/917977903102',
        whatsapp: 'https://wa.me/917977903102',
        instagram: 'https://www.instagram.com/a1creationsbysangeeta/'
    },
    // {
    //     img: anandyoga,
    //     name: 'Anandyoga Institute',
    //     tagline: 'Yoga classes for women by certified yoga therapist Rashmi Kulkarni. Learn beginner and advanced yoga asanas, advanced yoga techniques, meditation and more.',
    //     whatsapp: 'https://wa.me/919819779958'
    // },
    // {
    //     img: pulseofvedas,
    //     name: 'Ayurveda: The Pulse of Vedas',
    //     tagline: 'Nadiparikshan and consultation by Dr. Sumedha Kulkarni (B.A.M.S, Pune). Appointments are available online or in-clinic.',
    //     whatsapp: 'https://wa.me/917304056194'
    // },
    {
        img: beadnbloom,
        name: 'Bead-n-Bloom',
        tagline: 'Handcrafted healing crystal jewellery and accessories, bead, crystal and wire gifting trees, and paper flowers',
        catalog: 'https://wa.me/c/919833743385',
        whatsapp: 'https://wa.me/919833743385',
        instagram: 'https://instagram.com/beadnbloom'
    },
    {
        img: knottyknots,
        name: 'Knotty Knots',
        tagline: 'Colourful crochet bags, purses, accessories and home decor',
        catalog: 'https://wa.me/c/919820546543',
        whatsapp: 'https://wa.me/919820546543',
        instagram: 'https://www.instagram.com/knottyknots_byanita/'
    },
    {
        img: patacake,
        name: 'Pat-a-Cake',
        tagline: 'Delicious, homemade cakes, muffins and treats to feed your soul.',
        catalog: '',
        whatsapp: 'https://wa.me/919769794788',
        instagram: 'https://www.instagram.com/patacake_by_Sadhana/'
    },
    {
        img: popopaint,
        name: 'Pop-o-Paint',
        tagline: 'Adding a pop of paint to everything! Hand-painted jewellery, home decor & curios',
        catalog: 'https://drive.google.com/file/d/1RpCKpMLrpEDrWeOk40rsYka78kn7XYST/view?usp=drive_link',
        whatsapp: 'https://wa.me/918452979029',
        instagram: 'https://instagram.com/pop.o.paint'
    },
    {
        img: punnygreetsco,
        name: 'Punny Greets Co',
        tagline: 'Cute handcrafted greeting cards for all occasions. Customisations available for special events.',
        catalog: 'https://drive.google.com/file/d/1bkoQrytveJ3xTJoiWnrPF7YdxkiucG7O/view?usp=sharing',
        whatsapp: 'https://wa.me/918591894213',
        instagram: 'https://instagram.com/punnygreetsco'
    },
    {
        img: rollacoaster,
        name: 'Roll-a-coaster',
        tagline: 'Handmade diyas lamps, boxes and home decor made from upcycled newspaper. Fragrant wax sachets, candles and more.',
        catalog: 'https://wa.me/c/919930260106',
        whatsapp: 'https://wa.me/919930260106',
        instagram: 'https://instagram.com/rollacoaster_with_vandana'
    },
    // {
    //     img: saidriving,
    //     name: 'Sai Driving Classes for Women',
    //     tagline: 'Learn how to ride a 2-wheeler and/or a 4-wheeler with Vaishali Jadhav. An experienced driving teacher who has taught over 250 women since 2010.',
    //     whatsapp: 'https://wa.me/917738388651'
    // },
    // {
    //     img: swayampak,
    //     name: 'Swayampak',
    //     tagline: 'Delicious ready-to-eat and ready-to-fry food by Sangeeta! Find delightful palate pleasers like vada pav, kothimbir vadi, pav bhaji, corn chaat. Catering for large parties is available in Thane & Mumbai.',
    //     whatsapp: 'https://wa.me/919867208440'
    // },
    {
        img: theartseies,
        name: 'The Art Series',
        tagline: 'Healing through art! Handmade art, illustration, design home decor and curios',
        catalog: 'https://drive.google.com/file/d/1R0k1Te749dh59b4ryIopQeyz74_W1Nlo/view',
        whatsapp: 'https://wa.me/919833612607',
        instagram: 'https://www.instagram.com/the.art.series/'
    },
    {
        img: thebagtree,
        name: 'The Bag Tree',
        tagline: 'Unique, handcrafted bags, purses, pouches. Beautiful, functional utility products.',
        catalog: 'https://wa.me/c/919082181121',
        whatsapp: 'https://wa.me/919082181121',
        instagram: 'https://instagram.com/thebagtree21'
    },
    {
        img: thedenimproject,
        name: 'The Denim Project',
        tagline: 'Breathing new life into your old jeans! Multi-purpose bags and accessories made from upcycled denim.',
        catalog: 'https://wa.me/c/919326175047',
        whatsapp: 'https://wa.me/919326175047',
        instagram: 'https://www.instagram.com/thedenimprojectin/'
    },
    {
        img: quilingstory,
        name: 'The Quilling Story',
        tagline: 'Delicate quilling paper strips are rolled and shaped into beautiful diyas, boxes, frames and more!',
        catalog: '',
        whatsapp: 'https://wa.me/c/6580292144',
        instagram: ''
    },
    // {
    //     img: workoutwithme,
    //     name: 'Workout with (Me)ghashree',
    //     tagline: 'Learn aerobics, yoga, meditation, dance and Bokwa (a fun, movement-based cardio exercise!). Get moving and get flexible! Regular batches and private training are available for women.',
    //     whatsapp: 'https://wa.me/919833612607'
    // },
    {
        img: yarnaffairs,
        name: 'Yarn Affairs',
        tagline: 'Colourful yarn affairs featuring adorable crochet cushions, baby sweater and booties, amigurumi toys, etc.',
        catalog: 'https://drive.google.com/file/d/1RSks65coaE3Q4lGeP8AyQqahwDNovHZV/view?usp=drivesdk',
        whatsapp: 'https://wa.me/919819501700',
        instagram: 'https://www.instagram.com/yarnaffairs/'
    },

    // {
    //     img: shapeup,
    //     name: 'Shape Up with Bhargavi',
    //     tagline: 'Learn classic mat pilates and POP Pilates. Transform, tone and shape up your body! Beginner and advanced friendly online classes for women. Get in touch for online batches and timings below.',
    //     whatsapp: 'https://wa.me/918591894213'
    // },







];
