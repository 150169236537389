import React, {useEffect, useState} from 'react';
import {healingModalities} from "./HealingModalities";
import Navbar from "./Navbar";
import Footer from "./Footer";
import './HealingModalitiesPDP.scss'
import {phoneCheck} from "./helpers/browserCheck";
import {Link} from "react-router-dom";

const HealingModalitiesPDP = (props) => {

    const [name, setName] = useState('');

    const loadHealingModality = (urlName) => {
        setName(urlName);
    };

    useEffect(() => {
        const urlName = props.match.params.urlName;
        loadHealingModality(urlName)
        // console.log("today's date", isToday(new Date('2023-12-08')));
    }, [props]);

    const isToday = (date) => {
        const today = new Date();
        return today.toDateString() === date.toDateString();
    };

    const intro = (hm) => (
        <div className="intro-container">
            <div className="intro-content">
                <img src={hm.imageUrl} className="healing-modality-img"/>
                <span className="healing-modality-name">{hm.name}</span>
            </div>
            <hr/>
        </div>
    );

    const whatWillYouLearnSection = (hm) => (
        <div className={phoneCheck() ? "what-will-you-learn-container-mobile" : "what-will-you-learn-container"}>
            <div className="title">What will you learn?</div>
            {hm.whatWillYouLearn.map((wwyl, index) => (
                <p className="content" key={index}>{wwyl}</p>
            ))}
            {knowYourReikiGrandmasters()}
        </div>
    );

    const knowYourReikiGrandmasters = () => (
        <div className="know-your-grandmasters">
            Know more about your Reiki Grandmasters <Link to="/know-your-grandmasters">here.</Link>
        </div>
    );

    const whatCanYouDoSection = (hm) => (
        <div className="what-can-you-do-container">
            <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654840426/HealingAndWellness/HealingModalities/j4xeyop4vqmpnawoyruk.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654788256/HealingAndWellness/HealingModalities/jjgwcie4jstxyjnb7k4u.jpg"} width="100%" />
            <div className={phoneCheck() ? "content-mobile" : "content"}>
                <div className="title">What can you do with {hm.name} </div>
                <div className="wcyd-list">
                    <ul>
                        {hm.whatCanYouDo.map((wcyd,i) => (
                            <li key={i}>{wcyd}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );

    const langMindsetMaterials = (hm) => (
        <div className={phoneCheck() ? "lang-mindset-materials-container-mobile" : "lang-mindset-materials-container"}>
            <div className="lang-container">
                <div className="title">Language of instruction</div>
                <p className="content">{hm.language}</p>
            </div>

            {phoneCheck() && <img className="hr-arrow-line" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654841165/HealingAndWellness/HealingModalities/qnwzlncl3d2ahvewras6.png" />}

            <div className="mindset-container">
                <div className="title">Mindset changes that you can hope to achieve</div>
                <ul>
                    {hm.mindsetChanges.map((mc,i) => (
                        <li key={i}>{mc}</li>
                    ))}
                </ul>
            </div>

            {phoneCheck() && <img className="hr-arrow-line" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654841165/HealingAndWellness/HealingModalities/qnwzlncl3d2ahvewras6.png" />}

            <div className="materials-container">
                <div className="title">Materials provided</div>
                <ul>
                    {hm.materialsProvided.map((mp,i) => (
                        <li key={i}>{mp}</li>
                    ))}
                </ul>
            </div>
        </div>
    );

    const courseSchedule = (hm) => (
        <div className={phoneCheck() ? "course-schedule-container-mobile" : "course-schedule-container"}>
            <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654842053/HealingAndWellness/HealingModalities/m86o5k0q7qtkqmbxfcrt.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654791684/HealingAndWellness/HealingModalities/ll32afxsjbhzr9mwbtcr.jpg"} />
            <div className="content">
                <div className="title">Course Schedule</div>
                <ol>
                    {hm.courseSchedule.map((cs,i) => (
                        <li key={i}>{cs}</li>
                    ))}
                </ol>
            </div>
        </div>
    );

    const courseFees = (hm) => (
        <div className={phoneCheck() ? "course-fees-container-mobile" : "course-fees-container"}>
            <div className="who-this-course-for-container">
                <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654842756/HealingAndWellness/HealingModalities/oglmfqh66aepnbr1yinh.png" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654794782/HealingAndWellness/HealingModalities/zvrjoq0hfh9jbb2swo8t.png"} />
                <div className="who-this-course-for">
                    <div className="title">Who is this course for?</div>
                    <div className="content">
                        <ul>
                            {hm.whoIsThisCourseFor.map((witcf, i) => (
                                <li key={i}>{witcf}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>


            <div className="fees-container">
                <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654842533/HealingAndWellness/HealingModalities/bblcabehq7q9anvlcowi.png" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654794356/HealingAndWellness/HealingModalities/fatpmkbtkbgdywxayzv6.png"} />
                <div className="fees">Fees: Rs. {hm.id === 5 ? returnFees(hm) : hm.fees}/- </div>
                <div className="button-in-fees">{button(hm)}</div>
            </div>
        </div>
    );

    const faqs = (hm) => (
        <div className={phoneCheck() ? "faqs-container-mobile" : "faqs-container"}>
            {phoneCheck() && <img className="faq-line" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654843113/HealingAndWellness/HealingModalities/utz76nop8u3qbsxrofi0.png" />}
            <div className="title">FAQs</div>
            <div className="faq-content">
                {hm.FAQs.map((faq,i) => (
                    <div key={i} className="faq">
                        <p className="faq-question">
                            {/*<span className="q">Q.</span> */}
                            <span>{faq.question}</span></p>
                        <p className="faq-answer">
                            {/*<span className="a">A.</span> */}
                            <span>{faq.answer}</span></p>
                    </div>

                ))}
            </div>
            {/*{phoneCheck() && <hr/>}*/}
            {phoneCheck() && <img className="faq-line" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654843113/HealingAndWellness/HealingModalities/utz76nop8u3qbsxrofi0.png" />}
        </div>
    );



    const returnRegisterLink = (hm) => {
        // console.log('hm', hm)
        if(isToday(new Date('2023-12-08')) || isToday(new Date('2023-12-09')) || isToday(new Date('2023-12-10')) ||
            isToday(new Date('2023-12-11')) || isToday(new Date('2023-12-12')) || isToday(new Date('2023-12-13')) ||
            isToday(new Date('2023-12-14')) || isToday(new Date('2023-12-15')) || isToday(new Date('2023-12-16')) ||
            isToday(new Date('2023-12-17'))) {
            return hm.registerLink1;
        }
        else if(isToday(new Date('2023-12-18')) || isToday(new Date('2023-12-19')) || isToday(new Date('2023-12-20')) ||
            isToday(new Date('2023-12-21')) || isToday(new Date('2023-12-22')) || isToday(new Date('2023-12-23')) ||
            isToday(new Date('2023-12-24')) || isToday(new Date('2023-12-25')) || isToday(new Date('2023-12-26')) ||
            isToday(new Date('2023-12-27')) || isToday(new Date('2023-12-28')) || isToday(new Date('2023-12-29')) ||
            isToday(new Date('2023-12-30'))) {
            return hm.registerLink2;
        }
        else return hm.registerLink;
    };

    const returnFees = (hm) => {
        if(isToday(new Date('2023-12-08')) || isToday(new Date('2023-12-09')) || isToday(new Date('2023-12-10')) ||
            isToday(new Date('2023-12-11')) || isToday(new Date('2023-12-12')) || isToday(new Date('2023-12-13')) ||
            isToday(new Date('2023-12-14')) || isToday(new Date('2023-12-15')) || isToday(new Date('2023-12-16')) ||
            isToday(new Date('2023-12-17'))) {
            return hm.fees1;
        }
        else if(isToday(new Date('2023-12-18')) || isToday(new Date('2023-12-19')) || isToday(new Date('2023-12-20')) ||
            isToday(new Date('2023-12-21')) || isToday(new Date('2023-12-22')) || isToday(new Date('2023-12-23')) ||
            isToday(new Date('2023-12-24')) || isToday(new Date('2023-12-25')) || isToday(new Date('2023-12-26')) ||
            isToday(new Date('2023-12-27')) || isToday(new Date('2023-12-28')) || isToday(new Date('2023-12-29')) ||
            isToday(new Date('2023-12-30'))) {
            return hm.fees2;
        }
        else return hm.fees;
    };

    const button = (hm) => {
        // console.log('hm',hm.id);
        return <a href={hm.id === 5 ? returnRegisterLink(hm) : hm.registerLink} target="_blank">
            <button className="register-button">
                Register now!
            </button>
        </a>
    };

    // https://rzp.io/l/QX3dct6gj -- test
    // https://rzp.io/l/DPR2 -- live

    const registerButton = (hm) => (
        <div className="register-container">
            <div className="fees">Fees: Rs. {hm.id === 5 ? returnFees(hm) : hm.fees}/- </div><br/>
            {button(hm)}
        </div>
    );

    const registerButtonMobile = (hm) => (
        <div className="register-container-mobile">
            <div className="fees">Fees: Rs. {hm.id === 5 ? returnFees(hm) : hm.fees}/- </div><br/>
            {button(hm)}
        </div>
    );

    const testimonials = () => (
        <div className={phoneCheck() ? "testimonial-container-mobile" : "testimonial-container"}>
            <p>Check out testimonials from people who have attended
                these classes and have been attuned to Reiki. <br/> <Link to={`/courses`}>Testimonials</Link></p>
        </div>
    );

    return(
        <div className="HealingModalitiesPDP">
            <Navbar/>

            {healingModalities.map((hm,i) => {
                if(name === hm.urlName) {
                    return(
                        <div key={i} className="hm">
                            {intro(hm)}
                            {phoneCheck() ? registerButtonMobile(hm) : registerButton(hm)}
                            {phoneCheck() && <img className="hr-arrow-line" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654841165/HealingAndWellness/HealingModalities/qnwzlncl3d2ahvewras6.png" />}
                            {whatWillYouLearnSection(hm)}
                            {whatCanYouDoSection(hm)}
                            {langMindsetMaterials(hm)}
                            {courseSchedule(hm)}
                            {courseFees(hm)}
                            {testimonials()}
                            {!phoneCheck() && <hr/>}
                            {faqs(hm)}
                        </div>)
                }
            })}
            <Footer/>
        </div>
    )
};

export default HealingModalitiesPDP;
