import React from 'react';
import './Footer.scss';
import logo from '../assets/DishaaLogo.png';
import {check, iPadCheck} from "./helpers/browserCheck";
import {Link} from "react-router-dom";



const Footer = () => {

    const mobileView = () => (
        <div className="mobile-footer">
            <img src={logo} className={iPadCheck() ? "logo-tablet" : "logo"} alt="Dishaa Pratishthaan logo"/>
            {/*<div className="footer-para">*/}
            {/*    Dishaa Pratishthaan is a non-profit organisation based in Thane, India run by women for women & children*/}
            {/*</div>*/}
            <p className="contact">
                <i className="fa fa-envelope"/> hellodishaa@gmail.com
            </p>
            <p className="contact">
                <i className="fa fa-phone"/> +91 91528 43218
            </p>
            {/*<p>Mon to Fri 10.30 AM - 4.00 PM</p>*/}
            <p className="social-media">
                <a href="https://www.instagram.com/dishaapratishthaan/" target="_blank"><i className="fa fa-instagram"/></a>
                <a href="https://www.facebook.com/dishaapratishthaan/" target="_blank"><i className="fa fa-facebook-f"/></a>
            </p>
            <p>© 2021 by Dishaa Pratishthaan | All rights reserved</p>
        </div>
    );

    const footer = () => (
        <div>
            <div className="footer-container">
                <img src={logo} className="logo" alt="Dishaa Pratishthaan logo"/>

                <div className="footer-content">
                    {/*<div className="footer-para">*/}
                    {/*    Dishaa Pratishthaan is a women-led non-profit organisation that empowers women and children with the motto ‘Healthy living with positive thinking’.*/}
                    {/*</div>*/}
                    <p className="contact"><i className="fa fa-envelope"/> hellodishaa@gmail.com  <i className="fa fa-phone"/> +91 91528 43218</p>
                    <hr/>
                    <p className="social-media">
                        <a href="https://www.instagram.com/dishaapratishthaan/" target="_blank">
                            <i className="fa fa-instagram"/>
                        </a>
                        <a href="https://www.facebook.com/dishaapratishthaan/" target="_blank"><i className="fa fa-facebook-f"/></a>
                    </p>
                    {/*<p>© 2020 by Dishaa Pratishthaan | All rights reserved</p>*/}
                </div>

                <div className="important-links">
                    <p className="title">Our Policies</p>
                    <p><a href={window.location.origin+"/important-links/#privacyPolicy"}>Privacy Policy</a></p>
                    <p><a href={window.location.origin+"/important-links/#termsAndConditions"}>Terms and Conditions</a></p>
                    <p><a href={window.location.origin+"/important-links/#cancellationPolicy"}>Orders, Cancellations & Refund Policies</a></p>
                </div>
            </div>
            <div className="rights-reserved">© 2020 by Dishaa Pratishthaan | All rights reserved</div>
        </div>
    );

    return(
        <div className="Footer">
            {check() ?
                mobileView()
                :
                footer()
            }
        </div>
    )
};

export default Footer;
