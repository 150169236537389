import {isAuthenticated} from "../../auth";
import Layout from "../Layout";
import React, {useEffect, useState} from "react";
import {Button, Form} from "semantic-ui-react";
import {createProduct, getCategories, getProduct, updateProduct} from "./AdminApi";
import {Link} from "react-router-dom";

const UpdateProduct = ({ productId }) => {
    const [values, setValues] = useState({
        name: '',
        description: '',
        price: '',
        category: '',
        shipping: '',
        quantity: '',
        photo: '',
        loading: false,
        createdProduct: '',
        redirectToProfile: false,
        formData: '',
        categories: [],
        error: '',
        date: '',
        time: ''
    });

    const {
        name,
        description,
        price,
        category,
        shipping,
        quantity,
        loading,
        createdProduct,
        redirectToProfile,
        formData,
        // categories,
        error,
        date,
        time} = values;

    const [categories, setCategories] = useState([]);

    //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

    const {user, token} = isAuthenticated();

    const initCategories = () => {
        getCategories().then(data => {
            if(data.error) {
                setValues({ ...values, error: data.error})
            } else {
                setCategories(data);
                // setValues({...values, categories: data, formData: new FormData()})
            }
        })
    };

    const init = (productId) => {
        getProduct(productId)
            .then(data=> {
                if(data.error) {
                    setValues({...values, error: data.error})
                } else {
                    // populate state and then load categories
                    setValues({...values, name: data.name, description: data.description, price: data.price, category: data.category._id, quantity: data.quantity, date: data.date, time: data.time, formData: new FormData()});
                    initCategories();
                }
            });
    };

    useEffect(() => {
        init(productId);

    }, []);

    const handleChange = field => event => {
        const value = (field === 'photo') ? event.target.files[0] : event.target.value;
        formData.set(field, value);
        setValues({...values, [field]: value})
    };

    const handleSubmit = e => {
        e.preventDefault();
        setValues({...values, error: '', loading: true});


        updateProduct(productId, user._id, token, formData)
            .then(data => {
                if(data.error) {
                    setValues({...values, error: data.error})
                } else {
                    setValues({...values,
                        name: '', description: '', photo: '', price: '', quantity: '',date: '', time: '', loading: false, createdProduct: data.name});
                }
            })
    };

    const updateProductForm = () => (
        <form onSubmit={handleSubmit} className="create-form">
            <input className="text-input" type='file' name='photo' accept='image/*' onChange={handleChange('photo')}/>
            <input className="text-input" placeholder='Product name' onChange={handleChange('name')} value={name} type="text"/>
            <textarea className="text-input" placeholder='Product description' onChange={handleChange('description')} value={description}/>
            <input placeholder='date'
                   onChange={handleChange('date')}
                   className="text-input"
                   value={date} type="date"/>
            <input placeholder='time'
                   onChange={handleChange('time')}
                   className="text-input"
                   value={time} type="time"/>
            <input className="text-input" placeholder='Price' onChange={handleChange('price')} value={price} type="number"/>
            <select onChange={handleChange('category')} className="text-input" value={category}>
                <option>Please select a category</option>
                { categories && categories.map((cat, index) => (
                    <option key={index} value={cat._id}>
                        {cat.name}
                    </option>
                ))}
            </select>
            <input className="text-input" placeholder='Seats' onChange={handleChange('quantity')} value={quantity} type="number"/>
            <select onChange={handleChange('shipping')} className="text-input" value={shipping}>
                <option>Please select</option>
                <option value="0">No</option>
                <option value="1">Yes</option>
            </select>
            <button type='submit' className="button">Update product</button>
        </form>
    );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: createdProduct ? '' : 'none' }}>
            Created product!
        </div>
    );

    const showLoading = () => (
        loading && (<h6>Loading...</h6>)
    );

    return(

        <div className="AddCategory__Product">
            {showSuccess()}
            {showError()}
            {showLoading()}
            {updateProductForm()}
        </div>

    )
};

// const UpdateProduct = ({ match }) => {
//     const [values, setValues] = useState({
//         name: '',
//         description: '',
//         price: '',
//         category: '',
//         shipping: '',
//         quantity: '',
//         photo: '',
//         loading: false,
//         createdProduct: '',
//         redirectToProfile: false,
//         formData: '',
//         categories: [],
//         error: ''
//     });
//
//     const {
//         name,
//         description,
//         price,
//         category,
//         shipping,
//         quantity,
//         loading,
//         createdProduct,
//         redirectToProfile,
//         formData,
//         // categories,
//         error } = values;
//
//     const [categories, setCategories] = useState([]);
//
//     //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
//
//     const {user, token} = isAuthenticated();
//
//     const initCategories = () => {
//         getCategories().then(data => {
//             if(data.error) {
//                 setValues({ ...values, error: data.error})
//             } else {
//                 setCategories(data);
//                 // setValues({...values, categories: data, formData: new FormData()})
//             }
//         })
//     };
//
//     const init = (productId) => {
//         getProduct(productId)
//             .then(data=> {
//                 if(data.error) {
//                     setValues({...values, error: data.error})
//                 } else {
//                     // populate state and then load categories
//                     setValues({...values, name: data.name, description: data.description, price: data.price, category: data.category._id, quantity: data.quantity, formData: new FormData()});
//                     initCategories();
//                 }
//             });
//     };
//
//     useEffect(() => {
//         init(match.params.productId);
//
//     }, []);
//
//     const handleChange = field => event => {
//         const value = (field === 'photo') ? event.target.files[0] : event.target.value;
//         formData.set(field, value);
//         setValues({...values, [field]: value})
//     };
//
//     const handleSubmit = e => {
//         // code
//         e.preventDefault();
//         setValues({...values, error: '', loading: true});
//
//
//         updateProduct(match.params.productId, user._id, token, formData)
//
//         // createProduct(user._id, token, formData)
//             .then(data => {
//                 if(data.error) {
//                     setValues({...values, error: data.error})
//                 } else {
//                     setValues({...values,
//                         name: '', description: '', photo: '', price: '', quantity: '', loading: false, createdProduct: data.name})
//                 }
//             })
//     };
//
//     const newProductForm = () => (
//         <div className="form-container">
//             <Form>
//                 <Form.Field>
//                     <label>Upload product photo</label>
//                     <input type='file' name='photo' accept='image/*' onChange={handleChange('photo')}/>
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Product Name</label>
//                     <input placeholder='Category name' onChange={handleChange('name')} value={name} type="text"/>
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Description</label>
//                     <textarea placeholder='Category name' onChange={handleChange('description')} value={description}/>
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Price</label>
//                     <input placeholder='Category name' onChange={handleChange('price')} value={price} type="number"/>
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Category</label>
//                     <select onChange={handleChange('category')}>
//                         <option>Please select a category</option>
//                         { categories && categories.map((cat, index) => (
//                             <option key={index} value={cat._id}>
//                                 {cat.name}
//                             </option>
//                         ))}
//                     </select>
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Quantity</label>
//                     <input placeholder='Category name' onChange={handleChange('quantity')} value={quantity} type="number"/>
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Shipping</label>
//                     <select onChange={handleChange('shipping')}>
//                         <option>Please select</option>
//                         <option value="0">No</option>
//                         <option value="1">Yes</option>
//                     </select>
//                 </Form.Field>
//                 <Button type='submit' onClick={handleSubmit}>Create product</Button>
//             </Form>
//
//         </div>
//     );
//
//     const showError = () => (
//         <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
//             {error}
//         </div>
//     );
//
//     const showSuccess = () => (
//         <div className="alert alert-info" style={{ display: createdProduct ? '' : 'none' }}>
//             Created product!
//         </div>
//     );
//
//     const showLoading = () => (
//         loading && (<h6>Loading...</h6>)
//     );
//
//     return(
//
//             <div className="">
//                 {showSuccess()}
//                 {showError()}
//                 {showLoading()}
//                 {newProductForm()}
//             </div>
//
//     )
// };

export default UpdateProduct