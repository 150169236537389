import {API} from "../config";
import * as queryString from "query-string";

export const getProducts = (sortBy) => {
    return fetch(`${API}/products?sortBy=${sortBy}&order=asc&limit=50`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getFilteredProducts = (skip, limit, filters = {}) => {

    const data = {
        limit, skip, filters
    };

    return fetch(`${API}/products/by/search`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err.message)
        });
};

export const productSearchResults = params => {
    const query = queryString.stringify(params);
    console.log("query", query);
    return fetch(`${API}/products/search?${query}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSingleProduct = productId => {
    return fetch(`${API}/product/${productId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getRelatedProducts = productId => {
    return fetch(`${API}/products/related/${productId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const razorpay = amount => {
    return fetch(`${API}/razorpay`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({ amount: amount })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err)
        });
};

export const hamperRazorpay = amount => {
    return fetch(`${API}/hamper/razorpay`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify({ amount: amount })
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err)
        });
};

export const createOrder = (userId, token, orderData) => {
    return fetch(`${API}/order/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ order: orderData})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const createHamperOrder = (userId, token, orderData) => {
    return fetch(`${API}/hamper-order/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ order: orderData})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const createDonateOrder = (orderData) => {
    return fetch(`${API}/donate/order/create`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(orderData)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const createPaymentRequest = (userId, token, data) => {
    return fetch(`${API}/payment`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            // Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(data)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const getPaymentSuccessData = () => {
    return fetch(`${API}/payment/complete/callback`, {
        method: "GET"
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const getProductsForCategory = (catId) => {
    return fetch(`${API}/courses/category/${catId}/products`, {
        method: "GET"
    }).then(response => {
        return response.json();
    })
        .catch(err => console.log(err));
};

export const forgotPassword = (email) => {
    return fetch(`${API}/forgot-password`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify({ email: email})
    }).then(response => {
        return response.json()
    })
        .catch(err => {
            console.log(err)
        });
};

export const resetPassword = (newPassword, resetPasswordLink) => {
    return fetch(`${API}/reset-password`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json'
        },
        body: JSON.stringify({ newPassword, resetPasswordLink })
    }).then(response => {
        return response.json()
    })
        .catch(err => {
            console.log(err)
        });
};

export const getAllHampers = (sortBy, orderBy) => {
    return fetch(`${API}/hampers?sortBy=${sortBy}&order=${orderBy}`, {
        method: 'GET'
    }).then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSingleHamper = hamperId => {
    return fetch(`${API}/hamper/${hamperId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getSingleOrder = (orderId) => {
    return fetch(`${API}/orders/${orderId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            // Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
