export const info =
{
    categories: [
        {
            catId: 1,
            categoryName: "Reiki Level Attunement",
            courses: [
                {
                    _id: '5ffa63794c7a055903ee5904',
                    course: "Reiki Level 1 Attunement",
                    src: '',
                    comingSoon: false,
                    whatWillYouLearn: [
                        "When you choose to receive attunement in Reiki Level 1, you learn to use your hands to heal yourself using the Divine Light. Over the 21 days after your attunement, you learn how peaceful your life can be. With Reiki, you learn how to go through life with gratitude and how to improve your health. Before you know it, you are introduced to a new energised Self which is able to do much more that it used to.\n After the class, you will be part of a WhatsApp group for 21 days, where your instructors and peers are available for QnA and discussion.",
                    ],
                    whatCanYouDo: [
                        "You will be able to heal yourself using your hands. While you can heal others too, we recommend focusing just on yourself for the 21 days healing.",
                        "You can cleanse your home, office, car, any environment that you frequent.",
                        "Charge various things with Reiki such as: objects at home, food and drinks, animals, money in your wallet, almost any perceivable object."
                    ],
                    language: "English / Hindi",
                    mindsetChanges: [
                        "I am an instrument",
                        "The receiver draws the energy.",
                        "Reiki is universal love and wisdom. It knows where and how to work.",
                        "We need to give it without attachment and expectations.",
                        "Let the best happen."
                    ],
                    materialsProvided: [
                        "A digital manual consisting of the 5 principles, healing hand positions with affirmations, information about reiki",
                        "Reiki music with a 3-min bell to change position"
                    ],
                    courseSchedule: [
                        "3 different pranayama/breathing techniques to calm you down. These can be used if you're feeling anxious or unsettled in the future.",
                        "Attunement to open your chakras and let the energy flow through you.",
                        "What is Reiki?",
                        "The 5 principles, their deeper meaning and the application in your life.",
                        "Feel the energy channeled through you.",
                        "3-4 calming meditations.",
                        "Full body positions to heal yourself.",
                        "Interaction and Q&A sessions with the instructors."
                    ],
                    whoIsThisCourseFor: [
                        "If you’re looking for healing, therapeutic practise.",
                        "If you’re looking for answers on your spiritual journey. ",
                        "If you have been attuned to Reiki but haven’t practised and you’re looking to get reacquainted with it.",
                        "Anybody!"
                    ],
                    FAQs: [
                        {
                            question: "When and where can we do Reiki? Are there any rituals involved? Is it a religion? ",
                            answer: "Reiki is healing energy that you channel into your body. You can practise it anytime and anywhere, under any circumstances. It does not clash or take away from your existing religious practises."
                        },
                        {
                            question: "Will my physical pain go away if I practise Reiki?",
                            answer: "Reiki shows us that our physical pain is a manifestation of our thought patterns, it shows us how to change it to bring us relief."
                        },
                        {
                            question: "Can I do it if I'm under medication?",
                            answer: "Yes, Reiki can be practised under any circumstances. It does not affect an ongoing treatment negatively. It helps you get better."
                        },
                        {
                            question: "Are there any side effects of practising Reiki?",
                            answer: "No. There is nothing negative that comes out of practising Reiki."
                        }
                    ],
                },
                {
                    _id: '5ffa63d14c7a055903ee5905',
                    course: "Reiki Level 2 Attunement",
                    comingSoon: false,
                    whatWillYouLearn: [
                        "You learn that you are important, focus on yourself, recreate yourself. In Level 1 you learn that though life is good, it can be better. In Level 2: Though you are good, you can be better.",
                        "After you complete 21 days of your Reiki Level 1 practise, you are now eligible to receive the Level 2 attunement. At this level, you are open to higher frequencies and go deeper within your self-discovery. After your Level 2 attunement you will find that the affirmations hold a completely different meaning for you.",
                        "You learn the three symbols of Reiki for Power, Harmony & Distance Healing. These help you focus the Reiki energy. At Level 2, you also learn the power of intentions and how to send healing over a distance. Your power grows and you will now be able to achieve your goals and intentions with ease.",
                        "In the course, you also learn about the seven chakras, their imbalances, their effects on the body and how to balance them. An in-depth chakra study however, is a separate course."
                    ],

                    whatCanYouDo: [
                        "You will be able to heal yourself and others using your hands and symbols.",
                        "You can send the symbols to cleanse your home, office, car, any environment, situations",
                        "Charge various things with the Reiki symbols such as: objects at home, food and drinks, animals, money in your wallet, almost any perceivable object.",
                        "You will be able to heal a person who is not present.",
                        "You will be able to heal your past and future.",
                        "You will be able to balance your chakras."
                    ],
                    language: "English / Hindi",
                    mindsetChanges: [
                        "I am important",
                        "I am enough",
                        "I can choose to be peaceful.",
                        "My past is over and done with. I choose to be a new person from here on.",
                        "I can achieve anything I set my mind to."
                    ],
                    materialsProvided: [
                        "Healing hand positions with affirmations",
                        "Information about flow of Reiki energy",
                        "Each symbol’s meaning, intention, purpose and how to apply it.",
                        "Time healing meditation",
                        "Absentee healing meditation",
                        "Home cleaning meditation",
                        "Forgiveness meditation",
                        "Intention box",
                        "Each chakra position, relation to internal organ, issues that come with its imbalance.",
                        "Positive affirmations"
                    ],
                    courseSchedule: [
                        "3 different pranayama/breathing techniques to calm you down. These can be used if you're feeling anxious or unsettled in the future.",
                        "12 body positions and attunement to the next level.",
                        "Further understanding of Life Force Energy and why the intentions are not fulfilled.",
                        "Introduction to the symbols, how to draw them and apply them.",
                        "5 principle meditation with the symbols.",
                        "Absentee healing: healing a person that is not present in the short & long method",
                        "Healing Time: how to send Reiki to the past and future",
                        "House cleaning mediation",
                        "Forgiveness meditation",
                        "Scooping meditation",
                        "What are intentions and how to apply them to your life",
                        "How to apply intentions with respect to people around you",
                        "Using an intention box",
                        "Meditation for childhood to adult healing",
                        "Interaction and Q&A sessions with the instructors"
                    ],
                    whoIsThisCourseFor: [
                        "Anyone who has been attuned to Reiki Level 1 and completed 21 days of healing (from any teacher/institute)",
                        "Anyone looking to refresh your Level 2 learning"
                    ],
                    FAQs: [
                        {
                            question: "Am I ready for the next level? How do I know if I’m ready for the next level? Do I need to do Level 2?",
                            answer: "There are issues you overcome, and some you don’t. When you realise you’re struggling with issues and there is no progress, you need that much guidance (from the light, from yourself) , power, to push through and go ahead."
                        },
                        {
                            question: "How will I feel after Level 2?",
                            answer: "You will feel more energetic, confident, blissful and elated. You’re on the cusp of flying high, business class."
                        },
                        {
                            question: "Will I be able to understand the next level?",
                            answer: "Our instructors have been practising for the last two decades and will guide you perfectly."
                        },
                        {
                            question: "Are there any dietary restrictions before the class?",
                            answer: "No, please continue with your regular diet before the class, don’t make any sudden changes."
                        }
                    ]
                },
                {
                    _id: '5ffb87a2d852f5611b8aec9e',
                    course: 'Reiki Level 3A Attunement',
                    comingSoon: true
                },
                {

                    _id: '604708882326950e4688f3e6',
                    course: 'Karuna Reiki',
                    comingSoon: false,
                    whatWillYouLearn: [
                        "The name Karuna means kindness and compassion.",
                        "It is a non-traditional form of Reiki with which you bring kindness and compassion into your being. It is related to unconditional love and spiritual evolution, starting with yourself and radiating outwards to others.",
                        "To make things more clear, as it helps you to heal, you want to help others too because we are all interconnected. With the eight new symbols that you will learn as part of Karuna Reiki, you will find your spiritual power increasing and you will find your life improving tenfold."
                    ],

                    whatCanYouDo: [
                        "Release traumatic events from the past",
                        "Amplify distance healing",
                        'Restore balance physically, mentally, emotionally, and spiritually, and bring about deep healing',
                        "Develop self-love",
                        "Heal broken relationships",
                        "Bring peace into your life by healing resentment, disagreements and much more",
                        "Release insecurities",
                        "Release addictions",
                        "Release feelings of anxiety",
                        "Get out of abusive situations",
                        "Take strong decisions",
                        "Take better financial decisions",
                        "Reach a higher plane of spirituality",
                        "Master your thought processes",
                        "Increases self-confidence",
                        "Helps spiritual progress in a practical way",
                        "A revision of everything you have learnt in Level 1 & 2"
                    ],
                    language: "English / Hindi",
                    mindsetChanges: [
                        "I trust more",
                        "I am positively empowered and successful in all that I do",
                        "I feel complete",
                        "I am balanced",
                        "I release old limitations and create a new me",
                        "I step ahead in complete faith"
                    ],
                    materialsProvided: [
                        "Karuna Reiki Manual with new symbols and explanation",
                        "Healing hand positions with affirmations",
                        "Each symbol's detailed meaning, intention, purpose and how to apply it",
                        "Various meditations"
                    ],
                    courseSchedule: [
                        "3 different pranayama/breathing techniques to calm you down. These can be used if you're feeling anxious or unsettled in the future.",
                        "Revision of Level 2 and 3A symbols",
                        "Introduction to the new symbols, what they mean, how they affect you",
                        "How to draw the new symbols, how to use them with the Level 2 Usui Reiki symbols and how apply them",
                        "Pink bubble meditation",
                        "Sho-ku-rei symbol meditation",
                        "Absentee healing: healing a person that is not present in the short & long method",
                        "Meditation for childhood to adult healing",
                        "Interaction and Q&A sessions with the instructors"
                    ],
                    whoIsThisCourseFor: [
                        "Anyone who has been attuned to Level 3A (ART) and completed 21 days of healing (from any teacher/institute)",
                        "Anyone looking to refresh your Karuna Reiki learning."
                    ],
                    FAQs: [
                        {
                            question: "Am I ready for Karuna Reiki?",
                            answer: "If you have completed level 3A known as ART from ur or any teacher or institution, you can " +
                                "take this course. The symbols in Karuna Reiki are used along with the traditional Usui symbols."
                        },
                        {
                            question: "I have already done Karuna Reiki, can I still do this course?",
                            answer: "If you have already done Karuna Reiki with us, this class is free for you to attend."
                        },
                        {
                            question: "Will I be able to understand the next level?",
                            answer: "Our instructors have been practising for the last two decades and will guide you perfectly."
                        },
                        {
                            question: "Are there any dietary restrictions before the class?",
                            answer: "No, please continue with your regular diet before the class, don’t make any sudden changes."
                        }
                    ]
                },
                {
                    _id: '5ffb8808d852f5611b8aec9f',
                    course: 'Reiki Level 3B Attunement',
                    comingSoon: true
                },
                //// end of reiki courses
            ],
        },
        // {
        //     catId: 2,
        //     categoryName: "Counseling Session",
        //     courses: [
        //         {
        //             _id: 1,
        //             course: 'Counseling Session',
        //             comingSoon: true
        //         }
        //     ],
        // },
        // {
        //     catId: 3,
        //     categoryName: "21 Day Courses",
        //     courses: [
        //         {
        //             _id: 1,
        //             course: '21 Days Self Love Meditation',
        //             comingSoon: true
        //         },
        //         {
        //             _id: 2,
        //             course: '21 Days Abundance Meditation',
        //             comingSoon: true
        //         },
        //     ],
        // },
    ],
};

