import React from 'react';
import {isAuthenticated} from "../../auth";
import './TreasureBox.scss';
import level1manual from '../../assets/Reiki level 1 Manual.pdf';
import level2manual from '../../assets/Reiki level 2 manual.pdf';
import level3Amanual from '../../assets/Reiki level 3A manual.pdf';
import levelkarunamanual from '../../assets/Karuna Reiki Manual.pdf';
import reikiMusic from '../../assets/reikimusic.mpeg';

const TreasureBox = () => {

    const { user, token } = isAuthenticated();

    return(
        <div className="TreasureBox">

            {user.level === 'Not attuned yet' ? <div>Sorry! To access the Treasure Box, you need to be attuned to a Reiki level. To attend a class, visit our course catalogue</div> : <div>
                <h5 className="title">REIKI MUSIC</h5>
                <div className="music-container">
                    <audio controls>
                        <source src={reikiMusic} type="audio/mpeg" />
                    </audio>
                </div>

                <h5 className="title">REIKI MEDITATIONS</h5>
                <div className="meditation-videos-container">
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947549/Users/TreasureBoxGeneral/fcb24791-ec0f-4cf1-96ea-2d206c6ad34d_kftdwj.mov" width="550" height="300" controls/>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947507/Users/TreasureBoxGeneral/4ab7b026-e711-46bd-a5e0-ce6ceda7c299_ikp2ck.mov" width="550" height="300" controls/>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947512/Users/TreasureBoxGeneral/b72a5498-6ab1-4fa2-80eb-8c9b62d5b73c_m6lz4w.mov" width="550" height="300" controls/>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947514/Users/TreasureBoxGeneral/c64205c0-9f78-4f43-94ec-d3be5d88ab03_yohmog.mov" width="550" height="300" controls/>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947543/Users/TreasureBoxGeneral/05711bf6-6cbf-46ea-853a-2ffbd3ceb1bb_jkcq44.mov" width="550" height="300" controls/>
                </div>

                {/*<h5 className="title">REIKI MANUAL</h5>*/}
                {/*<div className="pdf-container">*/}
                {/*    {user.level === '1' && <embed src={level1manual} width="800px" height="2100px" />}*/}
                {/*    {user.level === '2' &&*/}
                {/*    <div className="pdfs">*/}
                {/*        <embed src={level1manual} width="800px" height="2100px" />*/}
                {/*        <embed src={level2manual} width="800px" height="700px" />*/}
                {/*    </div>}*/}
                {/*    {user.level === '3A' &&*/}
                {/*    <div className="pdfs">*/}
                {/*        <embed src={level1manual} width="800px" height="2100px" />*/}
                {/*        <embed src={level2manual} width="800px" height="700px" />*/}
                {/*        <embed src={level3Amanual} width="800px" height="700px" />*/}
                {/*    </div>}*/}
                {/*    {user.level === 'Karuna' &&*/}
                {/*    <div className="pdfs">*/}
                {/*        <embed src={level1manual} width="80px"  />*/}
                {/*        <embed src={level2manual} width="80px"  />*/}
                {/*        <embed src={level3Amanual} width="80px"  />*/}
                {/*        <embed src={levelkarunamanual} width="80px"  />*/}
                {/*    </div>}*/}
                {/*    /!*{user.level === '3B' && <embed src={level2manual} width="800px" height="700px" />}*!/*/}
                {/*    {user.level === 'Not attuned yet' && <div>Sorry! To access the Treasure Box, you need to be attuned to a Reiki level. If you are attuned to level and cannot see the contents of the Treasure Box then please contact us.</div>}*/}
                {/*</div>*/}
            </div>}

        </div>
    )
};

export default TreasureBox
