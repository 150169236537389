import React, {useEffect, useState} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './Enroll.scss';
import {emptyCart, getCart} from "./helpers/cart";
import moment from "moment";
import {isAuthenticated} from "../auth";
import logo from '../assets/DishaaLogo.png';
import {createOrder, razorpay} from "./CoreApi";
import {Link, useHistory} from 'react-router-dom';
import {check} from "./helpers/browserCheck";

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true)
        };
        script.onerror = () => {
            resolve(false)
        };
        document.body.appendChild(script)
    })
}

const __CHARS__ = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

const Enroll = () => {

    const [cart, setCart] = useState([]);
    const { user,token } = isAuthenticated();
    const history = useHistory();
    const [manualPay, setManualPay] = useState(true);
    // const __DEV__ = document.domain === 'localhost';

    const getTime = (time) => {
        if(time !== undefined) {
            let ampm = '';
            let arr = time.split(':');
            let hh = parseInt(arr[0]);
            let h=0;
            if(hh >= 12) {
                h = hh - 12;
                ampm = 'PM';
                if(h>=12){
                    h = h - 12;
                    ampm = 'PM';
                }
            } else {
                h = hh;
                ampm = 'AM';
            }
            if(h === 0) {
                h=12;
                ampm='PM'
            }
            return h.toString()+':'+arr[1]+ampm;
        }
    };

    const placeOrder = (product, paymentId, price) => {
        const orderData = {
            products: product,
            payment_id: paymentId,
            amount: price
        };

        createOrder(user._id, token, orderData)
            .then( response => {
                console.log("Order created successfully!");
                history.push('/payment-complete');
                console.log("enroll page",response)
            })
            .catch(error => {
                console.log(error);
            });
        emptyCart(() => {

            console.log("Payment was successful");
        });
    };



     async function displayRazorPay() {
         setManualPay(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return
        }

        razorpay(cart[0].price).then(res => {

            const options = {
                key: 'rzp_live_yFrqffHWFPwGBU',
                currency: 'INR',
                amount: res.amount.toString(),
                order_id: res.order_id,
                name: cart[0].name,
                // description: ' *insert description* ',
                image: {logo},
                handler: function (response) {
                    placeOrder(cart[0], response.razorpay_payment_id, cart[0].price);
                    // alert(response.razorpay_payment_id)
                    // alert(response.razorpay_order_id)
                    // alert(response.razorpay_signature)
                },
                prefill: {
                    name: user.firstName,
                    email: user.email,
                    contact: user.callNumber
                }
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open()

        })


    }

    const randomString = (length, chars)=> {
        let result = '';
        for (let i = length; i > 0; --i) {
            result += chars[Math.round(Math.random() * (chars.length - 1))];
        }
        return result;
    };

    const handleManualPayment = () => {
         setManualPay(false);
    };

     const proceedWithManualPay = () => {
         placeOrder(cart[0], 'manual_pay_' + randomString(12, __CHARS__), cart[0].price);
     };

     const refresherRegistration = () => {
         // console.log(cart[0]);
        placeOrder(cart[0], 'refresher_pay_' + randomString(14, __CHARS__), cart[0].price)
    };

     const refresherAttendanceCondition = (level, courseName) => {

         let bool;

         // change user.level in backend to int
         // add enum for following code
         if (courseName === 'Reiki Level 1 Attunement' && (level === '1' || level === '2' || level === '3A' || level === 'Karuna' || level === '3B' ))
             bool = true;
         if (courseName === 'Reiki Level 2 Attunement' && (level === '2' || level === '3A' || level === 'Karuna' || level === '3B' ))
             bool = true;
         if (courseName === 'Reiki Level 3A Attunement' && (level === '3A' || level === 'Karuna' || level === '3B' ))
             bool = true;
         if (courseName === 'Karuna Reiki' && (level === 'Karuna' || level === '3B'))
             bool = true;
         if (courseName === 'Reiki Level 3B Attunement' && (level === '3B'))
             bool = true;

         return bool;
     };

     const displayCourse = (level, courseName) => {
         let bool;

         if(courseName === 'Reiki Level 1 Attunement' && level === 'Not attuned yet')
             bool = true;
         else if(courseName === 'Reiki Level 2 Attunement' && level === '1')
             bool = true;
         else if(courseName === 'Reiki Level 3A Attunement' && level === '2')
             bool = true;
         else if(courseName === 'Karuna Reiki' && level === '3A')
             bool = true;
         else bool = courseName === 'Reiki Level 3B Attunement' && level === 'Karuna';

         return bool;
     };

     const alreadyPaidCondition = () => {
         let bool = false;
         user.history.map(x => {
             bool = x.fromDate === cart[0].fromDate &&
                 x.toDate === cart[0].toDate &&
                 x.name === cart[0].name
         });
         return bool;
     };

     const eligibilityClass = (courseName) => {
         let name;
         if(courseName === 'Reiki Level 1 Attunement' || courseName === 'Test')
             name = '...';
          if(courseName === 'Reiki Level 2 Attunement')
             name = 'Level 1';
         else if(courseName === 'Reiki Level 3A Attunement')
             name = 'Level 2';
         else if(courseName === 'Karuna Reiki')
             name = 'Level 3A (ART)';
         else if(courseName === 'Reiki Level 3B Attunement')
             name = 'Karuna Reiki';

         return name;
     };

    useEffect(() => {
        setCart(getCart());
    }, []);

    return(
        <div className="Enroll">
            <Navbar/>
            {isAuthenticated() ? (
                <div className={check() ? "enroll-section-mobile" : "enroll-section"}>
                    <div className="account-info-payment">
                        <h3>Your account Details:</h3>
                        <p><b>First name:</b> {user.firstName}</p>
                        <p><b>Last name:</b> {user.lastName}</p>
                        <p><b>Email:</b> {user.email}</p>
                        <p><b>Phone:</b> {user.callNumber}</p>
                        <p><b>Reiki Level: </b>{user.level}</p>
                    </div>


                    <div className="course-summary">
                        {cart.map((item, index) => {
                            return(
                                displayCourse(user.level, item.name) ?
                                <div key={index}>
                                    <h2><u>{item.name}</u></h2>
                                    <p><b>Fees:</b> ₹{item.price}</p>
                                    <p><b>Date:</b> <b>{moment(item.fromDate).format("MMM")} {moment(item.fromDate).format("Do")} - {moment(item.toDate).format("Do")}, {moment(item.fromDate).format("YYYY")}</b></p>
                                    <p><b>Time:</b> <b>{getTime(item.fromTime)}</b> to <b>{getTime(item.toTime)}</b> <small>Indian Standard Time (IST)</small></p>
                                    <p><b>Instructors:</b> Lakshmi & Sadhana</p>
                                    <p><b>Contact:</b> +91 91528 43218 for further enquiries</p>
                                    {alreadyPaidCondition() ?
                                        <div>
                                            <h1>You have completed your payment!</h1>
                                            <h3>To pay for a friend, please contact us! Thank you!</h3>
                                        </div>
                                        : <div className="checkout-button-group">
                                            <button className="button" onClick={displayRazorPay}>Pay</button>
                                        </div>}

                                    {!alreadyPaidCondition() && <div className="instructions">
                                        <p>Dishaa Pratishthaan is committed to your privacy, please read our <a href={window.location.origin+"/important-links/#privacyPolicy"}>privacy policy here</a>. Your payment details will be processed by <a href="https://razorpay.com/privacy/" target="_blank">Razorpay</a>, a payment gateway company (for Cards/UPI/GPay/NEFT), and a record of your payment will be stored by Dishaa Pratishthaan.</p>
                                        <p>(<b>Note:</b> We do not accept international cards yet. Coming soon!)</p>
                                    </div>}
                                </div>
                                    :
                                    refresherAttendanceCondition(user.level, item.name) ?
                                        <div>
                                            <h2><u>{item.name}</u></h2><br/>
                                            <div>This class is free for you. Please contact us at +91 91528 43218 to be added to the WhatsApp group.</div>
                                        </div>
                                        :
                                    <div>
                                        <h2><u>{item.name}</u></h2><br/>
                                        You will be eligible for this class, after you complete <b>{eligibilityClass(item.name)}.</b>
                                    </div>

                            )
                        })}
                        <div className="neft-details">
                            <h4>Dishaa Pratishthaan account details: </h4>
                            <div>Name of bank: <b>TJSB Bank</b></div>
                            <div>Bank Address: <b>Hiranandani Meadows, Manpada, Thane.</b></div>
                            <div>Name of account: <b>Dishaa Pratishthaan</b></div>
                            <div>Account no.: <b>046110100006441</b></div>
                            <div>Rtgs/Neft/IFSC code: <b>TJSB0000046</b></div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="sign-in-to-enroll-msg">
                    Please <Link to="/login">sign in</Link> to enroll for a class.
                </div>
                )
            }

            <Footer/>
        </div>
    )
};

export default Enroll;
