import {isAuthenticated} from "../../auth";
import React, {useEffect, useState} from "react";
import {createHamper} from "./AdminApi";
import FileUpload from "./FileUpload";
import ReactLoading from 'react-loading';

const CreateHamper = () => {
    const [values, setValues] = useState({
        name: '',
        price: '',
        shipping: '',
        stock: 0,
        // photo: '',
        createdProduct: '',
        // redirectToProfile: false,
        // formData: '',
        weight: '',
        items: [],
        images: [],
        variants: [],
        error: '',
    });
    const [hamperItem, setHamperItem] = useState('');
    const [loading, setLoading] = useState(false);
    const [favorite, setFavorite] = React.useState('');
    const [variantColor, setVariantColor] = useState('');
    const [variantStock, setVariantStock] = useState('');

    const {
        name,
        price,
        stock,
        createdProduct,
        redirectToProfile,
        // formData,
        weight,
        items,
        images,
        variants,
        error,
    } = values;

    const {user, token} = isAuthenticated();

    const init = () => {
        // what to load when we first open this component
        // setValues({...values, formData: new FormData()})
    };

    useEffect(() => {
        init();
    }, []);

    const handleChange = field => event => {
        setValues({...values, [field]: event.target.value});
    };

    const handleVariantRadioYesChange = () => {
        setFavorite('Yes');
    };
    const handleVariantRadioNoChange = () => {
        setFavorite('No');
    };

    const handleItemChange = (e) => {
        setHamperItem(e.target.value);
    };

    const handleClick = () => {
        let arr = values.items;
        arr.push(hamperItem);
        setValues({...values, items: arr});
        setHamperItem('');
        // console.log(values.items)
    };

    const handleSubmit = e => {
        // code
        e.preventDefault();
        setValues({...values, error: ''});
        setLoading(true);

        // if(favorite === 'Yes') {
        //     let sum = 0;
        //     variants.map(v => {
        //         sum = sum + Number(v.stock)
        //     });
        //     setValues({...values, stock: sum});
        // }

// console.log('this is the total stock:',stock);
        createHamper(user._id, token, JSON.stringify({ name, price, stock, weight, items, images, variants }))
            .then(data => {
                if(data.error) {
                    // console.log('this is the error bro: ',data.error)
                    setValues({...values, error: data.error})
                } else {
                    // console.log('data ----->>>>>',data)
                    setValues({...values,
                        name: '', photo: '', price: '', stock: '', shipping: '', weight: '', createdProduct: data.name});
                    window.location.reload();
                }
            })
    };

    const RadioButton = ({ label, value, onChange }) => {
        return (
            <label>
                <input type="radio" checked={value} onChange={onChange} />
                {label}
            </label>
        );
    };

    const addHamperForm = () => (
        <form onSubmit={handleSubmit} className="create-form">

            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <FileUpload values={values} setValues={setValues} setLoading={setLoading}/>
                {loading ? (
                    <ReactLoading type='spinningBubbles' color='#ee2a24' height={50} width={50} />
                ) : (
                    <></>
                )}
            </div>

            <input className="text-input" placeholder='Product name' onChange={handleChange('name')} value={name} type="text"/>

            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end'}}>
                <input className="text-input" placeholder='Hamper item' onChange={handleItemChange} value={hamperItem} name={hamperItem} type="text"/>
                <span className="add-button" style={{cursor: 'pointer'}} onClick={() => handleClick()}>Add</span>
            </div>
            <small><u>(Items added so far:</u> {values.items.map((val, idx) => (<span key={idx}>{val}, </span>))})</small>

            <input className="text-input" placeholder='Price' onChange={handleChange('price')} value={price} type="number"/>

            <input className="text-input" placeholder='Weight (in grams)' onChange={handleChange('weight')} value={weight} type="number"/>

            <div className="variant-section">
                <h4>Does this hamper have variants?</h4>
                <div className="radio-button-group">
                    <RadioButton
                        label="Yes"
                        value={favorite === 'Yes'}
                        onChange={handleVariantRadioYesChange}
                    />
                    <RadioButton
                        label="No"
                        value={favorite === 'No'}
                        onChange={handleVariantRadioNoChange}
                    />
                </div>
            </div>

            {favorite === 'No' ? <input className="text-input" placeholder='Stock available for selling' onChange={handleChange('stock')} value={stock} type="number"/>
            :
            favorite === 'Yes' ? <>

                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end'}}>
                    <input className="text-input" placeholder='Color variant' onChange={handleVariantColorChange} value={variantColor} name={variantColor} type="text"/>
                    <input className="text-input" placeholder='Stock of variant' onChange={handleVariantStockChange} value={variantStock} name={variantStock} type="number"/>
                    <span className="add-button" style={{cursor: 'pointer'}} onClick={() => addToVariantsMap()}>Add</span>
                </div>
                <small>Variants added so far: {JSON.stringify(values.variants)}</small>

            </> : <></>}

            {/*<select onChange={handleChange('shipping')} className="text-input">*/}
            {/*    <option>Can this item be shipped?</option>*/}
            {/*    <option value="0">No</option>*/}
            {/*    <option value="1">Yes</option>*/}
            {/*</select>*/}
            <button type='submit' className="button">Create hamper</button>
        </form>
    );

    const [totalStock, setTotalStock] = useState(0);

    const handleVariantColorChange = (e) => {
        setVariantColor(e.target.value);
    };
    const handleVariantStockChange = (e) => {
        setVariantStock(e.target.value);
    };

    const addToVariantsMap = () => {
        let arr = values.variants;
        let obj ={};
        obj.color = variantColor;
        obj.stock = variantStock;
        arr.push(obj);
        setValues({...values, variants: arr});
        setVariantColor('');
        setVariantStock('');
        setTotalStock(Number(totalStock)+ Number(variantStock));
        setValues({...values, stock: totalStock});
    };

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: createdProduct ? '' : 'none' }}>
            Created product!
        </div>
    );

    const showLoading = () => (
        loading && (<h6>Loading...</h6>)
    );

    return(
        <div className="AddCategory__Product">
            {showSuccess()}
            {showError()}
            {showLoading()}
            {addHamperForm()}
        </div>
    )
};

export default CreateHamper;
