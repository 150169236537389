import React from 'react';
import {API} from "../config";

const DisplayImage = ({item, url}) => {
    return(
            <img className="" src={`${API}/${url}/photo/${item._id}`} alt={item.name}
                 // style={{ maxHeight: '100%', maxWidth: '100%'}}
            />

    )
};

export default DisplayImage
