import React, {useState} from 'react';
import CarouselLeftArrow from "./CarouselLeftArrow";
import CarouselRightArrow from "./CarouselRightArrow";
import CarouselSlide from "./CarouselSlide";
import CarouselIndicator from "./CarouselIndicator";
import './CarouselStyle.scss';
import quote from '../../assets/quotes.png';
import {iPadCheck, phoneCheck} from "../helpers/browserCheck";

const Carousel = ({ slides }) => {

    const [activeIndex, setActiveIndex] = useState(0);

    const goToSlide = (index) => {
        setActiveIndex(index);
    };

    const goToPrevSlide = e => {

        e.preventDefault();
        let index = activeIndex;
        let slidesLength = slides.length;

        if(index < 1) {
            index = slidesLength;
        }

        --index;

        setActiveIndex(index);

    };

    const goToNextSlide = e => {
        e.preventDefault();
        let index = activeIndex;
        let slidesLength = slides.length -1;

        if(index === slidesLength) {
            index = -1;
        }

        ++index;

        setActiveIndex(index);
    };

    return(
        <div>
            {phoneCheck()  ?

                <div className="Carousel-container-mobile">
                    <CarouselLeftArrow onClick={e => goToPrevSlide(e)}/>
                    <div className="testimonials-container">
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651482687/HealingAndWellness/lpj8r9x2gdmye5ylju1y.jpg" width="100%" />
                        <div className="testimonial-text-container">
                            <ul className="carousel__slides">
                                {slides.map((slide, index) => (
                                    <CarouselSlide key={index} index={index} activeIndex={activeIndex} slide={slide}/>
                                ))}
                            </ul>
                        </div>
                        <ul className="carousel__indicators">
                            {slides.map((slide, index) => (
                                <CarouselIndicator key={index} index={index} activeIndex={activeIndex} isActive={activeIndex===index} onClick={() => goToSlide(index)}/>
                            ))}
                        </ul>
                    </div>
                    <CarouselRightArrow onClick={e => goToNextSlide(e)}/>
                </div>

                :

                <div className="Carousel-container">
                    <CarouselLeftArrow onClick={e => goToPrevSlide(e)}/>
                    <div className="testimonials-container">
                        {/*<img src={quote} alt="quotes"/>*/}
                        <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1651464613/HealingAndWellness/o5eqgojig5iw37oedqab.png" width="100%" />
                        <div className="testimonial-text-container">
                            <ul className="carousel__slides">
                                {slides.map((slide, index) => (
                                    <CarouselSlide key={index} index={index} activeIndex={activeIndex} slide={slide}/>
                                ))}
                            </ul>
                            {/*<ul className="carousel__indicators">*/}
                            {/*    {slides.map((slide, index) => (*/}
                            {/*        <CarouselIndicator key={index} index={index} activeIndex={activeIndex} isActive={activeIndex===index} onClick={() => goToSlide(index)}/>*/}
                            {/*    ))}*/}
                            {/*</ul>*/}
                        </div>
                        <ul className="carousel__indicators">
                            {slides.map((slide, index) => (
                                <CarouselIndicator key={index} index={index} activeIndex={activeIndex} isActive={activeIndex===index} onClick={() => goToSlide(index)}/>
                            ))}
                        </ul>
                    </div>

                    <CarouselRightArrow onClick={e => goToNextSlide(e)}/>
                </div>
            }
        </div>
    )
};

export default Carousel
