import React, {useEffect, useState} from 'react';
import './HealingModalities.scss';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {Link, useHistory} from "react-router-dom";
import {galleryImages, healingSessions} from "./HealingModalities";
import {phoneCheck} from "./helpers/browserCheck";
// import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/scss/image-gallery.scss";
// import YouTube from "react-youtube";

const HealingSessionsPDP = (props) => {

    const [name, setName] = useState('');

    const loadHealingModality = (urlName) => {
        setName(urlName);
    };

    useEffect(() => {
        const urlName = props.match.params.urlName;
        loadHealingModality(urlName)
        console.log(props.match.params)
        // console.log("today's date", isToday(new Date('2023-12-08')));
    }, [props]);

    const intro = (hm) => (
        <div className="intro-container">
            <div className="intro-content">
                <img src={hm.imageUrl} className="healing-modality-img"/>
                <span className="healing-modality-name">{hm.name}</span>
            </div>
            <hr/>
        </div>
    );


    const registerButton = (hm) => (
        <div className="register-container">
            <div className="fees">Fees: Rs. {hm.fees}/- </div><br/>
            {button(hm)}
        </div>
    );

    const registerButtonMobile = (hm) => (
        <div className="register-container-mobile">
            <div className="fees">Fees: Rs. {hm.fees}/- </div><br/>
            {button(hm)}
        </div>
    );

    const button = (hm) => {
        // console.log('hm',hm.id);
        return <a href={hm.registerLink} target="_blank">
            <button className="register-button">
                Register now!
            </button>
        </a>
    };

    const rogRegister = (hm) => (
        <div className="rog-register-container">

            <div className="rog-register-fees">Fees: Rs. {hm.fees}/-</div>
            <div className="lang-selection-section">
                <div className="language-text">Select language</div>
                <div className="register-button-group">
                    <a href={hm.registerLinkEng} target="_blank">
                        <button className="register-button">
                            English
                        </button>
                    </a>

                    <a href={hm.registerLinkHin} target="_blank">
                        <button className="register-button">
                            Hindi
                        </button>
                    </a>

                    <a href={hm.registerLinkMar} target="_blank">
                        <button className="register-button">
                            Marathi
                        </button>
                    </a>

                    <a href={hm.registerLinkGuj} target="_blank">
                        <button className="register-button">
                            Gujarati
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );

    const rogRegisterMobile = (hm) => (
        <div className="rog-register-container-mobile">

            <div className="rog-register-fees">Fees: Rs. {hm.fees}/-</div>
            <div className="lang-selection-section">
                <div className="language-text">Select language</div>
                <div className="register-button-group">
                    <a href={hm.registerLinkEng} target="_blank">
                        <button className="register-button">
                            English
                        </button>
                    </a><br/>

                    <a href={hm.registerLinkHin} target="_blank">
                        <button className="register-button">
                            Hindi
                        </button>
                    </a><br/>

                    <a href={hm.registerLinkMar} target="_blank">
                        <button className="register-button">
                            Marathi
                        </button>
                    </a><br/>

                    <a href={hm.registerLinkGuj} target="_blank">
                        <button className="register-button">
                            Gujarati
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );

    const whatWillYouLearnSection = (hm) => (
        <div className={phoneCheck() ? "what-will-you-learn-container-mobile" : "what-will-you-learn-container"}>
            <div className="title">What will you learn?</div>
            {hm.whatWillYouLearn.map((wwyl, index) => (
                <p className="content" key={index}>{wwyl}</p>
            ))}
            {knowYourReikiGrandmasters()}
        </div>
    );

    const knowYourReikiGrandmasters = () => (
        <div className="know-your-grandmasters">
            Know more about your Reiki Grandmasters <Link to="/know-your-grandmasters">here.</Link>
        </div>
    );

    const advantages = (hm) => (
        <div className={phoneCheck() ? "advantages-container-mobile" : "advantages-container"}>
            <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705645707/njnsp6yix1zkqmzexrsg.png" />
            <div className="advantages-content">
                <div className="title">Why do the Rainbow of Gratitude program</div>
                <ol>
                    {hm.advantages.map((a,i) => (
                        <li key={i}>{a}</li>
                    ))}
                </ol>
            </div>

        </div>
    );

    const courseSchedule = (hm) => (
        <div className={phoneCheck() ? "course-schedule-container-mobile" : "course-schedule-container"}>
            <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654842053/HealingAndWellness/HealingModalities/m86o5k0q7qtkqmbxfcrt.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654791684/HealingAndWellness/HealingModalities/ll32afxsjbhzr9mwbtcr.jpg"} />
            <div className="content">
                <div className="title">What to expect in the program</div>
                <ol>
                    {hm.courseSchedule.map((cs,i) => (
                        <li key={i}>{cs}</li>
                    ))}
                </ol>
            </div>
        </div>
    );

    const courseScheduleMobile = (hm) => (
        <div className={phoneCheck() ? "course-schedule-container-mobile" : "course-schedule-container"}>
            {/*<img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654842053/HealingAndWellness/HealingModalities/m86o5k0q7qtkqmbxfcrt.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654791684/HealingAndWellness/HealingModalities/ll32afxsjbhzr9mwbtcr.jpg"} />*/}
            <div className="content">
                <div className="title">What to expect in the program?</div>
                <ol>
                    {hm.courseSchedule.map((cs,i) => (
                        <li key={i}>{cs}</li>
                    ))}
                </ol>
            </div>
        </div>
    );

    {/*https://www.npmjs.com/package/react-image-gallery*/}
    // const gallery = () => (
    //     <div className="image-gallery-container">
    //
    //         <div className="gallery-section-title">Glimpses of program activities</div>
    //         <div className="gallery">
    //             <ImageGallery items={galleryImages} />
    //         </div>
    //     </div>
    // );

    const imageGallery = () => (
        <div className={phoneCheck() ? "gallery-section-mobile" : "gallery-section"}>
            <div className="title">Glimpses of program activities</div>
            <div className="gallery-container">
                {galleryImages.map((img,i) => (
                    <img src={img.original} key={i} className="image"/>
                    ))}
            </div>
        </div>
    );

    const videoSection = () => (
        <div>
            <div className="med-title" style={{ textAlign: "center", marginTop: '24px', fontSize: '18px', fontFamily: 'Montserrat sans-serif'}}><b>The following video will give you an idea of our meditations and the kind of course you can expect with 'New Beginnings 2024'</b></div><br/><br/>
            <div className="meditation-videos-container" style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                {/*<div>*/}
                {/*    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947507/Users/TreasureBoxGeneral/4ab7b026-e711-46bd-a5e0-ce6ceda7c299_ikp2ck.mov" width="200" height="150" controls/>*/}
                {/*    <p>Food Meditation</p>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <video src="https://youtu.be/ALo6zA5NwmU" width="200" height="150" controls/>*/}
                {/*    <p>Catch course glimpses of Goodbye 2022 above</p>*/}
                {/*</div>*/}
                {/*<iframe width="200" height="150"*/}
                {/*        src="https://youtu.be/ALo6zA5NwmU">*/}
                {/*</iframe>*/}
                <div>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947514/Users/TreasureBoxGeneral/c64205c0-9f78-4f43-94ec-d3be5d88ab03_yohmog.mov" width="200" height="150" controls/>
                    <p>Use our free meditation at your pace</p>
                </div>
            </div>
        </div>
    );

    // const videoSection1 = () => (
    //     <div className="video-section-container">
    //
    //         <div className="video-section-title">Meditation included in the program</div>
    //         <div className="video-section">
    //             {phoneCheck() ? <YouTube videoId="43KQTsQHPtU" options={mobileOptions} onReady={_onReady} id="video"/> : <YouTube videoId="43KQTsQHPtU" options={options} onReady={_onReady}/>}
    //         </div>
    //
    //     </div>
    // );

    const options = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
            controls: 1,
        },
    };

    const mobileOptions = {
        height: '50',
        width: '100',
        playerVars: {
            autoplay: 1,
            controls: 1,
        },
    };

    const _onReady = (event) => {
        event.target.pauseVideo();
    };

    return(
        <div className="HealingModalitiesPDP">
            <Navbar/>
            {healingSessions.map((hm,i) => {
                if(name === hm.urlName) {
                    return(
                        <div key={i} className="hm">
                            {intro(hm)}
                            {/*{phoneCheck() ? registerButtonMobile(hm) : registerButton(hm)}*/}
                            {phoneCheck() ? rogRegisterMobile(hm) : rogRegister(hm)}
                            {phoneCheck() && <img className="hr-arrow-line" src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654841165/HealingAndWellness/HealingModalities/qnwzlncl3d2ahvewras6.png" />}
                            {whatWillYouLearnSection(hm)}
                            {/*{whatCanYouDoSection(hm)}*/}
                            {/*{langMindsetMaterials(hm)}*/}
                            {phoneCheck() ? courseScheduleMobile(hm) : courseSchedule(hm)}
                            {advantages(hm)}
                            {imageGallery()}
                            {/*{gallery()}*/}
                            {/*{videoSection1()}*/}
                            {/*{videoSection()}*/}
                            {/*{courseFees(hm)}*/}
                            {/*{testimonials()}*/}
                            {/*{!phoneCheck() && <hr/>}*/}
                            {/*{faqs(hm)}*/}
                        </div>)

                }
            })}
            <br/><br/>
            <Footer/>
        </div>

    )
};

export default HealingSessionsPDP;
