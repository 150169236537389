import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './KnowYourGrandmasters.scss';
import {phoneCheck} from "./helpers/browserCheck";

const KnowYourGrandmasters = () => (
    <div className="KnowYourGrandmasters">
        <Navbar/>
        <div className={phoneCheck() ? "" : ""}>
            <img className="banner-img" src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654170691/HealingAndWellness/KnowYourGrandmasters/wtg3diiowilslhpbszyj.jpg" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654764023/HealingAndWellness/KnowYourGrandmasters/cmfscdow9q4jk4lsuxji.jpg"} />
            <p className="intro"><b>Lakshmi Bhat</b> is a Reiki Grandmaster and the Chief Trustee of Dishaa. She is the guiding light, the soul and the driving force of our family. For 13 years, she worked as a software professional before she started teaching yoga & Reiki. She has helped many women find their way to happiness. Several of them are a part of Dishaa Pratishthaan today. Every big and beautiful thing that happens at Dishaa, is her brainchild.</p>
            <div className={phoneCheck() ? "lines-n-quotes-container-mobile" : "lines-n-quotes-container"}>
                <img className="lines-n-quotes" src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654157432/HealingAndWellness/KnowYourGrandmasters/mmtjyxqwipqch14mlzll.png" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654155515/HealingAndWellness/KnowYourGrandmasters/o6je4yxjxotot77hruuo.png"} />
                <p className="text-for-quotes">"All that I had learnt before I got attuned to Reiki, had to be unlearnt and relearnt. 'Practicing Reiki' means following the five principles of the Universal Lifeforce. As I practised the Reiki hand positions, the unlearning and relearning was easier than I thought."</p>
            </div>
            {/*<p className="ending-line">Lakshmi also makes cute crochet objects at Yarn Affairs.</p>*/}
            <br/>

            <p className="intro"><b>Sadhana Desai</b> is a Reiki Grandmaster who has been practising Reiki for the last 17 years. She is the Co-Trustee of Dishaa and is always ready to share a joke, comforting words and a few pearls of wisdom too.</p>
            <div className={phoneCheck() ? "lines-n-quotes-container-mobile" : "lines-n-quotes-container"}>
                <img className="lines-n-quotes" src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654158501/HealingAndWellness/KnowYourGrandmasters/ob1535vyu0dc6wlongfq.png" : "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654158491/HealingAndWellness/KnowYourGrandmasters/f2vdomh0ibmymavf7zpc.png"} />
                <p className="text-for-quotes">“Reiki has taken care of my mental health in so many different ways and made me a better person today. It is a miracle, which I believe in and choose to believe also. It has shown me how to live a good life!”</p>
            </div>
            {/*<p className="ending-line">Sadhana also bakes yummy goodies at Pat-a-Cake.</p>*/}
            <br/><br/>
        </div>
        <Footer/>
    </div>
);

export default KnowYourGrandmasters;
