import React, {useEffect} from 'react';
import './InnerChildHealingProgram.scss';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {phoneCheck} from "./helpers/browserCheck";
import { useState } from 'react';
// import PasswordModal from "./PasswordModal";
import Modal from "./Modal";
import {menuData} from "./HamburgerMenuItems";
import {Link} from "react-router-dom";

const InnerChildHealingProgram = () => {

    const [selectedDay, setSelectedDay] = useState('Week 1 Day 1');
    const [selectedWeek, setSelectedWeek] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleDropdownMenuItem = (day) => {
        setSelectedDay(day);
        setSelectedWeek(day);
        setOpen(false)
    };


    useEffect(() => {
        setModalOpen(true)
    }, []);

    return(
        <div className="InnerChildHealing">
            <Navbar/>

            {/*<PasswordModal/>*/}

            {modalOpen && <Modal setOpenModal={setModalOpen} />}

            <h5 className={phoneCheck() ? "med-title-mobile" : "med-title"}>INNER CHILD HEALING</h5>
            <p className={phoneCheck() ?"intro-mobile" : "intro"}>Daily Session Recordings</p>
            {/*<h2 className="week-title">Week 4</h2>*/}


            {/*<div className="ich-days-container">*/}
            {/*    <div className={phoneCheck() ? "ich-days-mobile" : "ich-days"}>*/}
            {/*        {ichDays.map((d,i) => (*/}
            {/*            <button key={i} onClick={() => setSelectedDay(d.day)} className="day-button">{d.day}</button>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*mobile view*/}
            <div>
                {/*<span className="">*/}
                {/*        <ul className="nav-menu-items">*/}
                {/*           { ichDays.map((item) => (*/}
                {/*               <li className="menu-item">*/}
                {/*                       <span onClick={() => handleNavMenuClick(item.children, item.id)} className="menu-link">*/}
                {/*                           {item.label} &nbsp; <i className="fa fa-angle-down"/>*/}
                {/*                       </span>*/}
                {/*                   {childDropdownOpen.includes(item.id) && childDropdownMenu()}*/}
                {/*               </li>*/}
                {/*           ))}*/}
                {/*       </ul>*/}
                {/*    </span>*/}

                <div className="dropdown">
                    <button className="dropdown-button" onClick={handleOpen}>{'Week ' + selectedWeek} &nbsp; <i className="fa fa-angle-down"/></button>
                    {open ? (
                        <ul className="menu">
                            <li className="menu-item">
                            {ichDays1.map((d,i) => (
                                <button key={i} onClick={() => handleDropdownMenuItem(d.week)} className="day-button">Week {d.week}</button>
                            ))}
                            </li>

                        </ul>
                    ) : null}

                </div>
            </div>


            {/*start new list */}
            {ichDays1.map((w,i) => (
                <div className={phoneCheck() ? "video-container-mobile" : "video-container"} key={i}>

                    {w.week === selectedWeek ?

                        w.videos.map((vid, ix) => (
                            <div className="single-video" key={ix}>
                                <p>{vid.title}</p>
                                <iframe width={phoneCheck() ? "350" : "560"} height={phoneCheck() ? "200" : "315"} src={vid.src}
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen/>
                            </div>
                        ))
                        :
                        null}

                </div>
            ))}

            {/*end new list*/}


            {/*{ichDays.map((d,i) => (*/}
            {/*    <div className={phoneCheck() ? "video-container-mobile" : "video-container"} key={i}>*/}

            {/*        {d.day === selectedDay ?*/}
            {/*            <div className="single-video">*/}
            {/*                <p>{d.title}</p>*/}
            {/*                <iframe width={phoneCheck() ? "350" : "560"} height={phoneCheck() ? "200" : "315"} src={d.src}*/}
            {/*                        title="YouTube video player" frameBorder="0"*/}
            {/*                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*                        allowFullScreen/>*/}
            {/*            </div>*/}
            {/*        :*/}
            {/*        null}*/}

            {/*    </div>*/}
            {/*))}*/}
            <Footer/>
        </div>
    )
};

export default InnerChildHealingProgram;

const ichDays1 =
    [
        {
            week: 1,
            videos: [
                {
                    day: 'Week 1 Day 1',
                    src: 'https://www.youtube.com/embed/ZvBCSZ6PBH0?si=SvbUVCP44MENXNi2',
                    title: 'Day 1'
                },
                {
                    day: 'Week 1 Day 2',
                    src: 'https://www.youtube.com/embed/LZPVrYvMHfc?si=vPx6bKFoLQn0BDuZ',
                    title: 'Day 2'
                },
                {
                    day: 'Week 1 Day 3',
                    src: 'https://www.youtube.com/embed/a5vqV5LHZGE?si=2rtq9JfqY3Oda3Uo',
                    title: 'Day 3'
                },
                {
                    day: 'Week 1 Day 4',
                    src: 'https://www.youtube.com/embed/UM16hEww-Ko?si=z0PpVV8HXT1O8Ba7',
                    title: 'Day 4'
                },
                {
                    day: 'Week 1 Day 5',
                    src: 'https://www.youtube.com/embed/5D9bqKmIZ-E?si=YOX4-5etd8BLIr5_',
                    title: 'Day 5'
                }
            ]
        },
        {
            week: 2,
            videos: [
                {
                    day: 'Week 2 Day 1',
                    src: 'https://www.youtube.com/embed/xJ33lqQK2yQ?si=DRyiBx89-_kP2ulU',
                    title: 'Day 1'
                },
                {
                    day: 'Week 2 Day 2',
                    src: 'https://www.youtube.com/embed/oJMI8dkQd8A?si=3tpotD-PY97n2LqQ',
                    title: 'Day 2'
                },
                {
                    day: 'Week 2 Day 3',
                    src: 'https://www.youtube.com/embed/ZqwE9HMGC5s?si=4b0lsHBfYaqaoMAs',
                    title: 'Day 3'
                },
                {
                    day: 'Week 2 Day 4',
                    src: 'https://www.youtube.com/embed/BwX3IggufPE?si=PWxoog-6ufkQLwRf',
                    title: 'Day 4'
                },
                {
                    day: 'Week 2 Day 5',
                    src: 'https://www.youtube.com/embed/8Crk2hU38ns?si=vQIbIQ0yEU5lu7TP',
                    title: 'Day 5'
                }
            ]
        },
        {
            week: 3,
            videos: [
                {
                    day: 'Week 3 Day 1',
                    src: 'https://www.youtube.com/embed/8zvqJBNIj0o?si=cvVqjAsPOVYAM21C',
                    title: 'Day 1'
                },
                {
                    day: 'Week 3 Day 2',
                    src: 'https://www.youtube.com/embed/iJdbJLDcB90?si=l9eB_282Nsj35CcL',
                    title: 'Day 2'
                },
                {
                    day: 'Week 3 Day 3',
                    src: 'https://www.youtube.com/embed/FFkMuSeYYBI?si=O8QFhowyo0-0rh5R',
                    title: 'Day 3'
                },
                {
                    day: 'Week 3 Day 4',
                    src: 'https://www.youtube.com/embed/dLCu8gh6xqI?si=oopzn3amFUAVoQeV',
                    title: 'Day 4'
                },
                {
                    day: 'Week 3 Day 5',
                    src: 'https://www.youtube.com/embed/EFvObVS1Abc?si=k_Rrcrn9dAgFYuOp',
                    title: 'Day 5'
                }
            ]
        },
        {
            week: 4,
            videos: [
                {
                    day: 'Week 4 Day 1',
                    src: 'https://www.youtube.com/embed/2_2TlrlBujA?si=zKO3wpPWlu_5bdqo',
                    title: 'Day 1'
                },
                {
                    day: 'Week 4 Day 2',
                    src: 'https://www.youtube.com/embed/e9vNTIbHUuA?si=FqJfWMakPhd21hM1',
                    title: 'Day 2'
                },
                {
                    day: 'Week 4 Day 3',
                    src: 'https://www.youtube.com/embed/xnfpUC0-zYU?si=uE_ha1DhLsqKBhed',
                    title: 'Day 3'
                },
                {
                    day: 'Week 4 Day 4',
                    src: 'https://www.youtube.com/embed/yV6b2pWB6OQ?si=T6XN8A3JseKdskLc',
                    title: 'Day 4'
                },
                {
                    day: 'Week 4 Day 5',
                    src: 'https://www.youtube.com/embed/CP9b64cjxAw?si=BjkyamlFWh_W5C2e',
                    title: 'Day 5'
                }
            ]
        }
    ];


const ichDays = [

    {
        day: 'Week 1 Day 1',
        src: 'https://www.youtube.com/embed/ZvBCSZ6PBH0?si=SvbUVCP44MENXNi2',
        title: 'Week 1: Day 1'
    },
    {
        day: 'Week 1 Day 2',
        src: 'https://www.youtube.com/embed/LZPVrYvMHfc?si=vPx6bKFoLQn0BDuZ',
        title: 'Week 1: Day 2'
    },
    {
        day: 'Week 1 Day 3',
        src: 'https://www.youtube.com/embed/a5vqV5LHZGE?si=2rtq9JfqY3Oda3Uo',
        title: 'Week 1: Day 3'
    },
    {
        day: 'Week 1 Day 4',
        src: 'https://www.youtube.com/embed/UM16hEww-Ko?si=z0PpVV8HXT1O8Ba7',
        title: 'Week 1: Day 4'
    },
    {
        day: 'Week 1 Day 5',
        src: 'https://www.youtube.com/embed/5D9bqKmIZ-E?si=YOX4-5etd8BLIr5_',
        title: 'Week 1: Day 5'
    },
    {
        day: 'Week 2 Day 1',
        src: 'https://www.youtube.com/embed/xJ33lqQK2yQ?si=DRyiBx89-_kP2ulU',
        title: 'Week 2: Day 1'
    },
    {
        day: 'Week 2 Day 2',
        src: 'https://www.youtube.com/embed/oJMI8dkQd8A?si=3tpotD-PY97n2LqQ',
        title: 'Week 2: Day 2'
    },
    {
        day: 'Week 2 Day 3',
        src: 'https://www.youtube.com/embed/ZqwE9HMGC5s?si=4b0lsHBfYaqaoMAs',
        title: 'Week 2: Day 3'
    },
    {
         day: 'Week 2 Day 4',
         src: 'https://www.youtube.com/embed/BwX3IggufPE?si=PWxoog-6ufkQLwRf',
         title: 'Week 2: Day 4'
     },
     {
         day: 'Week 2 Day 5',
         src: 'https://www.youtube.com/embed/8Crk2hU38ns?si=vQIbIQ0yEU5lu7TP',
         title: 'Week 2: Day 5'
     },
    {
        day: 'Week 3 Day 1',
        src: 'https://www.youtube.com/embed/8zvqJBNIj0o?si=cvVqjAsPOVYAM21C',
        title: 'Week 3: Day 1'
    },
    {
        day: 'Week 3 Day 2',
        src: 'https://www.youtube.com/embed/iJdbJLDcB90?si=l9eB_282Nsj35CcL',
        title: 'Week 3: Day 2'
    },
    {
        day: 'Week 3 Day 3',
        src: 'https://www.youtube.com/embed/FFkMuSeYYBI?si=O8QFhowyo0-0rh5R',
        title: 'Week 3: Day 3'
    },
    {
        day: 'Week 3 Day 4',
        src: 'https://www.youtube.com/embed/dLCu8gh6xqI?si=oopzn3amFUAVoQeV',
        title: 'Week 3: Day 4'
    },
    {
        day: 'Week 3 Day 5',
        src: 'https://www.youtube.com/embed/EFvObVS1Abc?si=k_Rrcrn9dAgFYuOp',
        title: 'Week 3: Day 5'
    },

    {
        day: 'Week 4 Day 1',
        src: 'https://www.youtube.com/embed/2_2TlrlBujA?si=zKO3wpPWlu_5bdqo',
        title: 'Week 4: Day 1'
    },
    {
        day: 'Week 4 Day 2',
        src: 'https://www.youtube.com/embed/e9vNTIbHUuA?si=FqJfWMakPhd21hM1',
        title: 'Week 4: Day 2'
    },
    {
        day: 'Week 4 Day 3',
        src: 'https://www.youtube.com/embed/xnfpUC0-zYU?si=uE_ha1DhLsqKBhed',
        title: 'Week 4: Day 3'
    },
    {
        day: 'Week 4 Day 4',
        src: 'https://www.youtube.com/embed/yV6b2pWB6OQ?si=T6XN8A3JseKdskLc',
        title: 'Week 4: Day 4'
    },
    {
        day: 'Week 4 Day 5',
        src: 'https://www.youtube.com/embed/CP9b64cjxAw?si=BjkyamlFWh_W5C2e',
        title: 'Week 4: Day 5'
    }
];
