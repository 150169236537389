import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Register from './Components/user/Register';
import Login from './Components/user/Login';
import Home from "./Components/Home";
import About from "./Components/About";
import Cart from "./Components/Cart";
import ProductDetailPage from "./Components/ProductDetailPage";
import PrivateRoutes from "./auth/PrivateRoutes";
import UserDashboard from "./Components/user/UserDashboard";
import AdminRoute from "./auth/AdminRoute";
import AdminDashboard from "./Components/user/AdminDashboard";
import GetInTouch from "./Components/GetInTouch";
import PaymentComplete from "./Components/PaymentComplete";
import Schedule from "./Components/Schedule";
import Courses from './Components/Courses';
import Enroll from "./Components/Enroll";
import ForgotPassword from "./Components/ForgotPassword";
import ResetPassword from "./Components/ResetPassword";
import WhatWeDo from "./Components/WhatWeDo";
import ImportantLinks from "./Components/ImportantLinks";
import Donate from "./Components/Donate";
import DonationComplete from "./Components/DonationComplete";
import DishaaMela from "./Components/DishaaMela";
import Checkout from "./Components/Checkout";
import HamperPDP from "./Components/HamperPDP";
import GuestCheckout from "./Components/GuestCheckout";
import BulkOrders from "./Components/BulkOrders";
import AssociateBrands from "./Components/AssociateBrands";
import Journey from "./Components/Journey";
import KnowYourGrandmasters from "./Components/KnowYourGrandmasters";
import HealingModalities from "./Components/HealingModalities";
import HealingModalitiesPDP from "./Components/HealingModalitiesPDP";
import Meditations from "./Components/Meditations";
import DishaaMelaTemp from "./Components/DishaaMelaTemp";
import WorkshopPage from "./Components/WorkshopPage";
import HealingSessionsPDP from "./Components/HealingSessionsPDP";
import InnerChildHealing from "./Components/InnerChildHealing";
import InnerChildHealingProgram from "./Components/InnerChildHealingProgram";
// import { unregister as unregisterServiceWorker } from './registerServiceWorker'

const Routes = () => {

    const check = () => {
        return !!(navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i));
    };

    return(
        <Router>
            <div>
                {/*{unregisterServiceWorker()}*/}
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/home" component={Home}/>
                    <Route path="/about" component={About}/>
                    {/*<Route path="/reiki" component={ReikiPrograms}/>*/}
                    <Route path="/courses" component={Courses}/>
                    <Route path="/contact" component={GetInTouch}/>
                    {/*<Route path='/work' component={Work}/>*/}
                    <Route path="/login"  component={Login}/>
                    <Route path="/register"  component={Register}/>
                    <Route path="/cart"  component={Cart}/>
                    <Route path="/product/:productId"  component={ProductDetailPage}/>
                    <Route path="/payment-complete" component={PaymentComplete}/>
                    {/*<Route path="/schedule" component={Schedule}/>*/}
                    {/*<Route path="/enroll" component={Enroll}/>*/}
                    <PrivateRoutes path="/account" component={UserDashboard}/>
                    <AdminRoute path="/admin/account" component={AdminDashboard}/>
                    <Route path="/forgotpassword" component={ForgotPassword} />
                    <Route path="/password-reset/:token" component={ResetPassword} />
                    <Route path="/work" component={WhatWeDo} />
                    <Route path="/important-links" component={ImportantLinks} />
                    <Route path="/donate" component={Donate} />
                    <Route path="/donation-complete" component={DonationComplete} />
                    {/*<Route path="/shop" component={DishaaMela} />*/}
                    {/*<Route path="/test-shop" component={DishaaMela} />*/}
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/hamper/:hamperId" component={HamperPDP}/>
                    <Route path="/bulk-orders" component={BulkOrders} />
                    <Route path="/associate-brands" component={AssociateBrands} />
                    <Route path="/journey" component={Journey} />
                    <Route path="/know-your-grandmasters" component={KnowYourGrandmasters} />
                    <Route path="/healing-modalities" component={HealingModalities} />
                    <Route path="/healingModalities/:urlName" component={HealingModalitiesPDP}/>
                    <Route path="/healingSessions/:urlName" component={HealingSessionsPDP}/>
                    <Route path="/meditations" component={Meditations} />
                    <Route path="/workshop-page" component={WorkshopPage} />
                    <Route path="/inner-child-healing-webinar" component={InnerChildHealing} />
                    <Route path="/inner-child-healing-program" component={InnerChildHealingProgram} />
                </Switch>
            </div>
        </Router>
    )
};

const style = {
    padding: '24px',
    color: 'black',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Avenir-light, sans-serif'
};

export default Routes;
