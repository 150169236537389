import React from 'react';
import './Meditations.scss';
import Navbar from "./Navbar";
import Footer from "./Footer";

const Meditations = () => {

    return(
        <div className="Meditations">
            <Navbar/>
            <h5 className="med-title">REIKI MEDITATIONS</h5>
            <div className="meditation-videos-container">
                <div>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947507/Users/TreasureBoxGeneral/4ab7b026-e711-46bd-a5e0-ce6ceda7c299_ikp2ck.mov" width="550" height="300" controls/>
                    <p>Food Meditation</p>
                </div>
                <div>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947512/Users/TreasureBoxGeneral/b72a5498-6ab1-4fa2-80eb-8c9b62d5b73c_m6lz4w.mov" width="550" height="300" controls/>
                    <p>Water Meditation</p>
                </div>
                <div>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947514/Users/TreasureBoxGeneral/c64205c0-9f78-4f43-94ec-d3be5d88ab03_yohmog.mov" width="550" height="300" controls/>
                    <p>Sleep Meditation</p>
                </div>
                <div>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947543/Users/TreasureBoxGeneral/05711bf6-6cbf-46ea-853a-2ffbd3ceb1bb_jkcq44.mov" width="550" height="300" controls/>
                    <p>Air Meditation</p>
                </div>
                <div>
                    <video src="https://res.cloudinary.com/dishaa-pratishthaan/video/upload/v1661947549/Users/TreasureBoxGeneral/fcb24791-ec0f-4cf1-96ea-2d206c6ad34d_kftdwj.mov" width="550" height="300" controls/>
                    <p>Universe Meditation</p>
                </div>
            </div>
            <Footer/>
        </div>
    )

};

export default Meditations;
