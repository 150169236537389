import Layout from "../Layout";
import React, {useEffect, useState} from "react";
import {isAuthenticated} from "../../auth";
import {
    getAllReikiLevels,
    getAllUsers,
    setNavratriFlag,
    setReikiLevelForUser,
    setReikiLevelForUserByAdmin
} from "./AdminApi";
import {Link} from "react-router-dom";
import {CSVLink} from "react-csv";
import moment from "moment";

const UpdateUserLevel = () => {

    const [userList, setUserList] = useState([]);
    const [reikiLevel, setReikiLevel] = useState('');
    const [levels, setLevels] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const {user, token} = isAuthenticated(); // user is admin in this case

    const loadReikiLevels = () => {
        getAllReikiLevels(user._id, token)
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                } else {
                    setLevels(data)
                }
            })
    };

    const init = () => {
        getAllUsers().then(data => {
            if(data.error) {
                setError(data.error)
            } else {
                setError(false);
                setUserList(data)
            }
        });
        loadReikiLevels();
    };

    useEffect(() => {
        init();
    }, [reikiLevel]);

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            Successfully updated user's Reiki level.
        </div>
    );

    const renderHeader = () => {
        let headerElement = ['ID',
            // 'User ID',
            'First name', 'Last name', 'Email', 'Call number', 'WhatsApp number', 'Reiki Level', 'Navratri Flag'];

        return headerElement.map((key, index) => {
            return <th key={index}>{key}</th>
        })
    };

    const renderBody = () => {
        return userList && userList.map((u, i) => {
            return (
                <tr key={i} className={u.navratri === 'No' && 'temp-navratri'}>
                    <td>{i+1}</td>
                    {/*<td>{user._id}</td>*/}
                    <td>{u.firstName}</td>
                    <td>{u.lastName}</td>
                    <td>{u.email}</td>
                    <td>{u.callNumber}</td>
                    <td>{u.waNumber}</td>
                    <td className='reiki-level-cta'>
                        <span>{u.level}</span>
                        <select className="" onChange={(e) => handleLevelChange(e, u._id)}>
                            <option>Update level</option>
                            {levels.map((rl, i) => (
                                <option key={i} value={rl}>{rl}</option>
                            ))}
                        </select>
                    </td>
                    <td className=''>
                        <span>
                            {u.navratri}
                        </span>
                        <select className="" onChange={(e) => handleNavratriChange(e, u._id)}>

                            <option>Change</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>

                            {/*<option>Change</option>*/}
                            {/*{levels.map((rl, i) => (*/}
                            {/*    <option key={i} value={rl}>{rl}</option>*/}
                            {/*))}*/}
                        </select>
                    </td>
                </tr>
            )
        })
    };

    const handleLevelChange = (e, userId) => {
        setReikiLevel(e.target.value);
        setReikiLevelForUserByAdmin(user._id, userId, token, e.target.value)
            .then(data => {
                if(data.error) {
                    setError(data.error);
                    setSuccess(false)
                } else {
                    setError('');
                    setSuccess(true)
                }
            });

    };

    const handleNavratriChange = (e, userId) => {
        console.log(e.target.value);

        setNavratriFlag(user._id, userId, token, e.target.value)
            .then(data => {
                if(data.error) {
                    setError(data.error);
                    setSuccess(false)
                } else {
                    setError('');
                    setSuccess(true)
                }
            });
    };

    const userTable = () => (
        <div className="User-Table">
            <table className="user-table">
                <thead>
                    <tr>{renderHeader()}</tr>
                </thead>
                <tbody>
                    {renderBody()}
                </tbody>
            </table>
        </div>
    );

    const removePhoneExtension = (phone) => {
        let finalNumber = "";
        if(phone.toString().length === 12) {
            finalNumber = parseInt(phone.toString().slice(-10))
        } else
            finalNumber = phone;
        return finalNumber;
    };

    const createCsvFileName = ()=> `Users_Report_${moment().format('D.M.YYYY')}.csv`;
    const headers = [
        { label: 'Sr. No.', key: 'srno' },
        { label: 'Name', key: 'name' },
        { label: 'Call Number', key: 'callNumber' },
        { label: 'WhatsApp Number', key: 'waNumber' },
        { label: 'Email', key: 'email' },
        { label: 'Reiki Level', key: 'reikiLevel' },
        { label: 'Date', key: 'createdAt' },
        { label: 'City', key: 'city' },
        { label: 'Shipping Address', key: 'shippingAddress' },
        { label: 'Billing Address', key: 'billingAddress' },
        { label: 'User Id', key: 'user_id' },
    ];

    let data = [];
    userList.forEach((item,i=1) => {
        // console.log(item.shippingAddress && item.shippingAddress.firstName,i)
        data.push({
            srno: i+1,
            user_id: item._id,
            createdAt: moment(item.createdAt).format('Do MMMM YYYY'),
            callNumber: removePhoneExtension(item.callNumber),
            waNumber: removePhoneExtension(item.waNumber),
            email: item.email,
            name: item.firstName + ' ' + item.lastName,
            // payment_id: item.payment_id,
            reikiLevel: item.level,
            city: item.city,
            shippingAddress: item.shippingAddress && item.shippingAddress.firstName + ' ' + item.shippingAddress.lastName + ', ' + item.shippingAddress.flatBuilding + ', ' + item.shippingAddress.streetName + ', ' + item.shippingAddress.landmark + ', ' + item.shippingAddress.city + ', ' +item.shippingAddress.state + ', ' +item.shippingAddress.pincode,
            billingAddress: item.billingAddress && item.billingAddress.firstName + ' ' + item.billingAddress.lastName + ', ' + item.billingAddress.flatBuilding + ', ' + item.billingAddress.streetName + ', ' + item.billingAddress.landmark + ', ' + item.billingAddress.city + ', ' +item.billingAddress.state + ', ' +item.billingAddress.pincode,
        });
    });

    return (
        <div>
            <CSVLink
                data={data}
                headers={headers}
                filename={createCsvFileName()}
                target="_blank">
                <button className="download-button">
                    Download Excel
                </button>
            </CSVLink>
            {userTable()}
        </div>
    );
};

export default UpdateUserLevel
