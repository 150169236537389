import {isAuthenticated} from "../../auth";
import Layout from "../Layout";
import React, {useEffect, useState} from "react";
import {Button, Form} from "semantic-ui-react";
import {createProduct, getCategories} from "./AdminApi";
import {Link} from "react-router-dom";
import DatePicker from "react-multi-date-picker";

const AddProduct = () => {
    const [values, setValues] = useState({
        name: '',
        description: '',
        price: '',
        category: '',
        shipping: '',
        quantity: '',
        photo: '',
        loading: false,
        createdProduct: '',
        redirectToProfile: false,
        formData: '',
        categories: [],
        error: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: ''
    });

    const {
        name,
        description,
        price,
        category,
        shipping,
        quantity,
        loading,
        createdProduct,
        redirectToProfile,
        formData,
        categories,
        error,
        fromDate,
        toDate,
        fromTime,
        toTime
    } = values;

    const {user, token} = isAuthenticated();

    const init = () => {
        getCategories().then(data => {
            if(data.error) {
                setValues({...values, error: data.error})
            } else {
                setValues({...values, categories: data, formData: new FormData()})
            }
        })
    };

    useEffect(() => {
        // setValues({...values, formData: new FormData()})
        init();
    }, []);

  const handleChange = field => event => {
      const value = (field === 'photo') ? event.target.files[0] : event.target.value;
      formData.set(field, value);
      setValues({...values, [field]: value});
  };

  const handleSubmit = e => {
      // code
      e.preventDefault();
      setValues({...values, error: '', loading: true});

      createProduct(user._id, token, formData)
          .then(data => {
              if(data.error) {
                  setValues({...values, error: data.error})
              } else {
                  setValues({...values,
                  name: '', description: '', photo: '', price: '', quantity: '', loading: false, createdProduct: data.name})
              }
          })
  };

  const addProductForm = () => (
      <form onSubmit={handleSubmit} className="create-form">
          <input className="text-input" type='file' name='photo' accept='image/*' onChange={handleChange('photo')}/>
          <input className="text-input" placeholder='Product name' onChange={handleChange('name')} value={name} type="text"/>
          <textarea className="text-input" placeholder='Product description' onChange={handleChange('description')} value={description}/>
          <input placeholder='From'
                 onChange={handleChange('fromDate')}
                 className="text-input"
                 value={fromDate}
                 type="date"/>
          <input placeholder='To'
                 onChange={handleChange('toDate')}
                 className="text-input"
                 value={toDate}
                 type="date"/>
          <input placeholder='time'
                 onChange={handleChange('fromTime')}
                 className="text-input"
                 value={fromTime}
                 type="time"/>
          <input placeholder='time'
                 onChange={handleChange('toTime')}
                 className="text-input"
                 value={toTime}
                 type="time"/>
          <input className="text-input" placeholder='Price' onChange={handleChange('price')} value={price} type="number"/>
          <select onChange={handleChange('category')} className="text-input">
              <option>Please select a category</option>
              { categories && categories.map((cat, index) => (
                  <option key={index} value={cat._id}>
                      {cat.name}
                  </option>
              ))}
          </select>
          <input className="text-input" placeholder='Seats' onChange={handleChange('quantity')} value={quantity} type="number"/>
          <select onChange={handleChange('shipping')} className="text-input">
              <option>Please select</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
          </select>
          <button type='submit' className="button">Create product</button>
      </form>
  );

    const showError = () => (
        <div className="alert alert-danger" style={{ display: error ? '' : 'none' }}>
            {error}
        </div>
    );

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: createdProduct ? '' : 'none' }}>
            Created product!
        </div>
    );

    const showLoading = () => (
        loading && (<h6>Loading...</h6>)
    );

    return(
        <div className="AddCategory__Product">
            {showSuccess()}
            {showError()}
            {showLoading()}
            {addProductForm()}
        </div>
    )
};

export default AddProduct
