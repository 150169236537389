import React, {useEffect, useState} from 'react';
import './DetailedOrder.scss';

const DetailedOrder = ({ match }) => {


    const [orderId, setOrderId] = useState('');


    useEffect(() => {
        setOrderId(match.params.orderId);
    }, []);

    return(
        <div>
            {orderId}
        </div>
    )
};

export default DetailedOrder;
