import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './BulkOrders.scss';
import bulkCollage from '../assets/Revamp/Bulk collage.png';
import {iPadCheck, phoneCheck} from "./helpers/browserCheck";

const BulkOrders = () => {
    return(
        <div className="BulkOrders">
            <Navbar/>

            <div className="bulk-orders-banner-container">
                <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1648299435/Shop/Shop%20Page/jy2getfhmbgoowcrktni.png" width="100%" />

                <div className={phoneCheck() ? "bulk-orders-text-container-mobile" : "bulk-orders-text-container"}>
                    <p>Get a bulk order discount for weddings, festivals, birthday parties, farewells, or other occasions.</p>
                </div>
            </div>
            <div className={phoneCheck() ? "text-below-bulk-banner" : iPadCheck() ? "text-below-bulk-banner-tablet" : "text-below-bulk-banner"}>
                <p>We customise the hamper contents, the gift wrapping, and cards.
                    Get in touch via the form below or drop us a message on WhatsApp and we will get in touch.
                </p>
                <p>Wedding in the family? We would be happy to help you celebrate!
                    From gifts to decorations we offer a range of products and services.
                    <br/>
                    {/*<a href="#">Download our pdf here</a>*/}
                </p>
                {/*<p><a href="#">Download our pdf here</a></p>*/}
            </div>

            <img src={phoneCheck() ? "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1649266776/Shop/Bulk-orders/xvxygn12cwulrmehvxrd.png" : bulkCollage} width="100%" />

            <Footer/>
        </div>
    )
};

export default BulkOrders;
