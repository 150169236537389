import React, {useEffect, useState} from 'react';
import {isAuthenticated} from "../../auth";
import {
    deleteHamperOrder,
    getHamperOrdersForAdmin,
    getStatusValuesForHamperOrder,
    updateHamperOrderStatus,
} from "./AdminApi";
import moment from "moment";
import './HamperOrder.scss';
import { CSVLink } from 'react-csv';


const HamperOrder = () => {

    const [hamperOrders, setHamperOrders] = useState([]);
    const {user, token} = isAuthenticated();
    const [openProducts, setOpenProducts] = useState([]);
    const [statusValues, setSetStatusValues] = useState([]);

    const loadStatusValues = () => {
        getStatusValuesForHamperOrder(user._id, token)
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                } else {
                    setSetStatusValues(data)
                }
            })
    };

    const handleStatusChange = (e, orderId) => {
        updateHamperOrderStatus(user._id, token, orderId, e.target.value)
            .then(data => {
                if(data.error) {
                    console.log('Status update failed',data.error)
                } else {
                    loadHamperOrders()
                }
            })
    };

    const showStatus = o => (
        <div className="">
            {/*<div>Status: {o.status}</div>*/}
            <select className="form-control" onChange={(e) => handleStatusChange(e, o._id)}>
                <option>Update status</option>
                {statusValues.map((s, i) => (
                    <option key={i} value={s}>{s}</option>
                ))}
            </select>
        </div>
    );

    const showOrdersLength = () => {
        if(hamperOrders.length > 0) {
            return (
                <h3>Total orders: {hamperOrders.length}</h3>
            )
        } else {
            return(
                <h4 className="text-danger">Loading orders...</h4>
            )
        }
    };

    const loadHamperOrders = () => {
        getHamperOrdersForAdmin(user._id, token)
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                } else {
                    setHamperOrders(data)
                }
            })
    };

    const toggleProducts = (orderId) => {
        if(openProducts.includes(orderId)) {
            setOpenProducts(openProducts.filter(id => id !== orderId))
        } else {
            let newOpen = [...openProducts];
            newOpen.push(orderId);
            setOpenProducts(newOpen)
        }
    };

    const showProductsInOrder = (p,i) => (
        <div className="product-in-order" key={i}>
            <div>
                <img src={p.images[0].url} width="100vw"/>
            </div>
            <div>
                <div>Name: {p.name}</div>
                <div>Price: {p.price}</div>
                <div>Quantity: {p.count}</div>
                {p.color && <div> Color: {p.color}</div>}
            </div>
        </div>
    );

    const removeOrder = (orderId) => {
        // delete order from db
        deleteHamperOrder(orderId, user._id, token)
            .then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    loadHamperOrders();
                }
            });
    };

    const showOrders = (order, index) => (
        <div key={index} className="orders-list">
            <div className="order-header">
                {/*{showStatus(order)}*/}
                <span>Order ID: <b>{order._id}</b></span>
                <span>Order date: <b>{moment(order.createdAt).format("MMMM Do YYYY")}</b></span>
            </div>

            <div className="order-info">
                <div>
                    <div>Order placed by: {order.user.firstName} {order.user.lastName}</div>
                    <div>Email: {order.user.email}</div>
                    <div>Mobile Number: {order.user.callNumber}</div>
                    <div>WhatsApp Number: {order.user.waNumber}</div>
                    <div>Amount: ₹{order.amount}</div>
                </div>

                <div>
                    <div>This order is <b>{order.status}</b></div>
                    <div>
                        <select className="input-style" onChange={(e) => handleStatusChange(e, order._id)}>
                            <option>Update status:</option>
                            {statusValues.map((s, i) => (
                                <option key={i} value={s}>{s}</option>
                            ))}
                        </select>
                    </div><br/>
                    <div>{order.deliveryMethod === 'Shipping' ? "This order is for SHIPPING. Delivery address mentioned below:" : "This order will be PICKED UP by the customer."}</div>
                    <div>
                        {/*{order.deliveryMethod === 'Shipping' ?*/}
                        <div>
                            <p>&emsp;</p>
                            <div><b>Customer Address:</b></div>
                            <div>{order.deliveryAddress.firstName} {order.deliveryAddress.lastName}</div>
                            <div>{order.deliveryAddress.flatBuilding}</div>
                            <div>{order.deliveryAddress.streetName}</div>
                            <div>{order.deliveryAddress.landmark}</div>
                            <div>{order.deliveryAddress.city} - {order.deliveryAddress.pincode}</div>
                            <div>{order.deliveryAddress.state}, India</div>
                        </div>
                        {/*:*/}
                        <div>
                            <br/>
                            <div><b>Billing Address:</b></div>

                            <div>{order.user.firstName} {order.user.lastName}</div>
                            {order.billingAddress ? <div>
                                <div>{order.billingAddress.flatBuilding}</div>
                                <div>{order.billingAddress.streetName}</div>
                                <div>{order.billingAddress.landmark}</div>
                                <div>{order.billingAddress.city} - {order.billingAddress.pincode}</div>
                                <div>{order.billingAddress.state}, India</div>
                            </div> : <div>Shipping and billing Address not provided! :(</div>}
                        </div>
                    {/*}*/}
                    </div>
                </div>

            </div>

            <div className="products-list">
                <div className="order-header">
                <span>Total products in the order:
                    {/*{order.products.length}*/}
                </span>
                    <span onClick={() => toggleProducts(order._id)} className="down-chevron">
                    {openProducts.includes(order._id) ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>}
                </span>
                </div>

                {openProducts.includes(order._id) &&
                order.hampers.map((p,i) => (
                    showProductsInOrder(p,i)
                ))
                }
            </div>
            <span className="delete-order">
                <button className="button" onClick={() => removeOrder(order._id)}>Delete this order</button>
            </span>
        </div>
    );

    useEffect(() => {
        loadHamperOrders();
        loadStatusValues();
    }, []);


    const removePhoneExtension = (phone) => {
        let finalNumber = "";
        if(phone.toString().length === 12) {
            finalNumber = parseInt(phone.toString().slice(-10))
        } else
            finalNumber = phone;
        return finalNumber;
    };

    // const csvData = [
    //     {
    //         _id: 'something',
    //         name: 'Data science training',
    //         // description: 'Data Science certification training',
    //         billingAddress: {
    //             flatBuilding: 'blhel',
    //             streetName: 'jdisajf'
    //         },
    //         deliveryAddress: {
    //             flatBuilding: 'fndkslag',
    //             streetName: '98654'
    //         },
    //         deliveryMethod: 'Shipping',
    //         hampers: [
    //             {count: 1,
    //                 createdAt: "2021-10-28T09:06:12.935Z",
    //                 name: "Hamper 13",
    //                 price: 550,
    //                 updatedAt: "2021-10-28T09:06:12.935Z",
    //                 _id: "617a680461751e002502c796"},
    //             {count: 2,
    //                 createdAt: "2022-01-15T06:46:18.731Z",
    //                 name: "Hamper 35",
    //                 price: 560,
    //                 updatedAt: "2022-01-15T06:46:18.731Z",
    //                 _id: "61e26dba862d8e00257c444f"}],
    //         user: {
    //             firstName: 'ella',
    //             lastName: 'joe',
    //             callNumber: 918888888888,
    //             email: '22edvc@rrrrr.com'
    //         },
    //         payment_id: 'qwertyuu',
    //         status: 'zxcvbbnmm',
    //         createdAt: "2022-04-13T14:39:34.541Z"
    //     },
    //     {
    //         _id: 'something2',
    //         name: 'AWS',
    //         // description:
    //         //     'AWS certification training',
    //         billingAddress: {
    //             flatBuilding: 'eeeee',
    //             streetName: 'fffff'
    //         },
    //         deliveryAddress: {
    //             flatBuilding: 'bnmmii',
    //             streetName: '11111'
    //         },
    //         deliveryMethod: 'Pickup',
    //         hampers: [
    //             { id: 16, category: 'DEVELOPER', name: 'Cloud Engineer' },
    //             { id: 17, category: 'DEVELOPER', name: 'Network Engineer' }],
    //         user: {
    //             firstName: 'john',
    //             lastName: 'doe',
    //             callNumber: 919999999999,
    //             email: 'fkdsnf@fsufs.com'
    //         },
    //         payment_id: 'fndjsgnja',
    //         status: 'olmnjki',
    //         createdAt: "2022-01-05T08:33:49.298Z"
    //
    //     }];


    const createCsvFileName = ()=> `Hamper_Orders_Report_${moment().format('D.M.YYYY')}.csv`;
    const headers = [
        { label: 'Date', key: 'createdAt' },
        { label: 'Client Name', key: 'user' },
        { label: 'Hamper Name', key: 'hampers' },
        { label: 'Quantity', key: 'count' },
        { label: 'Rate', key: 'price' },
        { label: 'Total hamper price', key: 'totalHamperPrice' },
        { label: 'Subtotal', key: 'subtotal' },
        { label: 'Delivery Method', key: 'deliveryMethod' },
        { label: 'Shipping Fee', key: 'shippingFee' },
        { label: 'Total Amount', key: 'amount' },
        { label: 'Order Status', key: 'status' },
        { label: 'Contact Number', key: 'callNumber' },
        { label: 'Email', key: 'email' },
        { label: 'Billing Address', key: 'billingAddress' },
        { label: 'Delivery Address', key: 'deliveryAddress' },
        { label: 'Payment Id', key: 'payment_id' },
        { label: 'Order id', key: '_id' },
    ];

    let data = [];
    hamperOrders.forEach(item => {
        data.push({
            _id: item._id,
            createdAt: moment(item.createdAt).format('Do MMMM YYYY'),
            hampers: item.hampers[0].name,
            count: item.hampers[0].count,
            price: item.hampers[0].price,
            billingAddress: item.billingAddress.firstName + ' ' + item.billingAddress.lastName + ', ' + item.billingAddress.flatBuilding + ', ' + item.billingAddress.streetName + ', ' + item.billingAddress.landmark + ', ' + item.billingAddress.city + ', ' +item.billingAddress.state + ', ' +item.billingAddress.pincode,
            deliveryAddress: item.deliveryAddress.firstName + ' ' + item.deliveryAddress.lastName + ', ' + item.deliveryAddress.flatBuilding + ', ' + item.deliveryAddress.streetName + ', ' + item.deliveryAddress.landmark + ', ' + item.deliveryAddress.city + ', ' +item.deliveryAddress.state + ', ' +item.deliveryAddress.pincode,
            deliveryMethod: item.deliveryMethod,
            user: item.user.firstName + ' ' + item.user.lastName,
            callNumber: removePhoneExtension(item.user.callNumber),
            email: item.user.email,
            shippingFee: item.shippingFee,
            status: item.status,
            payment_id: item.payment_id,
            amount: item.amount,
            totalHamperPrice: item.hampers[0].price * item.hampers[0].count,
            subtotal: item.amount - item.shippingFee

        });
        for (let i = 1; i < item.hampers.length; i++) {
            const hamper = item.hampers[i];
            data.push({
                _id: '',
                createdAt: '',
                hampers: hamper.name,
                count: hamper.count,
                price: hamper.price,
                billingAddress: '',
                deliveryAddress: '',
                deliveryMethod: '',
                user: '',
                callNumber: '',
                email: '',
                shippingFee: '',
                status: '',
                payment_id: '',
                amount: '',
                subtotal: '',
                totalHamperPrice: hamper.price * hamper.count
            });
        }
    });

    return(
        <div className="HamperOrdersList">

            {console.log(hamperOrders)}

            <CSVLink
                data={data}
                headers={headers}
                filename={createCsvFileName()}
                target="_blank">
                <button className="download-button">
                    Download Excel
                </button>
            </CSVLink>

            {showOrdersLength()}
            {hamperOrders && hamperOrders.map((hOrder, index) => (
                showOrders(hOrder, index)
            ))}

        </div>
    )
};

export default HamperOrder;
