import React, {useState} from 'react';
import axios from 'axios';
import {isAuthenticated} from "../../auth";
import Resizer from "react-image-file-resizer";
import {API} from "../../config";

const FileUpload = ({ values, setValues, setLoading }) => {

    const [previewSource, setPreviewSource] = useState('');
    const { user, token } = isAuthenticated();
    // let allUploadedImages = values.images;

    const fileUploadAndResize = (e) => {
        // console.log(e.target.files);
        // resize
        let files = e.target.files; // 3
        let allUploadedFiles = values.images;

        if (files) {
            setLoading(true);
            for (let i = 0; i < files.length; i++) {
                Resizer.imageFileResizer(
                    files[i],
                    700,
                    700,
                    "JPEG",
                    100,
                    0,
                    (uri) => {
                        // console.log(uri);
                        // console.log(user ? token : '')
                        axios
                            .post(
                                `${API}/upload-images/${user._id}`,
                                { image: uri },
                                {
                                    headers: {
                                        // authtoken: user ? token : '',
                                        Authorization: 'Bearer ' + token
                                    },
                                }
                            )
                        // return fetch(`${API}/upload-images`, {
                        //     method: 'POST',
                        //     headers: {
                        //         Accept: 'application/json',
                        //         "Content-Type": 'application/json',
                        //         Authorization: `Bearer ${token}`
                        //     },
                        //     body: JSON.stringify(uri)
                        // })
                    .then((res) => {
                                // console.log("IMAGE UPLOAD RES DATA", res);
                                setLoading(false);
                                allUploadedFiles.push(res.data);

                                setValues({ ...values, images: allUploadedFiles });
                            })
                            .catch((err) => {
                                setLoading(false);
                                console.log("CLOUDINARY UPLOAD ERR", err);
                            });
                    },
                    "base64"
                );
            }
        }
        // send back to server to upload to cloudinary
        // set url to images[] in the parent component state - ProductCreate
    };

    // const handleFileUploadOnChange = (e) => {
    //     const images = e.target.files;
    //
    //     if(images) {
    //         setLoading(true);
    //         for(let i =0; i< images.length; i++) {
    //             previewFile(images[i])
    //         }
    //     }
    //
    // };
    //
    // const previewFile = (file) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onloadend = () => {
    //         // console.log(reader.result)
    //         setPreviewSource(reader.result);
    //         axios.post(`${process.env.REACT_APP_API_URL}/upload-images`, { image: reader.result }, {
    //             headers: {
    //                 authtoken: user ? token : ''
    //             }
    //         })
    //             .then(res => {
    //                 console.log('IMAGE UPLOAD DATA', res);
    //                 setLoading(false);
    //                 allUploadedImages.push(res.data);
    //                 setValues({ ...values, images: allUploadedImages})
    //             })
    //             .catch(err => {
    //                 console.log('CLOUDINARY ERROR:', err);
    //                 setLoading(false);
    //             })
    //     };
    // };

    const handleImageRemove = (public_id) => {
        setLoading(true);
        // console.log("remove image", public_id);
        axios
            .post(
                `${API}/remove-image/${user._id}`,
                { public_id },
                {
                    headers: {
                        Authorization: 'Bearer ' + token
                    },
                }
            )
            .then((res) => {
                setLoading(false);
                const { images } = values;
                let filteredImages = images.filter((item) => {
                    return item.public_id !== public_id;
                });
                setValues({ ...values, images: filteredImages });
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    return(
        <div>
            <div>
                <label style={{ backgroundColor: '#fabaa6', color: 'black', padding: '8px', cursor: 'pointer', border: '1px solid black', borderRadius: '8px' }}>
                    Upload images
                    <input type='file' multiple hidden accept='images/*' onChange={fileUploadAndResize}/>
                </label>
            </div>
            <br/>
            {values.images && values.images.map((img) => (
                <span className="remove-badge" key={img.public_id}>
                    <img src={img.url} width="100px"/>
                    <sup className="" style={{ backgroundColor: 'red', color: '#fff',
                        borderRadius: '50px',
                        padding: '2px 4px',
                        top: '-2.5em',
                        right: '1em', cursor: 'pointer'}}
                         onClick={() => handleImageRemove(img.public_id)}> X </sup>
                </span>
            ))}
        </div>
    )
};

export default FileUpload;
