import React from 'react';
import { isAuthenticated } from "../../auth";
import { Link, useHistory } from 'react-router-dom';
import Navbar from "../Navbar";
import PrivateRoutes from "../../auth/PrivateRoutes";
import Profile from "./Profile";
import AddCategory from "../admin/AddCategory";
import AdminRoute from "../../auth/AdminRoute";
import AddProduct from "../admin/AddProduct";
import OrdersList from "../admin/OrdersList";
import ManageProducts from "../admin/ManageProducts";
import './AdminDashboard.scss';
import UpdateUserLevel from "../admin/UpdateUserLevel";
import Footer from "../Footer";
import CreateHamper from "../admin/CreateHamper";
import HamperOrder from "../admin/HamperOrder";
import Donations from "../admin/Donations";

const AdminDashboard = () => {

    const { user: {firstName, lastName, email, role} } = isAuthenticated();
    const browser_history = useHistory();

    const adminInfo =() => (
        <div className="card mb-5">
            <h3 className="card-header">Your info:</h3>
            <ul className="list-group">
                <li className="list-group-item">{firstName} {lastName}</li>
                <li className="list-group-item">{email}</li>
                <li className="list-group-item">{role === 1 ? 'Admin' : 'Customer'}</li>
            </ul>
        </div>
    );

    const isActive = (history, path) => {
        if(history.location.pathname === path) {
            return { color: '#9a0000', fontWeight: 'bold' }
        } else {
            return {  color: '#000' }
        }
    };

    const crudList = () => (
        <div className="settings-list">
            <h4 className="">Your account</h4>
            <ul className="user-links">
                <Link to="/admin/account/create/category" className="" style={isActive(browser_history, `/admin/account/create/category`)}><li className="">Create category</li></Link>
                <Link to="/admin/account/create/product" className="" style={isActive(browser_history, `/admin/account/create/product`)}><li className="">Create product</li></Link>
                <Link to="/admin/account/orders" className="" style={isActive(browser_history,'/admin/account/orders')}><li className="">View all orders</li></Link>
                <Link to="/admin/account/products" className="" style={isActive(browser_history,'/admin/account/products')}><li className="">Update product</li></Link>
                <Link to="/admin/account/reiki/level" className="" style={isActive(browser_history,'/admin/account/reiki/level')}><li className="">Update user reiki level</li></Link>
                <Link to="/admin/account/create/hamper" className="" style={isActive(browser_history,'/admin/account/create/hamper')}><li className="">Create Hamper</li></Link>
                <Link to="/admin/account/hamperOrders" className="" style={isActive(browser_history,'/admin/account/hamperOrders')}><li className="">View All Hamper Orders</li></Link>
                <Link to="/admin/account/donations" className="" style={isActive(browser_history,'/admin/account/donations')}><li className="">View All Donations</li></Link>
                {/*    <Link to="/admin/account/create/treasure-item" className="" style={isActive(browser_history,'/admin/account/create/treasure-item')}><li className="">Create treasure item</li></Link>*/}
            </ul>
        </div>
    );

    return(
            <div className="AdminDashboard">
                <Navbar/>
                <div className="settings-container">
                    {crudList()}
                    <div className="settings">
                        <PrivateRoutes path="/account/profile/:userId" component={Profile}/>
                        <AdminRoute path="/admin/account/create/category" component={AddCategory}/>
                        <AdminRoute path="/admin/account/create/product" component={AddProduct}/>
                        <AdminRoute path="/admin/account/orders" component={OrdersList}/>
                        <AdminRoute path="/admin/account/products" component={ManageProducts}/>
                        <AdminRoute path="/admin/account/reiki/level" component={UpdateUserLevel}/>
                        <AdminRoute path="/admin/account/create/hamper" component={CreateHamper}/>
                        <AdminRoute path="/admin/account/hamperOrders" component={HamperOrder}/>
                        <AdminRoute path="/admin/account/donations" component={Donations}/>
                        {/*<AdminRoute path="/admin/account/create/treasure-item" component={AddTreasureItem}/>*/}
                        {browser_history.location.pathname === '/admin/account' && adminInfo()}
                    </div>
                </div>
                <Footer/>
        </div>
    )
};

export default AdminDashboard;
