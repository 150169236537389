import React from 'react';
import Navbar from "./Navbar";
import {Link} from "react-router-dom";
import './PaymentComplete.scss';
import Footer from "./Footer";

const PaymentComplete = () => {

    const paymentSuccess = () => (
        <div className="success">
            <h2>Thank you for shopping with us</h2>
            <p>Your order details will be sent to the email address provided.<br/>
                Once your order is shipped, we’ll send the tracking info to the same email address.
            </p>
                <Link to="/shop">Go to Shop</Link>
        </div>
    );


    return(
        <div className="PaymentComplete">
            <Navbar/>
            <div className="success-container">

                {paymentSuccess()}
            </div>
            <Footer/>
        </div>
    )
};

export default PaymentComplete
