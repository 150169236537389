import {API} from "../../config";

export const read = (userId, token) => {
    return fetch(`${API}/user/${userId}`, {
        method: "GET",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const update = (userId, token, userData) => {
    return fetch(`${API}/user/${userId}/shipping-address`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(userData)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const updateUserInLocalStorage = (user, next) => {
    if(typeof window !== 'undefined') {
        if(localStorage.getItem('jwt')) {
            let auth = JSON.parse(localStorage.getItem('jwt'));
            auth.user = user;
            localStorage.setItem('jwt', JSON.stringify(auth));
            next();
        }
    }
};

export const getPurchaseHistoryForUser = (userId, token) => {
    return fetch(`${API}/orders/by/user/${userId}`, {
        method: "GET",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

// export const findUserById = (userId, token) => {
//     return fetch(`${API}/user/${userId}`, {
//         method: "GET",
//         headers: {
//             Accept: 'application/json',
//             "Content-Type": 'application/json',
//             Authorization: `Bearer ${token}`
//         }
//     })
//         .then(response => {
//             return response.json()
//         })
//         .catch(err => {
//             console.log(err)
//         });
// }

export const saveShippingAddress = (userId, token, shippingAddress) => {
    return fetch(`${API}/user/${userId}/shipping-address`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(shippingAddress)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const saveBillingAddress = (userId, token, billingAddress) => {
    return fetch(`${API}/user/${userId}/billing-address`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({billingAddress: billingAddress})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};

export const saveMultipleShippingAddress = (userId, token, multipleShippingAddress) => {
    return fetch(`${API}/user/${userId}/multiple-shipping-address`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({multipleShippingAddress: multipleShippingAddress})
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err)
        });
};
