import React from'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './DonationComplete.scss';

const DonationComplete = () => {

    const donationSuccess = () => (
        <div className="success">
            <h2>Thank you for your donation!</h2>
            <h5>We will send you the receipt soon.</h5>
        </div>
    );

    return(
        <div className="DonationComplete">
            <Navbar/>
            <div className="success-container">
                {donationSuccess()}
            </div>
            <Footer/>
        </div>
    )
};

export default DonationComplete;
