import React from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {Link, useHistory} from "react-router-dom";
import './HealingModalities.scss';

const HealingModalities = () => {

        const browser_history = useHistory();


        const handleOnClick = (hmId) => {
                // {info.categories.filter(c =>
                //     c.catId === catId
                // ).map(filteredC => {
                //             setProductsForSelectedCategory(filteredC.courses)
                //     }
                // )}
                // setProducts([]);

                console.log(hmId);
        };

        const isActive = (history, path) => {
                if(history.location.pathname === path) {
                        return { color: '#9a0000' }
                } else {
                        return {  color: '#000' }
                }
        };

        return (
            <div className="HealingModalities">
                <Navbar/>
                <div className="healing-modalities-title">REIKI COURSES</div>
                <div className="healing-modalities-container">
                {healingModalities.map((hm, i) => (
                    <Link
                        to={`/healingModalities/${hm.urlName}`}
                        onClick={() => handleOnClick(hm.id)}
                        key={i}
                        className="healing-modalities-block"
                        style={isActive(browser_history, `/healingModalities/${hm.urlName}`)}>
                            <img src={hm.imageUrl} />
                            <div className="title">{hm.name}</div>
                    </Link>
                ))}
                </div>
                <br/><br/>
                <hr/>
                <br/><br/>

                    <div className="healing-modalities-title">ONGOING HEALING SESSIONS</div>
                    <div className="healing-modalities-container">
                            {healingSessions.map((hm, i) => (
                                <Link
                                    to={`/healingSessions/${hm.urlName}`}
                                    onClick={() => handleOnClick(hm.id)}
                                    key={i}
                                    className="healing-modalities-block"
                                    style={isActive(browser_history, `/healingSessions/${hm.urlName}`)}>
                                        <img src={hm.imageUrl} />
                                        <div className="title">{hm.name}</div>
                                        { console.log('hm',hm.urlName)}
                                </Link>
                            ))}
                    </div>
                <br/><br/>

                <Footer/>
        </div>)
};

export default HealingModalities;

export const galleryImages = [
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656616/u3b6dvycqfscq9pbmwz0.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656616/u3b6dvycqfscq9pbmwz0.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656616/voz5bwrjsbhxhsl2715s.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656616/voz5bwrjsbhxhsl2715s.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705970978/iaaxcqaxjn8fxyeat1ed.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656622/qhvhstzfcmkdgxcfpjpi.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656614/i1zqtxezllzq5c9tfdfv.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656614/i1zqtxezllzq5c9tfdfv.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705970977/ypwrfc9kh1qdtjbzt0oc.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705970977/ypwrfc9kh1qdtjbzt0oc.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656615/mckemeci8tjyovso25pb.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656615/mckemeci8tjyovso25pb.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656615/wvzolmj9jts1lsqryykw.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656615/wvzolmj9jts1lsqryykw.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656614/gvu9pd1wxppuxupu4rrh.jpg",
                thumbnail: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705656614/gvu9pd1wxppuxupu4rrh.jpg",
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705970977/lzmxv1kuynrf9b628izw.jpg"
        },

        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705972073/dzuvxrzxghcvd0lwdty5.jpg"
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705970977/a9ixti5mforx2am4vdqv.jpg"
        },
        {
                original: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705970977/omb4d8wjvnumbupygzm3.jpg"
        }


];

export const healingSessions = [
        // {
        //         id: 1,
        //         name: 'New Beginnings 2024',
        //         fees: 2051,
        //         registerLink: 'https://rzp.io/l/new-beginnings-2024',
        //         imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1703587061/i5p2pqkrjgn412nvt705.jpg',
        //         urlName: 'NewBeginnings2024',
        //         whatWillYouLearn: ['New Beginnings 2024','A freeing program designed to let go of the past year with grace and enter the new one with renewed energy.'],
        //         courseSchedule: [
        //                 "Grounding: a self-soothing technique to use when you are having a bad day or experiencing a lot of stress, overwhelming feelings, and/or anxiety. It helps to keep you in the present.",
        //                 "Advanced Ho'oponopono: It teaches us the importance of love and forgiveness in order to heal.",
        //                 "Emotional freedom technique (EFT) is an alternative treatment for physical pain and emotional distress. Tapping the body can create a balance in your energy system and treat pain.",
        //             "Chakra balancing with chakra dhyana: This releases blocked energies to create harmony in these areas to nurture physical and emotional well-being. ",
        //             "Crystal therapy: Crystals have specific healing properties that benefit your mind, body, and soul.",
        //             "Cord cutting: When cutting the cord, you energetically release a person, place, object, belief, or past life, setting the intention that you no longer wish to be negatively affected by it.",
        //             "Reiki: This ancient healing method is what is called an energy healing because it draws upon energy, which is also called universal life energy.",
        //
        //         ],
        //         videos: [
        //
        //         ]
        // },
        {
                id: 1,
                name: 'Rainbow of Gratitude',
                fees: 3000,
                registerLinkEng: 'https://rzp.io/l/rainbowofgratitude-eng',
                registerLinkHin: 'https://rzp.io/l/rainbowofgratitude-hin',
                registerLinkMar: 'https://rzp.io/l/rainbowofgratitude-mar',
                registerLinkGuj: 'https://rzp.io/l/rainbowofgratitude-guj',
                imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1705645558/u0cbalqfhj3thxn3svsf.png',
                urlName: 'Gratitude2024',
                whatWillYouLearn: [
                    'A month-long gratitude program meant to improve your outlook towards situations and improve your quality of life. Over the period of 30 days, you will experience how powerful it is to practise gratitude in your life.',
                    'Being intentionally grateful can change your mental well-being, which ultimately affects all areas of your life. Watch as adding more gratitude into your life brings powerful changes to your health, relationships, finance and career.'
                ],
                courseSchedule: [
                        'Live sessions with Lakshmi at the beginning and the end of the program',
                        'Goal setting to motivate you to reach a goal',
                        'Everyday gratitude activity provided over WhatsApp: colouring, mindfulness, and other creative activities',
                        'Meditations that can be accessed any time',
                        'PDF of activity for the month',
                        'QnA with course coordinators'
                ],
                advantages: [
                    'You can do the class at your own pace', 'Get back in touch with your inner child', 'Enhance your creativity', 'Reprogram your negative thoughts to positive, grateful ones',
                    'Actively find gratitude in your life', 'Bring more awareness and alertness into your life', 'Be more aware of your attitude towards life',
                    'Learn to live in the attitude of gratitude 24x7', 'Tune yourself to gratitude rather than criticism', 'Learn new ways to practise gratitude'
                ],
                FAQs: [
                        {
                                question: "Will the live sessions be available to watch afterwards?",
                                answer: "Yes, we will provide the link if you were unable to attend."
                        },
                        {
                                question: "At what pace can I do the program?",
                                answer: "You can do the program daily with us. Or, if you are unable to attend, the live recordings will be available for a week after and you can complete the course at your pace."
                        },
                        {
                                question: "Who is this course for?",
                                answer: "Teenagers and above can do this course."
                        }
                ],
        }
];



export const healingModalities = [
        {
                id: 1,
                name: 'REIKI 1',
                fees: 1500,
                registerLink: 'https://rzp.io/l/Reiki1',
                urlName: 'ReikiLevel1',
                imageUrl: "https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654768823/HealingAndWellness/HealingModalities/woqjkzxvad6zx5anlnzh.png",
                whatWillYouLearn: [
                        "When you choose to receive attunement in Reiki Level 1, you learn to use your hands to heal yourself using the Divine Light. Over the 21 days after your attunement, you learn how peaceful your life can be. With Reiki, you learn how to go through life with gratitude and how to improve your health. Before you know it, you are introduced to a new energised Self which is able to do much more than it used to. Though life is good, it can be better.",
                    "After the class, you will be part of a WhatsApp group for 21 days, where your instructors and peers are available for QnA and discussions."
                ],
                whatCanYouDo: [
                        "You will be able to heal yourself using your hands. While you can heal others too, we recommend focussing just on yourself for the 21 days healing.",
                        "You can cleanse your home, office, car, any environment that you frequent.",
                        "Charge various things with Reiki such as: objects at home, food and drinks, animals, money in your wallet, almost any perceivable object."
                ],
                language: "English / Hindi",
                mindsetChanges: [
                        "I am an instrument",
                        "The receiver draws the energy.",
                        "Reiki is universal love and wisdom. It knows where and how to work.",
                        "We need to give it without attachment and expectations.",
                        "Let the best happen."
                ],
                materialsProvided: [
                        "A PDF of the 5 principles, healing hand positions with affirmations, information about reiki",
                        "Reiki music with a 3-min bell to change position"
                ],
                courseSchedule: [
                        "3 different pranayama/breathing techniques to calm you down. These can be used if you're feeling anxious or unsettled in the future.",
                        "Attunement to open your chakras and let the energy flow through you.",
                        "What is Reiki?",
                        "The 5 principles, their deeper meaning and the application in your life.",
                        "Feel the energy channeled through you.",
                        "3-4 calming meditations.",
                        "Full body positions to heal yourself.",
                        "Interaction and Q&A sessions with the instructors.",
                        "Reiki Practice and Applications course begins the next day."
                ],
                whoIsThisCourseFor: [
                        "If you’re looking for healing, therapeutic practise.",
                        "If you’re looking for answers on your spiritual journey. ",
                        "If you have been attuned to Reiki but haven’t practised and you’re looking to get reacquainted with it.",
                        "Anybody!"
                ],
                FAQs: [
                        {
                                question: "When and where can we do Reiki? Are there any rituals involved? Is it a religion? ",
                                answer: "Reiki is healing energy that you channel into your body. You can practise it anytime and anywhere, under any circumstances. It does not clash or take away from your existing religious practises."
                        },
                        {
                                question: "Will my physical pain go away if I practise Reiki?",
                                answer: "Reiki shows us that our physical pain is a manifestation of our thought patterns, it shows us how to change it to bring us relief."
                        },
                        {
                                question: "Can I do it if I'm under medication?",
                                answer: "Yes, Reiki can be practised under any circumstances. It does not affect an ongoing treatment negatively. It helps you get better."
                        },
                        {
                                question: "Are there any side effects of practising Reiki?",
                                answer: "No. There is nothing negative that comes out of practising Reiki."
                        }
                ],
        },
        {
                id: 2,
                name: 'REIKI 2',
                fees: 3500,
                registerLink: 'https://rzp.io/l/DPR2',
                urlName: 'ReikiLevel2',
                imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654768823/HealingAndWellness/HealingModalities/fp7r9ptrogazarwy4fij.png',
                whatWillYouLearn: [
                        "You learn that you are important, focus on yourself, recreate yourself. In Level 1 you learn that though life is good, it can be better. In Level 2: Though you are good, you can be better.",
                        "After you complete 21 days of your Reiki Level 1 practise, you are now eligible to receive the Level 2 attunement. At this level, you are open to higher frequencies and go deeper within your self-discovery. After your Level 2 attunement you will find that the affirmations hold a completely different meaning for you.",
                        "You learn the three symbols of Reiki for Power, Harmony & Distance Healing. These help you focus the Reiki energy. At Level 2, you also learn the power of intentions and how to send healing over a distance. Your power grows and you will now be able to achieve your goals and intentions with ease.",
                        "In the course, you also learn about the seven chakras, their imbalances, their effects on the body and how to balance them. An in-depth chakra study however, is a separate course."
                ],

                whatCanYouDo: [
                        "You will be able to heal yourself and others using your hands and symbols.",
                        "You can send the symbols to cleanse your home, office, car, any environment, situations",
                        "You will be able to heal a person who is not present.",
                        "Charge various things with the Reiki symbols such as: objects at home, food and drinks, animals, money in your wallet, almost any perceivable object.",
                        "You will be able to heal your past and future.",
                        "You will be able to balance your chakras."
                ],
                language: "English / Hindi",
                mindsetChanges: [
                        "I am important",
                        "I am enough",
                        "I can choose to be peaceful.",
                        "My past is over and done with. I choose to be a new person from here on.",
                        "I can achieve anything I set my mind to."
                ],
                materialsProvided: [
                        "Healing hand positions with affirmations",
                        "Information about flow of Reiki energy",
                        "Each symbol’s meaning, intention, purpose and how to apply it.",
                        "Time healing meditation",
                        "Absentee healing meditation",
                        "Home cleaning meditation",
                        "Forgiveness meditation",
                        "Intention box",
                        "Each chakra position, relation to internal organ, issues that come with its imbalance.",
                        "Positive affirmations"
                ],
                courseSchedule: [
                        "3 different pranayama/breathing techniques to calm you down. These can be used if you're feeling anxious or unsettled in the future.",
                        "24 body positions and attunement to the level 2.",
                        "Further understanding of Life Force Energy and why the intentions are not fulfilled.",
                        "You will be attuned to 3 powerful symbols. Learn how to draw and apply them.",
                        "5 principle meditation with the symbols.",
                        "Absentee or Distance healing, where you can send healing energy to your loved ones who are not present or are far away.",
                        "Healing Time: how to send Reiki to the past and future",
                        "House cleaning meditation",
                        "Forgiveness meditation",
                        "Scooping meditation",
                        "What are intentions and how to apply them to your life",
                        "How to apply intentions with respect to people around you",
                        "Using an intention box",
                        "Meditation for childhood to adult healing",
                        "Interaction and Q&A sessions with the instructors"
                ],
                whoIsThisCourseFor: [
                        "Anyone who has been attuned to Reiki Level 1 and completed 21 days of healing (from any teacher/institute)",
                        "Anyone looking to refresh your Level 2 learning"
                ],
                FAQs: [
                        {
                                question: "Am I ready for the next level? How do I know if I’m ready for the next level? Do I need to do Level 2?",
                                answer: "There are issues you overcome, and some you don’t. When you realise you’re struggling with issues and there is no progress, you need that much guidance (from the light, from yourself) , power, to push through and go ahead."
                        },
                        {
                                question: "How will I feel after Level 2?",
                                answer: "You will feel more energetic, confident, blissful and elated. You’re on the cusp of flying high, business class."
                        },
                        {
                                question: "Will I be able to understand the next level?",
                                answer: "Our instructors have been practising for the last two decades and will guide you perfectly."
                        },
                        {
                                question: "Are there any dietary restrictions before the class?",
                                answer: "No, please continue with your regular diet before the class, don’t make any sudden changes."
                        }
                ]
        },
        {
                id: 3,
                name: 'REIKI 3A',
                fees: 1000,
                registerLink: '',
                urlName: 'ReikiLevel3A',
                imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654768823/HealingAndWellness/HealingModalities/la9tjonytdgjdkftgt3r.png',
                whatWillYouLearn: [
                    "Congratulations and warm welcome to the Advanced Reiki Therapy (ART), REIKI 3A.",
                    "It is with much Love and Light that we congratulate you for stepping forward, on the Path of traditional Usui Reiki.",
                    "As a Reiki Master Healer, you will now be able to use the Universal Life Force to deepen your knowledge on the Spiritual Path. You will now be able to heal yourself and others at a deeper level.",
                    "With Level -1 and Level - 2 itself you must have experienced many gratifying experiences. As you use the beautiful majestic Reiki Master symbol , you will now open up doors to much deeper magical opportunities, changes and experiences.",
                    "We truly hope these new teachings bring more love, joy and abundance in your life."
                ],
                whatCanYouDo: [],
                language: "English / Hindi",
                mindsetChanges: [],
                materialsProvided: [],
                courseSchedule: [],
                whoIsThisCourseFor: [],
                FAQs: []

        },
        {
                id: 4,
                name: 'KARUNA REIKI',
                fees: 500,
                registerLink: '',
                urlName: 'ReikiLevelKaruna',
                imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654768823/HealingAndWellness/HealingModalities/fzvmx9wzvjyst8m9lxiz.png',
                whatWillYouLearn: [
                    "The name Karuna means kindness and compassion.",
                    "It is a non-traditional form of Reiki with which you bring kindness and compassion into your being. It is related to unconditional love and spiritual evolution, starting with yourself and radiating outwards to others.",
                    "To make things more clear, as it helps you to heal, you want to help others too because we are all interconnected. With the eight new symbols that you will learn as part of Karuna Reiki, you will find your spiritual power increasing and you will find your life improving tenfold."
                ],
                whatCanYouDo: [
                        "Release traumatic events from the past",
                        "Amplify distance healing",
                        "Restores balance physically, mentally, emotionally, and spiritually and brings about deep healing",
                        "Develop self-love",
                        "Heal broken relationships",
                        "Bring peace into your life by healing resentment, disagreements and much more",
                        "Release insecurities",
                        "Release addictions",
                        "Release feelings of anxiety",
                        "Get out of abusive situations",
                        "Take strong decisions",
                        "Take better financial decisions",
                        "Reach a higher plane of spirituality",
                        "Master your thought processes",
                        "Increases self-confidence",
                        "Helps spiritual progress in a practical way",
                        "A revision of everything you have learnt in Level 1 & 2"
                ],
                language: "English / Hindi",
                mindsetChanges: [
                        "I trust more.",
                            "I am positively empowered and successful in all that I do.",
                            "I feel complete.",
                            "I am balanced.",
                            "I release old limitations and create a new me.",
                        "I step ahead in complete faith."

                ],
                materialsProvided: [
                        "Karuna Reiki Manual with new symbols & explanation",
                        "Healing hand positions with affirmations",
                        "Each symbol’s detailed meaning, intention, purpose and how to apply it.",
                        "Various meditations"

                ],
                courseSchedule: [
                        "3 different pranayama/breathing techniques to calm you down. These can be used if you're feeling anxious or unsettled in the future.",
                        "Revision of Level 2 & 3A symbols",
                        "Introduction to the new symbols, what they mean, how they affect you",
                        "How to draw the new symbols, how to use them with the Level 2 Usui Reiki symbols and how apply them",
                        "Pink bubble meditation",
                        "Cho-ku-rei symbol meditation",
                        "Absentee healing: healing a person that is not present in the short & long method",
                        "Meditation for childhood to adult healing",
                        "Interaction and Q&A sessions with the instructors."

                ],
                whoIsThisCourseFor: [
                        "Anyone who has been attuned to Level 3A (ART) and completed 21 days of healing (from any teacher/institute)",
                        "Anyone looking to refresh your Karuna Reiki learnings"
                ],
                FAQs: [
                        {
                                question: "Am I ready for Karuna Reiki?",
                                answer: "If you feel you are after you have completed level 3A known as ART from us, you can take this course. The symbols in Karuna Reiki are used along with the traditional Usui symbols."
                        },
                        {
                                question: "I have already done Karuna Reiki, can I still do this course?",
                                answer: "f you have already done Karuna Reiki with us, this class is free for you to attend."
                        },
                        {
                                question: "Will I be able to understand the next level?",
                                answer: "Our instructors have been practising for the last two decades and will guide you perfectly."
                        },
                        {
                                question: "Are there any dietary restrictions before the class?",
                                answer: "No, please continue with your regular diet before the class, don’t make any sudden changes."
                        },
                ]

        },
        // {
        //         id: 5,
        //         name: 'REIKI PRACTICE & APPLICATIONS',
        //         fees: 750,
        //         fees1: 600,
        //         fees2:450,
        //         registerLink: 'https://rzp.io/l/reikipractice1',
        //         registerLink1: 'https://rzp.io/l/reikipractice2',
        //         registerLink2: 'https://rzp.io/l/reikipractice3',
        //         urlName: 'ReikiPracticeAndApplications',
        //         imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1698681556/zitg6mdxpmdgjqy5gfjd.jpg',
        //         whatWillYouLearn: [
        //                 "Learn how to practise and apply Reiki to your daily life and situations and achieve major changes in your Health, Relationships, Career,  Finance, & Happiness."
        //         ],
        //         whatCanYouDo: [
        //                 "You will be able to heal yourself using your hands. While you can heal others too, we recommend focusing just on yourself for the 21 days of healing.",
        //                 "You can cleanse your home, office, car, any environment that you frequent.",
        //             "Charge various things with Reiki such as: objects at home, food and drinks, animals, money in your wallet, almost any perceivable object."
        //         ],
        //         language: "English / Hindi",
        //         mindsetChanges: [
        //                 "I am an instrument.",
        //                 "The receiver draws the energy.",
        //             "Reiki is universal love and wisdom. It knows where and how to work.",
        //             "We need to give it without attachment and expectations.",
        //             "Let the best happen."
        //         ],
        //         materialsProvided: [
        //                 "Youtube link for Reiki music with a 3-min bell to change position."
        //
        //         ],
        //         courseSchedule: [
        //                 "Learn to practise Reiki on 24 body positions",
        //                 "Receive Healing from Reiki Masters",
        //             "Learn Pranayam",
        //             "Learn usage of water therapy",
        //             "Mirror Work Practice",
        //             "Forgiveness Meditations",
        //             "House Cleaning Mediation",
        //             "Bubble Meditation for Protection",
        //             "Bubble Meditation Conversation",
        //             "Energy Ball"
        //
        //         ],
        //         whoIsThisCourseFor: [
        //                 "People with anxiety, stress, insomnia, migraines, back pain issues, anaemia, diabetes, blood pressure problems, thyroid issues, skin infections, urinary infections, depression, mood swings and disorders, low energy and lethargy, sensitive people. People who would like to invite change and progress into their lives."
        //         ],
        //         FAQs: [
        //                 {
        //                         question: "How long is the class?",
        //                         answer: "The class is one month and teaches you how to apply Reiki to your everyday life and situations. Every Monday to Friday from 11:00 am to 12:15 pm."
        //                 },
        //                 {
        //                         question: "When can I join the class?",
        //                         answer: "New batches start 1st, 11th, 21st of every month. You need to register three days before the date. That is, register on the 28th, 8th and 18th of every month."
        //                 },
        //                 {
        //                         question: "Will there be recording provided if I miss a class?",
        //                         answer: "Unfortunately we will not be providing recording for these sessions"
        //                 },
        //         ]
        //
        // },
        {
                id: 5,
                name: 'CRYSTAL THERAPY',
                fees: 5100,
                registerLink: 'https://rzp.io/l/CrystalClass',
                urlName: 'crystaltherapy',
                imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1698681556/zitg6mdxpmdgjqy5gfjd.jpg',
                whatWillYouLearn: [
                    "Crystal therapy is gentle non-invasive form of alternative healing that works holistically to harmonize the mind, body and emotions to help us to increase our feeling of well-being, neutralize negativity, lift depression and to help is feel stable.",
                    "Crystal therapy or crystal healing is a form of vibrational medicine. the treatment involves the application of crystals or gemstones to facilitate healing.",
                    "Crystals are powerful healing tools and you can use them to boost your natural spiritual healing power.", "You'll learn how to use crystals, energise them, program them for your intentions, store them, do grounding with crystals and heal your chakras.",
                    "After the class, you will be part of a WhatsApp group for 21 days, where your instructors and peers are available for QnA and discussions."
                ],
                whatCanYouDo: [
                        "You will be able to heal yourself using crystals.",
                        "You will be able to set clear intentions and watch them get fulfilled",
                        "You will be able to cleanse your chakras and aura."
                ],
                language: "English / Hindi",
                mindsetChanges: [
                        "I am grounded",
                        "I am safe",
                        "I am protected",
                        "I am confident",
                        "I am lucky",
                    "I am loved",
                    "My desires are good and I create them now"
                ],
                materialsProvided: [
                    "A set of 13 crystals costing Rs. 2100/- (included in the class cost)",
                    "Crystal pyramid",
                    "Crystal pencils",
                    "Crystal pendulum",
                    "Intention sheets",
                    "Affirmations for each crystal",
                    "A PDF of the crystals, their affirmations, instructions on how to use, energise, store, and do grounding with crystals."

                ],
                courseSchedule: [
                        "3 different pranayama/breathing techniques to calm you down. These can be used if you're feeling anxious or unsettled in the future.",
                        "Understanding the crystal properties",
                        "How to cleanse, energise and use crystals",
                        "Training Pendulum",
                        "Pendulum Dowsing",
                        "Setting intention on Star of David",
                        "Using intention sheets, crystal pyramid, pencils, and crystal pendulum"

                ],
                whoIsThisCourseFor: [
                        "If you’re looking for healing, therapeutic practise.",
                    "If you’re looking for answers on your spiritual journey.",
                    "Anybody!"
                ],
                FAQs: [
                        {
                                question: "How do I get the crystals?",
                                answer: "Once you register the class, we will send you a package with your crystals and a key to identifying which crystal is for what use."
                        },
                        {
                                question: "If I’m under medication can I do it?",
                                answer: "Yes, it can be practised under any circumstances. It does not affect an ongoing treatment negatively. It helps you get better. "
                        },
                        {
                                question: "Are there any side effects of practising Crystal Therapy?",
                                answer: "No. There is nothing negative that comes out of it."
                        },
                ]

        },
        // {
        //         id: 5,
        //         name: 'REIKI 3B',
        //         fees: 500,
        //         urlName: 'ReikiLevel3B',
        //         imageUrl: 'https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1654768823/HealingAndWellness/HealingModalities/vmj0zooainsfeac17taa.png',
        //         whatWillYouLearn: [],
        //         whatCanYouDo: [],
        //         language: "English / Hindi",
        //         mindsetChanges: [],
        //         materialsProvided: [],
        //         courseSchedule: [],
        //         whoIsThisCourseFor: [],
        //         FAQs: []
        //
        // },
        // {
        //         id: 6,
        //         name: 'CRYSTAL THERAPY',
        //         fees: 500,
        //         urlName: 'CrystalClass',
        //         imageUrl: '',
        //         whatWillYouLearn: [],
        //         whatCanYouDo: [],
        //         language: "English / Hindi",
        //         mindsetChanges: [],
        //         materialsProvided: [],
        //         courseSchedule: [],
        //         whoIsThisCourseFor: [],
        //         FAQs: []
        //
        // }
];
