import React, {useEffect, useState} from 'react';
import {isAuthenticated} from "../../auth";
import {getAllDonations} from "./AdminApi";
import moment from "moment";
import { CSVLink } from "react-csv";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Donations = () => {

    const {user, token} = isAuthenticated();
    const [donations, setDonations] = useState([]);

    const loadAllDonations = () => {
        getAllDonations(user._id, token)
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                } else {
                    setDonations(data)
                }
            })
    };

    useEffect(() => {
        loadAllDonations();
    }, []);

    const renderHeader = () => {
        let headerElement = ['ID', 'Date', 'Name', 'Email', 'Phone Number', 'Pan card number', 'Amount', 'Payment ID'];

        return headerElement.map((key, index) => {
            return <th key={index}>{key}</th>
        })
    };

    const renderBody = () => {
        return donations && donations.map((d, i) => {
            return (
                <tr key={i}>
                    <td>{i+1}</td>
                    <td>{moment(d.createdAt).format('Do MMMM YYYY')}</td>
                    <td>{d.name}</td>
                    <td>{d.email}</td>
                    <td>{removePhoneExtension(d.phone)}</td>
                    <td>{d.pancard}</td>
                    <td>{d.amount}</td>
                    <td>{d.payment_id}</td>
                </tr>
            )
        })
    };

    const changeKeyObjects = (arr, replaceKeys) => {
        return arr.map(item => {
            const newItem = {};
            Object.keys(item).forEach(key => {
                newItem[replaceKeys[key]] = item[[key]];
                delete item['undefined']
            });
            return newItem;
        });
    };

    const updatedDonations = (data) => {
        data.forEach(object => {
            delete object['updatedAt'];
            delete object['__v'];
            // object.createdAt = moment(object.createdAt).format('Do MMMM YYYY')
        });
        const replaceKeys = { _id: "Id", name: "Name", createdAt: 'Date', email: 'Email', phone: 'Contact Number',
            pancard: 'Pancard Number', amount: 'Amount', payment_id: 'Payment Id'};
        const replacedArr = changeKeyObjects(data, replaceKeys);

        return replacedArr;
        // return replacedArr.map(d => {
        //     console.log(moment(d.createdAt))
        //     return {...d,
        //         // Date: moment(d.createdAt).format('Do MMMM YYYY'),
        //     };
        //
        // });
    };

    const removePhoneExtension = (phone) => {
        let finalNumber = "";
        if(phone.toString().length === 12) {
            finalNumber = parseInt(phone.toString().slice(-10))
        } else
            finalNumber = phone;
        return finalNumber;
    };

    const newArr = donations.map(d => {
        return {...d,
            createdAt: moment(d.createdAt).format('Do MMMM YYYY'),
            phone: removePhoneExtension(d.phone)
        };

    });

    const createCsvFileName = ()=> `Donations_Report_${moment().format('D.M.YYYY')}.csv`;
    const headers = [
        { label: 'Sr. No.', key: 'srno' },
        { label: 'Permanent Account Number', key: 'pancard' },
        { label: 'Receipt Number', key: 'receiptNo' },
        { label: 'Date', key: 'createdAt' },
        { label: 'Name of Donor', key: 'name' },
        { label: 'Address', key: 'address' },
        { label: 'Amount of Donation (INR)', key: 'amount' },
        { label: 'Contact Number', key: 'phone' },
        { label: 'Email', key: 'email' },
        { label: 'Payment Id', key: 'payment_id' },
        // { label: 'DB id', key: '_id' },
    ];

    let data = [];
    donations.forEach((item,i=1) => {
        data.push({
            srno: i+1,
            // _id: item._id,
            createdAt: moment(item.createdAt).format('Do MMMM YYYY'),
            phone: removePhoneExtension(item.phone),
            email: item.email,
            pancard: item.pancard,
            payment_id: item.payment_id,
            amount: item.amount,
            name: item.name
        });
    });

    const donationTable = () => (
        <div className="User-Table">

            <CSVLink
                data={data}
                headers={headers}
                filename={createCsvFileName()}
                target="_blank">
                <button className="download-button">
                    Download Excel
                </button>
            </CSVLink>


            {/*<ExcelFile element={<button className="download-button">Download as .xlsx</button>}>*/}
            {/*    <ExcelSheet data={newArr} name="Donations">*/}
            {/*        <ExcelColumn label="Date" value= "createdAt"/>*/}
            {/*        <ExcelColumn label="Name" value="name"/>*/}
            {/*        <ExcelColumn label="Amount" value="amount"/>*/}
            {/*        <ExcelColumn label="Pancard Number" value="pancard"/>*/}
            {/*        <ExcelColumn label="Phone number" value="phone"/>*/}
            {/*        <ExcelColumn label="Email" value="email"/>*/}
            {/*        <ExcelColumn label="Payment Id" value="payment_id"/>*/}
            {/*    </ExcelSheet>*/}
            {/*</ExcelFile>*/}

            <table className="user-table">
                <thead>
                <tr>{renderHeader()}</tr>
                </thead>
                <tbody>
                {renderBody()}
                </tbody>
            </table>
        </div>
    );


    return(
        <div>
            {donationTable()}
        </div>
    )
};

export default Donations;
