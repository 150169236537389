import React, {useEffect, useState} from 'react';
import Navbar from "./Navbar";
import './GetInTouch.scss';
import {isAuthenticated} from "../auth";
import emailjs from 'emailjs-com';
import Footer from "./Footer";
import {iPadCheck, phoneCheck} from "./helpers/browserCheck";

const GetInTouch = () => {

    const [values, setValues] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        success: false
    });

    const {
        name,
        email,
        phone,
        subject,
        message, success } = values;
    const { user } = isAuthenticated();

    const handleChange = field => event => {
        setValues({ ...values, [field]: event.target.value });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            from_name: isAuthenticated() ? user.firstName+' '+user.lastName : name,
            reply_to: isAuthenticated() ? user.email : email,
            subject: subject,
            message: message
        };
        emailjs.send('service_lgsq1a5', 'template_dki2s3n', templateParams, 'user_eYqUHQGXvQfYu8szF5ntW')
            .then((result) => {
                console.log(result.text);
                setValues({
                    ...values,
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                    success: true
                });
            }, (error) => {
                console.log(error.text);
            });
    };

    const showSuccess = () => (
        <div className="alert alert-info" style={{ display: success ? '' : 'none' }}>
            Your message has been sent! We'll get back to you as soon as possible.
        </div>
    );

    const showContactForm = () => (
        <form className="contact-form" onSubmit={handleSubmit}>
            {showSuccess()}

            {/*{console.log(user)}*/}
            <div className="field">
                <input type="text" name="fullname" id="fullname" placeholder="Enter your email" onChange={handleChange('name')} value={isAuthenticated() ? user.firstName+' '+user.lastName : name}/>
                <label htmlFor="fullname">Full name</label>
            </div>
            <div className="field">
                <input type="email" name="email" id="email" placeholder="Enter your email" onChange={handleChange('email')} value={isAuthenticated() ? user.email : email}/>
                <label htmlFor="email">Email</label>
            </div>
            <div className="field">
                <input type="number" name="phone" id="phone" placeholder="Enter your phone number" onChange={handleChange('phone')} value={isAuthenticated() ? user.callNumber : phone}/>
                <label htmlFor="phone">Phone number</label>
            </div>
            <div className="field">
                <input type="text" name="subject" id="subject" placeholder="Enter the subject" onChange={handleChange('subject')} value={subject}/>
                <label htmlFor="subject">Subject</label>
            </div>
            <div className="field">
                <textarea name="message" id="message" placeholder="Type your message here..."  onChange={handleChange('message')} value={message}/>
                {/*<input type="text" name="subject" id="subject" placeholder="Enter the subject" onChange={handleChange('subject')} value={subject}/>*/}
                <label htmlFor="message">Message</label>
            </div>

            {/*<div>*/}
            {/*    <input type="text" placeholder="Full Name" className="text-input" onChange={handleChange('name')} value={isAuthenticated() ? user.firstName+' '+user.lastName : name} />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <input type="text" placeholder="Email" className="text-input" onChange={handleChange('email')} value={isAuthenticated() ? user.email : email} />*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*    <input type="number" placeholder="Phone" className="text-input" onChange={handleChange('phone')} value={isAuthenticated() ? user.callNumber : phone} />*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    <input type="text" placeholder="Subject" className="text-input msg" onChange={handleChange('subject')} value={subject}/>*/}
            {/*</div>*/}

            {/*<div>*/}
            {/*    <textarea placeholder="Type your message here..." className="text-input msg" onChange={handleChange('message')} value={message}/>*/}
            {/*</div>*/}
            <div className="button-container">
                <button type="submit" className="button">Submit</button>
            </div>
        </form>
    );

    return(
        <div className="GetInTouch">
            <Navbar/>
            <div className={phoneCheck() ? "contact-container-mobile" : iPadCheck() ? "contact-container-mobile" : "contact-container"}>
                <img src="https://res.cloudinary.com/dishaa-pratishthaan/image/upload/v1650637639/Contact-page/kj2ljafh83nr8krwws10.jpg" />
                <div className="info">
                    <p className="info-title">We'd love to hear from you!</p>
                    { showContactForm()}
                    {/*<p>63/103 Kachnar, Vasant Vihar, Thane West - 400602</p>*/}
                    {/*<p>hellodishaa@gmail.com</p>*/}
                    {/*<p>+91 91528 43218</p>*/}
                </div>

            </div>
            <div className="address-container">
                <div className="title">GET IN TOUCH</div>
                <p className="intro-text">Dishaa Pratishthaan is a registered non-profit charitable organisation under Section 80g of Income Tax Act 1961 of India.</p>
                <div className="address">
                    <div className="left">
                        <div className="sub-title">Address:</div>
                        <p className="body-text">63/103, Kachnar,</p>
                        <p className="body-text">Vasant Vihar Complex,</p>
                        <p className="body-text">Pokhran Rd. No. 2,</p>
                        <p className="body-text">Thane (W) - 400610</p>
                        <p className="body-text">Maharashtra, India</p>
                    </div>
                    <div className="right">
                        <div className="sub-title">Phone:</div>
                        <p className="body-text">+91 9152843218</p>
                        <div className="sub-title">Email:</div>
                        <p className="body-text">trustdishaapratishthaan@gmail.com</p>
                        <div className="sub-title">Registration no.:</div>
                        <p className="body-text">E-10282/Thane</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default GetInTouch
