
export const addItemToCart = (item, count, next) => {
    let cart = [];
    if(typeof window!== 'undefined') {
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'));
        }

        // here we are replacing the existing item in the cart,
        // there by limiting to only one item in the cart at a time.
        // cart[0] = item;

        // here we were adding to the cart while keeping the previous items
        // cart.push({
        //     ...item, count: count
        // });

        // remove duplicates
        // build an Array from new Set and turn it back into array using Array.from
        // so that later we can re-map it
        // new set will only allow unique values in it
        // so pass the ids of each object/product
        // If the loop tries to add the same value again, it'll get ignored
        // ...with the array of ids we got on when first map() was used
        // run map() on it again and return the actual product from the cart
        // cart = Array.from(new Set(cart.map((p) => (p._id)))).map((id)=>{
        //     return cart.find(p => p._id === id);
        // });

        // https://javascript.tutorialink.com/how-to-update-cart-quantity-if-item-already-exist-in-cart-reactjs/
        const check_index = cart.findIndex((elem) => elem._id === item._id);
        if (check_index !== -1) {
            // console.log("cart item: ",cart[check_index])
            cart[check_index].count = cart[check_index].count + count;
        } else {
            cart.push({...item, count: count});
        }

        localStorage.setItem('cart', JSON.stringify(cart));
        next();
    }
};

export const addItemWithVariantToCart = (item, count, color, next) => {
    let cart = [];
    if(typeof window!== 'undefined') {
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'));
        }

        // here we are replacing the existing item in the cart,
        // there by limiting to only one item in the cart at a time.
        // cart[0] = item;

        // here we were adding to the cart while keeping the previous items
        // cart.push({
        //     ...item, count: count
        // });

        // remove duplicates
        // build an Array from new Set and turn it back into array using Array.from
        // so that later we can re-map it
        // new set will only allow unique values in it
        // so pass the ids of each object/product
        // If the loop tries to add the same value again, it'll get ignored
        // ...with the array of ids we got on when first map() was used
        // run map() on it again and return the actual product from the cart
        // cart = Array.from(new Set(cart.map((p) => (p._id)))).map((id)=>{
        //     return cart.find(p => p._id === id);
        // });

        // https://javascript.tutorialink.com/how-to-update-cart-quantity-if-item-already-exist-in-cart-reactjs/
        const check_index = cart.findIndex((elem) => elem._id === item._id);
        if (check_index !== -1) {
            // console.log("cart item: ",cart[check_index])
            cart[check_index].count = cart[check_index].count + count;
        } else {
            cart.push({...item, count: count, color: color});
        }

        localStorage.setItem('cart', JSON.stringify(cart));
        next();
    }
};

export const addItemToCart1 = (item, count, color, next) => {
    let cart = [];
    if(typeof window!== 'undefined') {
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'));
        }

        // here we are replacing the existing item in the cart,
        // there by limiting to only one item in the cart at a time.
        // cart[0] = item;

        // here we were adding to the cart while keeping the previous items
        // cart.push({
        //     ...item, count: count
        // });

        // remove duplicates
        // build an Array from new Set and turn it back into array using Array.from
        // so that later we can re-map it
        // new set will only allow unique values in it
        // so pass the ids of each object/product
        // If the loop tries to add the same value again, it'll get ignored
        // ...with the array of ids we got on when first map() was used
        // run map() on it again and return the actual product from the cart
        // cart = Array.from(new Set(cart.map((p) => (p._id)))).map((id)=>{
        //     return cart.find(p => p._id === id);
        // });

        if(color === '') {
            const check_index = cart.findIndex((elem) => elem._id === item._id);
            if (check_index !== -1) {
                // console.log("cart item: ",cart[check_index])
                cart[check_index].count = cart[check_index].count + count;
            } else {
                cart.push({...item, count: count});
            }
        } else {
            const check_index = cart.findIndex((elem) => elem._id === item._id);
            if (check_index !== -1 && color === cart[check_index].color) {
                console.log("cart item: ",cart[check_index])
                cart[check_index].count = cart[check_index].count + count;
            } else {
                cart.push({...item, count: count, color: color});
            }
        }

        // https://javascript.tutorialink.com/how-to-update-cart-quantity-if-item-already-exist-in-cart-reactjs/
        // const check_index = cart.findIndex((elem) => elem._id === item._id);
        // if (check_index !== -1) {
        //     // console.log("cart item: ",cart[check_index])
        //     cart[check_index].count = cart[check_index].count + count;
        // } else {
        //     cart.push({...item, count: count, color: color});
        // }

        localStorage.setItem('cart', JSON.stringify(cart));
        next();
    }
};

export const cartTotal = () => {
    if(typeof window!== 'undefined') {
        if (localStorage.getItem('cart')) {
           return JSON.parse(localStorage.getItem('cart')).length;
        } else {
            return 0;
        }
    }
};

export const getCart = () => {
    if(typeof window!== 'undefined') {
        if (localStorage.getItem('cart')) {
            return JSON.parse(localStorage.getItem('cart'));
        } else {
            return [];
        }
    }
};

export const updateCartItem = (productId, quantity) => {
    let cart = [];
    if(typeof window!== 'undefined'){
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'))
        }

        cart.map((product, index) => {
            if(product._id === productId) {
                cart[index].count = quantity;
                //remove from cart if quantity has become 0
                if(cart[index].count === 0) {
                    const i = cart.indexOf(cart[index]);
                    if (i > -1) { // only splice array when item is found
                        cart.splice(i, 1); // 2nd parameter means remove one item only
                    }
                }
                localStorage.setItem('cart', JSON.stringify(cart));
            }
        });
    }

    if(cart.length === 0) {
        console.log('cart is empty!');
        if(localStorage.getItem('checkoutInfo')) {
            localStorage.removeItem('checkoutInfo');
        }
    }

    else
        console.log('cart is not empty');

};

export const updateCartItemForVariant = (productId, quantity, color) => {
    console.log(color)
    let cart = [];
    if(typeof window!== 'undefined'){
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'))
        }

        cart.map((product, index) => {
            if(product._id === productId && product.color === color) {
                cart[index].count = quantity;
                //remove from cart if quantity has become 0
                if(cart[index].count === 0) {
                    const i = cart.indexOf(cart[index]);
                    if (i > -1) { // only splice array when item is found
                        cart.splice(i, 1); // 2nd parameter means remove one item only
                    }
                }
                localStorage.setItem('cart', JSON.stringify(cart));
            }
        });
    }
};

export const removeCartItem = (productId) => {
    let cart = [];
    if(typeof window!== 'undefined'){
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'))
        }

        cart.map((product, index) => {
            if(product._id === productId) {
                // cart[index].count = 0
                cart.splice(index, 1);
            }
        });
        localStorage.setItem('cart', JSON.stringify(cart));
    }
    return cart;
};

export const emptyCart = next => {
    if(typeof window!== 'undefined') {
        localStorage.removeItem('cart');
        next();
    }
};

export const isItemPresentInCart = (productId) => {
    let cart = [];
    if(typeof window!== 'undefined'){
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'))
        }

        cart.map((product) => {
            console.log(product._id === productId);
            return product._id === productId;
        });
    }
};

export const getCartTotal = () => {
    let cart = [];
    let total = 0;
    if(typeof window!== 'undefined') {
        if(localStorage.getItem('cart')) {
            cart = JSON.parse(localStorage.getItem('cart'))
        }

        cart.map(item => {
            total = total + (item.count * item.price);
        });
    }
    return total;
};

export const updateCartTotal = (amount) => {
    if(typeof window!== 'undefined') {
        console.log('update total ',getCartTotal() + amount)
        return getCartTotal() + amount;
    }
};
