export const menuData = [
    {
        id: 1,
        label: 'HOME',
        url: '/',
        dropdown: false
    },
    {
        id: 2,
        label: 'ABOUT',
        url: '/about',
        dropdown: true,
        children: [
            {
                id: 2.1,
                label: 'ABOUT',
                url: '/about'
            },
            {
                id: 2.2,
                label: 'JOURNEY',
                url: '/journey'
            }
        ]
    },
    {
        id: 3,
        label: 'WHAT WE DO',
        url: '/work',
        dropdown: false
    },
    {
        id: 4,
        label: 'HEALING & WELLNESS',
        url: '/courses',
        dropdown: true,
        children: [
            {
                id: 4.1,
                label: 'INTRODUCTION',
                url: '/courses'
            },
            {
                id: 4.2,
                label: 'HEALING MODALITIES',
                url: '/healing-modalities'
            },
            {
                id: 4.3,
                label: 'GRANDMASTERS',
                url: '/know-your-grandmasters'
            },
            {
                id: 4.4,
                label: 'FREE MEDITATIONS',
                url: '/meditations'
            },
            {
                id: 4.5,
                label: 'INNER CHILD HEALING',
                url: '/inner-child-healing-program'
            }
        ]
    },
    // {
    //     label: 'SCHEDULE',
    //     url: '/schedule'
    // },
    {
        id: 5,
        label: 'SHOP',
        url: '/shop',
        dropdown: true,
        children: [
            // {
            //     id: 5.1,
            //     label: 'SHOP',
            //     url: '/shop'
            // },
            {
                id: 5.2,
                label: 'BULK ORDERS',
                url: '/bulk-orders'
            },
            {
                id: 5.3,
                label: 'ASSOCIATE BRANDS',
                url: '/associate-brands'
            }]
    },
    {
        id: 6,
        label: 'GET IN TOUCH',
        url: '/contact',
        dropdown: false
    }
];
