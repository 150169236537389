import Layout from "./Layout";
import moment from "moment";
import DisplayImage from "./DisplayImage";
import ProductCard from "./ProductCard";
import React, {useEffect, useState} from "react";
import {getCart, removeCartItem, updateCartItem, updateCartItemForVariant} from "./helpers/cart";
import {Link, Route} from "react-router-dom";
import Checkout from "./Checkout";
import './Cart.scss';
import Navbar from "./Navbar";
import { API } from "../config";
import {isAuthenticated} from "../auth";
import Footer from "./Footer";
import {check} from "./helpers/browserCheck";
import {update} from "./user/UserApi";

const Cart = () => {

    const [cart, setCart] = useState([]);
    const [run, setRun] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const { user } = isAuthenticated();

    const handleQuantityChange = hamperId => event => {
        let quant = event.target.value;
        setQuantity(quant < 1 ? 1 : quant);
        if(quant >= 1) {
            updateCartItem(hamperId, quant);
        }
    };

    const increaseQuantityStepper = (hamper, quant, variant) => {

        if(variant) {
            // all colors related stuff
            if(quant < variant.stock) {
                setQuantity(quant+1);
                // updateCartItem(hamper._id, quant+1)
                updateCartItemForVariant(hamper._id, quant+1, variant.color)
            }
        } else {
            //regular incr stepper
            if(quant < hamper.stock) {
                setQuantity(quant+1);
                updateCartItem(hamper._id, quant+1)
            }
        }
    };

    const decreaseQuantityStepper = (hamperId, quant, variant) => {
        if(variant) {
            // variant stuff
            updateCartItemForVariant(hamperId, quant-1, variant.color);
            setQuantity(quant-1);
        } else {
            // regular decr stepper
            updateCartItem(hamperId, quant-1);
            setQuantity(quant-1);
        }
        // updateCartItem(hamperId, quant-1);
        // setQuantity(quant-1);

    };

    const subtotal = (price, quant) => {
        return price*quant;
    };

    const getCartSubtotal = () => {
        return cart.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.price;
        }, 0);
    };

    const cartItemMobile = (item, index) => (
        <li className="cart-item" key={index}>
            <div className="item">
                <Link to={`/hamper/${item._id}`} className="linkToItem">
                    {/*<DisplayImage item={item} url="hamper"/>*/}
                    {item.images.length === 0 ?  <DisplayImage item={item} url="hamper"/> : <img src={item.images[0].url} />}
                </Link>
                <div className="name_price">
                    <Link to={`/hamper/${item._id}`} className="linkToItem">
                        <div>{item.name}</div>
                        <div><small>₹{item.price} each</small></div>
                        {item.color && <div><small>Color: {item.color}</small></div>}
                    </Link>

                    {item.color ? item.variants.map((v) => {
                            return item.color === v.color && <div className="quantity-stepper">
                                <button onClick={() => decreaseQuantityStepper(item._id, item.count, v)} className="button">-</button>
                                <input type="number" className="button" disabled={true} value={item.count} onChange={handleQuantityChange(item._id)}/>
                                <button onClick={() => increaseQuantityStepper(item, item.count, v)} className="button">+</button>
                            </div>
                        })
                        :
                        <div className="quantity-stepper">
                            <button onClick={() => decreaseQuantityStepper(item._id, item.count)} className="button">-</button>
                            <input type="number" className="button" disabled={true} value={item.count} onChange={handleQuantityChange(item._id)}/>
                            <button onClick={() => increaseQuantityStepper(item, item.count)} className="button">+</button>
                        </div>
                    }

                    {/*{item.stock !== 0 && <div className="quantity-stepper">*/}
                    {/*    <button onClick={() => decreaseQuantityStepper(item._id, item.count)} className="button">-</button>*/}
                    {/*    <input type="number" className="button" disabled={true} value={item.count} onChange={handleQuantityChange(item._id)}/>*/}
                    {/*    <button onClick={() => increaseQuantityStepper(item, item.count)} className="button">+</button>*/}
                    {/*</div>}*/}
                </div>

                {/*<div className="quantity-stepper">*/}
                {/*    <button onClick={() => decreaseQuantityStepper(item._id, item.count)} className="button">-</button>*/}
                {/*    <input type="number" className="button" value={item.count} onChange={handleQuantityChange(item._id)}/>*/}
                {/*    <button onClick={() => increaseQuantityStepper(item._id, item.count)} className="button">+</button>*/}
                {/*</div>*/}
                <div className="price_n_remove">
                    <b>₹{subtotal(item.price, item.count)}</b>
                    <div className="remove-cta">
                        <span onClick={() => {
                            removeCartItem(item._id);
                            setRun(!run);
                        }}>Remove</span>
                    </div>
                </div>
                {/*<div className="remove-cta">*/}
                {/*        <span onClick={() => {*/}
                {/*            removeCartItem(item._id);*/}
                {/*            setRun(!run);*/}
                {/*        }}>Remove</span>*/}
                {/*</div>*/}

            </div>
        </li>
    );

    const cartItem = (item, index) => (
        <li className="cart-item" key={index}>
            <div className="item">
                <Link to={`/hamper/${item._id}`} className="linkToItem">
                    {item.images.length === 0 ?  <DisplayImage item={item} url="hamper"/> : <img src={item.images[0].url} />}
                    {/*<DisplayImage item={item} url="hamper"/>*/}
                    <div className="name_price">
                        <div>{item.name}</div>
                        <div><small>₹{item.price} each</small></div>
                        {item.color && <div><small>Color: {item.color}</small></div>}
                    </div>
                </Link>
                {item.color ? item.variants.map((v,i) => {
                    return item.color === v.color && <div className="quantity-stepper">
                        <button onClick={() => decreaseQuantityStepper(item._id, item.count, v)} className="button">-</button>
                        <input type="number" className="button" disabled={true} value={item.count} onChange={handleQuantityChange(item._id)}/>
                        <button onClick={() => increaseQuantityStepper(item, item.count, v)} className="button">+</button>
                    </div>
                })
                :
                    <div className="quantity-stepper">
                        <button onClick={() => decreaseQuantityStepper(item._id, item.count)} className="button">-</button>
                        <input type="number" className="button" disabled={true} value={item.count} onChange={handleQuantityChange(item._id)}/>
                        <button onClick={() => increaseQuantityStepper(item, item.count)} className="button">+</button>
                    </div>
                }
                {/*{item.stock !== 0 && <div className="quantity-stepper">*/}
                {/*    <button onClick={() => decreaseQuantityStepper(item._id, item.count)} className="button">-</button>*/}
                {/*    <input type="number" className="button" disabled={true} value={item.count} onChange={handleQuantityChange(item._id)}/>*/}
                {/*    <button onClick={() => increaseQuantityStepper(item, item.count)} className="button">+</button>*/}
                {/*</div>}*/}
                <div className="price_n_remove">
                    <b>₹{subtotal(item.price, item.count)}</b>
                </div>
                <div className="remove-cta">
                        <span onClick={() => {
                            removeCartItem(item._id);
                            setRun(!run);
                        }}>Remove</span>
                </div>

            </div>
        </li>
    );

    const showItems = cart => (
        <div className="show-items">
            {/*number of items need to be TOTAL count */}
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <h2>My Cart:  <small>{cart.length} {cart.length === 1 ? 'item' : 'items'}</small></h2>
                <Link to="/shop"><div style={{color: 'black', textDecoration: 'underline'}}>Continue shopping?</div></Link>
            </div>

            <ul>
                {cart.map((item, index) => (
                    item.count > 0 && check() ? cartItemMobile(item, index) : cartItem(item, index)
                ))}
            </ul>
        </div>
    );

    const emptyCartMessage = () => (
        <div className="empty-cart-message">
            <h3>
                {cart.length} items in your cart.
            </h3>
            <p>Go ahead and add something!</p>
            <Link to="/shop">Continue shopping.</Link>
            { isAuthenticated() ? '' : showLoginMessage() }
        </div>
    );

    const showLoginMessage = () => (
        <div className="login-message">
            <span>
                <h3>Do you have items in your cart?</h3>
            <Link to="/login" className="button">Sign in to see</Link>
            </span>
        </div>
    );

    const showCartSummary = () => (
        <div className="cart-summary">
            <div className="summary-item">
                {/*number of items need to be TOTAL count */}
                <div>Subtotal <small> ({cart.length} {cart.length === 1 ? 'item' : 'items'})</small></div>
                <div>₹{getCartSubtotal()}</div>
            </div>
            <div className="summary-item">
                <div>Shipping fee will be applied at checkout.</div>
                {/*<div>Est. taxes & fees</div>*/}
                {/*<div>₹0</div>*/}
            </div>
            <div className="total">
                <div className="summary-item">
                    <div>Est. total</div>
                    <div><b>₹{getCartSubtotal()}</b></div>
                </div>
            </div>
        </div>
    );

    const showCheckoutButton = () => (
        localStorage.getItem('cart') && <Link to={isAuthenticated() ? `/checkout/shipping-details/${user._id}` : `/checkout/guest`}>
            <button className="button">Checkout</button>
        </Link>
    );

    useEffect(() => {
        setCart(getCart());
    },[run, quantity]); // removed cart from here cuz it was causing infinite loop issue!

    useEffect(() => {
        cart.map(c => {
            if(c.stock === 0) {
                removeCartItem(c._id)
            }
        })
    }, []);

    return(
        <div className="Cart">
            <Navbar/>
            {check() ?
                <div className="cart__checkout-mobile">
                    <div className="cart">
                        {cart.length > 0 ? showItems(cart) : emptyCartMessage()}
                    </div>
                    {cart.length > 0 &&
                    <div className="checkout">
                        <p>Order Summary</p>
                        <hr/>
                        {showCartSummary()}
                        {showCheckoutButton()}
                    </div>}
                </div>
                :
                <div className="cart__checkout">
                    <div className="cart">
                        {cart.length > 0 ? showItems(cart) : emptyCartMessage()}
                    </div>
                    {cart.length > 0 &&
                    <div className="checkout">
                        <p>Order Summary</p>
                        <hr/>
                        {showCartSummary()}
                        {showCheckoutButton()}
                    </div>}
                </div>
            }
            <Footer/>
        </div>
    )
};

export default Cart
