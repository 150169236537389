import React, {useEffect, useState} from "react";
import {deleteOrder, getOrdersForAdmin, getStatusValuesForOrder, updateOrderStatus} from "./AdminApi";
import {isAuthenticated} from "../../auth";
import Layout from "../Layout";
import moment from 'moment';
import {read} from "../user/UserApi";

const OrdersList = () => {

    const [orders, setOrders] = useState([]);
    const [statusValues, setSetStatusValues] = useState([]);
    const [openProducts, setOpenProducts] = useState([]);
    const {user, token} = isAuthenticated();
    const [message, setMessage] = useState('');

    const loadOrders = () => {
        getOrdersForAdmin(user._id, token)
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                } else {
                    setOrders(data)
                }
            })
    };

    const loadStatusValues = () => {
        getStatusValuesForOrder(user._id, token)
            .then(data => {
                if(data.error) {
                    console.log(data.error)
                } else {
                    setSetStatusValues(data)
                }
            })
    };

    const handleStatusChange = (e, orderId) => {
        updateOrderStatus(user._id, token, orderId, e.target.value)
            .then(data => {
                if(data.error) {
                    console.log('Status update failed',data.error)
                } else {
                    loadOrders()
                }
            })
    };

    const showStatus = o => (
        <div className="form-group">
            <h5>Status: {o.status}</h5>
            <select className="form-control" onChange={(e) => handleStatusChange(e, o._id)}>
                <option>Update status</option>
                {statusValues.map((s, i) => (
                    <option key={i} value={s}>{s}</option>
                ))}
            </select>
        </div>
    );

    const showOrdersLength = () => {
        if(orders.length > 0) {
            return (
                <h3>Total orders: {orders.length}</h3>
            )
        } else {
            return(
                <h4 className="text-danger">No orders</h4>
            )
        }
    };

    const showProduct = (key, value) => (
        <div className='input-group mb-2 mr-sm-2'>
            <div className='input-group-prepend'>
                <div className='input-group-text'>
                    {key}
                </div>
            </div>
            <input type="text" value={value} className="form-control" readOnly/>
        </div>
    );

    const toggleProducts = (orderId) => {
        if(openProducts.includes(orderId)) {
            setOpenProducts(openProducts.filter(id => id !== orderId))
        } else {
            let newOpen = [...openProducts];
            newOpen.push(orderId);
            setOpenProducts(newOpen)
        }
    };

    useEffect(() => {
        loadOrders();
        loadStatusValues();
    },[]);

    const getTime = (time) => {
        if(time !== undefined) {
            let ampm = '';
            let arr = time.split(':');
            let hh = parseInt(arr[0]);
            let h=0;
            if(hh >= 12) {
                h = hh - 12;
                ampm = 'PM';
                if(h>=12){
                    h = h - 12;
                    ampm = 'PM';
                }
            } else {
                h = hh;
                ampm = 'AM';
            }
            if(h === 0) {
                h=12;
                ampm='PM'
            }
            return h.toString()+':'+arr[1]+ampm;
        }
    };

    const showProductsInOrder = (p,i) => (
        <div className="product-in-order" key={i}>
            <div>Name: {p.name}</div>
            <div>Price: {p.price}</div>
            <div>Date: {moment(p.fromDate).format("MMM")} {moment(p.fromDate).format("Do")} - {moment(p.toDate).format("Do")}, {moment(p.fromDate).format("YYYY")}</div>
            <div>Time: {getTime(p.fromTime)} to {getTime(p.toTime)} <small>Indian Standard Time (IST)</small></div>
        </div>
    );

    const removeOrder = (orderId) => {
        deleteOrder(orderId, user._id, token)
            .then(data => {
                if (data.error) {
                    console.log(data.error);
                } else {
                    loadOrders();
                }
            });
    };

    const showOrders = (order, index) => (
        <div key={index} className="orders-list">
            <div className="order-header">
                <span>Order ID: <b>{order._id}</b></span>
                <span>Order date: <b>{moment(order.createdAt).format("MMMM Do YYYY")}</b></span>
            </div>

            <div className="order-info">
                {/*<div>Update status:*/}
                {/*    <select className="input-style" onChange={(e) => handleStatusChange(e, order._id)}>*/}
                {/*        <option>Update status</option>*/}
                {/*        {statusValues.map((s, i) => (*/}
                {/*            <option key={i} value={s}>{s}</option>*/}
                {/*        ))}*/}
                {/*    </select>*/}
                {/*</div>*/}

                {/*<div>Order date: {moment(order.createdAt).format("MMMM Do YYYY")}</div>*/}

                <div>Order placed by: {order.user.firstName} {order.user.lastName}</div>
                <div>Email: {order.user.email}</div>
                <div>Mobile Number: {order.user.callNumber}</div>
                <div>WhatsApp Number: {order.user.waNumber}</div>
                <div>Amount: ₹{order.amount}</div>
                <div>Paid via: {(order.payment_id).includes("refresher") ? 'Refresher' : (order.payment_id).includes('manual_pay') ? 'Manual Pay' : 'Razorpay'} </div>
            </div>

            <div className="products-list">
                <div className="order-header">
                <span>Total products in the order:
                    {/*{order.products.length}*/}
                </span>
                    <span onClick={() => toggleProducts(order._id)} className="down-chevron">
                    {openProducts.includes(order._id) ? <i className="fa fa-chevron-up"/> : <i className="fa fa-chevron-down"/>}
                </span>
                </div>
                {/*{console.log('products:', order.products)}*/}
                {openProducts.includes(order._id) &&
                order.products.map((p,i) => (
                    showProductsInOrder(p,i)
                ))
                }
            </div>
            <span className="delete-order">
                <button className="button" onClick={() => removeOrder(order._id)}>Delete this order</button>
            </span>
        </div>
    );

    return(
        <div className="OrdersList">

            {showOrdersLength()}
            {orders && orders.map((order, index) => (
                 showOrders(order, index)
            ))}

        </div>
    )
};

export default OrdersList
