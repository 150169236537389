import React from 'react';
import './InnerChildHealing.scss';
import Navbar from "./Navbar";
import Footer from "./Footer";
import {phoneCheck} from "./helpers/browserCheck";

const InnerChildHealing = () => {


    return(
        <div className="InnerChildHealing">
            <Navbar/>


            <h5 className={phoneCheck() ? "med-title-mobile" : "med-title"}>INNER CHILD HEALING - WEBINAR</h5>
            <p className={phoneCheck() ?"intro-mobile" : "intro"}>3-day webinar for the 30 day Inner Child Healing program</p>

            <div className={phoneCheck() ? "video-container-mobile" : "video-container"}>
                <div className="single-video">
                    <p>Day 1: Parents' behaviour & our nature</p>
                    <iframe width={phoneCheck() ? "350" : "560"} height={phoneCheck() ? "200" : "315"} src="https://www.youtube.com/embed/LC61F5VK-30?si=UWwwqGU9pWSvFOs-"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen/>
                </div>

                <div className="single-video">
                    <p>Day 2: Types of Trauma</p>
                    <iframe width={phoneCheck() ? "350" : "560"} height={phoneCheck() ? "200" : "315"} src="https://www.youtube.com/embed/vySrrcHy4Go?si=NNZjIA4arUvBtqhZ"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </div>

                <div className="single-video">
                    <p>Day 3: Triggers</p>
                    <iframe width={phoneCheck() ? "350" : "560"} height={phoneCheck() ? "200" : "315"} src="https://www.youtube.com/embed/vh-tFuuioL0?si=tCfifaMYRcPXbXAL"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen/>
                </div>
            </div>
            <Footer/>
        </div>
    )
};

export default InnerChildHealing;
