import {getCartTotal} from "./cart";

export const addShippingAddressToLocalStorage = (address, next) => {
    let checkoutInfo = {};
    let user = {};
    if(typeof window!== 'undefined') {

        if(localStorage.getItem('jwt')) {
            let auth = JSON.parse(localStorage.getItem('jwt'));
            user = auth.user;
            auth.user = user;
        }

        if(localStorage.getItem('checkoutInfo')) {
            checkoutInfo = JSON.parse(localStorage.getItem('checkoutInfo'));
            checkoutInfo = {...checkoutInfo, shippingAddress: address, user, multipleShippingAddress: []}
        } else {
            checkoutInfo = {
                shippingAddress: address, user, multipleShippingAddress: []
            }
        }

        localStorage.setItem('checkoutInfo', JSON.stringify(checkoutInfo));
        next();
    }
};

export const addBillingAddressToLocalStorage = (billingAddress, next) => {
    let checkoutInfo = {};
    let user = {};
    if(typeof window!== 'undefined') {

        if(localStorage.getItem('jwt')) {
            let auth = JSON.parse(localStorage.getItem('jwt'));
            user = auth.user;
            auth.user = user;
        }

        if(localStorage.getItem('checkoutInfo')) {
            checkoutInfo = JSON.parse(localStorage.getItem('checkoutInfo'));
            checkoutInfo = {...checkoutInfo, billingAddress}
        }

        localStorage.setItem('checkoutInfo', JSON.stringify(checkoutInfo));
        next();
    }
};

export const addDeliveryMethodToLocalStorage = (method, fee, next) => {
    let checkoutInfo = {};
    if(typeof window!== 'undefined') {

        if(localStorage.getItem('checkoutInfo')) {
            checkoutInfo = JSON.parse(localStorage.getItem('checkoutInfo'));
            checkoutInfo = {...checkoutInfo, method, total: getCartTotal() + fee}
        }

        localStorage.setItem('checkoutInfo', JSON.stringify(checkoutInfo));
        next();
    }
};

export const removeCheckoutInfoFromLocalStorage = (next) => {
    if(typeof window!== 'undefined') {
        if(localStorage.getItem('checkoutInfo'))
            localStorage.removeItem('checkoutInfo');
        next();
    }
};

export const getCheckoutTotal = () => {
    let data={};
    let total = 0;
    if (typeof window !== 'undefined') {
        if (localStorage.getItem('checkoutInfo')) {
            data = JSON.parse(localStorage.getItem('checkoutInfo'));
            total = data.total
        }
    }
    return total;
};

export const getDeliveryMethod = () => {
    let data = {};

    if (typeof window !== 'undefined') {
        if (localStorage.getItem('checkoutInfo')) {
            data = JSON.parse(localStorage.getItem('checkoutInfo'));
        }
        return data.method;
    }
};

export const getShippingFee = () => {
    return getCheckoutTotal() - getCartTotal();
};

export const saveMultipleAddressInLocalStorage = (multipleShippingAddress, next) => {
    let checkoutInfo = {};
    if(typeof window!== 'undefined') {

        if(localStorage.getItem('checkoutInfo')) {
            checkoutInfo = JSON.parse(localStorage.getItem('checkoutInfo'));
            checkoutInfo.multipleShippingAddress.push({...multipleShippingAddress})
        } else {
            checkoutInfo = {
                multipleShippingAddress: [{...multipleShippingAddress}]
            };
        }

        localStorage.setItem('checkoutInfo', JSON.stringify(checkoutInfo));
        next();
    }
};

export const getMultipleAddressesFromLocalStorage = () => {
    let obj;
    if(typeof window!== 'undefined') {
        if(localStorage.getItem('checkoutInfo')) {
            obj = JSON.parse(localStorage.getItem('checkoutInfo'))
        }
    }

    return obj.multipleShippingAddress;
};

export const getCheckoutInfoFromLocalStorage = () => {
    let checkoutInfo = {};
    if(typeof window!== 'undefined') {
        if(localStorage.getItem('checkoutInfo')) {
            checkoutInfo = JSON.parse(localStorage.getItem('checkoutInfo'))
        }
    }

    return checkoutInfo;
};
