import React from 'react';
import ReactDOM from 'react-dom';
// import Home from "./Components/Home";
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/font-awesome/css/font-awesome.css';
import 'semantic-ui-css/semantic.min.css';
import Routes from './Routes';
import UnderConstruction from "./Components/UnderConstruction";

ReactDOM.render(
    <Routes/>, document.getElementById('root')
);

