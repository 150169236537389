import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {isAuthenticated} from "../../auth";
import './Navratri.scss';
import day1hw from '../../assets/Revamp/navratri/Day 1 Homework.pdf';
import day2hw from '../../assets/Revamp/navratri/Day 2 Homework.pdf';
import day3hw from '../../assets/Revamp/navratri/Day 3 Homework.pdf';
import day4hw from '../../assets/Revamp/navratri/Homework Day 4.pdf';
import { Document, Page } from 'react-pdf';
import {check} from "../helpers/browserCheck";

const Navratri = () => {

    const { user, token } = isAuthenticated();
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const browser_history = useHistory();

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        console.log(browser_history);
    }, []);

    return(
        <div className="Navratri">
                    <div className="title">DAY 1</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/ErB9eMg8oQI?showinfo=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                        />

                        {/*<embed src={day1hw} width="400px" height="310px" />*/}
                        {/*<Document*/}
                        {/*    file={day1hw}*/}
                        {/*    onLoadSuccess={onDocumentLoadSuccess}>*/}
                        {/*    <Page pageNumber={pageNumber} />*/}
                        {/*</Document>*/}
                        {/*<p>Page {pageNumber} of {numPages}</p>*/}
                    </div>


                    <div className="title">DAY 2</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/TEYSDYuNoIw"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day2hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 3</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/vamx1Wh-TyE"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day3hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 4</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/kG956sWQC4A"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day4hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 5</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/3bFJiQ1a2d0"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day4hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 6</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/ILlvU3jFm7g"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day4hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 7</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/whYkm9j5smQ"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day4hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 8</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/Yh8BIZWzWhc"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day4hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 9</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>

                        <iframe src="https://www.youtube.com/embed/O44G4s6Hgeo?showinfo=0"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day2hw} width="400px" height="300px" />*/}
                    </div>

                    <div className="title">DAY 10</div>
                    <div className={check() ? "videos-container-mobile" : "videos-container"}>
                        <iframe src="https://www.youtube.com/embed/K-N3FA1z5bQ?showinfo=0"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>

                        {/*<embed src={day2hw} width="400px" height="300px" />*/}
                    </div>


        </div>
    )
};

export default Navratri;
