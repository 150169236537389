import React, {useState} from 'react';
import moment from "moment";
import {API} from "../../config";
import {Link, useHistory, Route} from "react-router-dom";
import {isAuthenticated} from "../../auth";
import DisplayImage from "../DisplayImage";
import DetailedOrder from "./DetailedOrder";
import PrivateRoutes from "../../auth/PrivateRoutes";
import {getShippingFee} from "../helpers/checkout";

const PurchaseHistory = ({ history }) => {

    const {user} = isAuthenticated();
    const [open, setOpen] = useState([]);
    const browser_history = useHistory();

    const toggleOpen = (pid) => {
        if(open.includes(pid)) {
            setOpen(open.filter(id => id !== pid))
        } else {
            let newOpen = [...open];
            newOpen.push(pid);
            setOpen(newOpen)
        }
    };

    const productListItem = product => (
        <div className="product-list-item">
            <span className="img-container">
                <Link to={`/hamper/${product._id}`}>
                    <DisplayImage item={product} url="hamper"/>
                </Link>
            </span>
            <div className="product-info">
                <div> <Link to={`/hamper/${product._id}`}>{product.name}</Link></div>
                <div>₹{product.price}</div>
            </div>

        </div>
    );

    const getOrderSubtotal = (order) => {
        let tot=0;
        {order.hampers.map((o,i) => {
            tot = tot + (o.price * o.count)
        })}
        console.log(tot)
        return tot;
    };

    const summary = (order) => (
        <div className="payment-summary">
            {open.includes(order._id) ?
                <div onClick={() => toggleOpen(order._id)} className="summary-cta">Hide details <i className="fa fa-chevron-up"/></div>
            :
                <div onClick={() => toggleOpen(order._id)} className="summary-cta">Show details <i className="fa fa-chevron-down"/></div>}
            {open.includes(order._id) &&
            <div className="summary-info-container">
                <h4>Order Summary</h4>
                <div className="summary-info">
                    {console.log(order)}
                    {order.products && <span>Subtotal ({order.products.length} items)</span>}
                    {order.hampers && <span>Subtotal ({order.hampers.length} items)</span>}
                    <span>₹{getOrderSubtotal(order)}</span>
                </div>
                <div className="summary-info">
                    <span>Shipping</span>
                    <span>₹{order.amount - (getOrderSubtotal(order))} </span>
                </div>
                <div className="summary-info total">
                    <span>Total</span>
                    <span>₹{order.amount}</span>
                </div>
            </div>}
        </div>
    );

    const showNoHistoryMessage = () => (
        <div className="no-history">
            <div className="">No orders have been placed with this account.</div>
            <button className="button"><Link to='/reiki'>Continue shopping</Link></button>
        </div>
    );

    const purchaseHistory = () => (
        <ul className="orders">
            {
                history.length >= 1 ?
                    [...history].reverse().map((h,i) => (
                            <li className="order" key={i}>
                                <div className="order-header">
                                    <div>
                                        <b>{moment(h.createdAt).format("MMMM Do YYYY")}</b>
                                        <span> | Order# {h._id}</span>
                                        <span> | Total ₹{h.amount}</span>
                                    </div>

                                    {/*<div className="view-details-cta">*/}
                                    {/*    <Link to={{*/}
                                    {/*        pathname: `/account/orders/${h._id}`,*/}
                                    {/*    }}>View details</Link>*/}
                                    {/*</div>*/}
                                </div>

                                { h.hampers && h.hampers.map((p,index) => (
                                    <div key={index} className="products">
                                        {productListItem(p)}
                                        {/*{summary(p, h.products.length)}*/}
                                    </div>
                                ))}
                                {summary(h)}
                            </li>

                    )) : showNoHistoryMessage()  }
        </ul>
    );

    return(
        <div className="purchase-history">

            {browser_history.location.pathname === '/account/orders' && purchaseHistory()}
            <Route path="/account/orders/:orderId" component={DetailedOrder}/>


        </div>
    );
};

export default PurchaseHistory;
