import {API} from "../../config";

export const createCategory = (userId, token, category) => {
    return fetch(`${API}/category/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(category)
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err.message)
        });
};

export const createProduct = (userId, token, product) => {
    console.log('is reach here?',product)
    return fetch(`${API}/product/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: product
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err.message)
        });
};

// move to core api
export const getCategories = () => {
    return fetch(`${API}/categories`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllUsers = () => {
    return fetch(`${API}/users`, {
        method: 'GET'
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err));
};

// export const setReikiLevelByNewUser = (level) => {
//     // console.log("api:", uId, level)
//     return fetch(`${API}/user/level/`, {
//         method: "PUT",
//         headers: {
//             Accept: 'application/json',
//             "Content-Type": 'application/json',
//             Authorization: `Bearer ${token}`
//         },
//         body: JSON.stringify({ level, userId })
//     })
//         .then(response => {
//             return response.json()
//         })
//         .catch(err => {
//             console.log(err.message)
//         });
// };

export const setReikiLevelByUser = (userId, token, level) => {
    // console.log("api:", uId, level)
    return fetch(`${API}/user/level/${userId}`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ level, userId })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err.message)
        });
};

export const setReikiLevelForUserByAdmin = (userId, uId, token, level) => {
    console.log("api:", userId, uId, level)
    return fetch(`${API}/user/${uId}/level/${userId}`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ level, uId })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err.message)
        });
};

export const setNavratriFlag = (userId, uId, token, flag) => {
    return fetch(`${API}/user/${uId}/navratri/${userId}`, {
        method: "PUT",
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ flag, uId })
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err.message)
        });
};

export const getOrdersForAdmin = (userId, token) => {
    return fetch(`${API}/orders/list/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getHamperOrdersForAdmin = (userId, token) => {
    return fetch(`${API}/hamper-orders/list/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getAllDonations = (userId, token) => {
    return fetch(`${API}/donations/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getStatusValuesForOrder = (userId, token) => {
    return fetch(`${API}/orders/status-values/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getStatusValuesForHamperOrder = (userId, token) => {
    return fetch(`${API}/hamper-orders/status-values/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateOrderStatus = (userId, token, orderId, status) => {
    return fetch(`${API}/order/${orderId}/status/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({status, orderId})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateHamperOrderStatus = (userId, token, hamperOrderId, status) => {
    return fetch(`${API}/hamper-order/${hamperOrderId}/status/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({status, hamperOrderId})
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// CRUD on products

export const getAllProducts = () => {
    return fetch(`${API}/products?limit=undefined`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteProduct = (productId, userId, token) => {
    return fetch(`${API}/product/${productId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getProduct = productId => {
    return fetch(`${API}/product/${productId}`, {
        method: 'GET'
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updateProduct = (productId, userId, token, product) => {
    return fetch(`${API}/product/${productId}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: product
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

// export const getAllReikiLevels = (userId, token) => {
//
//     return fetch(`${API}/reiki/levels/${userId}`, {
//         method: 'GET',
//         headers: {
//             Accept: 'application/json',
//             Authorization: `Bearer ${token}`
//         }
//     })
//         .then(response => {
//             return response.json();
//         })
//         .catch(err => console.log(err));
// };

export const getAllReikiLevels = () => {

    return fetch(`${API}/reiki/levels`, {
        method: 'GET',
        headers: {
            Accept: 'application/json'
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteOrder = (orderId, userId, token) => {
    return fetch(`${API}/delete/order/${orderId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const deleteHamperOrder = (hamperOrderId, userId, token) => {
    return fetch(`${API}/delete/hamper-order/${hamperOrderId}/${userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const createHamper = (userId, token, hamper) => {
    // console.log('from admin api', hamper)
    return fetch(`${API}/hamper/create/${userId}`, {
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        },
        body: hamper
    })
        .then(response => {
            return response.json()
        })
        .catch(err => {
            console.log(err.message)
        });
};
