import React, {useState} from 'react';
import Navbar from "./Navbar";
import Footer from "./Footer";
import './ForgotPassword.scss';
import {forgotPassword} from "./CoreApi";
import logo from "../assets/DishaaLogo.png";
import {check} from "./helpers/browserCheck";

const ForgotPassword = () => {

    const [values, setValues] = useState({
        email: '',
        buttonText: 'Request password rest link',
        error: ''
    });

    const { email, buttonText, error} = values;

    const handleSubmit = () => {
        forgotPassword(email)
            .then(data => {
                if (data.error) {
                    setValues({...values, error: data.error});
                } else {
                    setValues({...values, buttonText: 'Sent! Check your inbox.', email: ''});
                }
            });
    };

    const showErrors = () => (
        error && <div className="form-validation-errors">
            <p>{error}</p>
        </div>
    );

    return(
        <div className="ForgotPassword">
            <Navbar/>
            <div className={check() ? "forgot-password-mobile":"forgot-password"}>
                <div>
                    <img src={logo}
                         alt="logo"/>
                    <h3>Forgot your password? <br/>Don't worry!</h3>
                    <p>Enter your email below and we'll send a password reset link to your inbox</p>
                </div>
                <div className='text-input-container'>
                    {showErrors()}
                    <div className="field">
                        <input type="email" name="email" id="email" placeholder="Enter your email"
                            onChange={(e) => setValues({...values, email: e.target.value, error: ''})}
                               value={email}
                        />
                        <label htmlFor="email">Email</label>
                    </div>
                </div>
                <div>
                    <button type='submit' className={check() ? 'button-mobile' : 'button'} onClick={handleSubmit}>{buttonText}</button>
                </div>
            </div>

            <Footer/>
        </div>
    )
};

export default ForgotPassword;
